import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Avatar, IconButton } from "@mui/material";
import { imageURL } from "../../Constants/commonURLS";
import { deepPurple } from "@mui/material/colors";
import CompanyOverViewJobs from './CompanyOverViewJobs';

function CompanyAndJobProfileDesc({ company,compJobList,setJobId }){
    return (
        <>
        <div className="company_details_in_job_page">
        <div className="">
          <ul className="company_essential_details">
            <li>
              <div className="sidebar-text-info">
                {/* <span className="text-title">Company Name</span> */}
                <span className="comany_details_list_items">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <Avatar
                      alt="avatar"
                      src={`${imageURL}${company?.company?.company_image}`}
                      sx={{ width: 38, height: 38, bgcolor: deepPurple[500] }}
                      style={{background:"#cde2e7",border:"0.5px solid rgba(0, 0, 0, 0.25)"}}
                    >
                      <span className="profile_letters">
                        {company?.company?.company_name.charAt(0) +
                          "" +
                          company?.company?.company_name.charAt(0)}
                      </span>
                    </Avatar>
                  </IconButton>
                  <Link to={`../employer-single-list?id=${company?.id}`} style={{color:"#111111"}}>
                    {company?.company?.company_name}
                  </Link><br/>
                  {/* <a href={`../employer-single-list/${company?.id}`} style={{color:"#081753"}}>View Company Profile</a> */}
                </span>
          
              </div>
            </li>
          </ul>
        </div>
        <CompanyOverViewJobs company={company} compJobList={compJobList} setJobId={setJobId}/>
      </div>
       
        </>
    )
}

export default CompanyAndJobProfileDesc;