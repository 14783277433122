import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../../assets/componies/companyListMain.css";
import defaultCompanyImage from "../../../assets/images/company_default_img.svg";
import user_invelop from "../../../assets/images/user_invelop.svg";
import users from "../../../assets/images/users.svg";
import location from "../../../assets/images/location.svg";
// import noJobs from "../../../assets/images/no_jobs.svg";
import noJobs from "../../../assets/images/nojobsShiyft.png";
import AspectRatio from "@mui/joy/AspectRatio";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";

import Skeleton from "@mui/material/Skeleton";

function EmployerList(props) {
  const { companiesList, loading } = props;
  const navigate = useNavigate();
  const [value, setValue] = useState(3);
  function randomcolor() {
    return Math.ceil(Math.random() * 255);
  }

  return (
    <>
      <div className="content-page">
        {!loading ? (
          <>
            {/* {companiesList.length === 0 ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div className="d-flex align-items-center flex-column">
                  <img
                    src={noJobs}
                    style={{ width: "300px", height: "300px" }}
                    alt="no jobs"
                  />
                  <p>No Companies Found</p>
                </div>
              </div>
            ) : (
              ""
            )} */}
            {/* 
            <div className="employers-list  employer_list_card_cont">
              {companiesList?.data.map(item => (
                <div
                  key={item.id}
                  className="card-employers hover-up animate__fadeIn"
                >
                  <div className="align-items-center comp_card_main_cont">
                    <div className="comp_employers_logo">
                      <div className="comp_profile_card_cont">
                        <img
                          className="comp_profile_card_cont_img"
                          onError={e => {
                            e.target.src = defaultCompanyImage;
                          }}
                          alt="company-profile"
                          src={
                            item.company_image_details
                              ? item.company_image_details
                              : defaultCompanyImage
                          }
                        />
                      </div>
                    </div>
                    <div className=" employer_name_cont text-truncate">
                      <p className="cursor-pointer text-truncate employer_name">
                        <span
                          className="basic-4"
                          onClick={() =>
                            navigate(
                              `/employer-single-list/${item.company_user_details?.id}`
                            )
                          }
                        >
                          <strong className="comp_name">
                            {(item?.company_name).charAt(0).toUpperCase() +
                              (item?.company_name).slice(1)}
                          </strong>
                        </span>
                      </p>
                      <div className="d-flex comp_specs_main">
                        <span className="comp_user_cont">
                          <span>
                            <img src={users} />
                          </span>
                          <span>120</span>
                        </span>
                        <span className="comp_location_cont">
                          <span>
                            <img src={location} />
                          </span>
                          <span>Hyderabad</span>
                        </span>
                      </div>
                      <div className="d-flex comp_position_cont">
                        <span>
                          <img src={user_invelop} />
                        </span>
                        <span> &#x2022; 12 Position</span>
                      </div>
                      <div className="comp_specs_cont">
                        <span>IT Cunsulting</span>
                      </div>
                    </div>
                    <div className="comp_employers_redirection_cont">
                      <ArrowForwardIosIcon
                        onClick={() =>
                          navigate(`/employer-single-list/${item.id}`)
                        }
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div> */}

            <div className="employers-list  employer_list_card_cont">
              {companiesList?.data?.map(item => (
                <Card
                  variant="outlined"
                  orientation="horizontal"
                  // style={{display:"flex",flexDirection:"row",height:"auto",width:"auto"}}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "auto",
                    width: "auto",
                    padding: "10px",
                    fontFamily:"Inter,sans-serif"
                  }}
                  onClick={() =>
                    navigate(
                      `../employer-single-list?id=${item.company_user_details?.id}`
                    )
                  }
                  sx={{
                    width: 320,
                    gap: 1,
                    fontFamily:"Inter,sans-serif",
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                  }}
                >
                  <AspectRatio
                    ratio="1"
                    sx={{ width: 80 }}
                    style={{
                      paddingTop: "10px",
                      borderRadius: "10px",
                      marginLeft: "3px",
                      width: "60px",
                      fontFamily:"Inter,sans-serif"
                    }}
                  >
                    {item?.company_image_details ? (
                      <div className="comp_employers_logo">
                        <div className="comp_profile_card_cont">
                          <img
                            className="comp_profile_card_cont_img"
                            onError={e => {
                              e.target.src = defaultCompanyImage;
                            }}
                            alt="company-profile"
                            src={
                              item.company_image_details
                                ? item.company_image_details
                                : defaultCompanyImage
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="comp_employers_logo"
                        style={{
                          background: `rgba( ${randomcolor()},  229,  255 )`,
                        }}
                      >
                        <div className="comp_profile_card_cont2">
                          <strong style={{ fontFamily: "bolder" }}>
                            {(item?.company_name).charAt(0).toUpperCase()}
                          </strong>
                        </div>
                      </div>
                    )}
                  </AspectRatio>
                  <div style={{ paddingLeft: "13px" }}>
                    {/* <Typography
                  level="h2"
                  fontSize="lg"
                  id="card-description"
                  mb={0.5}
                  onClick={() =>
                    navigate(
                      `/employer-single-list/${item.company_user_details?.id}`
                    )}
                >
                  <Link>{(item?.company_name).charAt(0).toUpperCase() +
                              (item?.company_name).slice(1)}</Link> 
                              
                </Typography> */}
                    <Typography
                      fontWeight="md"
                      textColor="primary.plainColor"
                      mb={0.5}
                      style={{
                        cursor: "pointer",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        fontWeight: "800",
                        color:"#066a4c",
                        fontFamily:"Inter,sans-serif"
                      }}
                    
                      onClick={() =>
                        navigate(
                          // `../employer-single-list?id=${item.company_user_details?.id}`
                          `../employer-single-list?id=${item.company_user_details?.id}`
                        )
                      }
                    >
                      <a>
                        {(item?.company_name).charAt(0).toUpperCase() +
                          (item?.company_name).slice(1)}
                      </a>
                    </Typography>
                    <Typography
                      fontSize="sm"
                      aria-describedby="card-description"
                      mb={1}
                    >
                      <div className="d-flex comp_specs_main">
                        <span className="comp_user_cont">
                          <span>
                            <img src={users} />
                          </span>
                          <span>{item.size_of_company}</span>
                        </span>
                        <span
                          className="comp_location_cont"
                          style={{ paddingLeft: "" }}
                        >
                          <span>
                            <img src={location} />
                          </span>
                          <span>{item?.location?.toString().slice(0, 15)}</span>
                        </span>
                      </div>
                    </Typography>
                    <Typography
                      fontSize="sm"
                      aria-describedby="card-description"
                      mb={1}
                    >
                      <div className="d-flex comp_position_cont">
                        <span>
                          <img src={user_invelop} />
                        </span>
                        <span>
                          {" "}
                          &#x2022; {item.company_job_post_count} Position
                        </span>
                      </div>
                      {/* <div className="comp_specs_cont">
                        <span>IT Cunsulting {item?.industry}</span>
                      </div> */}

                      {item?.company_industry_details
                        ?.slice(0, 2)
                        ?.map(each => {
                          return (
                            <div className="comp_specs_cont">
                              <span>{each?.name}</span>
                            </div>
                          );
                        })}
                      <div className="comp_employers_redirection_cont"></div>
                    </Typography>
                  </div>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <div className="employers-list  employer_list_card_cont">
            {ElementsList()}
          </div>
        )}
      </div>
    </>
  );
}

export default EmployerList;

function ElementsList() {
  return (
    <>
      {Array.from(new Array(15)).map((item, index) => (
        <div className="card-employers" id="card-employers">
          <div className="align-items-center comp_card_main_cont">
            <div className="comp_employers_logo">
              <div
                className="comp_profile_card_cont"
                id="comp_profile_card_cont"
              >
                <Skeleton variant="rectangular" width={80} height={80} />
              </div>
            </div>
            <div className="employer_name_cont">
              <p className="employer_name">
                <span>
                  <strong className="comp_name">
                    <Skeleton width="100%" />
                  </strong>
                </span>
              </p>
              <div className="d-flex comp_position_cont">
                <Skeleton width="100%" />
              </div>
              <div className="comp_specs_cont">
                <Skeleton width="100%" />
              </div>
            </div>
            <div className="comp_employers_redirection_cont"></div>
          </div>
        </div>
      ))}
    </>
  );
}
