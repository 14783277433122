import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import "./leadership.css";
import LeadershipTable from "./LeadershipTable";
import ReferAndEarn from "./ReferEarnCard";
import { getLeaderShipBoardDetails } from "../../helpers/dataFetcher/profile/profile";
import { useSelector } from "react-redux";
import { imageURL } from "../../Constants/commonURLS";
import LeaderShipTable2 from './LeadershipTable2'
import ComingSoon from '../../Reusable/comingSoon';

function LeadershipBoard() {
  const user = useSelector(state => state.User.user);
  return (
    <>
      <div className="mb-3 profile_main_cont">
        <Container fluid className="chat_main_cont"  style={{ paddingRight: "0px", paddingLeft: "0px",marginTop:"30px",marginLeft:"51px" }}>
          <div className="row">
            <div className="col-sm-12 col-md-2 col-lg-2 profile_left_sidebar">
              <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
              <Sidebar />
            </div>
            </div>

            <div className="col-sm-12 col-md-10 col-lg-10 leadership_inside_main_cont">
            <ComingSoon />
              <div className="leadership_inside_part1">
              {/* <div className="leadership-bg p-4 mb-4">
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4 className="leadership-text">LeadershipBoard</h4>
                    <p className="leadership-desc">
                      Potential to earn Up to ₹82 Crore Commission
                    </p>
                  </div>
                </div>
                
              </div> */}
           

               <div className="col-sm-12 col-md-12 col-lg-12">
                    {/* <div className="leadership_self_ranks_image_cont">
                      <div>
                        <img
                          src={`${imageURL}${user.image}`}
                          onError={e => {
                            e.target.src =
                              "https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo=";
                          }}
                          alt="name"
                          className="leadership_self_ranks_image"
                        />
                      </div>
                    </div>
                    <div className="leadership_self_rank_details">
                      <h5> My Rank </h5>
                      <p>102</p>
                    </div>
                    <div className="leadership_self_rank_details">
                      <h5> Name </h5>
                      <p>{user?.user_name}</p>
                    </div>
                    <div className="leadership_self_rank_details">
                      <h5> No. of Referal </h5>
                      <p>102</p>
                    </div>
                    <div className="leadership_self_rank_details">
                      <h5> Total Earning </h5>
                      <p>102</p>
                    </div> */}
                  </div>

                  {/* <h2 className="earn_txt">My Earning</h2> */}

              {/* <div className="mb-4">
               <LeaderShipTable2 />
              </div> */}
              {/* <div>
                <ReferAndEarn />
              </div>

              <div className="border-bottom">
                <p className="question">What is the Referral Program?</p>
                <p className="mb-4">
                  An OptimHire.com Referrer is an individual or company that
                  partners with OptimHire.com to help promote our website to
                  their audience. We provide marketing banners, tracking links,
                  videos, and create exclusive promotions to help you out. As a
                  Referrer, you'll earn a revenue share of the Referral Fee
                  after a successful hire.
                </p>

                <p className="question"> How much Referral can I earn?</p>
                <p className="mb-4">
                  Signing a new client or candidate: You will earn 25% of the
                  revenue that Optimhire makes as your commission on all
                  successful hires, within six months from the date of signup
                  via your referral link. Bring an inactive* client or
                  candidate: You will earn 12.5% of the revenue that OptimHire
                  makes as your commission on all successful hires, within three
                  months from the date of re-login at OptimHire.com via your
                  referral link. *An inactive client or candidate is an existing
                  user who hasn’t logged in the past 90 days.
                </p>
                <p className="question"> What is a Referral Fee?</p>
                <p className="mb-4">
                  For each Job posting, Client decides a Referral Fee to pay on
                  successful hire.
                </p>

                <p className="question">
                  {" "}
                  Where can I see the status of my referrals?
                </p>
                <p className="mb-4">
                  After you log in, you can go to the “My Report” tab on the
                  left-side menu.
                </p>
              </div>

              <h5 className="text-center my-4">Need Help.? Call now:</h5>
              <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="media d-flex align-items-center">
                    <img
                      className="mr-3"
                      style={{ width: "25px" }}
                      src="https://res.cloudinary.com/dt6u679fs/image/upload/v1666076351/india_zgaswr.png"
                      alt="leadership"
                    />
                    <div className="media-body">
                      <h6 className="mt-0"> +91 9123728372</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="media d-flex align-items-center">
                    <img
                      className="mr-3"
                      style={{ width: "25px" }}
                      src="https://res.cloudinary.com/dt6u679fs/image/upload/v1666076344/uk_wlmfjk.png"
                      alt="Generic placeholder"
                    />
                    <div className="media-body">
                      <h6 className="mt-0"> +44 1416 280266</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="media d-flex align-items-center">
                    <img
                      className="mr-3"
                      style={{ width: "25px" }}
                      src="https://res.cloudinary.com/dt6u679fs/image/upload/v1666076338/us_dzvm7m.png"
                      alt="Generic placeholder"
                    />
                    <div className="media-body">
                      <h6 className="mt-0"> +1 3123728372</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <p>
                    <i className="fas fa-envelope" />
                    partner@Shifyt.com
                  </p>
                </div>
              </div> */}
              </div>
            </div>
          
          </div>
        </Container>
      </div>
    </>
  );
}

export default LeadershipBoard;
