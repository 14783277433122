import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { addUserAction } from "../store/auth/actions";
import { useDispatch } from "react-redux";

const Logout = () => {
  localStorage.clear();
  const dispatch = useDispatch();
  dispatch(addUserAction({}));

  return (
    <React.Fragment>
      <Navigate to="/login" replace={true} />
    </React.Fragment>
  );
};

export default Logout;
