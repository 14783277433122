import { UPDATE_PROFILE_SIDEBAR, LOGIN_BTN_HANDLER } from "./actionTypes";

const initState = {
  tab: 0,
  locationBtn: false,
};
export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case UPDATE_PROFILE_SIDEBAR:
      return { ...state, tab: payload };
    case LOGIN_BTN_HANDLER:
      return { ...state, locationBtn: payload };
    default:
      return state;
  }
}
