import React, { useEffect, useState } from "react";
import CompanyAndJobProfile from "./CompanyAndJobProfile";
import JobDetails from "./JobDetails";
import JobDetailsPage from "./JobDetails2";
import JobInsights from "./JobInsights";
import { getJobPostById } from "../../helpers/dataFetcher/jobs/jobs";
import { useParams } from "react-router-dom";
import { getCompanyById } from "../../helpers/dataFetcher/companies";
import CompanyAndJobProfileDesc from "./CompanyAndJobProfileDesc";
import { getCompanyJobsById } from "../../helpers/dataFetcher/companies";
import ShifytLoader from "../../Loader/ZepulLoader";
import { useSelector } from "react-redux";

function BrowseJobsList() {

  const searchParams = new URLSearchParams(window.location.search);
const id = searchParams.get('id');
const userId = searchParams.get("userId")

const jobApply = useSelector((state)=>state.JobApplyStatusReducer)


// const newId = searchParams.set('id',jobId.id)

  // const { id } = useParams();

  const [Job, setJob] = useState({});
  const [company, setCompany] = useState({});
  const [compJobList,setCompJobList] = useState()
  const [loader,setLoader] = useState(true)
  const [jobId,setJobId] = useState({id:""})

  // let userId = localStorage.getItem("user_id")

  useEffect(()=>{
    dataFetcher();
  },[jobApply])

  useEffect(() => {
    window.scrollTo(0, 0);
    dataFetcher();
  }, []);

 useEffect(()=>{
  searchParams.set("id",jobId.id)
  setLoader(true)
  dataFetcher(jobId.id)
 },[jobId.id])


  async function dataFetcher(searchId,userId) {
    let profileData
    if(searchId){
      const { data } = await getJobPostById(searchId,userId);
      profileData = data
      setJob(data);
    }else {
      const { data } = await getJobPostById(id,userId);
      profileData = data
      setJob(data);
    }
    

    const { data: company_data } = await getCompanyById(profileData?.company_user_id);


    // const { data } = await getJobPostById(id);
  
    // setJob(data);
    // const { data: company_data } = await getCompanyById(data?.company_user_id);
    setCompany(company_data);
    // const CompanyJobsData = await getCompanyJobsById(data?.company_user_id)

    const CompanyJobsData = await getCompanyJobsById(profileData?.company_user_id)


    const removeCurrentCompany = CompanyJobsData?.data?.filter((each)=>each.id !== id)
  
    setCompJobList(removeCurrentCompany)
    // setLoader(!loader)
    setLoader(false)
  }

  if (loader) {
    return <ShifytLoader />; // Show the loader component while data is being fetched
  }

  return (
  
    <div className="section-box mt-50">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <JobDetailsPage jobDetails={Job} company={company}/>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30 job_company_sidebar">
            <CompanyAndJobProfileDesc company={company} compJobList={compJobList} setJobId={setJobId}/>
          </div>
        </div>
      </div>
    </div>

  );
}
export default BrowseJobsList;
