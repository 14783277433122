import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import "../header/notification.css";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PropTypes from 'prop-types';
import "./index.css";
import {
  Nav,
  NavbarContainer,
  NavItem,
  MobileIcon,
  NavMenu,
} from "./NavElements";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addUserAction } from "../../store/auth/actions";
import { imageURL } from "../../Constants/commonURLS";
import { Avatar } from "@mui/material";
import { cyan, deepPurple, grey, purple } from "@mui/material/colors";
import { IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  AttachMoneyOutlined,
  DashboardOutlined,
  Logout,
  MenuOutlined,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { toast } from "react-hot-toast";
import SingupNavs from "./singUpNavs";
import LoginNavs from "./loginNavs";
import questionMarkIcon from "../../assets/images/questionMark.png";
import ExampleNavigationMenu from "./DropDownNavbar";
// import ShifytLogo9 from '../../assets/images/ShifytLogo9.png';
// import ShifytLogo9 from '../../assets/images/Shifyt4.png';
import ShifytLogo9 from '../../assets/images/shifyt1.png';
import NotificationDrawer from "../../pages/Notifications/NotificationDrawer";
import FeatherIcon from "feather-icons-react";

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Navbar = ({}) => {

  const [drawer,setDrawer] = useState(false)
  const routes = [
    {
      id: 1,
      name: "Dashoard",
      slug: "/dashboard",
      // icon: "bx bxs-dashboard",
      icon: "fa-solid fa-home",
    },
    {
      id: 2,
      name: "Profile",
      slug: "/profile",
      // icon: "bx bxs-user",
      icon: "fa-solid fa-user-tie",
    },
    {
      id: 3,
      name: "Referred Me",
      slug: "/refered-jobs",
      // icon: "bx bxs-user-plus",
      icon: "fa-solid fa-gift",
    },
    {
      id: 3,
      name: "My Refferals",
      slug: "/my-referals",
      // icon: "bx bxs-help-circle",
      icon: "fa-solid fa-forward-fast",
    },
    {
      id: 4,
      name: "Interviews",

      // icon: "bx bxs-group",
      icon: "fa-solid fa-users-line",
      slug: "/interviews",
    },
    {
      id: 5,
      name: "Applied Jobs",

      // icon: "bx bxs-file",
      icon: "fa-regular fa-paper-plane",
      slug: "/applied-jobs",
    },
    {
      id: 6,
      name: "Saved Jobs",
      type: "all",
      type: "all",
      icon: "fa-regular fa-floppy-disk",
      slug: "/my-savedjobs",
    },
    // {
    //   id: 6,
    //   name: "Chat",

    //   // icon: "bx bxs-conversation",
    //   icon: "fa-regular fa-comment-dots",
    //   slug: "/chat",
    // },
    {
      id: 7,
      name: "Chats",

      // icon: "bx bxs-conversation",
      icon: "fa-regular fa-comment-dots",
      slug: "/demochat",
    },
    {
      id: 8,
      name: "Interviews",
      type: "all",
      type: "all",
      icon: "fa-thin fa-clipboard-question",
      slug: "/interviews",
    },
    {
      id: 9,
      name: "Leadership board",
      // icon: "bx bxs-bar-chart-alt-2",
      icon: "fa-solid fa-chess-king",
      slug: "/leadership-board",
    },
    {
      id: 10,
      name: "My Earning",

      // icon: "bx bxs-dollar-circle",
      icon: "fa-solid fa-dollar-sign",
      slug: "/myearning",
    },
    // {
    //   id: 9,
    //   name: "My Money",

    //   icon: "bx bxs-user-plus",
    //   slug: "/mymoney",
    // },
    // {
    //     id: 10,
    //     name: "Application Status",
    // icon: "bx bxs-user-plus",
    // slug: "/application-status"
    // },
    {
      id: 11,
      name: "Settings",
      type: "normal",
      icon: "fa-solid fa-gear",
      slug: "/settings",
      type: "external",
    },
    {
      id: 12,
      name: "Logout",
      // icon: "bx bx-log-out",
      icon: "fa-solid fa-arrow-right-from-bracket",
      slug: "/logout",
    },
  ];
  const sidebarMain = [
    { name: "Companies", link: "employer-list", icon: "bx bx-buildings" },
    { name: "Jobs", link: "jobs-list", icon: "bx bx-jobs" },
    { name: "About", link: "/about-us", icon: "bx bx-jobs" },
    { name: "Refer", link: "/refernow", icon: "bx bx-jobs" },
    { name: "Employers", link: "/employerpage", icon: "bx bx-jobs" },
    // { name: "blogs", link: "blogs", icon: "bx bx-file" },
    // { name: "notifications", link: "notifications", icon: "bx bx-alarm" },
  ];
  const list = anchor => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250,}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List style={{background:"#cde2e7"}}>
        <Link to="/" className="d-flex">
          <img
            alt="Shifyt"
            // src="https://res.cloudinary.com/dt6u679fs/image/upload/v1663336534/MicrosoftTeams-image_5_ngznfx.png"
            src={ShifytLogo9}
            style={{
              width: "161px",
            }}
          />
        </Link>
        {sidebarMain.map((text, index) => (
          <ListItem key={text?.name} disablePadding className="list_item_text">
            <ListItemButton to={`../${text.link}`}>
              <Link to={`../${text.link}`} style={{color:"#066a4c"}}>
                <ListItemText primary={text?.name} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List style={{background:"#cde2e7",height:"400px",overflow:"auto"}} >
        {routes.map((text, index) => (
          <ListItem key={text?.name} disablePadding className="list_item_text">
            <ListItemButton>
              <ListItemIcon style={{ color: "#4295f5" }}>
                <i className={text?.icon} style={{color:"#066a4c"}}></i>
                {/* <FeatherIcon icon={text.icon} size="14" style={{color:"#ffffff"}}/> */}
              </ListItemIcon>
              <Link to={`../${text.slug}`} style={{color:"#066a4c"}}>
                <ListItemText primary={text?.name} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const user = useSelector(state => state.User.user);
  const [scrollNav, setScrollNav] = useState(false);
  const [changeValue, setChangeValue] = useState(0);
  const changeNav = () => {
    setChangeValue(window.scrollY);
  };
  useEffect(() => {
    if (changeValue) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  },[changeValue])
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  /* scroll indicator function */
  const onScroll = (e) => {
    const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollTop = window.pageYOffset;
    const scrollFraction = scrollTop / scrollHeight;
    const scrollPercentage = scrollFraction * 100;
    setScrollPosition(scrollPercentage);
  };

  /* scroll indicator  function ends */

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    window.addEventListener("scroll", onScroll);
    return ()=> {
      window.removeEventListener("scroll",changeNav)
      window.removeEventListener("scroll", onScroll);
    }
  }, []);
  const dispatch = useDispatch();

  const data = [
    {
      id: 1,
      img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660212017/marketing-director_w9zneb.svg",
      name: "Application Sent",
      time: "23 min",
      desc: "Lorem ipsum doller samet ulharu jingat zing zing ulvaerghuta Lorem ipsum doller samet ulharu jingat zing zing ",
    },
    {
      id: 2,
      img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660211965/business-development_atj0ff.svg",
      name: "Application Viewed",
      time: "23 min",
      desc: "Lorem ipsum doller samet ulharu jingat zing zing ulvaerghuta",
    },
    {
      id: 3,
      img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660211938/testing_tpzeev.svg",
      name: "Application Downloaded",
      time: "23 min",
      desc: "Lorem ipsum doller samet ulharu jingat zing zing ulvaerghuta",
    },
    {
      id: 4,
      img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660210867/proof-reading_fqghvi.svg",
      name: "Interview Scheduled",
      time: "23 min",
      desc: "Lorem ipsum doller samet ulharu jingat zing zing ulvaerghuta",
    },
  ];

  const userNavData = [
    {
      nav: "Dashboard",
      icons: "bx bxs-dashboard",
      link: "../dashboard",
      onClick: () => {},
    },
    {
      nav: "Logout",
      icons: "bx bxs-log-in",
      link: "../logout",
      onClick: () => {
        logOut();
      },
    },
  ];
  const logOut = () => {
    localStorage.clear();
    dispatch(addUserAction({}));
    navigate("/login");
  };
  const location = useLocation().pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "simple-popover" : undefined;

  

  return (
    <>  
      <Nav
        scrollnav={scrollNav}
        path={location === '/'}
        changevalue={changeValue}
        //  style={{ background: location === '/' ? '#EFF7FF' : '#FFFFFF' }}
        // style={{ background: location === '/' ? 'var(--theme-Shifyt-bg-color)' : '#FFFFFF' }}
        // style={{ background: location === '/' ? '#FFFFFF' : '#FFFFFF' }}
        // className=" sticky-bar "
      >
         {/* <div className="scroll-indicator" style={{ width: `${scrollPosition}%` }} /> */}
        <NavbarContainer className="width_90vw">
          <div className="header-logo mt-3 nav_logo">
            <Link to="/" className="d-flex">
              <img
                alt="Shifyt"
                // src="https://res.cloudinary.com/dt6u679fs/image/upload/v1663336534/MicrosoftTeams-image_5_ngznfx.png"
                src={ShifytLogo9}
                style={{
                  width: "161px",
                  height:"40px"
                }}
              />
            </Link>
          </div>

          <NavMenu style={{marginLeft:"auto"}}>
            {user?.access ? (
              <>
                {/* <NavItem>
                  <Link className="nav_items_link" to="/">
                    <span className="header_items">Home</span>
                  </Link>
                </NavItem> */}
                <NavItem>
                  <Link className="nav_items_link" to="../jobs-list">
                    <span className="header_items">DiscoverJobs</span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav_items_link" to="../employer-list">
                    <span className="header_items"> Companies</span>
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <Link className="nav_items_link" to="../blogs">
                    <span className="header_items">Blogs</span>
                  </Link>
                </NavItem> */}
                {/* <NavItem>
                  <Link className="nav_items_link" to="/about-us">
                    <span className="header_items">About</span>
                  </Link>
                </NavItem> */}
                {/* <NavItem>
                  <Link className="nav_items_link" to="/contact-us">
                    <span className="header_items">Contact</span>
                  </Link>
                </NavItem> */}
                {/* <NavItem>
                  <Link className="nav_items_link" to="/refernow">
                    <span className="header_items">Refer</span>
                  </Link>
                </NavItem> */}
                {/* <NavItem>
                  <Link className="nav_items_link" to="/employerpage">
                    <span className="header_items">Employers</span>
                  </Link>
                </NavItem> */}

                {/* <NavItem>
                  <Link className="nav_items_link" to="/about-us">
                    <span className="header_items"><ExampleNavigationMenu /></span>
                  </Link>
                </NavItem> */}

                <NavItem>
                  {/* <div>
                    <Link
                      className="notification_cont nav_items_link"
                      to="../notification"
                    >
                      <span className="header_items"> Notifications</span>
                      <NotificationsNoneIcon/>
                    </Link>
                    <div className="navbar_popups">
                      <div className="d-flex justify-content-end m-2">
                        <Link
                          to="../notification"
                          className="noti_read_all_ink"
                        >
                          View more
                        </Link>
                      </div>
                      <ul className="list-style-type-none">
                        {data.map(item => (
                          <li
                            key={item.id}
                            className="p-3 border-bottom notification-hover cursor-pointer list-unstyled"
                          >
                            <div className="media mb-2 d-flex">
                              <img
                                className=""
                                src={item.img}
                                style={{ width: "45px", height: "45px" }}
                                alt="icon"
                              />
                              <div className="media-body ms-2 notification_body_text">
                                <p className="mb-2">{item.name}</p>
                                <p>{item.desc}</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between ms-5 notification_action_text">
                              <span className="notification-time ms-2">
                                {item.time}
                              </span>
                              <span className="notification-delete cursor-pointer">
                                Delete
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div> */}
                </NavItem>
              </>
            ) : (
              <>
                {/* <NavItem>
                  <Link className="nav_items_link" to="/">
                    <span className="header_items">Home</span>
                  </Link>
                </NavItem> */}
                <NavItem>
                  <Link className="nav_items_link" to="../jobs-list">
                    <span className="header_items">Discover Jobs</span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav_items_link" to="../employer-list">
                    <span className="header_items"> Companies</span>
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <Link className="nav_items_link" to="../blogs">
                    <span className="header_items">Blogs</span>
                  </Link>
                </NavItem> */}
                {/* <NavItem>
                  <Link className="nav_items_link" to="/refernow">
                    <span className="header_items">Refer</span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav_items_link" to="/employerpage">
                    <span className="header_items">Employers</span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav_items_link" to="../about-us">
                    <span className="header_items">About us</span>
                  </Link>
                </NavItem> */}
                
                {/* <NavItem>
                  <Link className="nav_items_link" to="/about-us">
                    <span className="header_items"><ExampleNavigationMenu /></span>
                  </Link>
                </NavItem> */}
                {/* <NavItem>
                  <Link className="nav_items_link" to="../contact-us">
                    <span className="header_items">Contact us</span>
                  </Link>
                </NavItem> */}
              </>
            )}
          </NavMenu>
          <NavMenu>
            {/*....*/}
            <NavItem>
              <div>
                  <Link
                    className="notification_cont nav_items_link"
                    to="../notification"
                  >
                    {/* <NotificationsNoneIcon /> */}
                  </Link>
                <div className="navbar_popups">
                  <div className="d-flex justify-content-end m-2">
                    <Link to="../notification" className="noti_read_all_ink">
                      View more
                    </Link>
                  </div>
                  <ul className="list-style-type-none">
                    {data.map(item => (
                      <li
                        key={item.id}
                        className="p-3 border-bottom notification-hover cursor-pointer list-unstyled"
                      >
                        <div className="media mb-2 d-flex">
                          <img
                            className=""
                            src={item.img}
                            style={{ width: "45px", height: "45px" }}
                            alt="icon"
                          />
                          <div className="media-body ms-2 notification_body_text">
                            <p className="mb-2">{item.name}</p>
                            <p>{item.desc}</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between ms-5 notification_action_text">
                          <span className="notification-time ms-2">
                            {item.time}
                          </span>
                          <span className="notification-delete cursor-pointer">
                            Delete
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
               {user?.access?<NotificationDrawer isOpen={drawer}/>:""} 
              </div>
            </NavItem>

            {!user?.access ? (
              <div className="d-flex">
                <div className="login_elements" style={{display:"flex",flexDirection:"row",gap:"5px"}}>
                  {/* {location === "/login" ? <SingupNavs /> : <LoginNavs />} */}
                  <LoginNavs />
                  <SingupNavs /> 
              
                </div>
              </div>
            ) : (
              <div>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={handleClick}
                >
                  <Avatar
                    alt="avatar"
                    src={`${imageURL}${user?.image}`}
                    // src={`${imageURL}${userDetails?.profile_image}`}
                    // onClick={handleClick}
                    // sx={{ width: 38, height: 38, bgcolor: deepPurple[500] }}
                    sx={{ width: 38, height: 38, background: "#cde2e7" }}
                  >
                    <span className="profile_letters">
                      {user.first_name?.charAt(0) +
                        "" +
                        user.last_name?.charAt(0)}
                    </span>
                  </Avatar>
                </IconButton>

                <Popover
                  id={id}
                  // sx={{
                  //   "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                  //     marginTop: "30px",
                  //     boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                  //     backdropFilter: "blur(13px)",
                  //     backgroundImage:
                  //       "linear-gradient(to right, rgb(160, 189, 220) 0%, rgb(171, 149, 216) 100%)",
                  //     border: "1px solid rgba(255, 255, 255, 0.18)",
                  //     borderRadius: "30px",
                  //   },
                  // }}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  className="profile_container"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="profile_popover">
                    <div className="pofile_header_pop">
                      <div className="d-flex align-items-center gap-2">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <Avatar
                            alt="avatar"
                            src={`${imageURL}${user?.image}`}
                            sx={{
                              width: 45,
                              height: 45,
                              // bgcolor: deepPurple[500],
                              background: "#ECECEC",
                              borderRadius: "50%",
                            }}
                          >
                            <span className="profile_letters">
                              {user.first_name?.charAt(0) +
                                "" +
                                user.last_name?.charAt(0)}
                            </span>
                          </Avatar>
                        </IconButton>
                        <div className="text_in_pop">
                          <div>
                            <Typography
                              variant="p"
                              component="p"
                              className="profile_name_normal"
                            >
                              {user?.first_name + " " + user?.last_name}
                            </Typography>{" "}
                            <Typography
                              variant="p"
                              component="p"
                              className="email_popup"
                            >
                              {user?.email}
                            </Typography>
                          </div>
                          {/* <ThemeProvider theme={theme}>
                            <Button
                              className="bnt_for_resume"
                              onClick={() => {
                                if (
                                  user?.resume !== "" &&
                                  user?.resume !== null &&
                                  user?.resume !== undefined
                                )
                                  window.open(
                                    `${imageURL}${user?.resume}`,
                                    "_blank"
                                  );
                                else {
                                  navigate("/profile");
                                  toast.error("Upload your resume first");
                                }
                              }}
                              size="small"
                              variant="outlined"
                            >
                              View Resume
                            </Button>
                          </ThemeProvider> */}
                        </div>
                      </div>
                    </div>
                    <div className="profile_header_navigators">
                      <Link
                        onClick={() => {
                          handleClose();
                        }}
                        to="/profile"
                        className="links_in_popover"
                      >
                        <DashboardOutlined fontSize="sm" />
                        <span>My Profile</span>
                      </Link>
                      <Link
                        onClick={() => {
                          handleClose();
                        }}
                        to="/myearning"
                        className="links_in_popover"
                      >
                        <AttachMoneyOutlined fontSize="sm" />

                        <span>My Earnings</span>
                      </Link>
                      <Link
                        onClick={() => {
                          handleClose();
                        }}
                        to="/myearning"
                        className="links_in_popover"
                      >
                        <AttachMoneyOutlined fontSize="sm" />
                        <span>Help Center</span>
                      </Link>

                      <Link
                        onClick={() => {
                          handleClose();
                        }}
                        to="/logout"
                        className="links_in_popover"
                      >
                        <Logout fontSize="sm" />
                        <span>Logout</span>
                      </Link>
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </NavMenu>
          <MobileIcon>
            <IconButton onClick={toggleDrawer(true)} className="hamberger_cont">
              <MenuOutlined fontSize="small" />
            </IconButton>
          </MobileIcon>
          <Drawer anchor={"left"} open={state} onClose={toggleDrawer(false)} sx={{".css-4t3x6l-MuiPaper-root-MuiDrawer-paper":{background:"#cde2e7"}}}>
            {list("left")}
          </Drawer>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: grey[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

// Navbar.propTypes = {
//   scrollNav: PropTypes.bool.isRequired,
//   changevalue: PropTypes.number.isRequired
// };
