import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function RightArrow(){
    return (
        <>
        <div style={{height:"50px",width:"50px",background:"#ffffff"}}>
        <ArrowForwardIosIcon />
        </div>
        </>
    )
}

export default RightArrow;
