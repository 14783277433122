import { POST } from "../../../Constants/methods";
import callApi from "../../callApi";

export async function verifyOTP(email, otp) {
  return await callApi("/company_authorise/verify_otp", { email, otp }, POST);
}

export async function verifyMobileOTP( otp) {
  return await callApi("/common/verify_otp", {  otp }, "PUT");
}

export async function handleResendOTP(email) {
  return await callApi(
    "/company_authorise/resend_otp",
    {
      email,
    },
    POST
  );
}
