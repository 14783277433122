import React from 'react';
import Skeleton from "@mui/material/Skeleton";

function CompanySkeletion(){
    return (
        <>
          {Array.from(new Array(15)).map((item, index) => (
        <div className="card-employers" id="card-employers">
          <div className="align-items-center comp_card_main_cont">
            <div className="comp_employers_logo">
              <div
                className="comp_profile_card_cont"
                id="comp_profile_card_cont"
              >
                <Skeleton variant="rectangular" width={80} height={80} />
              </div>
            </div>
            <div className="employer_name_cont">
              <p className="employer_name">
                <span>
                  <strong className="comp_name">
                    <Skeleton width="100%" />
                  </strong>
                </span>
              </p>
              <div className="d-flex comp_position_cont">
                <Skeleton width="100%" />
              </div>
              <div className="comp_specs_cont">
                <Skeleton width="100%" />
              </div>
            </div>
            <div className="comp_employers_redirection_cont"></div>
          </div>
        </div>
      ))}
        </>
    )
}

export default CompanySkeletion;