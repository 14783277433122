import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
  SecondaryTypography
} from "../../../assets/MUI/materialUiStyles"
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";


export default function AdditionalInformation({ candidateDetails,setRecall,reCall }) {
  const [candidate, setCandidate] = useState({});
 
  useEffect(() => {
    setCandidate(candidateDetails);
  }, [candidateDetails]);

 

  

const formatSalary = (data) => {
  if (!data || data.length === 0) {
    return ''; // Return an empty string if data is not provided or is empty
  }

  const formattedData = data?.map(item => {
    const formattedSalary = `${item?.currency}-${item?.current_salary}`;
    // const formattedPay = item.pay.charAt(0).toUpperCase() + item.pay.slice(1);
    const formattedPay = item?.pay
  
    return `${formattedSalary}/${formattedPay}`;
  });

  const formattedString = formattedData.join(','); 

  return formattedString; 
}

    const formatExpSalary = (data)=>{
      if (!data || data.length === 0) {
        return ''; // Return an empty string if data is not provided or is empty
      }
    
      const formattedData = data?.map(item => {
        const formattedSalary = `${item.currency}-${item.expected_salary}`;
        const formattedPay = item?.pay
      
        return `${formattedSalary}/${formattedPay}`;
      });
    
      const formattedString = formattedData.join(','); 
    
      return formattedString; 
    }

  return (
    <Stack spacing={2} className="mt-3">
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px !important",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
       <SecondaryTypography component="h3" variant="h3">
          Additional Information
        </SecondaryTypography>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={1}>
         
            <InputButtonField
              text_field={"Current salary"}
              currency_field={"currency"}
              work_field={"worktype"}
              pay_field = {"pay"}
              input_value={formatSalary(candidate?.current_salary)}
              // input_value={"INR-20000/Weekly"}
              input_type="number"
              type="salary"
              update_type={"current_salary"}
              update_type2={"symbol"}
              update_type3={"pay"}
              update_type4={"work"}
              // setSalaryModel={setSalaryModel}
              // salaryModel={salaryModel}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
              preDefineValue={candidate?.current_salary?.length ? candidate?.current_salary[0]:null}
            />
            <InputButtonField
              text_field={"Expected salary"}
              currency_field={"currency"}
              work_field={"worktype"}
              pay_field = {"pay"}
              input_value={formatExpSalary(candidate?.expected_salary)}
              // input_value={"INR-30000/Weekly"}
              input_type="number"
              type="salary"
              update_type={"expected_salary"}
              update_type2={"symbol"}
              update_type3={"pay"}
              update_type4={"work"}
              // setSalaryModel={setSalaryModel}
              // salaryModel={salaryModel}
              wholeData = {candidate}
              setRecall={setRecall}
              preDefineValue={candidate?.expected_salary?.length ? candidate?.expected_salary[0]:null}
            />
            <InputButtonField
              text_field={"Notice Period"}
              input_value={candidate?.notice_period}
              input_type="number"
              type="noticeperiod"
              update_type={"notice_period"}
              wholeData = {candidate}
              setRecall={setRecall}
            />
            <InputButtonField
              text_field={"Experience"}
              input_value={candidate?.experience}
              input_type="number"
              type="experience"
              update_type={"experience"}
              wholeData = {candidate}
              setRecall={setRecall}
            />
            <InputButtonField
              text_field={"Nationality"}
              input_value={candidate?.nationality}
              input_type="number"
              type="nationality"
              update_type={"nationality"}
              wholeData = {candidate}
              setRecall={setRecall}
            />
             <InputButtonField
              text_field={"Languages"}
              input_value={candidate?.languages}
              input_type="number"
              type="languages"
              update_type={"languages"}
              wholeData = {candidate}
              setRecall={setRecall}
            />
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}