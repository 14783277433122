import React, { useState, useEffect } from "react";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Chip from "@mui/joy/Chip";
// import IconButton from '@mui/material/IconButton';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import JobTabs from "./JobTabs";
import { toast } from "react-hot-toast";
import { imageURL } from "../../Constants/commonURLS";
import { PrimaryTypography } from "../../assets/MUI/globalStyles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "copy-to-clipboard";
const options = ["Share"];

const ITEM_HEIGHT = 48;

function BrowseJobsIndex(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const API_URL = "https://Shifyt.com/api/";
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseOption = () => {
    copy(`https://shifyt.com/job?id=${props?.jobDetails?.id}`);
    // copy(`https://zepul.com/job?id=${props?.jobDetails?.id}`)
    toast.success("link copied successfully");
    setAnchorEl(null);
  };

  /* Jobs Desc & Comp Desc API Integration start @seshu */

  const [Job, setJob] = useState({});
  const [company, setCompany] = useState({});
  const [compJobList, setCompJobList] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    dataFetcher();
  }, []);
  async function dataFetcher() {
    // const { data } = await getJobPostById(props.id);
    // // setJob(data);
    // const { data: company_data } = await getCompanyById(data?.company_user_id);
    // console.log(company_data,"company_datacompany_data")
    // setCompany(company_data);
    // const CompanyJobsData = await getCompanyById(data?.company_user_id)
    // setCompJobList(CompanyJobsData?.data)
  }

  /* Jobs Desc & Comp Desc API Integrstion End @seshu */

  return (
    <>
      <div className="job_draw_description_sec1">
        <Card
          // variant="outlined"
          sx={{
            minWidth: 300,
            "--Card-radius": theme => theme.vars.radius.xs,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 1.5, gap: 1 }}>
            <div
              style={{
                height: "54px",
                width: "54px",
                borderRadius: "50%",
                border: "0.5px solid rgba(0, 0, 0, 0.25)",
              }}
            >
              <Avatar
                src={`${imageURL}${props.jobDetails?.company_details?.company_image}`}
                style={{
                  borderRadius: "50%",
                  borderColor: "rgba(0, 0, 0, 0.25)",
                  height: "100%",
                  width: "100%",
                }}
              >
                {props.jobDetails?.company_details?.company_name?.charAt(0)}
              </Avatar>
            </div>

            <Box className="job_draw_title_sec">
              <Typography fontWeight="lg" sx={{ fontFamily: "Inter" }}>
                {props.jobDetails?.job_title?.length <= 20
                  ? props.jobDetails?.job_title
                  : props.jobDetails?.job_title?.slice(0, 25) + ".."}
                <Chip
                  variant="soft"
                  color="neutral"
                  size="sm"
                  style={{
                    background: "rgba(0, 116, 86, 0.23)",
                    color: "#007456",
                    borderRadius: "8px",
                    marginLeft: "4px",
                    fontFamily: "Inter,sans-serif",
                  }}
                >
                  Urgently hiring
                </Chip>
              </Typography>
              <Typography fontWeight="lg">
                {props.jobDetails?.company_details?.company_name}
              </Typography>
            </Box>

            <IconButton
              variant="plain"
              color="neutral"
              size="sm"
              sx={{ ml: "auto" }}
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map(option => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={handleCloseOption}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Card>
      </div>
      <div className="tabs_section">
        <JobTabs
          jobDetails={props.jobDetails}
          company={props.compJobList}
          jobsList={props.jobsListBy}
        />
      </div>
    </>
  );
}

export default BrowseJobsIndex;
