import React , {useState} from 'react';
import ShifytTrust from '../Home/ZepulTrustDescription';
import Button from '@mui/joy/Button';
function EmployerPage3(){
    return (
        <>
         <div className="hire_candidates_card_section_layout">
          <section className="trust_section">
            <ShifytTrust />
          </section>
        </div>
        </>
    )
}

export default EmployerPage3;