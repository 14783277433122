import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
  PrimaryButton,
  SecondaryTypography
} from "../../../assets/MUI/materialUiStyles";
import { Avatar, CircularProgress, IconButton, Skeleton } from "@mui/material";
import { resumeParser } from "../../../helpers/dataFetcher/resume/resume_parser";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tooltip from "@mui/material/Tooltip";
import { imageURL } from "../../../Constants/commonURLS";
import FileViewer from "react-file-viewer";
import { getProfile } from "../../../helpers/dataFetcher/profile/profile";
// import SearchParamHook from "hooks/searchParams";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import { updateProfileNormal } from "../../../helpers/dataFetcher/profile/update";

export default function ResumeSummary({
  candidateDetails,
  fetchUserJobPostDetails,
  setRecall,
  reCall
}) {
  const [candidate, setCandidate] = useState({});
  const [resumeLoad, setResumeLoad] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [preview, setPreview] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [status, setStatus] = useState(false);
  const [resumePre, setResumePre] = useState();
  const [previewUrl, setPreviewUrl] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const supportedFileTypes = ["pdf", "docx", "xlsx", "csv"];
  const filePath = userDetails ? `${imageURL}${userDetails.resume_url}` : "";

  let fileType = supportedFileTypes.find(type => {
    const fileExtension = filePath.split(".").pop().toLowerCase();
    return supportedFileTypes.includes(fileExtension);
  });

  // If no supported file type is found, set a default fileType
  if (!fileType) {
    fileType = "docx";
  }

  useEffect(() => {
    dataFetcher();
  }, []);
  async function dataFetcher() {
    const { data, status } = await getProfile();
    setUserDetails(data);
    if (status === 200) {
      setStatus(true);
      setRecall(!reCall)
    }
  }

  return (
    <Stack spacing={2} className="mt-3">
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px !important",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <SecondaryTypography component="h3" variant="h3">
          Resume
        </SecondaryTypography>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={1}>
            {/* <InputButtonField
              text_field={"Country*"}
              input_value={candidate?.nationality?candidate.nationality:"India"}
              type="nationality"
              update_type={"nationality"}
            /> */}

          <div className="resume_main_cont">
                  <div className="col-sm-12 col-md-5 col-lg-5">
                    <div className="resume_url_cont">
                      {/* <p
                        style={{
                          fontSize: "10px",
                          paddingTop: "3%",
                          paddingLeft: "3%",
                        }}
                      >{`${imageURL}${userDetails?.resume_url}`}</p> */}
                      {userDetails?.resume_url ? (
                        <p
                          style={{
                            fontSize: "10px",
                            paddingTop: "3%",
                            paddingLeft: "3%",
                            wordWrap:"break-word"
                          }}
                        >{`${imageURL}${userDetails?.resume_url}`}</p>
                      ) : (
                        <p
                          style={{
                            fontSize: "10px",
                            paddingTop: "3%",
                            paddingLeft: "3%",
                          }}
                        ></p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-7 col-lg-7">
                    <div className="resume_name_cont">
                      <PrimaryButton
                        type="button"
                        variant="outlined"
                        className="user-desc"
                        style={{
                          background: "#cde2e7",
                          color: "#066a4c",
                          borderRadius: "8px",
                          height: "48px",
                          marginRight: "5%",
                          border:"0px"
                        }}
                        onClick={async () => {
                          const input_field = document.createElement("input");
                          input_field.type = "file";
                          input_field.accept = "application/*";
                          input_field.click();
                          input_field.onchange = async e => {
                            setResumeLoad(true);
                            const file = e.target.files;
                            setResumePre(
                              URL.createObjectURL(e.target.files[0])
                            );
                            setPreviewUrl(
                              URL.createObjectURL(e.target.files[0])
                            );
                          
                            const parseData = await resumeParser(
                              file[0],
                              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ilh5Zyt4flUlPklQU1MtO2oiLCJpYXQiOjE2NzAyMzY4NzN9.f86Y2GG0a9eQENcKPpfB7tZHNsNFWvuME9WbqPBfonU"
                            );
                          
                            // const skillData =
                            //   parseData?.data?.ResumeParserData?.SegregatedSkill?.map(
                            //     each => each.Skill
                            //   );
                            // const userCustomSkills =
                            //   userData?.user?.skill_details?.map(
                            //     each => each.name
                            //   );
                            // const appendedSkills = new Set([
                            //   ...skillData,
                            //   ...userCustomSkills,
                            // ]);
                      
                            // const skillUpdate = await updateSkill(appendedSkills)
                            const data = await getPreSignedURL(file);
                            const { status } = await updateProfileNormal(
                              "resume",
                              {
                                resume_file: data.replace(imageURL, ""),
                                first_name: userDetails.first_name,
                                last_name: userDetails.last_name,
                                email: userDetails.email,
                                update_type_to_send_email: "email",
                              }
                            );
                            if (status === 200) {
                              setResumeLoad(false);
                              const { data } = await getProfile();
                              setUserDetails(data);
                            } else {
                              toast.error("Something went wrong");
                            }
                          };
                        }}
                      >
                        <span>
                          {!resumeLoad ? (
                            <>
                              {/* <EditIcon fontSize="sm" /> */}
                              {userDetails?.resume_url === null
                                ? "Add"
                                : "Update"}{" "}
                              resume
                            </>
                          ) : (
                            <CircularProgress thickness={1} color="primary" />
                          )}
                        </span>
                      </PrimaryButton>

                      <Tooltip title="Preview">
                        <VisibilityOutlinedIcon
                          style={{ marginRight: "5%", size: "8" }}
                          onClick={() => {
                            setPreview(!preview);
                            // setProfileModal({
                            //   modal: "center",
                            //   type: "resumePreview",
                            //   file: resumePre,
                            // });
                          }}
                        />
                      </Tooltip>
                      {/* {preview ? (
                        <a
                          href={URL.createObjectURL(resumePre)}
                          download={resumePre.name}
                        >
                          {resumePre.name}
                        </a>
                      ) : (
                        ""
                      )} */}

                      <Tooltip title="Download">
                        <FeatherIcon
                          icon="download"
                          size="18"
                          style={{
                            color: "#000000",
                            cursor: "pointer",
                            marginRight: "5%",
                          }}
                          // onClick={() => {
                          //   window.open(
                          //     `${imageURL}${userDetails?.resume_url}`
                          //   );
                          // }}
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href = `${imageURL}${userDetails?.resume_url}`;
                            link.download = "resume.pdf";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                        />
                      </Tooltip>

                      {/* <Tooltip title="Delete">
                        <FeatherIcon
                          icon="trash-2"
                          size="14"
                          style={{ color: "#c13515", cursor: "pointer" }}
                          onClick={() => {
                            window.open(
                              `${imageURL}${userDetails?.resume_url}`
                            );
                          }}
                        />
                      </Tooltip> */}
                    </div>
                  </div>
                </div>

                {preview && (
          
          <div style={{ width: "100%", height: "350px", overflow: "auto" }}>
            <FileViewer
              fileType={fileType}
              filePath={`${imageURL}${userDetails?.resume_url}`}
            />
          </div>
        )}

          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}
