import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb(props) {
  return (
    <>
      <div className="breacrumb-cover">
        <div className="container">
          <ul className="breadcrumbs">
            <li>
              <Link to="/">
                <span>Home</span>
              </Link>
            </li>

            <li>{props.breadcrumb}</li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default Breadcrumb;
