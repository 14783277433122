import React, { useEffect, useState } from "react";
import Chip from "@mui/joy/Chip";
// import noJobs from "../../../assets/images/no_jobs.svg";
import noJobs from "../../../assets/images/nojobsShiyft.png";
import Sidebar from "../Sidebar/Sidebar";
import "./Alljobs.css";
import {
  getAllMyReferals,
  getAllSelfReferedJobs,
  getAllSelfReferedAppliedJobs,
} from "../../../helpers/dataFetcher/jobs/jobs";
import moment from "moment";
import { Skeleton } from "@mui/material";
import MyAppliedJobs from './MyAppliedJobs';
//TODO PUT A DEFAULT IMAGE AND LOADER HERE
import { styled } from "@mui/system";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/joy";
import { deepPurple } from "@mui/material/colors";
import { imageURL } from "../../../Constants/commonURLS";
import SocketConnection from "../../../hooks/socket";
function createData(name, calories, fat) {
  return { name, calories, fat };
}

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
  }
  `
);
function AppliedJobs() {
  const { lastMessage,connectionStatus } = SocketConnection();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = useState("");
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const userId = localStorage.getItem('user_id')
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [status, setStatus] = useState(false);
  const [referJob, setReferJob] = useState([]);
  useEffect(() => {
    getAllJobs();
  }, []);
  useEffect(() => {
    searchFilterData();
  }, [search]);
  // useEffect(() => {
  //   getAllAppliedJobs();
  // }, [search !== ""]);
  useEffect(() => {
    getAllJobs();
  }, [search == ""]);

  useEffect(()=>{
    getAllJobs()
  },[page])

  /*socket integration for appliedJobs page start */
  useEffect(()=>{},[connectionStatus])

  useEffect(() => {
    if (
      lastMessage?.data !== undefined ||
      lastMessage?.data !== null ||
      lastMessage?.data !== ""
    ) {
      const resultant = lastMessage?.data;
      if (resultant !== undefined) {
        const data = JSON.parse(resultant);
        if (data?.type === "kanban" && data.data?.user_id === userId) {
          getAllJobs()
        }
      }
    }
  }, [lastMessage]);

  /*socket integration for appliedJobs page End */

  async function searchFilterData() {
    const filterData = referJob.filter(item => {
      if (
        item.company_details?.company?.company_name
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        return item;
      } else if (
        item.job_post_in_user_job_post?.job_title
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        return item;
      }
    });
    setReferJob(filterData);
  }

  async function getAllJobs() {
    const { data, status } = await getAllSelfReferedJobs();
    setReferJob(data);
    if (status === 200) {
      setStatus(true);
    }
  }

  async function getAllAppliedJobs() {
    const { data, status } = await getAllSelfReferedAppliedJobs(search);
    setReferJob(data);
    if (status === 200) {
      setStatus(true);
    }
  }

  return (
    
    <div className="d-flex align-items-start gap-2 justify-content-center" style={{marginTop:"30px",marginLeft:"58px"}}>
    <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
      <Sidebar />
    </div>
    <div className="col-lg-10 col-sm-12 col-md-12 mt-4 font_inherit overflow-auto">
      {/* <div className="">
        {status ? (
          <div>              
         <MyAppliedJobs referData={referJob} handleChangePage={handleChangePage} page={page} setSearch={setSearch}/>
          </div>
        ) : (
          <>
            {Array.from(new Array(15)).map((item, index) => (
              <div className="card-employers" id="card-employers" key={index}>
                <div className="align-items-center comp_card_main_cont">
                  <div className="comp_employers_logo">
                    <div
                      className="comp_profile_card_cont"
                      id="comp_profile_card_cont"
                    >
                      <Skeleton
                        variant="rectangular"
                        width={80}
                        height={80}
                      />
                    </div>
                  </div>
                  <div className="employer_name_cont">
                    <p className="employer_name">
                      <span>
                        <strong className="comp_name">
                          <Skeleton width="100%" />
                        </strong>
                      </span>
                    </p>
                    <div className="d-flex comp_position_cont">
                      <Skeleton width="100%" />
                    </div>
                    <div className="comp_specs_cont">
                      <Skeleton width="100%" />
                    </div>
                  </div>
                  <div className="comp_employers_redirection_cont"></div>
                </div>
              </div>
            ))}
          </>
        )}
      </div> */}


      <div className="">
          {status ? (
            referJob.length > 0 ? (

                <MyAppliedJobs referData={referJob} handleChangePage={handleChangePage} page={page} setSearch={setSearch}/>
              
            ) : (
              <><div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100%",
                  width: "100%",
                  marginTop:"70px"
                }}
              >
                <div className="d-flex align-items-center flex-column">
                  <img
                    src={noJobs}
                    style={{ width: "300px", height: "300px" }}
                    alt="no jobs"
                  />
                  <p>No Jobs Found</p>
                </div>
              </div></>
            )
          ) : (
            <>
              {Array.from(new Array(15)).map((item, index) => (
                <div className="card-employers" id="card-employers" key={index}>
                  <div className="align-items-center comp_card_main_cont">
                    <div className="comp_employers_logo">
                      <div
                        className="comp_profile_card_cont"
                        id="comp_profile_card_cont"
                      >
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={80}
                        />
                      </div>
                    </div>
                    <div className="employer_name_cont">
                      <p className="employer_name">
                        <span>
                          <strong className="comp_name">
                            <Skeleton width="100%" />
                          </strong>
                        </span>
                      </p>
                      <div className="d-flex comp_position_cont">
                        <Skeleton width="100%" />
                      </div>
                      <div className="comp_specs_cont">
                        <Skeleton width="100%" />
                      </div>
                    </div>
                    <div className="comp_employers_redirection_cont"></div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>


    </div>
  </div>

  );
}

export default AppliedJobs;
