import React from "react";
import "./companyProfile.css";
import { Chip } from "@mui/joy";

function AboutEmployer(props) {
  const { company } = props;
  return (
    <>
      <div className="comp_about_top">
        <h4>Overview</h4>
        <p className="comp_details_col">{company?.company?.about}</p>
      </div>
      <div className="comp_about_website">
        <h5>Website</h5>
        <a href={`//${company?.company?.website}`} target="_blank">
          {company?.company?.website}
        </a>
      </div>
      <div className="comp_about_industry">
        <h5>Industry</h5>
        <p className="comp_details_col">Software Development</p>
      </div>
      <div className="comp_about_size">
        <h5>Company size</h5>
        <p className="comp_details_col">
          {company?.company?.size_of_company} employees
        </p>
      </div>
      {/* <div className="comp_about_founded">
        <h5>Founded</h5>
        <p className="comp_details_col">2015</p>
      </div> */}
      {/* <div className="comp_about_specialization">
        <h5>Specialties</h5>
        <p className="comp_details_col">
          enquiry management, client management, videocall, appointment
          scheduler, invoice management, conversational bot, one platform,
          smartpops, smartforms, insights, pipelines, taskmanagement, and
          5xsales
        </p>
      </div> */}
      <div className="comp_about_perks">
        <h5>Perks</h5>
        <div className="comp_about_perks_items">
          {company?.company?.perks?.map(item => (
            <Chip variant="outlined" color="neutral" size="sm">
              {item}
            </Chip>
          ))}
        </div>
      </div>
    </>
  );
}
export default AboutEmployer;
