import FeatherIcon from "feather-icons-react";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import moment from "moment";
import * as React from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { Link } from "react-router-dom";
import { resumeParser } from "../../../helpers/dataFetcher/resume/resume_parser";
import { useSelector } from "react-redux";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";

export default function LayoutModalDialog({
  show,
  onHide,
  step,
  purpose,
  setStep,
  setStatus,
  details,
  setResumeDetails,
}) {
  const user = useSelector(state => state.User.user );

  const [approved, setApproved] = React.useState(false);
  
  return (
    <React.Fragment>
      <Modal
        open={!!show}
        onClose={() => {
          onHide("");
          setStep(1);
        }}
        style={{ overflowY: "scroll", maxHeight: "100vh" }}
      >
        <ModalDialog
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center" || undefined}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Typography
            id="layout-modal-title"
            component={Link}
            level="inherit"
            to={`../job/${details?.id}`}
            className="text_headling_p d-flex align-items-center gap-2 justify-content-start"
            fontSize="1.25em"
            mb="0.25em"
          >
            <FeatherIcon icon="briefcase" size="14" />
            {details?.job_title}
          </Typography>
          {purpose === "self" ? (
            <div className="basic_details_resume">
              <Typography
                id="layout-modal-title"
                component={"span"}
                level="inherit"
                className="text_resume_info"
                fontSize="14px"
                mb="0.25em"
              >
                Continue application with current resume?
              </Typography>
              <div className="resume_file_background">
                <Typography
                  id="layout-modal-title"
                  component={Button}
                  style={{
                    background: "#cde2e7",
                    color: "#066a4c",
                  }}
                  onClick={() => {
                    setStep(3);
                    console.log(user, "user");
                    setResumeDetails({
                      first_name: user?.first_name,
                      last_name: user?.last_name,
                      location: user?.city,
                      email: user?.email,
                      skills: user?.skill_details?.map(item => ({
                        title: item.name,
                        id: item.id,
                      })),
                      phone_number: user?.phone_number,
                      dob: user?.dob,
                      work_exp: user?.work_exp_details?.map(item => ({
                        employer: item?.company,
                        title: item?.title,
                        start_date: item?.start_date,
                        end_date: item?.end_date,
                      })),
                      courses: user?.education_training_details?.map(item => ({
                        institute: item?.institute_name,
                        certificate: item?.certificate_name,
                        Specialization: "",
                        start_date: item?.start_date,
                        end_date: item?.end_date,
                      })),
                    });
                    setApproved(true);
                  }}
                  level="inherit"
                  fontSize="14px"
                  mb="0.25em"
                >
                  <FeatherIcon icon="file-text" size="14" style={{marginRight:"5px"}} />
                  {/* {user?.resume_url} */}Proceed
                </Typography>
              </div>
              or
            </div>
          ) : (
            <></>
          )}
          <CssVarsProvider>
            <Button
              variant="solid"
              style={{
                background: "#cde2e7",
                color: "#066a4c",
              }}
              onClick={() => {
                const input_field = document.createElement("input");
                input_field.type = "file";
                input_field.accept = "application/*";
                // input_field.accept = "application/json";
                input_field.click();
                input_field.onchange = async e => {
                  const file = e.target.files[0];
                  setStep(step + 3);
                  const { status, data } = await resumeParser(
                    file,
                    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ilh5Zyt4flUlPklQU1MtO2oiLCJpYXQiOjE2NzAyMzY4NzN9.f86Y2GG0a9eQENcKPpfB7tZHNsNFWvuME9WbqPBfonU"
                  );           
                
                  setStatus(status);
                
                  if (status === 200) {
                    // setStep(3);
                   console.log(data,"parseddata...")
                    setResumeDetails({
                      first_name: data?.ResumeParserData?.Name?.FirstName,
                      last_name: data?.ResumeParserData?.Name?.LastName,
                      location: data?.ResumeParserData?.CurrentLocation[0],
                      email:
                        purpose === "self"
                          ? user?.email
                          : data?.ResumeParserData?.Email[0].EmailAddress,
                      skills: [],
                      // phone_number:
                      //   data?.ResumeParserData?.PhoneNumber[0]?.Number,
                      phone_number:
                      data?.ResumeParserData?.PhoneNumber[0]?.FormattedNumber,
                      dob: data?.ResumeParserData?.DateOfBirth,
                      work_exp:
                        data?.ResumeParserData?.SegregatedExperience?.map(
                          item => ({
                            employer: item?.Employer?.EmployerName,
                            title: item?.JobProfile?.Title,
                            start_date: item?.StartDate,
                            end_date: item?.EndDate,
                          })
                        ),
                      courses:
                        data?.ResumeParserData?.SegregatedQualification?.map(
                          item => ({
                            institute: item?.Institution.Name,
                            certificate: item?.Degree?.NormalizeDegree,
                            Specialization:
                              item?.Degree?.Specialization.join(" "),
                            start_date: item?.StartDate,
                            end_date: item?.EndDate,
                          })
                        ),
                    });
                    
                    setApproved(true);
                    setStep(3);
                  }
                };
              }}
            >
              {purpose === "self" ? "Upload a new resume" : "upload resume"}
            </Button>
          </CssVarsProvider>
          <div className="d-flex justify-content-end">
            <CssVarsProvider>
              <Button
                style={{
                  background: "#ffe7bb",
                  color: "#066a4c",
                }}
                type="submit"
                className="theme_new_btn"
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                Back
              </Button>
            </CssVarsProvider>
          </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
