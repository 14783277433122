import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import BlogPic from "../../assets/images/BlogPic.png";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    "@media (min-width: 0px) and (max-width: 599px)": {
      "&.css-hfwr93-MuiGrid-root > .MuiGrid-item": {
        paddingTop: "0px !important",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
      },
      "&.css-mhc70k-MuiGrid-root>.MuiGrid-item": {
          paddingTop:"20px"
      },
      "&.css-mhc70k-MuiGrid-root": {
          padding:"10px"
      }
    },
    "&.css-hfwr93-MuiGrid-root > .MuiGrid-item": {
      paddingTop: "43px",
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
    },
  },
  root2: {
    "@media (min-width: 0px) and (max-width: 599px)": {
      "&.css-hfwr93-MuiGrid-root > .MuiGrid-item": {
        paddingTop: "0px !important",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
      },
      "&.css-mhc70k-MuiGrid-root>.MuiGrid-item": {
          paddingTop:"20px"
      },
      "&.css-mhc70k-MuiGrid-root": {
          padding:"10px"
      }
    },
    "&.css-hfwr93-MuiGrid-root > .MuiGrid-item": {
      paddingTop: "30px",
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
    },
  },
  imgBtn: {
    "&.css-57i4uo-MuiButtonBase-root": {
      height: 215,
      width: 215,
    },
  },
  head: {
    "&.css-1wniyei-MuiTypography-root": {
      fontFamily: "Inter,sans-serif !important",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      color: "#000000",
    },
  },

  rtxt: {
    "&.css-1wniyei-MuiTypography-root": {
      fontFamily: "Inter,sans-serif !important",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      color: "#000000",
      opacity: "70%",
    },
  },
  para: {
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.6)",
  },
});

function BlogsPageList() {
  const classes = useStyles();
  return (
    <>
      <section className="blog_sec2">
        <div>
          <div className="blogs_sec2_main_cont">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="blogs_sec2_inside1">
                {[0, 1, 2, 3].map((each,index) => (
                  <div className="compa_prf_cont1" key={index}>
                    <Grid container spacing={2} className={classes.root}>
                      <Grid item>
                        <ButtonBase
                          sx={{
                            width: 215,
                            height: 215,
                            "@media (max-width: 599px)": {
                              height: "auto",
                              width: 215,
                            },
                          }}
                        >
                          <Img alt="company Image" src={BlogPic} />
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={12} lg={8} sm container>
                        <Grid
                          item
                          xs
                          container
                          direction="column"
                          spacing={2}
                          className={classes.root}
                        >
                          <Grid item xs>
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              className={classes.head}
                            >
                              Digital Insurance
                            </Typography>

                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              className={classes.para}
                            >
                              Your BAE might not give you Space but after
                              reading this article you will definitely give your
                              UI objects some well-deserved Space.When we Start
                              Out as designers, spacing is Often the most
                            </Typography>

                            <div className="categories_c">
                              <Chip
                                label="Industry"
                                className={classes.para}
                                sx={{ fontFamily: "Inter,sans-serif" }}
                              />
                              <Chip label="Ecommerce" />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-5">
              <div className="blogs_sec2_inside2">
                <div className="inside2_main_sec">
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    className={classes.rtxt}
                  >
                    Recommended topics
                  </Typography>

                  <div className="reco_chips_cont">
                    <Chip label="Social Media" />
                    <Chip label="Sass Tools" />
                    <Chip label="Freelance" />
                    <Chip label="Mobile App Development" />
                    <Chip label="Ecommerce" />
                    <Chip label="Digital Marketing" />
                  </div>
                  {/* <span className="see_span">See more topics</span> */}

                  <div className="more_blogs_view">
                  <span className="see_span">See more topics</span>
                    {[0, 1, 2].map((each,index) => (
                      <Grid container spacing={2} className="extra_blogs" key={index}>
                        <Grid item container>
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            spacing={2}
                            className={classes.root2}
                          >
                            <Grid item xs>
                              <Typography
                                gutterBottom
                                variant="subtitle1"
                                className={classes.head}
                              >
                                Digital Insurance
                              </Typography>

                              <Typography
                                gutterBottom
                                variant="subtitle1"
                                className={classes.para}
                              >
                                Union oelebratec itc firot annivercary, Age of
                                Union and look at the chip': highlighta and
                                aohievementa from the lact month: at tea
                              </Typography>

                              <div className="categories_c">
                                <Chip
                                  label="Industry"
                                  className={classes.para}
                                  sx={{ fontFamily: "Inter,sans-serif" }}
                                />
                                <Chip label="Ecommerce" />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogsPageList;
