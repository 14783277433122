import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import "./login.css";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { updatePassword } from "../helpers/dataFetcher/profile/update";
import { Link, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import toast from "react-hot-toast";

function ChangePassword() {
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const changePassword = async ChangePasswordData => {
    console.log(ChangePasswordData.newPassword);
    const res = await updatePassword(
      ChangePasswordData.newPassword
    );
    const { data, status } = res
    console.log(res);
    if (status === 400) {
      {
        /*window.alert("New and old messages not be same")*/
        toast.error(data.data);
        reset()
      }
    } else if (status === 200) {
      toast.success(data.data);
      reset()
      // navigate("/");
    }
  };

  /*show & hide password start*/
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  /*show & hide password End*/

  return (
    <div className="main_container">
      <h1 className="password_heading">Password</h1>
      <p className="password_paragraph">
        Please enter your current password to change your password
      </p>
      {/* <hr /> */}
      <Box
        component="form"
        onSubmit={handleSubmit(changePassword)}
        // noValidate
        sx={{ mt: 1 }}
        className="form_container"
      >
        <div className="bank_card_cont">
          <p>Current Password</p>
          <div className="bank_input_filed_card">
            <TextField
              margin="normal"
              required
              fullWidth
              label="Old password"
              name="email"
              // type="password"
              autoComplete="password"
              // error={errors.oldPassword ? true : false}
              {...register("oldPassword", { required: true })}
              type={showOldPassword ? "text" : "password"}
              InputProps={{
                style: { borderRadius: "5px",fontFamily:"Inter,sans-serif"},
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        {/* <hr /> */}
        <div className="bank_card_cont">
          <p>New Password</p>
          <div className="bank_input_filed_card">
            <TextField
              margin="normal"
              required
              fullWidth
              label="New password"
              name="email"
              // type="password"
              autoComplete="password"
              // error={errors.oldPassword ? true : false}
              {...register("newPassword", { required: true })}
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                style: { borderRadius: "5px", minWidht: "30vw",fontFamily:"Inter,sans-serif" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        {/* <hr /> */}
        <div className="bank_card_cont">
          <p>Confirm Password</p>
          <div className="bank_input_filed_card">
            {/* <TextField
              margin="normal"
              required
              // fullWidth
              label="Confirm password"
              name="email"
              type="password"
              autoComplete="password"
              autoFocus
              // error={errors.oldPassword ? true : false}
              {...register("oldPassword", { required: true })}
              InputProps={{
                style: { borderRadius: "5px", width: "30vw" },
              }}
            /> */}

            <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm password"
              name="email"
              // type="password"
              error={errors.confirmPassword}
              helperText="Password must match"
              autoComplete="password" 
              // error={errors.oldPassword ? true : false}
              {...register("confirmPassword", { required: true,validate:(value)=>value !=="" ? value === watch("newPassword") : false })}
              type={showPassword ? "text" : "password"}
              InputProps={{
                style: { borderRadius: "5px", minWidht: "30vw",fontFamily:"Inter,sans-serif" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        {/* <hr /> */}
        <div className="d-flex justify-content-center submit-button mt-4">
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              type="submit"
              style={{
                // background: "#081753",
                // color: "#ffffff",
                background: "#cde2e7",
                color: "#066a4c",
                textTransform: "capitalize",
              }}
            >
              Update
            </Button>
          </Stack>
        </div>
      </Box>
    </div>
  );
}

export default ChangePassword;
