import React,{useState} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { ReferNowModal } from "../JobsList/Alljobs/ReferModal";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy";
import moment from "moment";
import Lists from '../JobsList/Alljobs/Lists';
import JobDescription from './JobDescription';

function JobDetailsPage({ jobDetails,company }){
    const [ReferStatus, setReferStatus] = useState({
      modal: "",
      type: "",
      action: "",
      modalType: "",
    });
    return (
        <>
        <div className='job_description_sec'>
            <JobDescription  jobDetails={jobDetails} company={company}/>
        </div>
        </>
    )
}

export default JobDetailsPage;