import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from '@mui/material/styles';
import { globalTheme } from "../../assets/MUI/globalStyles";

const useStyles = makeStyles({
 
  toggleButton: {
    
    "&.css-d9c359-MuiButtonBase-root-MuiToggleButton-root" : {
        border:0,
        textTransform:"capitalize",
        color: "rgba(0, 0, 0, 0.52)",
        fontFamily:"Inter,sans-serif",
        fontSize:"16px",
        fontWeight:500,
        padding:"8px !important",
        
    },
    "@media (min-width:420px) and (max-width:600px)": {
      "&.css-d9c359-MuiButtonBase-root-MuiToggleButton-root" : {
        border:0,
        textTransform:"capitalize",
        color: "rgba(0, 0, 0, 0.52)",
        fontFamily:"Inter,sans-serif",
        fontSize:"16px",
        fontWeight:500,
        // padding:"2.6px !important",
        height:"fit-content"
    },
    },
    "@media (min-width:300px) and (max-width:419px)": {
      "&.css-d9c359-MuiButtonBase-root-MuiToggleButton-root" : {
        border:0,
        textTransform:"capitalize",
        color: "rgba(0, 0, 0, 0.52)",
        fontFamily:"Inter,sans-serif",
        fontSize:"16px",
        fontWeight:500,
        // padding:"1.2px !important",
        height:"fit-content"
    },
    },


    "&.css-1gjgmky-MuiToggleButtonGroup-root" : {
      gap:"20px !important"
    },

    "&.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type)" : {
      borderRadius:"10px !important"
    },
   
    "&.Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",

    },
    "&.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected": {
        color: "#012582",
        backgroundColor: "none",
    },
   "&.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
       borderRadius:"10px !important"
   },
   
  }
});

 function BlogsCategoriesList() {
  const [alignment, setAlignment] = React.useState("");
  const classes = useStyles();

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <ThemeProvider theme={globalTheme}>
     <section className="blog_sec1"> 
    <ToggleButtonGroup
      // className={classes.toggleButton}
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton  value="web">
        Recruit
      </ToggleButton>
      <ToggleButton  value="android">
        Journey
      </ToggleButton>
      <ToggleButton  value="ios">
        Social Media
      </ToggleButton>
      <ToggleButton  value="Ecommerce">
       Ecommerce
      </ToggleButton>
    </ToggleButtonGroup>
    </section>
     </ThemeProvider>
  );
}

export default BlogsCategoriesList;
