import React, { useEffect, useState } from "react";
import "./Home.css";
import "./RecentJobs.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CardsCarousel from "./CardsCarousel";
import CompanyCarousal from "./CompanyCarousal";
import HireCandidates from "./HireCandidatesSection";
import HirePartners from "./HirePartners";
import PopularRoles from "./PopularRoles";
import ShifytTrust from "./ZepulTrustDescription";
import ShifytTrustDesc from "./ZepulTrustDesc";
import ShifytJobsDesc from "./ZepulJobs";
import RecentJobs from "./RecentJobs";
import defaultCompanyImage from "../../assets/images/company_default_img.svg";
import {
  findAllFuncArea,
  findAllJobs,
} from "../../helpers/dataFetcher/jobs/jobs";
import Input from "@mui/joy/Input";
import { PlacesAutocomplete } from "../../data/locationSearch";
import { PlaceAutocomplete } from "../../data/locationSearch2";
import advs_image from "../../assets/images/advs_image.png";
import { Col, Row } from "reactstrap";
import EastIcon from "@mui/icons-material/East";
import { ShifytShow } from "./ZepulShow";
import { getAllCompanies } from "../../helpers/dataFetcher/companies";
import Button from "@mui/joy/Button";
import { ArrowRightAltOutlined, Search } from "@mui/icons-material";
import { CssVarsProvider } from "@mui/joy/styles";
import HomePagePic from "../../assets/images/homepagePic.png";
import searchImg from "../../assets/images/searchImage.png";
import SocketConnection from "../../hooks/socket";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Avatar from "@mui/material/Avatar";
import Remote from "../../assets/images/remote.jpg";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import refer from "../../assets/images/refersection.png";
import CompanyListCarousal from "./CompanyListCarousal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import profileIcon from "../../assets/images/profileIcon1.png";
import Footer from "../../layouts/footer/Footer";
// import noJobs from "../../assets/images/no_jobs.svg";
import noJobs from "../../assets/images/nojobsShiyft.png";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Home3 from "../../assets/images/Home3.png";
import shifytIcon from "../../assets/images/shifytIcon.png";
import combinator from "../../assets/images/combinator.png";
import stripe from "../../assets/images/stripe.png";
import razor from "../../assets/images/razor.png";
import { useTransition, animated } from "react-spring";
import LandingImage from "../../assets/images/LandingImage.png";
// import landingPage from '../../../src/assets/images/HomeLandingPage.png';
import Reveal from "react-awesome-reveal";
import Chrono from "./chrono";
import { keyframes } from "@emotion/react";
import { useSelector } from "react-redux";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export default function HomeUI() {
  const [FunctionalArea, setFunctionalArea] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [funcLoader, setFuncLoader] = useState(true);
  const userData = useSelector(state => state.User);
  // const { lastMessage } = SocketConnection();
  const jobApply = useSelector((state)=>state.JobApplyStatusReducer)

  useEffect(()=>{
    dataFetcher();
    getCompanyList();
    // setLoading(true)
    getAllJobs({
      fn_areas: searchParams.get("q") == null ? [] : searchParams.get("q"),
    });
  },[jobApply])

  useEffect(() => {
    dataFetcher();
    getCompanyList();
    // setLoading(true)
    getAllJobs({
      fn_areas: searchParams.get("q") == null ? [] : searchParams.get("q"),
    });
    // setLoading(false);
  }, []);

  /*socket integration for Home page start */

  // useEffect(() => {
  //   if (
  //     lastMessage?.data !== undefined ||
  //     lastMessage?.data !== null ||
  //     lastMessage?.data !== ""
  //   ) {
  //     const resultant = lastMessage?.data;
  //     if (resultant !== undefined) {
  //       const data = JSON.parse(resultant);
  //       if (data?.type === "home") {
  //         dataFetcher();
  //         getCompanyList();
  //         getAllJobs({
  //           fn_areas:
  //             searchParams.get("q") == null ? [] : [searchParams.get("q")],
  //         });
  //         setLoading(false);
  //       }
  //     }
  //   }
  // }, [lastMessage]);

  /*socket integration for Home page End */

  async function getCompanyList() {
    const { data } = await getAllCompanies("", "");
    setCompanies(data?.data);
  }
  const [searchParams, _] = useSearchParams();
  useEffect(() => {
    setLoading(true);
    getAllJobs({ fn_areas: [searchParams.get("q")] });
    setLoading(false);
  }, [searchParams.get("q")]);

  async function getAllJobs({ fn_areas }) {
    // const { data } = await findAllJobs({
    //   job_post_title: "",
    //   location: "",
    //   job_type: [],
    //   experience: "",
    //   salary: [],
    //   functional_area: fn_areas,
    //   key_skills: [],
    //   offset: 0,
    //   limit: 10,
    //   work_mode:[]
    // });
    let token = await localStorage.getItem("token");

    if (token !== null) {
      const { data } = await findAllJobs({
        job_post_title: "",
        location: "",
        job_type: [],
        experience: "",
        salary: [],
        functional_area: fn_areas,
        key_skills: [],
        offset: 0,
        limit: 10,
        work_mode: [],
        candidate_id: userData?.user.id,
        token: token,
      });
      if (data) {
        setLoading(false);
      }
      setJobs(data);
    } else {
      const { data } = await findAllJobs({
        job_post_title: "",
        location: "",
        job_type: [],
        experience: "",
        salary: [],
        functional_area: fn_areas,
        key_skills: [],
        offset: 0,
        limit: 10,
        work_mode: [],
      });
      if (data) {
        setLoading(false);
      }
      setJobs(data);
    }

    // setJobs(data);
  }
  const dataFetcher = async () => {
    setFuncLoader(true);
    const { data: fn_areas } = await findAllFuncArea();
    if (fn_areas) {
      setFuncLoader(false);
    }
    setFunctionalArea(
      fn_areas?.map(item => ({
        name: item.name,
        id: item.id,
        count: item.countOfJobs,
        icon: "fas fa-envelope",
        url: `/jobs-list?fn_areas=${item.id}`,
      }))
    );
  };
  const [title, setTitle] = useState("");

  return (
    <>
      <main
        // className="main pb-4"
        // style={{ paddingLeft: "22px", overflow: "auto" }}
        style={{ overflow: "auto", marginTop: "-25px" }}
      >
        <section className="Demo_home_page">
          <div className="home_main_page">
            {/* <div className="banner-hero hero-1 home_top_cont"> */}
            <div className="hero-1 home_top_cont">
              <div className="banner-inner">
                <div className="row mt-4" style={{ width: "97vw" }}>
                  <div className="col-sm-12 col-md-12 col-lg-7">
                    <div className="block-banner">
                      <div className="home_main_container">
                        <Reveal
                          className="onStep"
                          keyframes={fadeInUp}
                          delay={0}
                          duration={900}
                          triggerOnce
                        >
                          <div className="heading_section">
                            The Most Efficient Platform to
                            <div className="span_section">
                              {"  "}Refer & Hire
                            </div>
                          </div>
                        </Reveal>
                        <Reveal
                          className="onStep"
                          keyframes={fadeInUp}
                          delay={0}
                          duration={900}
                          triggerOnce
                        >
                          <div className="heading_section">Talented People</div>
                        </Reveal>
                      </div>

                      <div className="home_jobs_search_filters  mt-4 ">
                        <div className="search_section">
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              navigate(
                                `/jobs-list?title=${title}&location=${location}`
                              );
                            }}
                          >
                            <div className="search_tabs_main_cont">
                              <div className="search_tab">
                                <p className="paragraph_ele">Location</p>
                                <div className="input_div">
                                  <PlaceAutocomplete
                                    className="home_input-search-location"
                                    location={location}
                                    setLocation={e => {
                                      setLocation(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  borderLeft: "1px",
                                  borderStyle: "solid",
                                  height: "50px",
                                  top: "10",
                                  marginTop: "15px",
                                  color: "#066a4c",
                                }}
                                className="hr_line"
                              ></div>

                              <div className="search_tab1">
                                <p className="paragraph_ele">
                                  Job Title/Company name
                                </p>
                                <div className="input_div">
                                  <input
                                    type="search"
                                    placeholder="Jobs/skills"
                                    className="input_ele"
                                    value={title}
                                    onChange={e => {
                                      setTitle(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  borderLeft: "1px",
                                  borderStyle: "solid",
                                  height: "50px",
                                  top: "10",
                                  marginTop: "15px",
                                  color: "#066a4c",
                                }}
                                className="hr_line"
                              ></div>

                              <div
                                className="search_tab1"
                                style={{ padding: "10px", marginTop: "3px" }}
                              >
                                <button
                                  className="search_btn_home"
                                  type="submit"
                                  style={{ border: "none" }}
                                  // style={{textAlign:"start",paddingLeft:"30px"}}
                                >
                                  <img
                                    src={searchImg}
                                    style={{
                                      paddingRight: "10px",
                                      color: "#024430",
                                      filter:
                                        "sepia(100) hue-rotate(152deg) saturate(5)",
                                    }}
                                  />
                                  Search Job
                                </button>
                              </div>
                            </div>

                            {/* <div className="input_div">
                              <div className="search-input-wrapper">
                                <PlaceAutocomplete
                                  className="home_input-search-location"
                                  location={location}
                                  setLocation={e => {
                                    setLocation(e);
                                  }}
                                />
                              </div>
                            </div> */}
                          </form>
                        </div>
                      </div>
                      <div className="banner-description mt-10 home_details_text">
                        Popular Searches : Backend Developer, Database
                        management
                      </div>

                      <h2 className="strtup_txt">
                        Trusted by leading Startups
                      </h2>

                      <Stack
                        direction="row"
                        spacing={2}
                        style={{ marginBottom: "20px" }}
                      >
                        <img src={combinator} className="startup_img" />
                        <img src={stripe} className="startup_img" />
                        <img src={razor} className="startup_img startup_img2" />
                      </Stack>
                    </div>
                  </div>

                  <div className="col-sm-12 col-lg-5 col-md-5 home_banner">
                    <div className="banner-imgs">
                      <div className="card text-bg-dark border-0">
                        {/* <img
                        src="assets/imgs/banner/banner-color.png"
                        className="card-img"
                        alt="banner-colors"
                      /> */}
                        <div
                          className="card-img-overlay"
                          style={{ height: "500px" }}
                        >
                          {/* <img
                            alt="jobhub"
                            // src="https://res.cloudinary.com/dt6u679fs/image/upload/v1666782413/MicrosoftTeams-image_7_q2fhku.png"
                            src={Home3}
                            className="img-responsive shape-1 home_img_card"
                          /> */}
                          <div className="lamp" style={{position:"relative",zIndex:4,top:"-93px"}}>                          
                            <img
                            src="https://civi.uxper.co/version-2-0/wp-content/uploads/sites/4/2023/07/lead.svg"
                            style={{
                              height: "20%",
                              left: "45%",
                              position: "relative",
                            }}
                          />
                          </div>

                          <img
                            decoding="async"
                            width="100%"
                            height="60%"
                            data-cfsrc="https://civi.uxper.co/version-2-0/wp-content/uploads/sites/4/2023/07/img.png"
                            class="attachment-full size-full wp-image-11238"
                            alt=""
                            srcset="https://civi.uxper.co/version-2-0/wp-content/uploads/sites/4/2023/07/img.png 979w, https://civi.uxper.co/version-2-0/wp-content/uploads/sites/4/2023/07/img-300x187.png 300w, https://civi.uxper.co/version-2-0/wp-content/uploads/sites/4/2023/07/img-768x478.png 768w"
                            sizes="(max-width: 979px) 100vw, 979px"
                            src="https://civi.uxper.co/version-2-0/wp-content/uploads/sites/4/2023/07/img.png"
                            style={{position:"absolute",top:"42px"}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-box">
          <div className="latest_jobs_cont_main">
            <div className="row home_category_head">
              <div className="col-lg-7">
                <h2 className="latest_categories_head">
                  Filter Jobs by <span>Various Job Categories</span>
                </h2>
              </div>
              <div className="col-lg-5 text-lg-end justify-content-end">
                <Button
                  variant="outlined"
                  className="apply-now-btn cursor-pointerf"
                  onClick={() => navigate("/more-fn-areas")}
                  style={{
                    // background: "#ffffff",
                    // color: "#012480",
                    background: "#cde2e7",
                    color: "#066a4c",
                    border: "1px solid #cde2e7",
                    fontFamily: "Inter,sans-serif",
                    // border: "1px solid rgba(25, 103, 210, 0.23)",
                    fontWeight: "400",
                    fontSize: "16px",
                    marginRight: "23px",
                    borderRadius: "24px",
                  }}
                >
                  Show All <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
                </Button>
              </div>
            </div>

            {funcLoader ? (
              <>
                {" "}
                <Stack
                  sx={{
                    color: "grey.500",
                    justifyContent: "center", // Align items horizontally in center
                    alignItems: "center",
                  }}
                  spacing={2}
                  direction="row"
                >
                  <CircularProgress color="success" />
                </Stack>
              </>
            ) : (
              <>
                {" "}
                {FunctionalArea?.length > 0 ? (
                  <div className="talent_categories_card_cont">
                    {FunctionalArea.slice(0, 8).map(item => (
                      <div
                        className="func_home_card"
                        key={item.id}
                        onClick={() =>
                          navigate(`../jobs-list?fn_areas=${item.id}`)
                        }
                      >
                        <div className="avatar_con">
                          <img
                            src={profileIcon}
                            style={{ paddingTop: "10px" }}
                          />
                        </div>
                        <div className="func_home_inside_sec1">
                          <h4 className="func_name">{item.name}</h4>
                        </div>
                        <KeyboardArrowRight />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex align-items-center flex-column">
                      <img
                        src={noJobs}
                        style={{ width: "300px", height: "300px" }}
                        alt="no jobs"
                      />
                      <p>Not Found</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </section>

        <section
          className="companies_carousal_sec"
          style={{ marginTop: "20px" }}
        >
          <div className="companies_car_main_sec">
            <div className="comp_inside_sec">
              <h2 className="comp_heading">
                1000+ <span className="comp_span_text">Enterprises</span> Choose
                us
              </h2>
              <div className="comp_carousal_section">
                <CompanyCarousal />
              </div>
            </div>
          </div>
        </section>

        <section className="chrono_section">
          <Chrono />
        </section>

        {/* <div className="hire_candidates_card_section_layout">
          <section className="refer_section_tab">
            <div className="refer_main_sec">
              <div className="refer_partition_sec">
                <div className="refer_sec1">
                  <h2 className="refer_text_content_head">
                    Refer, <span className="refer_text_content">Earn,</span>
                  </h2>
                  <h2 className="refer_text_content_head">Settle</h2>
                  <p className="refer_para_content">
                    We pay you for referring colleagues, friends and peers to
                    jobs.
                    <br /> Refer anyone you know and as many people as you want
                    and get rewarded for every successull referral
                  </p>
                </div>
                <img
                  src={refer}
                  style={{ marginTop: "12%", height: "150px" }}
                  className="dummyImage"
                />
              </div>
            </div>
          </section>
        </div> */}

        <div className="ShifytTrustDescSec">
          <section className="trust_section">
            <ShifytTrustDesc />
          </section>
        </div>

        <RecentJobs
          jobs={jobs}
          loading={loading}
          functionalArea={FunctionalArea}
        />

        <div className="hire_candidates_card_section_layout">
          <section className="hire_candidates_card_section">
            <HireCandidates />
          </section>
        </div>

        {/* <div
          style={{ marginTop: "50px" }}
          className="hire_candidates_card_section_layout"
        >
          <section className="hire_partners_card_section">
            <HirePartners />
          </section>
        </div> */}

        <div
          style={{ marginTop: "50px" }}
          className="hire_candidates_card_section_layout"
        >
          <section className="companies_carousal_sec">
            <div className="companies_car_main_sec">
              <div className="comp_inside_sec">
                <h2 className="comp_heading">
                  Featured <span className="comp_span_text">Companies</span>{" "}
                  Actively hiring us
                </h2>
                <div className="comp_carousal_section">
                
                  <CompanyListCarousal />
                </div>
              </div>
            </div>
            <div
              className="view_more_btn_home"
              onClick={() => navigate("../employer-list")}
            >
              <Button
                variant="outlined"
                className="apply-now-btn cursor-pointerf"
                style={{
                  // background: "rgba(8, 24, 84, 0.1)",
                  // color: "#081854",
                  background: "#cde2e7",
                  color: "#066a4c",
                  fontFamily: "Inter,sans-serif",
                  fontWeight: "700",
                  border: "0px",
                  marginTop: "60px",
                }}
              >
                View More Companies
              </Button>
            </div>
          </section>
        </div>

        {/* <div className="hire_candidates_card_section_layout">
          <section className="popular_roles_section">
            <PopularRoles />
          </section>
        </div> */}

        <div className="hire_candidates_card_section_layout">
          <section className="trust_section">
            <ShifytTrust />
          </section>
        </div>

        <div
          style={{ marginTop: "40px" }}
          className="hire_candidates_card_section_layout"
        >
          <section className="Shifyt_main_section">
            <div className="Shifyt_work_section">
              <h2 className="Shifyt_txt">
                How <span className="Shifyt_span">Shifyt</span> works
              </h2>
              <p className="Shifyt_para_txt">
                {/* We are helping individuals referring and graphic design , Lorem
                ipsum is a placeholder text commonly used to demonstrate <br />{" "} */}
                We offer a comprehensive solution to streamline your entire
                hiring process enabling <br />
              </p>
              <p className="Shifyt_para_txt">
                {" "}
                {/* the visual form of a document or a typeface without relying on
                meaningful content. */}
                you to effortlessly post jobs, receive referrals and hire top
                talent.
              </p>
            </div>

            <ShifytJobsDesc />
          </section>
        </div>

        <section style={{ paddingTop: "5%" }}>
          <Footer />
        </section>

        {/* <section className="section-box mt-50 mb-70 bg-patern recruitement_section">
          <div className="for_recrutment_main">
            <div className="recruitement_left_cot">
              <div className="recruiter_image_cont">
                <img
                  className="recruitement_section_img"
                  alt="Shifyt"
                  src="assets/imgs/banner/banner-computers.png"
                />
              </div>
            </div>
            <div className="recruitement_right_cot">
              <p className="recruiters_card_head_1">For Recruiters</p>
              <p className="mb-4 recruiters_card_head">
                Customize your recruiting process in one place
              </p>

              <div
                onClick={() =>
                  window.open(
                    "https://app.Shifyt.com/login?user_type=CA",
                    "_blank"
                  )
                }
                className=" p-4 col-5 mb-4 recruiters_card_boxes"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
              >
                <p>Post a job and</p>
                <div className="d-flex justify-content-between">
                  <p>hire a pro</p>
                  <EastIcon />
                </div>
              </div>

              <div
                onClick={() =>
                  window.open(
                    "https://app.Shifyt.com/login?user_type=CA",
                    "_blank"
                  )
                }
                className="card col-5 p-4 mb-4 recruiters_card_boxes"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
              >
                <p>Refer a Job and</p>

                <div className="d-flex  justify-content-between">
                  <p>Earn money</p>
                  <EastIcon />
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <img src="assets/imgs/banner/banner-star.svg" alt="Shifyt" />
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="section-box mt-50">
          <div className="latest_job_section">
            <div className="row mb-3 align-items-center">
              <div className="col-lg-7 col-md-7">
                <h2
                  className="latest_job_header_cont section-title wow animate__animated animate__fadeInUp hover-up"
                  data-wow-delay=".1s"
                >
                  Featured <span className="latest_job_header">Resources</span>
                </h2>
              </div>
              <div className="col-lg-5 col-md-5 text-lg-end text-start">
                <a
                  href="/blogs"
                  // onClick={() => navigate("/blogs")}
                  className="wow animate__animated animate__fadeInUp hover-up latest_job_view_btn"
                  data-wow-delay=".1s"
                >
                  View all <EastIcon className="m-1" />
                </a>
              </div>
            </div>
            <CardsCarousel />
          </div>
        </section> */}
        {/* <section className="section-box mt-50">
          <ShifytShow />
        </section> */}
        {/* <Row
          style={{
            marginLeft: "50px",
          }}
        >
          {companies?.splice(0, 10)?.map((company, index) => (
            <Col
              lg={2}
              md={4}
              xs={6}
              style={{
                marginBottom: "10px",
                marginLeft: "0px",
                marginRight: "20px",
              }}
            >
              <div className="small_card">
                <div className="d-flex align-items-center gap-2">
                  <img
                    className="company_small_logo"
                    src={company?.company_image_details}
                    onError={e => {
                      e.target.src = defaultCompanyImage;
                    }}
                    alt="image"
                  />
                  <div>
                    <h5 className="company_name_bold">
                      {company?.company_name}
                    </h5>
                    <h6 className="company_location_bold secondary_text">
                      <span>hyderabd</span>
                    </h6>
                  </div>
                </div>
                <aside className="secondary_text mt-2 mb-4">
                  <p className="details_company">
                    <span>
                      <i className="bx bx-globe"></i>
                    </span>
                    <span>It Conusulting</span>
                  </p>
                  <p className="details_company">
                    <i className="bx bx-briefcase-alt"></i>
                    <span>{company?.size_of_company}</span>
                  </p>
                </aside>
                <Link
                  to={`../employer-single-list/${company?.id}`}
                  className="view_more_btn mt-4"
                >
                  View
                </Link>
              </div>
            </Col>
          ))}
        </Row> */}
      </main>
    </>
  );
}
//TODO change array to string when changed in admin panel location
