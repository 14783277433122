import React from "react";

function Recruiting() {
    return (
        <>
            <div className="sidebar-with-bg background-primary bg-sidebar pb-80">
                <h5 className="medium-heading text-white mb-20 mt-20">Recruiting?</h5>
                <p className="text-body-999 text-white mb-30">Advertise your jobs to millions of monthly users
                    and
                    search 16.8 million CVs in our database.</p>
                <a href="job-grid-2.html" className="btn btn-border icon-chevron-right btn-white-sm">Post a Job</a>
            </div>
        </>
    )
}
export default Recruiting;