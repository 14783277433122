import { CircularProgress, Grid } from "@mui/material";
import CandidateInformation from "./CandidateInformation";
import CandidateExperience from "./WorkExperience";
import AdditionalInformation  from './additionalInformation';
import CandidateSkills from "./skills";
import { useEffect, useState } from "react";
import EducationInformation from "./educationalInformation";
import WorkAuthInformation from "./workAuthInformation";
// import CandidateLanguages from "./languages";
// import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";
// import SearchParamHook from "hooks/searchParams";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getProfile } from "../../../helpers/dataFetcher/profile/profile";
import ResumeSummary from "./ResumeSummary";
import { getProfileEducationDetails } from "../../../helpers/dataFetcher/profile/update";


export default function ProfileSummary() {
  const [loading, setLoading] = useState(true);

  const [candidateDetails, setCandidateDetails] = useState({});
  const [recall,setRecall] = useState(false)


const getUserProfileDetails = async()=>{
  const { data: response } = await getProfile();
  localStorage.setItem("userData",response)
  setCandidateDetails(response);
  setLoading(false)
}

useEffect(()=>{
  setLoading(true)
  getUserProfileDetails()
},[])

useEffect(()=>{
  getUserProfileDetails()
},[recall])


  return (
    <>
      {loading ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2} >
          <Grid item xs={12} md={12} lg={6} spacing={2}>
            <CandidateInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              reCall={recall}
              setRecall={setRecall}
            />
            <CandidateExperience
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              setRecall={setRecall}
              reCall={recall}
            />
             <EducationInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              setRecall={setRecall}
              reCall={recall}
            />
          
            
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <CandidateSkills
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
            />
              <ResumeSummary
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              setRecall={setRecall}
              reCall={recall}
            />
             <WorkAuthInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              setRecall={setRecall}
              reCall={recall}
            />
          
            <AdditionalInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              setRecall={setRecall}
              reCall={recall}
            />
           
          </Grid>
        </Grid>
      )}
    </>
  );
}