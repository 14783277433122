import React from "react";
import { useNavigate } from "react-router-dom";

function JobsOpening(props) {
    const { routeName } = props;
    const navigate = useNavigate();

    return (
        <>
        </>
    )
}

export default JobsOpening;