import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Lists from "../JobsList/Alljobs/Lists";
import BrowseJobsList from "../BrowseJobsList/Index";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../../assets/css/Drawer.css";
import Typography from "@mui/material/Typography";
import BrowseJobsIndex from "../BrowseJobsList/BrowseJobsIndex";
import Slide from "@mui/material/Slide";
import { getJobPostById } from "../../helpers/dataFetcher/jobs/jobs";
import { getCompanyById } from "../../helpers/dataFetcher/companies";
import JobDrawDescription from "../BrowseJobsList/JobDrawDescription";
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles({
  root: {
    "& .MuiDrawer-paper": {
      height: "100vh !important",
    },

    ".MuiDrawer-root>.MuiPaper-root": {
      height: "100vh !important",
    },
    "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
      // boxShadow:"none"
      boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    },
  },

  paper: {
    height: "100vh",
    boxShadow: "none",
    ".MuiDrawer-root>.MuiPaper-root": {
      height: "100vh !important",
    },
  },
});

export default function EyeJobDrawer(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [apiCall,setApiCall] = useState(false)

  const navigate = useNavigate();

  /* Jobs Desc & Comp Desc API Integration start @seshu */

  const [Job, setJob] = useState({});
  const [company, setCompany] = useState({});
  const [compJobList, setCompJobList] = useState();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   dataFetcher();
  // }, []);

  async function dataFetcher() {
    if(apiCall){
      const { data } = await getJobPostById(props.id);
      setJob(data);
       // const { data: company_data } = await getCompanyById(data?.company_user_id);
    // setCompany(company_data);
    // const CompanyJobsData = await getCompanyById(data?.company_user_id)
    // setCompJobList(CompanyJobsData?.data)
    }
 
  }

  /* Jobs Desc & Comp Desc API Integrstion End @seshu */

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setApiCall(!apiCall)
  };

  useEffect(() => {
    dataFetcher();
  }, [apiCall == true]);

  const closeDrawer = anchor => {
    props.setDrawerOpen(false);
    setState(prevState => ({
      ...prevState,
      [anchor]: false,
    }));
  };

  const List = ({anchor}) => (
    <>
      <Box
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 700,
          "@media (min-width:320px) and (max-width:577px)": {
            width: anchor === "top" || anchor === "bottom" ? 350 : 360,
          },
          "@media (min-width:578px) and (max-width:772px)": {
            width: anchor === "top" || anchor === "bottom" ? "auto" : 500,
          },
          overflow: "auto",
        }}
        role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        <Box className="job_draw_heading_sec">
          <Typography className="view_bob_txt">{props.jobDetailsData?.job_title}</Typography>
          <IconButton
            onClick={() => closeDrawer(anchor)}
            // sx={{ position: "absolute", top: 0, right: 0, margin: "8px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box style={{marginTop:"30px",paddingLeft:"10px",margin:"4%"}}>
          <JobDrawDescription jobDetails={props.jobDetailsData}  />
        </Box>
      </Box>
    </>
  );

  return (
    // <div className="job_drawer">
    //   {["right"].map(anchor => (
    //     <React.Fragment key={anchor}>
    //       {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
    //       <Drawer
    //         anchor={anchor}
    //         // open={state[anchor]}
    //         open={props.isDrawerOpen}
    //         onClose={toggleDrawer(anchor, false)}
    //         className={classes.root}
    //       >
    //         {list(anchor)}
    //       </Drawer>
    //     </React.Fragment>
    //   ))}
    // </div>

    <div>
      {["right"].map(anchor => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{props.title}</Button> */}

          <Typography
            fontWeight="md"
            textColor="primary.plainColor"
            mb={0.5}
            style={{
              cursor: "pointer",
              textTransform: "capitalize",
              fontSize: "15px",
              fontWeight: "600",
              color: "#121212",
              fontFamily: "Inter,sans-serif",
            }}
            onClick={toggleDrawer(anchor, true)}
          >
            <InfoIcon />
          </Typography>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className={classes.root}
          >
            <List anchor={anchor} />
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
