const user = async () => {
  return await JSON.parse(localStorage.getItem("authUser"));
};
export const WS_URL = `wss://p51ljy6ub2.execute-api.ap-south-1.amazonaws.com/dev?user_id=`;
export const get_chat_active_user_list = () => {
  return fetch("https://3be1lwsrg0.execute-api.ap-south-1.amazonaws.com/users")
    .then(req => req.json())
    .catch(e => console.log(e));
};

export const deleteChatActiveUser = (id) => {
  return fetch(
    `https://3be1lwsrg0.execute-api.ap-south-1.amazonaws.com/deleteuser/${id}`,
    { method: "DELETE" }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

