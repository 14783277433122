import React from "react";
import { Constants } from "../../../Constants/Constants";

function SetReminder() {
    return (
        <>
            <div className="sidebar-with-bg">
                <h5 className="font-semibold mb-10">{Constants.job_reminder}</h5>
                <p className="text-body-999">Enter you email address and get job notification.</p>
                <div className="box-email-reminder">
                    <form>
                        <div className="form-group mt-15">
                            <input
                                type="search"
                                className="form-control input-bg-white form-icons"
                                placeholder="Enter email address"
                            />
                            <i className="fi-rr-envelope"></i>
                        </div>
                        <div className="form-group mt-25 mb-5">
                            <button className="btn btn-default btn-md">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default SetReminder