import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
// import { Toaster } from "react-hot-toast";
import toast,{ Toaster,ToastBar } from "react-hot-toast";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useSelector } from "react-redux";
import webSocketUrl from "../src/assets/sockets";
import SocketConnection from "./hooks/socket";



function App() {
  const data = SocketConnection();
 
  return (
    <Router>
      <AppRoutes />
      {/* <Toaster position="top-right" reverseOrder={false} /> */}
      <Toaster
          toastOptions={{
            duration: 2000,
          }}
          position="top-right"
          reverseOrder={false}
        >
          {t => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <div style={{display:'flex',gap:"8px"}} onClick={() => toast.dismiss()}> 
                  {icon}
                  {message}
                </div>
              )}
            </ToastBar>
          )}
        </Toaster>
    </Router>
  );
}

export default App;
