import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import website from "../../../assets/images/website.png";
import websiteLogo from "../../../assets/images/websiteLogo.png";
import GoogleReviews from "../../../assets/images/GoogleReviews.png";
import GlassdoorReview from "../../../assets/images/GlassdoorReview.png";
import AmbitionLogo from "../../../assets/images/AmbitionLogo.png";
import ComparablyLogo from "../../../assets/images/ComparablyLogo.png";
import PayscaleLogo from "../../../assets/images/PayscaleLogo.png";
import CarrerLogo from "../../../assets/images/CarrerLogo.png";
import reviews from "../../../assets/images/reviews.png";
import Comparably from "../../../assets/images/comparably.svg";
import carrerBliss from "../../../assets/images/carrerBliss.svg";
import glassDoor from "../../../assets/images/Glassdoor.svg";
import payScale from "../../../assets/images/Payscale.svg";
import googleReview from "../../../assets/images/reviews2.svg";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

function OverViewPage6({ cProfile }) {
  const urlList = {
    website: cProfile?.company?.website ? cProfile?.company?.website : "",
    google: cProfile?.company?.google_reviews
      ? cProfile.company.google_reviews
      : "",
    glassdoor: cProfile?.company?.glassdoors_url
      ? cProfile.company.glassdoors_url
      : "",
    ambition: cProfile?.company?.ambition_box_url
      ? cProfile.company.ambition_box_url
      : "",
    compare: cProfile?.company?.comparably_url
      ? cProfile.company.comparably_url
      : "",
    carrer: cProfile?.company?.careerbliss_url
      ? cProfile.company.careerbliss_url
      : "",
  };
  const hasData = Object.values(urlList).some(value => value !== "");
  return (
    <>
      <div className="overView_sec2">
        <h2 className="benefits_txt">Social Media Links</h2>
        <div className="comp_about_card">
          {hasData ? (
            <Row
              className="user-desc"
              xl="5"
              lg="4"
              md="3"
              sm="3"
              xs="2"
              sx={{ padding: "40px" }}
            >
             
              {cProfile?.company?.twitter_url && (
                <Col xs={4} md={4} className="about_comp_details">
                  <div className="social_cont">
                    <a
                      className="footer_social_icons"
                      href={`${cProfile?.company?.twitter_url}`}
                      target="_blank"
                    >
                      <FaTwitter size={20} color="#1DA1F2" className="social_img"/>
                    </a>
                  </div>
                </Col>
              )}
                {cProfile?.company?.facebook_url && (
                <Col xs={4} md={4} className="about_comp_details">
                  <div className="social_cont">
                    <a
                      className="footer_social_icons"
                      href={`${cProfile?.company?.facebook_url}`}
                      target="_blank"
                    >
                    <FaFacebook size={15} color="#3b5998" className="social_img"/>
                    </a>
                  </div>
                </Col>
              )}
             {cProfile?.company?.linkedin_url && (
                <Col xs={4} md={4} className="about_comp_details">
                  <div className="social_cont">
                    <a
                      className="footer_social_icons"
                      href={`${cProfile?.company?.linkedin_url}`}
                      target="_blank"
                    >
                      <FaLinkedin size={20} color="#1DA1F2" className="social_img"/>
                    </a>
                  </div>
                </Col>
              )}
             
            </Row>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "15px",
              }}
            >
              No data found
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default OverViewPage6;
