import React from "react";
// import "../../../../src/assets/css/AboutUs/About.css";

import CompanyCarousal from '../Home/CompanyCarousal';
function EmployesPage4() {
  return (
    <>
     <section className="companies_carousal_sec" style={{marginTop:"20px"}}>
          <div className="companies_car_main_sec">
            <div className="comp_inside_sec">
              <h2 className="comp_heading">
                1000+ <span className="comp_span_text">Enterprises</span> Choose
                us
              </h2>
              <div className="comp_carousal_section">
                <CompanyCarousal />
              </div>
            </div>
          </div>
        </section>
    </>
  );
}
export default EmployesPage4;
