import React, { useReducer, useState } from "react";
import "./login.css";
import { signup } from "../helpers/dataFetcher/profile/profile";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import linkedin from "../assets/images/linkedin2.png";
import registration from "../assets/images/infoImages/registration.jpg";
import forgotImage from "../assets/images/infoImages/forgot.jpg";
import google from "../assets/images/google.png";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import RegisterSlides from "./RegisterSlides";
import "./register.css";
import SignUpForm from './SignUpPage';
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import OtpVerificationModal from "./OtpVerificationModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MuiTelInput } from "mui-tel-input";

function Registration() {
  const navigate = useNavigate();
  const [phone, setPhone] = React.useState("");
  const handleChange = newPhone => {
    setPhone(newPhone);
    var mobile = '';
if (newPhone.slice(0,1) == '+' || newPhone.slice(0,1) == '0') {
    mobile = newPhone.replace(/[^a-zA-Z0-9+]/g, "");
} else {
    mobile = newPhone.replace(/[^a-zA-Z0-9]/g, "");
}
    dispatch({ type: "phoneNumber", payload: mobile });
  };

  const initState = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    error: "",
    jsonError: "",
    status: 200,
    otpModal: false,
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const reducer = (state, action) => {
    switch (action.type) {
      case "email":
        return { ...state, email: action.payload };
      case "firstName":
        return { ...state, firstName: action.payload };
      case "lastName":
        return { ...state, lastName: action.payload };
      case "password":
        return { ...state, password: action.payload };
      case "phoneNumber":
        return { ...state, phoneNumber: action.payload };
      case "otpModal":
        return { ...state, otpModal: action.payload };
      case "error":
        return { ...state, error: action.payload };
      case "status":
        return { ...state, status: action.payload };
      case "jsonError":
        return { ...state, jsonError: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initState);

  const handleLogin = async e => {
    e.preventDefault();
    const { status, data } = await signup({
      email: state.email,
      first_name: state.firstName,
      last_name: state.lastName,
      user_password: state.password,
      usertype: "REFERAL_PARTNER",
      phone_number: `${state.phoneNumber}`,
    });
    if (status === 201) {
      dispatch({ type: "otpModal", payload: true });
    }
    if (status === 422) {
      dispatch({ type: "error", payload: data });
      dispatch({ type: "status", payload: status });
    }
    if (status === 400) {
      dispatch({ type: "jsonError", payload: data?.message });
    }
  };
  console.log("phone",phone)
  return (
    <section className="vh-100">
      {/* <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img
            
              src={registration}
              className="img-fluid"
              alt="Sample image"
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <div>
              <div className="mb-2">
                <h4 style={{ fontFamily: "sans-serif" }}>Welcome to Shifyt</h4>
              
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                <div
                  className="login_with_google_cont"
                  style={{ marginRight: "10px" }}
                >
                  <div>
                    <img
                      src={google}
                      className="login_google_icon"
                      alt="login-Shifyt"
                    />
                  </div>
                  <div>Log in with Google</div>
                </div>
                <div
                  className="login_with_google_cont"
                  style={{ marginLeft: "10px" }}
                >
                  <div>
                    <img
                      src={linkedin}
                      size={12}
                      className="login_google_icon"
                      alt="login-Shifyt"
                    />
                  </div>
                  <div>Log in with linkedin</div>
                </div>
              </div>



              <div className="login_hr_cont text-center">
                <div>
                  <hr />
                </div>
                <div>
                  <span>or</span>
                </div>
                <div>
                  <hr />
                </div>
              </div>

              <Box
                component="form"
                onSubmit={handleLogin}
              
                sx={{ mt: 1 }}
              >
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="First Name"
                      autoFocus
                      name="email"
                      type="text"
                      autoComplete="email"
                      autoFocus
                      invalid={state?.error?.includes("first_name")}
                      onChange={e => {
                        dispatch({
                          type: "firstName",
                          payload: e.target.value,
                        });
                      }}
                      InputProps={{
                        style: { borderRadius: "5px" ,height:"50px"},
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Last Name"
                      name="email"
                      type="text"
                      autoComplete="email"
                      autoFocus
                      invalid={state?.error?.includes("last_name")}
                      onChange={e => {
                        dispatch({ type: "lastName", payload: e.target.value });
                      }}
                      InputProps={{
                        style: { borderRadius: "5px",height:"50px" },
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      invalid={state?.error?.includes("user_password")}
                   
                      onChange={e => {
                        dispatch({ type: "password", payload: e.target.value });
                      }}
                      InputProps={{
                        style: { borderRadius: "5px",height:"51.3px",},
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      invalid={
                        state?.error?.includes("email") ||
                        state?.jsonError?.includes("email")
                      }
                    
                      id="email"
                   
                      onChange={e => {
                        dispatch({ type: "email", payload: e.target.value });
                      }}
                      InputProps={{
                        style: { borderRadius: "5px",height:"51.3px"},
                      }}
                    />
                    <p style={{ color: "red" }}>
                      {state?.jsonError !== "" ? state?.jsonError : ""}
                    </p>
                  </div>
                </div>

                <FormGroup className="w-100">
                  <div>
                   
                    <MuiTelInput
                      defaultCountry="IN"
                      fullWidth
                      countryCodeEditable={true}
                      value={phone}
                      onChange={e =>handleChange(e)}
                      InputProps={{
                        style: { borderRadius: "5px",height:"50px"},
                      }}
                    />
                  </div>

                  <Input
                    className="form-control"
                    style={{ display: "none" }}
                    invalid={state?.error?.includes("phone_number")}
                    id="ph_number"
                    onChange={e => {
                      dispatch({
                        type: "phoneNumber",
                        payload: e.target.value,
                      });
                    }}
                  />
                  <FormFeedback>Phone Number is Required</FormFeedback>
                </FormGroup>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Next
                </Button>
                <Grid container style={{ paddingBottom: "30px" }}>
                  <Grid item>
                    <p>By signing up you agree to our <span style={{color:"#5d97f5"}}>terms and conditions</span> and <span style={{color:"#5d97f5"}}>privacy policy.</span></p>
                  </Grid>
                  <Grid item>
                  Already have an account? 
                    <Link href="#" variant="body2">
                      <span onClick={() => navigate("/login")}>
                        {" "}
                        {"Sign In"}
                      </span>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </div>
      </div> */}
      <SignUpForm />
      <OtpVerificationModal
        show={state.otpModal}
        onHide={() => dispatch({ type: "otpModal", payload: false })}
        email={state.email}
      />
    </section>
  );
}
export default Registration;
