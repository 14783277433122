import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReferNowModal } from "../JobsList/Alljobs/ReferModal";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy";
import moment from "moment";
import { imageURL } from "../../Constants/commonURLS";
import Stack from "@mui/material/Stack";
import EarnImage from "../../assets/images/earnImage.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import CardOverflow from "@mui/joy/CardOverflow";
// import Divider from "@mui/joy/Divider";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import JobInsights from "./JobInsights";
import SkillsSection from "./SkillsSection";
import JobInfoDescription from "./JobInfoDescription";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Chip from "@mui/joy/Chip";

function JobDrawDescription(props) {
  const [ReferStatus, setReferStatus] = useState({
    modal: "",
    type: "",
    action: "",
    modalType: "",
  });
  const [referModal, setReferModal] = useState({
    modal: "",
    purpose: "",
    modalType: "",
  });
  const user = useSelector(state => state.User.user);

  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    return `${scaledNumber.toFixed(0)}k`;
  };

  return (
    <>
      <section className="jobdraw_desc_section mt-2">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={12} lg={7} md={12} sm={12} spacing={1}>
              <Box
                sx={{
                  border: "0.5px solid rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                  marginBottom: "10px",
                  marginLeft: "8px",
                  marginTop: "4px",
                }}
              >
                <div
                  className="profile_category_desc pb-0 gap-0"
                  id="job_category_header"
                >
                  <h2 className="job_desc_text_1 pt-2">Job Description</h2>
                </div>
                <Box
                  sx={{
                    borderRadius: "4px",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {props.jobDetails?.job_desc ? (
                    <div
                      style={{
                        overflow: "auto",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        padding: "5px",
                        margin: "10px",
                        minHeight: "199px",
                        maxHeight: "200px",
                        fontFamily:"Inter,sans-serif"
                      }}
                      className="ShifytScroll"
                    >
                      <div
                        // className="job_desc_content"
                        dangerouslySetInnerHTML={{
                          __html: props.jobDetails?.job_desc,
                        }}
                      ></div>
                    </div>
                  ) : (
                    "Nothing to show"
                  )}
                </Box>
                <Box
                  // sx={{
                  //   borderRadius: "4px",
                  //   overflowY: "scroll",
                  //   "&::-webkit-scrollbar": {
                  //     display: "none",
                  //   },
                  // }}
                >
                  <div
                    // style={{
                    //   overflow: "auto",
                    //   "&::-webkit-scrollbar": {
                    //     display: "none",
                    //   },
                    //   minHeight: "199px",
                    //   maxHeight: "200px",
                    // }}
                    className="ShifytScroll"
                  >
                    <div
                      className="profile_category_desc pb-0 gap-0 "
                      id="job_category_header"
                    >
                      <h2 className="job_desc_text_1 pt-2">Requirements</h2>
                    </div>
                    {props.jobDetails?.requirements ? (
                      <div
                        style={{
                          overflow: "auto",
                          padding: "5px",
                          margin: "10px",
                          minHeight: "199px",
                          maxHeight: "200px",
                          fontFamily:"Inter,sans-serif"
                        }}
                        className="ShifytScroll"
                      >
                        <div
                          // className="job_desc_content"
                          dangerouslySetInnerHTML={{
                            __html: props.jobDetails?.requirements,
                          }}
                        ></div>
                      </div>
                    ) : (
                      "Nothing to show"
                    )}
                  </div>
                </Box>
              </Box>
            </Grid>

            <Grid
              xs={12}
              lg={5}
              md={12}
              sm={12}
              sx={{ flexDirection: "column" }}
            >
              <Grid>
                <Box
                  sx={{
                    border: "0.5px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    marginLeft: "8px",
                  }}
                >
                  <div
                    className="profile_category_desc pb-0 gap-0"
                    id="job_category_header"
                  >
                    {/* <div
                      style={{ borderWidth: "2px", borderRadius: "18%" }}
                      className="profile_divider"
                    ></div> */}
                    <h2 className="job_desc_text_1 pt-2">Job Role Insights</h2>
                  </div>

                  {/* <div
                    className="jobs_title_card pb-5 pt-3"
                    style={{ paddingLeft: "12px" }}
                  >
                    <Chip
                      variant="solid"
                      color="neutral"
                      size="sm"
                      style={{ color: "#777AD6", background: "#F5ECFF" }}
                    >
                      {props.jobDetails?.job_type?.replace("_", " ")}
                   
                    </Chip>

                    <Chip
                      variant="solid"
                      color="neutral"
                      size="sm"
              
                      style={{
                        color: "#081753",
                        background: "rgba(8, 23, 83, 0.07)",
                      }}
                    >
                      {props.jobDetails?.location?.length <= 17
              ? props.jobDetails?.location
              : props.jobDetails?.location?.slice(0, 35) + ".."}
                
                    </Chip>

                    <Chip
                      variant="solid"
                      color="neutral"
                      size="sm"

                      style={{
                        color: "#081753",
                        background: "rgba(8, 23, 83, 0.07)",
                      }}
                    >
                      3-5 &nbsp; Yrs
                    </Chip>
                    <Chip
                      variant="soft"
                      color="neutral"
                      size="sm"
                      style={{ background: "#F2F8F6", color: "#07A996" }}
                    >
                      {getSymbolFromCurrency(props.jobDetails?.currency)}
            {Math.round(props.jobDetails?.min_sal / 1000, 2)}k-
            {Math.round(props.jobDetails?.max_sal / 1000, 2)}k
                 
                    </Chip>
                    <Chip
                      variant="soft"
                      color="neutral"
                      size="sm"
                      style={{ background: "#F2F8F6", color: "#07A996" }}
                    >
                      {props.jobDetails?.work_mode?.replace("_", " ")}
                    </Chip>
                  </div> */}

                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                      }}
                      className="ShifytScroll"
                    >
                      <h2 className="profile_summ_txt requirement_text">
                        Role:
                        <span className="job_desc_content">
                          {props.jobDetails?.job_title}
                        </span>
                      </h2>

                      <h2 className="profile_summ_txt requirement_text">
                        Job Type:
                        <span className="job_desc_content">
                          {props.jobDetails?.job_type?.replace("_", " ")}
                        </span>
                      </h2>

                      <h2 className="profile_summ_txt requirement_text">
                        Location:
                        <span className="job_desc_content">
                          {" "}
                          {props.jobDetails?.location?.length <= 17
                            ? props.jobDetails?.location
                            : props.jobDetails?.location?.slice(0, 15) + "..."}
                        </span>
                      </h2>
                      <h2 className="profile_summ_txt requirement_text">
                        Experience:
                        <span className="job_desc_content">
                          {props.jobDetails?.min_exp}-
                          {props.jobDetails?.max_exp}Yrs
                        </span>
                      </h2>
                      <h2 className="profile_summ_txt requirement_text">
                        Work Mode:
                        <span className="job_desc_content">
                          {props.jobDetails?.work_mode?.replace("_", " ")}
                        </span>
                      </h2>
                      <h2 className="profile_summ_txt requirement_text">
                        Salary:
                        <span className="job_desc_content">
                          {getSymbolFromCurrency(props.jobDetails?.currency)}
                          {Math.round(props.jobDetails?.min_sal / 1000, 2)}k-
                          {Math.round(props.jobDetails?.max_sal / 1000, 2)}k
                        </span>
                      </h2>
                      {/* <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                        <h2 className="profile_summ_txt requirement_text">work modee</h2>
                        <h2 className="job_desc_content">hybrid</h2>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                        <h2 className="profile_summ_txt requirement_text">work modee</h2>
                        <h2 className="job_desc_content">hybrid</h2>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                        <h2 className="profile_summ_txt requirement_text">work modee</h2>
                        <h2 className="job_desc_content">hybrid</h2>
                      </div> */}
                    </div>
                  </>
                </Box>
              </Grid>

              <Grid>
                <Box
                  sx={{
                    border: "0.5px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    marginLeft: "8px",
                    marginTop: "5px",
                  }}
                >
                  <div
                    className="profile_category_desc pb-0 gap-0"
                    id="job_category_header"
                  >
                    {/* <div
                    style={{ borderWidth: "2px", borderRadius: "18%" }}
                    className="profile_divider"
                  ></div> */}
                    <h2 className="job_desc_text_1 pt-2">Skills</h2>
                  </div>

                  <div
                    className="insights_sec pt-3 ShifytScroll"
                    style={{
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      minHeight: "150px",
                      maxHeight: "200px",
                    }}
                  >
                    {props.jobDetails?.key_skills_details?.length >= 0 ? (
                      <div className="jobs_title_card pb-5">
                        {props.jobDetails?.key_skills_details?.map(each => (
                          <Chip
                            variant="solid"
                            color="neutral"
                            size="sm"
                            // icon={<LocationOnIcon />}
                            style={{
                              color: "#066a4c",
                              background: "#cde2e7",
                            }}
                          >
                            {each.name}
                          </Chip>
                        ))}
                      </div>
                    ) : (
                      "No data Found"
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  );
}

export default JobDrawDescription;
