import { COMPANY_DESC, COMPANY_DETAILS } from "./actionTypes";
const initState = {
  companyId: "",
  companyDetails: [],
};
export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case COMPANY_DESC:
      return { ...state, companyId: payload };
    case COMPANY_DETAILS:
      return { ...state, companyDetails: payload.data };
    default:
      return state;
  }
}
