import React from "react";

function EmployerVacancies(props) {
    const { routeName } = props;
    return (
        <>
            <div className="divider"></div>
            <h4 className="mb-10 mt-30">{routeName === "/candidate-single" ? "Professional Skills":"Required skills" }</h4>
            <div className="block-progress-status">
                <div className="item-progress">
                    <div className="title-progress"><strong className="text-md-bold">Figma</strong> (8.5/10)</div>
                    <div className="info-progress">
                        <div className="progress">
                            <div style={{ width: "90%" }} className="progress-bar"></div>
                        </div>
                    </div>
                </div>
                <div className="item-progress">
                    <div className="title-progress"><strong className="text-md-bold">Sketch</strong> (8.5/10)</div>
                    <div className="info-progress">
                        <div className="progress">
                            <div style={{ width: "60%" }} className="progress-bar"></div>
                        </div>
                    </div>
                </div>
                <div className="item-progress">
                    <div className="title-progress"><strong className="text-md-bold">After Effect</strong> (8.5/10)</div>
                    <div className="info-progress">
                        <div className="progress">
                            <div style= {{ width: "80%" }} className="progress-bar"></div>
                        </div>
                    </div>
                </div>
                <div className="item-progress">
                    <div className="title-progress"><strong className="text-md-bold">Photoshop</strong> (8.5/10)</div>
                    <div className="info-progress">
                        <div className="progress">
                            <div style= {{ width: "70%" }} className="progress-bar"></div>
                        </div>
                    </div>
                </div>
                <div className="item-progress">
                    <div className="title-progress"><strong className="text-md-bold">HTML & CSS</strong> (8.5/10)</div>
                    <div className="info-progress">
                        <div className="progress">
                            <div style={{ width: "40%" }} className="progress-bar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmployerVacancies;