import callApi from "../../callApi";
async function getId() {
  return localStorage.getItem("user_id");
}
async function getCompanyId() {
  return localStorage.getItem("company_id");
}
export default async function updateProfile(type, data) {
  return await callApi(
    `/referral_partner_authorise/${await getId()}`,
    {
      update_type: type,
      work_exp: data,
    },
    "PUT"
  );
}

export  async function updateSkill(data){
  console.log(data,"skilsssss")
  return await callApi(`/job_attributes/key_skills`,{skills:data},POST)
}

export  async function updateDeleteProfile(type, data) {
  return await callApi(
    `/referral_partner_authorise/${await getId()}`,
    {
      update_type: type,
      // work_exp: data,
      ...data
    },
    "PUT"
  );
}

export async function updateProfileNormal(type, data) {
  return await callApi(
    `/referral_partner_authorise/${await getId()}`,
    {
      update_type: type,
      ...data,
      // company_id: await getCompanyId(),
    },
    "PUT"
  );
  
}

export async function getProfileEducationDetails(){
  return await callApi(`/job_attributes/educational_qualification/${await getId()}`,"GET")
}


export async function getEarning(){
  return await callApi(`/common/withdraw_earnings`,"GET")
}

export async function getEarningTableData(){
  return await callApi(`common/get_earnings`,"GET")
}

export async function getWithTableData(){
  return await callApi(`common/withdraw`,"GET")
}

export async function getWithAmount(data){
  console.log(data)
  return await callApi(`common/withdraw/`,{
    amount:(data)
  },"POST")
}
export async function updatePassword(newPassword){
  return await callApi(`common/changepassword`,{
    password:newPassword
  },"PATCH")
}

export async function updateNumber(type,updatedData,number){
  return await callApi(`/referral_partner_authorise/${await getId()}`,    {
    update_type: type,
    ...updatedData,
    phone_number:number
  },"PUT")
}
export async function updateEmail(type,updatedData,email){
  return await callApi(`/referral_partner_authorise/${await getId()}`,    {
    update_type: type,
    ...updatedData,
    email:email
  },"PUT")
}

export async function sendOtp(type,number){
  return await callApi(`/common/send_otp`,{
    update_type: type,
    phone_number:number
  },"PUT")
}
export async function sendOtpForMail(type,email){
  return await callApi(`/common/send_otp`,{
    update_type: type,
    email:email
  },"PUT")
}