import React, { useEffect, useState, Suspense } from "react";
import SignUpForJobs from "../../JobsList/SignUpForJobs/SignUpForJobs";
import OurPartners from "../../OurClients/OurPartners";
import EmployerList from "./EmployersList";
import "../../../assets/componies/companyListMain.css";
import {
  getAllCompanies,
  getCompanyIndustires,
  getCompanyIndustryById,
  getAllCompanyLocations,
  getExpLevel,
  findAllNewCompanies,
} from "../../../helpers/dataFetcher/companies";
import Button from "@mui/joy/Button";
import { CompanyFilters } from "./CompanyFilters";
import CompanyMainSearch from "../../MainSearchFilterBar/CompanyMainSearch";
import { useSearchParams } from "react-router-dom";
import SocketConnection from "../../../hooks/socket";
// import noJobs from "../../../assets/images/no_jobs.svg";
import noJobs from "../../../assets/images/nojobsShiyft.png";
import CompanyIndustries from "./CompanyIndustries";
import SidePanelFilters from "../../JobsList/JobsListSidePanel/SidePanelFilter";
import SmallScreenCompanyFilter from "../../MainSearchFilterBar/smallScreenCompanyFilters";
import SidePanelCompanyFilters from "./SidePanelCompanyFilters";
import CompanySkeletion from "../../../Loader/companySkeletion";
import IndustrySkeletion from "../../../Loader/industrySkeletion";

function MainEmployerList() {
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const { lastMessage } = SocketConnection();
  let [searchParams, setSearchParams] = useSearchParams();
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [newCompany, setNewCompany] = useState([]);
  const [filterApplied, setFilterApplied] = useState(true);
  const [industries, setIndustries] = useState([]);
  const [industryCheck, setIndustryCheck] = useState("SESHU");
  const [shownFilterBtn, setShownFilterBtn] = useState(false);
  const [industryLoader,setIndustryLoader] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0);
    dataFetcher(
      searchParams.get("name") === null ? "" : searchParams.get("name"),
      searchParams.get("location") === null ? "" : searchParams.get("location")
    );
    setIndustryLoader(true)
    getAllIndustries();
  }, []);
  useEffect(() => {
    setName(searchParams.get("name"));
    dataFetcher(
      searchParams.get("name") === null ? "" : searchParams.get("name"),
      searchParams.get("location") === null ? "" : searchParams.get("location")
    );
  }, [searchParams.get("name"), searchParams.get("location")]);

  async function dataFetcher(name, location) {
    setLoading(true);
    const { data } = await getAllCompanies(name, location);
    setLoading(false);
    setCompanies(data);
  }

  const recallAllApi = () => {
    dataFetcher(
      searchParams.get("name") === null ? "" : searchParams.get("name"),
      searchParams.get("location") === null ? "" : searchParams.get("location")
    );
    getAllIndustries();
  };
  /*socket integration for companies page start (No need of socket here)*/

  // useEffect(() => {
  //   if (
  //     lastMessage?.data !== undefined ||
  //     lastMessage?.data !== null ||
  //     lastMessage?.data !== ""
  //   ) {
  //     const resultant = lastMessage?.data;
  //     if (resultant !== undefined) {
  //       const data = JSON.parse(resultant);
  //       if (data?.type === "companies") {
  //         dataFetcher(
  //           searchParams.get("name") === null ? "" : searchParams.get("name"),
  //           searchParams.get("location") === null
  //             ? ""
  //             : searchParams.get("location")
  //         );
  //       }
  //     }
  //   }
  // }, [lastMessage]);

  /*socket integration for companies page End */

  /*search params based on company filter code start here testing @seshu */

  // useEffect(() => {
  //   fechJobsBasedonOffset(
  //     searchParams.get("title"),
  //     searchParams.get("location"),
  //     searchParams.getAll("industry"),
  //     searchParams.getAll("locations_list"),
  //     searchParams.get("exp"),
  //   );
  // }, [
  //   limit,
  //   offset,
  //   searchParams.get("title"),
  //   searchParams.get("location"),
  //   searchParams.get("industry"),
  //   searchParams.getAll("locations_list"),
  //   searchParams.get("exp"),
  // ]);

  // const fechJobsBasedonOffset = async (
  //   title,
  //   location,
  //   industry,
  //   locations_list,
  //   exp,
  // ) => {
  //   if(localStorage.getItem("token") !== ""){
  //     const response = await findAllNewCompanies({
  //       company_name: title,
  //       location: location,
  //       locations_list: locations_list,
  //       experience: exp?`0,${exp}`:"",
  //       industry_list: industry,
  //       limit: limit,
  //       offset: offset,
  //       // candidate_id:userData.user.id
  //     });
  //     if (response.status === 200) {
  //       setLoad(false);
  //       setFilterApplied(false);
  //     }

  //     setNewCompany([...response.data]);
  //   }else {
  //     const response = await findAllNewCompanies({
  //       company_name: title,
  //       location: location,
  //       locations_list: locations_list,
  //       experience: exp?`0,${exp}`:"",
  //       industry_list: industry,
  //       limit: limit,
  //       offset: offset,
  //       // candidate_id:userData.user.id
  //     });
  //     if (response.status === 200) {
  //       setLoad(false);
  //       setFilterApplied(false);
  //     }

  //     setNewCompany([...response.data]);
  //   }
  // };

  /*search params based on company filter code End here testing @seshu */

  /*Filters based on Industry testing puprpose code written start here @seshu */

  /* Industries getting Api GET Call testing start @seshu */
  const getAllIndustries = async () => {
    const industriesList = await getCompanyIndustires();
    setIndustryLoader(false)
    setIndustries(industriesList?.data);
  };

  /* Industries getting Api GET Call testing End @seshu */

  /*Get companiesList by industry ID testing start @seshu */
  const selectedIndustryById = async id => {
    const { data } = await getAllCompanies(name, location, id);
    setLoading(false);
    setCompanies(data);
  };
  /*Get companiesList by industry ID testing End @seshu */

  /*Get Company Locations List GET Method start here @seshu */
  const getAllLocations = async () => {
    const locationslist = await getAllCompanyLocations();
  };
  /*Get Company Locations List GET Method start here @seshu */

  /*Get Company Locations List GET Method start here @seshu */
  const getExperienceLevel = async () => {
    const expList = await getExpLevel();
  };
  /*Get Company Locations List GET Method start here @seshu */

  /*Filters based on Industry testing puprpose code written End here @seshu */

  return (
    <>
      {/* <MainSearchFilterBar /> */}
      <div className="section-box pt-2">
        <div className="employer_jobs_list_cont">
          <div className="col-lg-12 col-md-12 col-sm-12" style={{}}>

            {industryLoader?<IndustrySkeletion />:  <CompanyIndustries
              industryList={industries}
              selectedIndustry={id => selectedIndustryById(id)}
              industryCheck={industryCheck}
              setShownFilterBtn={setShownFilterBtn}
            />}
          </div>

          <div className="row">
            {/* <div
              className="col-lg-3 col-md-4 col-sm-12 side_panel_container"
              style={{ overflow: "auto" }}
            >
              <SidePanelCompanyFilters
                clearFilter={() => recallAllApi()}
                setIndustryCheck={setIndustryCheck}
                shownFilterBtn={shownFilterBtn}
                setShownFilterBtn={setShownFilterBtn}
              />
            </div> */}
            {/* <div className="col-lg-9 col-md-8 col-sm-12"> */}
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                className=" ShifytScroll"
                style={{ overflow: "auto", height:"calc(100vh - 230px)"}}
              >
                <div
                  className="col-lg-12 col-md-12 col-sm-12"
                  style={{
                    position: "sticky",
                    zIndex: 2,
                    top: 0,
                    background: "#fff",
                  }}
                >
                  <CompanyMainSearch
                    name={name}
                    setName={setName}
                    location={location}
                    setLocation={setLocation}
                    companies={companies}
                  />
                </div>

                <div className="sm_job_filter" style={{marginBlockEnd:"16px"}}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <SmallScreenCompanyFilter
                    // functionalArea={functionalArea}
                    // keySkills={keySkills}
                    // salaryRange={salaryRange}
                    // jobTypes={jobTypes}
                    // workTypes={workMode}
                    // filterJobs ={()=>filterJobsBased()}
                    />
                  </Suspense>
                </div>

                <div
                  className="col-lg-12 col-md-12 col-sm-12"
                  // style={{ height: "500px", overflow: "auto" }}
                >
                  {/* {companies?.data?.length > 0 ? (
                    <EmployerList companiesList={companies} loading={loading} />
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={noJobs}
                          style={{ width: "300px", height: "300px" }}
                          alt="no jobs"
                        />
                        <p>No Companies Found</p>
                      </div>
                    </div>
                  )} */}

                  {loading ? (
                    <CompanySkeletion />
                  ) : companies?.data?.length > 0 ? (
                    <EmployerList companiesList={companies} loading={loading} />
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={noJobs}
                          style={{ width: "300px", height: "300px" }}
                          alt="no jobs"
                        />
                        <p>No Companies Found</p>
                      </div>
                    </div>
                  )}
                </div>

                {/* {newCompany.length >= 10?  <Button
                      style={{ backgroundColor: "#081753" }}
                      variant="solid"
                      className="refer-now-btn cursor-pointer "
                      disabled={newCompany.length == 0}
                      onClick={() => {
                        setOffset(offset)
                        setLimit(limit + 11);
                      }}
                  
                    >
                      Load More
                    </Button>:""} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MainEmployerList;
