import React from "react";

function EmployerBranches(props) {
    const { routeName } = props;

    return (
        <>
            <div className="divider"></div>
            <h4 className="mb-20 mt-25">
                {routeName === "/candidate-single" ? "Work Experience" : "Branches"}
            </h4>
            <div className="row">
                <div className="col-md-4 col-sm-6 col-12 mb-60">
                    <strong className="text-md-bold">Behance Design</strong>
                    <span className="dis-block text-muted text-md-lh24">
                        NY, UK<br />
                        Jan 2021 — Present
                    </span>
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-60">
                    <strong className="text-md-bold">Behance Accounting</strong>
                    <span className="dis-block text-muted text-md-lh24">
                        pxdraft Ltd, UK<br />
                        Jan 2018 — Dec 2021
                    </span>
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-60">
                    <strong className="text-md-bold">Behance Creative</strong>
                    <span className="dis-block text-muted text-md-lh24">
                        AT Studio, UK<br />
                        Jan 2017 — May 2017
                    </span>
                </div>

                <div className="col-md-4 col-sm-6 col-12 mb-10">
                    <strong className="text-md-bold">Behance Social</strong>
                    <span className="dis-block text-muted text-md-lh24">
                        NY, UK<br />
                        Jan 2021 — Present
                    </span>
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-10">
                    <strong className="text-md-bold">Behance Search</strong>
                    <span className="dis-block text-muted text-md-lh24">
                        NY, UK<br />
                        Jan 2021 — Present
                    </span>
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-10">
                    <strong className="text-md-bold">Behance Video</strong>
                    <span className="dis-block text-muted text-md-lh24">
                        NY, UK<br />
                        Jan 2021 — Present
                    </span>
                </div>
            </div>
        </>
    )
}
export default EmployerBranches;