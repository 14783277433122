import React, { useEffect, useState } from "react";
import { Constants } from "../../../Constants/Constants";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import Slider from "@mui/material/Slider";
import Button from "react-bootstrap/Button";
// import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { useSearchParams } from "react-router-dom";
// import Input from "@mui/joy/Input";
import { Search } from "@mui/icons-material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Skeleton from "@mui/material/Skeleton";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import { OutlinedInput } from "@mui/material";
import "../../../assets/css/Jobs/jobsPage.css";
import Checkbox from "@mui/material/Checkbox";

function SidePanelFilters(props) {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  let [searchParams, setSearchParams] = useSearchParams();
  const [showJobs, setShowJobs] = useState(true);
  const [work, setWork] = useState(true);
  const [showExperience, setShowExperience] = useState(true);
  const [showSalary, setShowSalary] = useState(true);
  const [showCategories, setShowCategories] = useState(true);
  const [showKeySkills, setShowKeySkills] = useState(true);
  const [categoryModal, setCategoryModal] = useState(false);
  const [keySkillModal, setKeySkillModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const [searchFunc, setSearchFunc] = useState("");
  
  const [searchModalFunc,setSearchModalFunc] = useState("");
  const [searchModalSkill,setSearchModalSkill] = useState("")

  const [searchKeySkill, setSearchKeySkill] = useState("");
  const [fnAreas, setFnAreas] = useState([]);
  const [keySkills, setKeySkills] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [workMode, setWorkMode] = useState([]);
  const [salary, setSalary] = useState([]);
  const [jobTypes, setJobTypes] = useState([
    { id: 0, type: "Full time jobs", isChecked: false },
    { id: 1, type: "Part time jobs", isChecked: false },
    { id: 2, type: "Remote jobs", isChecked: false },
    { id: 3, type: "Freelance", isChecked: false },
    { id: 4, type: "Temporary jobs", isChecked: false },
  ]);

  const urlParams = new URLSearchParams(window.location.search);
  const fnAreasId = urlParams.get("fn_areas");
  // const [workMode, setWorkMode] = useState([
  //   { id: 0, type: "Fully_remote", isChecked: false },
  //   { id: 1, type: "On_site", isChecked: false },
  //   { id: 2, type: "Partially_remote", isChecked: false },
  // ]);

  const [salaryRange, setSalaryRange] = useState([
    { id: 0, range: "0-3", isChecked: false },
    { id: 1, range: "3-6", isChecked: false },
    { id: 2, range: "6-10", isChecked: false },
    { id: 3, range: "10-15", isChecked: false },
    { id: 4, range: "15-30", isChecked: false },
    { id: 5, range: "30-50", isChecked: false },
    { id: 6, range: "50+", isChecked: false },
  ]);
  const [experienceLevel, setExperienceLevel] = useState("");

  // useEffect(() => {
  //   props.filterJobs();
  // }, [searchParams]);

  const setParameters = data => {
    setSearchParams({
      title:
        searchParams.get("title") !== null ? searchParams.get("title") : "",
      location:
        searchParams.get("location") !== null
          ? searchParams.get("location")
          : "",
      fn_areas: searchParams.getAll("fn_areas"),
      salary: searchParams.getAll("salary"),
      key_skills: searchParams.getAll("key_skills"),
      exp: searchParams.get("exp") !== null ? searchParams.get("exp") : "",
      job_type: searchParams.getAll("job_type"),
      work_mode: searchParams.getAll("work_mode"),
      ...data,
    });
    // props.filterJobs()
  };

  const removeAllFilters = () => {
    const emptySearchParams = new URLSearchParams();
    setSearchParams(emptySearchParams);
    setFnAreas([]);
  };

  // useEffect(()=>{

  // },[fnAreas])

  const handleClose = () => setModal(!modal);
  const handleCloseOfCategories = () => setCategoryModal(!categoryModal);
  const handleCloseOfKeySkills = () => setKeySkillModal(!keySkillModal);
  const handleShow = () => setShow(true);
  const handleChecked = (e, id, type) => {
    const exist = jobType.findIndex(x => x === type);
    if (exist !== -1) {
      const jobT = jobType;
      jobT.splice(exist, 1);
      setParameters({
        job_type: jobT,
      });
      setJobType(jobT);
    } else {
      setParameters({ job_type: [...jobType, type] });
      setJobType([...jobType, type]);
    }
    const iterateOverJobTypes = jobTypes.map((each, i) => {
      if (i === id) {
        return { ...each, isChecked: !each.isChecked };
      } else {
        return each;
      }
    });
    setJobTypes(iterateOverJobTypes);
  };

  const handleCheckedWork = (e, id, type) => {
    const exist = workMode.findIndex(x => x === type);
    if (exist !== -1) {
      const jobT = workMode;
      jobT.splice(exist, 1);
      setParameters({
        work_mode: jobT,
      });
      setWorkMode(jobT);
    } else {
      setParameters({ work_mode: [...workMode, type] });
      setWorkMode([...workMode, type]);
    }
  };

  const handleFuncAreasChange = (e, id) => {
    const exist = fnAreas.findIndex(x => x === id);
    if (exist !== -1) {
      const fnAreaList = fnAreas;
      fnAreaList.splice(exist, 1);
      setFnAreas(fnAreaList);
      setParameters({ fn_areas: fnAreaList });
    } else {
      setFnAreas([...fnAreas, id]);
      setParameters({ fn_areas: [...fnAreas, id] });
    }
    const iterateOverFuncAreas = props.functionalArea.map((each, i) => {
      if (each.id === id) {
        return { ...each, isChecked: !each.isChecked };
      } else {
        return each;
      }
    });

    const iterateOverFunc1 = iterateOverFuncAreas.filter((each, i) => {
      if (each.isChecked) {
        return { ...each };
      }
    });

    const iterateOverFunc2 = iterateOverFuncAreas.filter((each, i) => {
      if (!each.isChecked) {
        return { ...each };
      }
    });

    props.update([...iterateOverFunc1, ...iterateOverFunc2]);
  };
  const handleKeySkillChange = (e, id) => {
    const exist = keySkills.findIndex(x => x === id);
    if (exist !== -1) {
      const fnAreaList = keySkills;
      keySkills.splice(exist, 1);
      setKeySkills(keySkills);
      setParameters({ key_skills: keySkills });
    } else {
      setKeySkills([...keySkills, id]);
      setParameters({ key_skills: [...keySkills, id] });
    }
    const iterateOverKeySkills = props.keySkills.map((each, i) => {
      if (each.id === id) {
        return { ...each, isChecked: !each.isChecked };
      } else {
        return each;
      }
    });

    const iterateOverKeySkills1 = iterateOverKeySkills.filter((each, i) => {
      if (each.isChecked) {
        return { ...each };
      }
    });

    const iterateOverKeySkills2 = iterateOverKeySkills.filter((each, i) => {
      if (!each.isChecked) {
        return { ...each };
      }
    });

    props.updateSkills([...iterateOverKeySkills1, ...iterateOverKeySkills2]);
  };

  const handleSalaryChange = (e, id, range) => {
    const exist = salary.findIndex(x => x === range);
    if (exist !== -1) {
      const salaryList = salary;
      salaryList.splice(exist, 1);
      setSalary(salaryList);
      setParameters({ salary: salaryList });
    } else {
      setSalary([...salary, range]);
      setParameters({ salary: [...salary, range] });
    }
    const iterateOverSalaryRange = props.salaryRange.map((each, i) => {
      if (each.id === id) {
        return { ...each, isChecked: !each.isChecked };
      } else {
        return each;
      }
    });

    const iterateOverSalaryRange1 = iterateOverSalaryRange.filter((each, i) => {
      if (each.isChecked) {
        return { ...each };
      }
    });

    const iterateOverSalaryRange2 = iterateOverSalaryRange.filter((each, i) => {
      if (!each.isChecked) {
        return { ...each };
      }
    });
    props.updateSalaryRange([
      ...iterateOverSalaryRange1,
      ...iterateOverSalaryRange2,
    ]);
  };
  const handleMoreJobs = () => {
    setModal(!modal);
    setShowJobs(!showJobs);
    const iterateOverSalaryRange = salaryRange.map(each => each);
    setSalaryRange(iterateOverSalaryRange);
  };

  // function displayFunctanality() {
  //   const filterFunctalityAreas = props.functionalArea?.filter(each =>
  //     each.name.toLowerCase().includes(searchFunc.toLowerCase())
  //   );
  //   if (filterFunctalityAreas?.length > 0) {
  //     return filterFunctalityAreas?.slice(0, 4).map((each, i) => {
  //       return (
  //         <li key={each.id}>
  //           <label className="cb-container">
  //             <input
  //               type="checkbox"
  //               checked={each.isChecked}
  //               onChange={e => handleFuncAreasChange(e, each.id)}
  //             />{" "}
  //             <span className="text-small">{each.name}</span>
  //             <span className="checkmark"></span>
  //           </label>
  //           <span className="number-item">{each.countOfJobs}</span>
  //         </li>
  //       );
  //     });
  //   } else {
  //     return "Nothing to show";
  //   }
  // }

  function displayKeyskills() {
    if (!props.keySkills) {
      // Render skeleton view
      return (
        <>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </>
      );
    }

    const filterFunctalityAreas = props.keySkills?.filter(each =>
      each.skill.toLowerCase().includes(searchKeySkill.toLowerCase())
    );

    if (filterFunctalityAreas?.length > 0) {
      return filterFunctalityAreas?.slice(0, 4).map((each, i) => {
        return (
          <li key={each.id}>
            <label className="cb-container">
              <input
                type="checkbox"
                checked={each.isChecked}
                onChange={e => handleKeySkillChange(e, each.id)}
              />{" "}
              <span className="text-small">{each.skill}</span>
              <span className="checkmark"></span>
            </label>
            <span className="number-item">{each.jobsCount}</span>
          </li>
        );
      });
    } else {
      return <p>No data</p>;
    }

    const handleMoreJobs = () => {
      setModal(!modal);
      setShowJobs(!showJobs);
      const iterateOverSalaryRange = salaryRange.map(each => each);
      setSalaryRange(iterateOverSalaryRange);
    };
  }

  const handleSaveOfSalary = id => {
    const iterateOverSalaryRange = salaryRange.map((each, i) => {
      if (i === id) {
        return { ...each, isChecked: !each.isChecked };
      } else {
        return each;
      }
    });
    setSalaryRange(iterateOverSalaryRange);
  };

  function displayFunctanality() {
    if (!props.functionalArea) {
      // Render skeleton view
      return (
        <>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </>
      );
    }

    const filterFunctalityAreas = props?.functionalArea?.filter(each =>
      each.name.toLowerCase().includes(searchFunc.toLowerCase())
    );

    if (filterFunctalityAreas?.length > 0) {
      return filterFunctalityAreas?.slice(0, 4).map((each, i) => {
        return (
          <li key={each.id}>
            <label className="cb-container">
              <input
                type="checkbox"
                // checked={each.isChecked}
                checked={each.id == fnAreasId ? true : each.isChecked}
                onChange={e => handleFuncAreasChange(e, each.id)}
              />{" "}
              <span className="text-small">{each.name}</span>
              <span className="checkmark"></span>
            </label>
            <span className="number-item">{each.countOfJobs}</span>
          </li>
        );
      });
    } else {
      return <p>No data</p>;
    }
  }


  const filterFunctalityAreasModal = props?.functionalArea?.filter(each =>
    each.name.toLowerCase().includes(searchModalFunc.toLowerCase())
  );

  const filterSkillsAreasModal = props?.keySkills?.filter(each =>
    each?.skill.toLowerCase().includes(searchModalSkill.toLowerCase())
  );

  return (
    <>
      <ThemeProvider theme={globalTheme}>
        {props ? (
          <div className="company_filter_main  ShifytScroll">
            <div className="company_filter_main_cont ">
              <div>
                <span className="company_filters_heading">Filters</span>
                <hr />
              </div>
              <div>
                <p
                  className="comp_filters_headers"
                  onClick={() => setShowCategories(!showCategories)}
                >
                  <span className="company_filters_heading">
                    {Constants.category}
                  </span>{" "}
                  {showCategories ? (
                    <span onClick={() => setShowCategories(!showCategories)}>
                      <BsChevronUp
                        size={14}
                        className="comp_filter_handle_icon"
                      />
                    </span>
                  ) : (
                    <span onClick={() => setShowCategories(!showCategories)}>
                      <BsChevronDown
                        size={14}
                        className="comp_filter_handle_icon"
                      />
                    </span>
                  )}
                </p>

                {showCategories ? (
                  <div>
                    <OutlinedInput
                      size="sm"
                      // startDecorator={<Search size="sm" color={"primary"} />}
                      startDecorator={
                        <Search size="sm" style={{ color: "#066a4c" }} />
                      }
                      type="text"
                      placeholder="Search categories..."
                      onChange={e => {
                        setSearchFunc(e.target.value), setShowCategories(true);
                      }}
                    />
                    <ul className="list-checkbox1">{displayFunctanality()}</ul>
                    {props?.functionalArea?.length > 4 ? (
                      <span
                        onClick={() => {
                          setCategoryModal(!categoryModal),
                            setShowCategories(!showCategories);
                        }}
                        style={{ color: "#066a4c", fontWeight: "bold" }}
                      >
                        {props?.functionalArea?.length > 4
                          ? `+${props?.functionalArea?.length - 4} more`
                          : ""}
                        <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
                      </span>
                    ) : (
                      ""
                    )}

                    <hr />
                  </div>
                ) : null}
                {categoryModal ? (
                  <>
                    <Modal
                      show={categoryModal}
                      onHide={handleCloseOfCategories}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{Constants.category}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group">

                        <OutlinedInput
                      size="sm"
                      // startDecorator={<Search size="sm" color={"primary"} />}
                      startDecorator={
                        <Search size="sm" style={{ color: "#066a4c" }} />
                      }
                      type="text"
                      placeholder="Search categories..."
                      onChange={e => {
                        setSearchModalFunc(e.target.value)
                        // setShowCategories(true);
                      }}
                    />

                          <ul
                            className="list-checkbox ShifytScroll"
                            style={{ height: "300px", overflow: "auto" }}
                          >
                            {/* {props?.functionalArea?.map((each, i) => { */}
                            {filterFunctalityAreasModal?.map((each, i) => {
                              return (
                                <li key={each.id}>
                                  <label className="cb-container">
                                    <input
                                      type="checkbox"
                                      // checked={each.isChecked}
                                      checked={
                                        each.id == fnAreasId
                                          ? true
                                          : each.isChecked
                                      }
                                      onChange={e =>
                                        handleFuncAreasChange(e, each.id)
                                      }
                                    />{" "}
                                    <span className="text-small">
                                      {each.name}
                                    </span>
                                    <span className="checkmark"></span>
                                  </label>
                                  <span className="number-item">
                                    {each.countOfJobs}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleCloseOfCategories}
                          style={{
                            background: "#ffe7bb",
                            color: "#066a4c",
                            border: "0px",
                            fontWeight:"bold"
                          }}
                        >
                          Close
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleCloseOfCategories}
                          style={{
                            background: "#cde2e7",
                            color: "#066a4c",
                            border: "0px",
                            fontWeight:"bold"
                          }}
                        >
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : null}
              </div>

              <div>
                <p
                  className="comp_filters_headers"
                  onClick={() => setShowKeySkills(!showKeySkills)}
                >
                  <span className="company_filters_heading"> Keyskills</span>
                  {showKeySkills ? (
                    <span onClick={() => setShowKeySkills(!showKeySkills)}>
                      <BsChevronUp size={14} />
                    </span>
                  ) : (
                    <span onClick={() => setShowKeySkills(!showKeySkills)}>
                      <BsChevronDown size={14} />
                    </span>
                  )}
                </p>

                {showKeySkills ? (
                  <>
                    <OutlinedInput
                      size="sm"
                      // startDecorator={<Search size="small" color={"primary"} />}
                      startDecorator={
                        <Search size="sm" style={{ color: "#066a4c" }} />
                      }
                      // type="text"
                      placeholder="Search keyskills..."
                      onChange={e => {
                        setSearchKeySkill(e.target.value),
                          setShowKeySkills(true);
                      }}
                    />
                    <ul className="list-checkbox">{displayKeyskills()}</ul>
                    {props.keySkills?.length > 4 ? (
                      <span
                        onClick={() => {
                          setKeySkillModal(!keySkillModal),
                            setShowKeySkills(!showKeySkills);
                        }}
                        style={{ color: "#066a4c", fontWeight: "bold" }}
                      >
                        {props.keySkills?.length > 4
                          ? `+${props?.functionalArea?.length - 4} more`
                          : ""}
                        <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
                      </span>
                    ) : (
                      ""
                    )}

                    <hr />
                  </>
                ) : null}
                {keySkillModal ? (
                  <>
                    <Modal show={keySkillModal} onHide={handleCloseOfKeySkills}>
                      <Modal.Header closeButton>
                        <Modal.Title>Keyskills</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group">

                          
                        <OutlinedInput
                      size="sm"
                      // startDecorator={<Search size="sm" color={"primary"} />}
                      startDecorator={
                        <Search size="sm" style={{ color: "#066a4c" }} />
                      }
                      type="text"
                      placeholder="Search keyskills..."
                      onChange={e => {
                        setSearchModalSkill(e.target.value)
                        // setShowCategories(true);
                      }}
                    />

                          <ul
                            className="list-checkbox ShifytScroll"
                            style={{ height: "300px", overflow: "auto" }}
                          >
                            {/* {props.keySkills?.map((each, i) => { */}
                            {filterSkillsAreasModal?.map((each, i) => {
                              return (
                                <li key={each.id}>
                                  <label className="cb-container">
                                    <input
                                      type="checkbox"
                                      checked={each.isChecked}
                                      onChange={e =>
                                        handleKeySkillChange(e, each.id)
                                      }
                                    />{" "}
                                    <span className="text-small">
                                      {each.skill}
                                    </span>
                                    <span className="checkmark"></span>
                                  </label>
                                  <span className="number-item">
                                    {each.jobsCount}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleCloseOfKeySkills}
                          style={{
                            background: "#ffe7bb",
                            color: "#066a4c",
                            border: "0px",
                            fontWeight:"bold"
                          }}
                        >
                          Close
                        </Button>
                        <Button
                          variant="primary"
                          style={{
                            background: "#cde2e7",
                            color: "#066a4c",
                            border: "0px",
                            fontWeight:"bold"
                          }}
                          onClick={handleCloseOfKeySkills}
                        >
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : null}
              </div>

              <div>
                <p
                  className="comp_filters_headers"
                  onClick={() => setShowJobs(!showJobs)}
                >
                  <span className="company_filters_heading">
                    {Constants.type}
                  </span>
                  {showJobs ? (
                    <span onClick={() => setShowJobs(!showJobs)}>
                      <BsChevronUp size={14} />
                    </span>
                  ) : (
                    <span onClick={() => setShowJobs(!showJobs)}>
                      <BsChevronDown size={14} />
                    </span>
                  )}
                </p>

                {showJobs ? (
                  <div className="form-group">
                    <ul className="list-checkbox">
                      {props.jobTypes ? (
                        props.jobTypes?.map(each => {
                          return (
                            <li key={each.id}>
                              <label className="cb-container">
                                <input
                                  type="checkbox"
                                  isChecked={each.isChecked}
                                  onChange={(e, id) =>
                                    handleChecked(e, each.id, each.jobType)
                                  }
                                />{" "}
                                <span className="text-small">
                                  {each.jobType.replace("_", " ")}
                                </span>
                                <span className="checkmark"></span>
                              </label>
                              <span className="number-item">
                                {each.jobPostsCount}
                              </span>
                            </li>
                          );
                        })
                      ) : (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress color="success"/>
                        </Box>
                      )}
                    </ul>
                  </div>
                ) : null}
              </div>
              <hr />

              <div>
                <p
                  className="comp_filters_headers"
                  onClick={() => setWork(!work)}
                >
                  <span className="company_filters_heading">Work Mode</span>
                  {work ? (
                    <span onClick={() => setWork(!work)}>
                      <BsChevronUp size={14} />
                    </span>
                  ) : (
                    <span onClick={() => setWork(!work)}>
                      <BsChevronDown size={14} />
                    </span>
                  )}
                </p>

                {work ? (
                  <div className="form-group">
                    <ul className="list-checkbox">
                      {props.workTypes ? (
                        props.workTypes?.map((item, id) => {
                          return (
                            <li key={id}>
                              <label className="cb-container">
                                <input
                                  type="checkbox"
                                  isChecked={item.isChecked}
                                  onChange={(e, id) =>
                                    handleCheckedWork(e, id, item.workModeValue)
                                  }
                                />{" "}
                                <span className="text-small">
                                  {item.workModeShown}
                                </span>
                                <span className="checkmark"></span>
                              </label>
                              <span className="number-item">
                                {item.workModeCount}
                              </span>
                            </li>
                          );
                        })
                      ) : (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress color="success"/>
                        </Box>
                      )}
                    </ul>
                  </div>
                ) : null}
              </div>
              <hr />

              {/* <div>
            <p
              className="comp_filters_headers"
              onClick={() => setShowExperience(!showExperience)}
            >
              <span className="company_filters_heading">
                {Constants.experience}
              </span>
              {showExperience ? (
                <span onClick={() => setShowExperience(!showExperience)}>
                  <BsChevronUp size={14} />
                </span>
              ) : (
                <span onClick={() => setShowExperience(!showExperience)}>
                  <BsChevronDown size={14} />
                </span>
              )}
            </p>

            {showExperience ? (
              <div className="form-group">
                <Slider
                  defaultValue={1}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                 
                  step={1}
                  min={0}
                  max={30}
                  onChange={e => {
                    setExperienceLevel(e.target.value);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <span>0Yrs</span>
                  <span>30Yrs</span>
                </div>
                <button
                  className="theme_new_btn "
                  onClick={() => {
                    setParameters({ exp: experienceLevel });
                  }}
                >
                  Apply
                </button>
                <hr />
              </div>
            ) : null}
          </div> */}
              <div>
                <p
                  className="comp_filters_headers"
                  onClick={() => setShowSalary(!showSalary)}
                >
                  <span className="company_filters_heading">
                    {Constants.salary}
                  </span>
                  {showSalary ? (
                    <span onClick={() => setShowSalary(!showSalary)}>
                      <BsChevronUp size={14} />
                    </span>
                  ) : (
                    <span onClick={() => setShowSalary(!showSalary)}>
                      <BsChevronDown size={14} />
                    </span>
                  )}
                </p>

                {showSalary ? (
                  <div className="form-group">
                    <ul className="list-checkbox">
                      {props?.salaryRange ? (
                        props.salaryRange?.map(each => {
                          return (
                            <li key={each.id}>
                              <label className="cb-container">
                                <input
                                  type="checkbox"
                                  checked={each.isChecked}
                                  onChange={e =>
                                    handleSalaryChange(
                                      e,
                                      each.id,
                                      each.salaryValue
                                    )
                                  }
                                />{" "}
                                <span className="text-small">
                                  {each.salaryRange}
                                </span>
                                <span className="checkmark"></span>
                              </label>
                              <span className="number-item">
                                {each.jobsCountOnSalary}
                              </span>
                            </li>
                          );
                        })
                      ) : (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress color="success"/>
                        </Box>
                      )}
                    </ul>
                  </div>
                ) : null}
                {modal ? (
                  <>
                    <Modal show={modal} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Salary Range</Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        <div className="form-group">
                          <ul className="list-checkbox ShifytScroll">
                            {props.salaryRange.map(each => {
                              return (
                                <li key={each.id}>
                                  <label className="cb-container">
                                    <input
                                      type="checkbox"
                                      checked={each.isChecked}
                                      onChange={e =>
                                        handleSalaryChange(e, each.salaryValue)
                                      }
                                    />{" "}
                                    <span className="text-small">
                                      {each.salaryRange}
                                    </span>
                                    <span className="checkmark"></span>
                                  </label>
                                  <span className="number-item">
                                    {each.jobsCountOnSalary}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleSaveOfSalary}>
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : null}

                {showSalary ? (
                  <div className="">
                    <div className="row mb-20">
                      <div className="col-sm-12">
                        <div id="slider-range"></div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div style={{ marginBottom: "30px" }}>
                <p
                  className="comp_filters_headers"
                  onClick={() => setShowExperience(!showExperience)}
                >
                  <span className="company_filters_heading">
                    {Constants.experience}
                  </span>
                  {showExperience ? (
                    <span onClick={() => setShowExperience(!showExperience)}>
                      <BsChevronUp size={14} />
                    </span>
                  ) : (
                    <span onClick={() => setShowExperience(!showExperience)}>
                      <BsChevronDown size={14} />
                    </span>
                  )}
                </p>

                {showExperience ? (
                  <div className="form-group pt-4">
                    <Slider
                      defaultValue={1}
                      aria-label="Default"
                      valueLabelDisplay="on"
                      sx={{
                        "& .MuiSlider-track": {
                          backgroundColor: "#cde2e7", // Replace with your desired color
                        },
                        "& .MuiSlider-thumb": {
                          backgroundColor: "#066a4c", // Replace with your desired color
                        },
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "#066a4c", // Replace with your desired value label background color
                        },
                      }}
                      step={1}
                      min={0}
                      max={30}
                      onChange={e => {
                        setExperienceLevel(e.target.value);
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <span>0Yrs</span>
                      <span>30Yrs</span>
                    </div>
                    <button
                      className="theme_new_btn "
                      onClick={() => {
                        setParameters({ exp: experienceLevel });
                      }}
                    >
                      Apply
                    </button>

                    {/* <button
                  className="theme_new_btn "
                 onClick={()=>removeAllFilters()}
                >
                  clear
                </button> */}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </ThemeProvider>
    </>
  );
}
export default SidePanelFilters;
