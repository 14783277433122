import React, { useState, useEffect } from "react";
import Navbar from "./Navabar/index";
import Footer from "./footer/Footer";
import SignUpForJobs from "../pages/JobsList/SignUpForJobs/SignUpForJobs";
import { useLocation } from "react-router-dom";

export default function LayoutUI({ children }) {
  const location = useLocation();
  useEffect(() => {
    // Get the page name from the location and set the title
    document.title = getPageTitle(location.pathname);
  }, [location]);

  const getPageTitle = path => {
    switch (path) {
      case "/jobs-list":
        return "Jobs";
      case "/employer-list":
        return "Companies";
      case "/employer-list":
        return "Companies";
      case "/profile":
        return "Profile";
      case "/dashboard":
        return "Dashboard";
      case "/refered-jobs":
        return "Refered Jobs";
      case "/my-referals":
        return "My Referals";
      case "/applied-jobs":
        return "Applied jobs";
      case "/my-savedjobs":
        return "Saved Jobs";
      case "/demochat":
        return "Chat";
      case "/interviews":
        return "Interviews";
      case "/leadership-board":
        return "Leadership Board";
      case "/myearning":
        return "My Earnings";
      case "/settings":
        return "Settings";
      case "/login":
        return "Login";
      case "/registration":
        return "registration";  
      case "/registration":
          return "registration";  
      case "/forget-password":
        return "Forget Password"    
      default:
        return "Shifyt";
    }
  };

  return (
    <>
      <Navbar />
      <main className="">{children}</main>
      {/* <SignUpForJobs /> */}
      {/* <Footer /> */}
    </>
  );
}
