import React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import defaultCompanyImage from "../../assets/images/company_default_img.svg";


function CompanyCard({ item }) {
  const navigate = useNavigate();
  return (
    <>
      <Card
        variant="outlined"
        orientation="vertical"
        sx={{
          //   width: 320,
          borderColor: "rgba(0, 0, 0, 0.07)",
          marginRight: 1,
          "@media (min-width: 0px) and (max-width: 577px)": {
            marginRight: 0,
            marginLeft: 1,
          },
          gap: 2,
          "&:hover": {
            boxShadow: "md",
            borderColor: "neutral.outlinedHoverBorder",
          },
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: 8,minHeight:"65px",maxHeight:"66px"}}>
          <AspectRatio
            ratio="1"
            sx={{ height: 58, width: 58, borderRadius: "50%",borderColor:"rgba(0, 0, 0, 0.07)",border:"1px solid rgba(0, 0, 0, 0.07)"}}
          >
            <img
              src={
                item.company_image_details
                  ? item.company_image_details
                  : defaultCompanyImage
              }
              loading="lazy"
              alt=""
              onError={e => {
                e.target.src = defaultCompanyImage;
              }}
            />
          </AspectRatio>
          <div>
            <Typography
              level="h2"
              fontSize="lg"
              id="card-description"
              className="company_name_txt"
              style={{fontFamily:"Inter"}}
              onClick={() =>
                navigate(
                  `../employer-single-list?id=${item?.company_user_details?.id}`
                )
              }
            >
              {(item?.company_name).charAt(0).toUpperCase() +
                (item?.company_name).slice(1)}
            </Typography>
            <Typography
              fontSize="sm"
              aria-describedby="card-description"
              className="company_location_txt"
              sx={{fontFamily:"Inter,sans-serif"}}
            >
              {item?.location
                ? item.location.toString().slice(0, 17) + "..."
                : "--"}
            </Typography>
          </div>
        </div>
        {/* <Typography className="company_descr_txt">
          {item?.describe_your_culture
            ? item.describe_your_culture.slice(0, 25)
            : "Great Place to work"}
        </Typography> */}
        <Typography className="company_descr_txt" sx={{fontFamily:"Inter"}}>
          {item?.describe_your_culture
            ? item.describe_your_culture.length > 25
              ? `${item.describe_your_culture.slice(0, 25)}...`
              : item.describe_your_culture
            : "Great Place to work"}
        </Typography>

        {item.company_job_post_count > 0 ? (
          <Button
            variant="outlined"
            startIcon={<CheckCircleIcon />}
            style={{
              width: "fit-content",
              background: "#E0F9EA",
              color: "#00431B",
              borderRadius: 8,
              borderColor: "#E0F9EA",
              textTransform: "capitalize",
              textAlign: "start",
              fontFamily: "Inter,sans-serif",
              fontWeight: 600,
              fontSize: "12px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            Actively Hiring
          </Button>
        ) : (
          <Button
            variant="outlined"
            style={{
              width: "fit-content",
              background: "#E0F9EA",
              color: "#00431B",
              borderRadius: 8,
              borderColor: "#E0F9EA",
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "12px",
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            Actively Hiring
          </Button>
        )}

        <Divider  sx={{ borderColor: "rgba(0, 0, 0, 0.07)", borderWidth: "0.9px",width:"100%"}} component="li"/>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p className="open_text">
            {item.company_job_post_count} Open positions
          </p>
          <p
            onClick={() =>
              navigate(
                `/employer-single-list?id=${item?.company_user_details?.id}`
              )
            }
          >
            <ArrowForwardIosIcon fontSize="md" />
          </p>
        </div>
      </Card>
    </>
  );
}

export default CompanyCard;
