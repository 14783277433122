import React, { useState } from "react";
import { PasswordModal } from "./PasswordModal";

function WithdrawMoney() {
    
    const [passwordModal, setPasswordModal] = useState({
        modal: false,
        type: "",
        action: "",
        modalType: ""
    })

    const data = [
        {
            id: 1,
            label: "Transferwise (Recommended)",
            desc: "Transferwise may charge additional fee, take up to 4 working days."
        },
        {
            id: 2,
            label: "Stripe (Us Banks Only)",
            desc: "ACH transfer costs 0.8%, capped at $5, takes up to 3 working days"
        }
    ]

    return (
        <>
            <div className="row align-items-center">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="card p-3 border-0">
                        <h5 className="mb-3 text-secondary">ADD WITHDRAW METHOD</h5>
                        <div>
                            {
                                data.map((item, index) =>
                                    <div key={index} className="d-flex" style={{ marginBottom: "50px" }}>
                                        <div className="flex-shrink-0">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onChange={() => setPasswordModal({ modal: true, details: "" })}
                                                />
                                                <label className="form-check-label mb-0 h5" for="flexRadioDefault1">
                                                    {item.label}
                                                </label>
                                                <div>{item.desc}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="card p-5 border-0 table-bg">
                        <h6 className="mb-4">WITHDRAW</h6>
                        <label>Enter the Amount</label>
                        <div className="input-group mb-3">
                            <input type="text"
                                className="form-control"
                                value="0.000"
                            />
                            <span className="input-group-text" id="basic-addon2">USD</span>
                        </div>

                        <button className="btn btn-primary">Withdraw</button>
                    </div>
                </div>
            </div>

            {passwordModal ? <PasswordModal
                setPasswordModal={(doc) => setPasswordModal(doc)}
                show={passwordModal.modal}
                onHide={() =>
                    setPasswordModal({ modal: false, modalType: "", type: "", details: {} })
                }
                params={passwordModal}
            /> : null}
        </>
    )
}
export default WithdrawMoney;


