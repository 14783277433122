import React, { useEffect, useState } from "react";
import Profile from "./Profiles/Profile";
import Sidebar from "./Sidebar/Sidebar";
import ProfileSummary from "./LocalProfile/TestProfile";
import ProfileHeader from "./LocalProfile/ProfileHeader";
import { ThemeProvider } from "@mui/material/styles";
import { globalTheme } from "../../assets/MUI/materialUiStyles";
import ErrorBoundary from "../../components/common/ErrorBoundary";

function AccountsIndex(props) {
  return (
    <div className="d-flex align-items-start gap-2 justify-content-center" style={{marginTop:"30px",marginLeft:"58px"}}>
      <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
        <Sidebar />
      </div>
      <div className="col-lg-10">
        {/* <Profile /> */}

        <ProfileHeader />
        <ThemeProvider theme={globalTheme}>
        <ProfileSummary />
        </ThemeProvider>
      </div>
    </div>
  );
}

export default AccountsIndex;
