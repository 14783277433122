import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";
import EarningsTable1 from './EarningsTable1';
import WithDrawTable1 from "./TransactionTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {/* <Typography>{children}</Typography> */}
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 16,
  fontWeight:700,
  textTransform: "none",
  color: "rgba(0, 0, 0, 0.75)",
  fontFamily: "Inter,sans-serif",
  fontSize: "16px",
  "&:first-letter": {
    textTransform: "capitalize",
  },
}));


function MyEarningsTabs(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "80vw" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
               
                ".MuiTabs-indicator":{
                    backgroundColor:"#012582"
                },
                ".MuiTab-root.Mui-selected ": {
                    color:"#012582"
                },
               
              }}
          >
            <StyledTab
              label="Earnings"
              {...a11yProps(0)}
              
            />
            <StyledTab label="Transactions" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <EarningsTable1 tableData={props.tableData}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <WithDrawTable1 withDrawTableData={props.withDrawTableData}/>
        </TabPanel>
      </Box>
    </>
  );
}

export default MyEarningsTabs;
