import React , {useState} from 'react';
import { Button } from '@mui/material';
import CompanyListCarousal from '../Home/CompanyListCarousal'

function CompaniesScrollPage(){
    return (
        <>
         <div style={{ marginTop: "50px" }} className="hire_candidates_card_section_layout">
          <section className="companies_carousal_sec">
            <div className="companies_car_main_sec">
              <div className="comp_inside_sec">
                <h2 className="comp_heading">
                  Featured <span className="comp_span_text">Companies</span>{" "}
                  Actively us
                </h2>
                <div className="comp_carousal_section">
                  {/* <CompanyCarousal /> */}
                  <CompanyListCarousal />
                </div>
              </div>
            </div>
            <div
              className="view_more_btn_home"
              onClick={() => navigate("../employer-list")}
            >
              <Button
                variant="outlined"
                className="apply-now-btn cursor-pointerf"
                style={{
                  background: "rgba(8, 24, 84, 0.1)",
                  fontFamily: "Inter,sans-serif",
                  fontWeight: "700",
                  color: "#081854",
                  border: "0px",
                  marginTop: "60px",
                  textTransform:"capitalize"
                }}
              >
                View More Companies
              </Button>
            </div>
          </section>
        </div>
        </>
    )
}

export default CompaniesScrollPage;