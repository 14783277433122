import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { NotificationSwitch } from "../../assets/MUI/globalStyles";
import { MiddleDivider } from "../../assets/MUI/globalStyles";
import { NotificationTitleTypography } from "../../assets/MUI/globalStyles";
import { NotificationDescTypography } from "../../assets/MUI/globalStyles";
import { NotificationDescTextTypography } from "../../assets/MUI/globalStyles";

const NotificationsList = [
    {
    id:0,
    name:"Account",
    description:{
        heading:"Account settings are updated",
        statusDescription:"You will receive a notification when you make a change in the administration such as enabling or disabling features"
    },
    applicationStatus:"true",
    applicationStatus:"true",
},
{
    id:2,
    name:"Users",
    description:{
        heading:"Account settings are updated",
        statusDescription:"You will receive a notification when you make a change in the administration such as enabling or disabling features"
    },
    applicationStatus:"true",
    applicationStatus:"true",
},
{
    id:3,
    name:"Organizations",
    description:{
        heading:"Account settings are updated",
        statusDescription:"You will receive a notification when you make a change in the administration such as enabling or disabling features"
    },
    applicationStatus:"true",
    applicationStatus:"true",
},
{
    id:4,
    name:"Jobs",
    description:{
        heading:"Account settings are updated",
        statusDescription:"You will receive a notification when you make a change in the administration such as enabling or disabling features"
    },
    applicationStatus:"true",
    applicationStatus:"true",
}
]

function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          {/* <Item>Item</Item> */}
        </Grid>
        <Grid item xs={5}>
         Notification Settings
        </Grid>
        <Grid item xs={2}>
          In Application<NotificationSwitch />
        </Grid>
        <Grid item xs={2}>
        Email<NotificationSwitch />
        </Grid>
        <MiddleDivider varient="middle" style={{}}/>
      </React.Fragment>
    );
  }


function NotificationEnable() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid container item spacing={1}>
            <FormRow />
          </Grid>    
        </Grid>

        <Grid container spacing={1} sx={{marginTop:"20px"}}> 
        {NotificationsList.map((each)=>{
            return (
                <>
                <Grid lg={3}>
                   <NotificationTitleTypography>{each.name}</NotificationTitleTypography>
                </Grid>
                <Grid lg={5}>
                   <NotificationDescTypography>{each.description.heading}</NotificationDescTypography>
                   <NotificationDescTextTypography>{each.description.statusDescription}</NotificationDescTextTypography>
                </Grid>
                <Grid lg={2}>
                <NotificationSwitch />
                </Grid>
                <Grid lg={2}>
                <NotificationSwitch />
                </Grid>
                <MiddleDivider varient="middle" sx={{borderWidth:"0.1px",borderColor:"pink"}}/>
                </>
            )
        })}
        
        </Grid>

      </Box>
    </>
  );
}

export default NotificationEnable;
