export const convertToInputDates = date => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  let month = dateObj.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let day = dateObj.getDate();
  if (day < 10) day = "0" + day;
  const output = year + "-" + month + "-" + day;
  return output;
};

export const hoursToStandard = date => {
  let hours1 = new Date(date).getHours();
  let minutes = new Date(date).getMinutes();
  let ampm = hours1 >= 12 ? "pm" : "am";
  hours1 = hours1 % 12;
  hours1 = hours1 ? hours1 : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours1 + ":" + minutes + " " + ampm;
  return strTime;
};

export function convertintoStringDates(item) {
  return `${new Date(item?.createdAt).getDate()}/${
    new Date(item?.createdAt).getMonth() + 1
  }/ ${new Date(item?.createdAt).getFullYear()}`;
}
