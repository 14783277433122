import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { imageURL } from "../../../Constants/commonURLS";
import { ReferNowModal } from "./ReferModal";
import getSymbolFromCurrency from "currency-symbol-map";
import "../../../assets/JobList/AllJobs.css";
import defaultCompanyImage from "../../../assets/images/company_default_img.svg";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import AspectRatio from "@mui/joy/AspectRatio";
import CardContent from "@mui/joy/CardContent";
//import List from './List';
import Skeleton from "@mui/material/Skeleton";
import { BookmarksOutlined } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import EarnImage from "../../../assets/images/earnImage.svg";
import workIcon from "../../../assets/images/work 2.svg";
import LocationIcon from "../../../assets/images/location 2.svg";
import peopleIcon from "../../../assets/images/people.svg";
import heartIcon from "../../../assets/images/heart.svg";
import { useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PlaceIcon from "@mui/icons-material/Place";
import FaceIcon from "@mui/icons-material/Face";
import { savedJobById } from "../../../helpers/dataFetcher/jobs/jobs";
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Favorite from "@mui/icons-material/Favorite";
import { toast } from "react-hot-toast";
import NotificationDrawer from "../../Notifications/NotificationDrawer";
import JobDrawer from "../../Notifications/JobDrawer";
import { motion } from "framer-motion";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ShareIcon from '@mui/icons-material/Share';
import copy from "copy-to-clipboard";
const options = ["Share"];
const ITEM_HEIGHT = 48;

function Lists(props) {
  
  /* share link code start here */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id,setId] = React.useState({id:""})
  const loginUserId = localStorage.getItem("user_id")
  const openS = Boolean(anchorEl);
  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClick = (event,id) => {
    setId({id:id})
    setAnchorEl(event.currentTarget);
    handleCloseOption(id)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseOption = (id) => {
    if(loginUserId){
      copy(`https://shifyt.com/job?id=${id}&&userId=${loginUserId}`);
    }else {
      copy(`https://shifyt.com/job?id=${id}`);
    }
  
    toast.success("link copied successfully");
    setAnchorEl(null);
  };

  /* share link code End here */

  const [open, setOpen] = React.useState("");
  const [heartOpen, setHeartOpen] = useState(true);
  const [type, setType] = useState("");
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [referModal, setReferModal] = useState({
    modal: "",
    purpose: "",
    modalType: "",
  });

  // props.jobsList?.sort(function (a, b) {
  //   // Turn your strings into dates, and then subtract them
  //   // to get a value that is either negative, positive, or zero.
  //   return (
  //     new Date(b.createdAt.slice(0, 10).toLocaleString()) -
  //     new Date(a.createdAt.slice(0, 10).toLocaleString())
  //   );
  // });
  const user = useSelector(state => state.User.user);

  const number = 200000;

  // const abbreviateNumber = (number) => {
  //   const SI_SYMBOLS = ["", "k", "M", "G", "T", "P", "E"];
  //   const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
  //   const scaledNumber = number / Math.pow(10, tier * 3);
  //   const suffix = SI_SYMBOLS[tier];
  //   return `${scaledNumber.toFixed(1)}${suffix}`;
  // };

  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    if (scaledNumber.toFixed(0) > 0) {
      return `${scaledNumber.toFixed(0)}k`;
    } else {
      return `${scaledNumber.toFixed(0)}`;
    }
    // return `${scaledNumber.toFixed(0)}k`;
  };

  /*"G" represents "giga" and denotes a factor of 10^9 (1,000,000,000).
"T" represents "tera" and denotes a factor of 10^12 (1,000,000,000,000).
"P" represents "peta" and denotes a factor of 10^15 (1,000,000,000,000,000).
"E" represents "exa" and denotes a factor of 10^18 (1,000,000,000,000,000,000).*/

  const handelSavedJobs = async data => {
    const { status } = await savedJobById(data);
    if (status !== 200) {
      setTimeout(() => {
        toast.success("Saved job successfully");
      }, []);
    } else {
      setTimeout(() => {
        toast.error("Unsaved job successfully");
      }, []);
    }
    props.fechJobsBasedonOffset();
  };

  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("user_id")

  return (
    <>
      <div className="content-page">
        <div className="box-filters-job mt-15 mb-10"></div>
        <div className="job-list-list mb-15">
          <div className="list-recent-jobs">
            {props.jobsList?.map((item, index) => (
              <React.Fragment key={index}>
                <div className="latest_jobs_main_card_section">
                  {/* <span
                    class="urgent-hiring-tag color-primary-bg ng-scope"
                    ng-if="item.is_premium > 0 &amp;&amp; item.job_post_status!='hold' &amp;&amp; item.invites_jobs==0"
                  >
                    <span>
                      <i class="fa fa-clock-o"></i>
                    </span>
                    Urgently hiring
                  </span> */}

                  <div key={item.id} className="latest_jobs_inside_section">
                    <div className="title_section_1">
                      <div className="title_name_logo_sec">
                        <div
                          style={{
                            height: "44px",
                            width: "44px",
                            borderRadius: "50%",
                            border: "0.5px solid rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <Avatar
                            src={`${imageURL}${item?.image}`}
                            style={{
                              borderRadius: "50%",
                              borderColor: "rgba(0, 0, 0, 0.25)",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            {item?.company_name?.charAt(0)}
                          </Avatar>
                        </div>
                        <div className="title_sec_divider">
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              fontWeight="md"
                              textColor="primary.plainColor"
                              mb={0.5}
                              style={{
                                cursor: "pointer",
                                textTransform: "capitalize",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#121212",
                                fontFamily: "Inter,sans-serif",
                              }}
                              onClick={() => navigate(`../job?id=${item.id}&&userId=${userId}`)}
                              // onClick={() => setDrawerOpen(true)}
                            >
                              {item?.job_title?.length <= 20
                                ? item?.job_title
                                : item?.job_title.slice(0, 18) + ".."}
                            </Typography>
                            <span>
                              {item?.job_title && (
                                <JobDrawer
                                  id={item.id}
                                  isDrawerOpen={isDrawerOpen}
                                  setDrawerOpen={setDrawerOpen}
                                  title={item.job_title}
                                />
                              )}
                            </span>
                          </div>

                          {/* {item?.job_title && <JobDrawer id={item.id} isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} title={item.job_title}/>}     */}

                          {/* {isDrawerOpen && <JobDrawer id={item.id} isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen}/>} */}
                          {/* <JobDrawer id={item.id} isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen}/> */}
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                            }}
                            // onClick={() =>
                            //   navigate(
                            //     `../employer-single-list/${item?.company_user_id}`
                            //   )
                            // }
                            onClick={() =>
                              navigate(
                                `../employer-single-list?id=${item?.company_user_id}`
                              )
                            }
                          >
                            {/* {item.company_name} */}
                            {item?.company_name?.length <= 20
                              ? item?.company_name
                              : item?.company_name.slice(0, 18) + ".."}
                          </Typography>
                        </div>
                      </div>
                      <div className="commission_sec">
                        {/* <Stack direction="row" spacing={2}>
                          <Avatar alt="Remy Sharp" src={EarnImage} />
                        </Stack> */}
                        <div className="title_sec_divider">
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            sx={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#121212",
                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            Earn Upto
                          </Typography>
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            sx={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "Bold",
                              color: "#066a4c !important",
                              // opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            {getSymbolFromCurrency(item.currency)}{" "}
                            {/* {Math.round(
                              (item.max_sal * item.commission_rate) /
                                100 /
                                1000,
                              2
                            )}
                            K */}
                            {abbreviateNumber(
                              (item.max_sal * item.commission_rate) / 100
                            )}
                          </Typography>

                         
                        </div>
                        <IconButton
                            variant="plain"
                            color="neutral"
                            size="sm"
                            sx={{ ml: "auto",marginTop:"-17px"}}
                            aria-controls={openS ? "long-menu" : undefined}
                            aria-expanded={openS ? "true" : undefined}
                            aria-haspopup="true"
                            // onClick={handleClick}
                            onClick={(event)=>handleClick(event,item?.id)}
                          >
                  
                            <ShareIcon fontSize="small"/>
                          </IconButton>
                      </div>
                    </div>

                    {/* <div className="keypoints_section_2">
                      <div className="keypoints_inside_section">
                        <div className="basic_details_section">
                          <img src={workIcon} className="icon_size" />
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                              paddingLeft: "5px",
                            }}
                          >
                            {item.min_exp}-{item.max_exp}Yrs
                          </Typography>
                        </div>

                        <div
                          style={{
                            borderLeft: "1px",
                            borderStyle: "solid",
                            height: "22px",
                            top: "10",
                            fontWeight: "400",
                            color: "#000000",
                            opacity: "26%",
                            fontFamily: "Inter,sans-serif",
                            // paddingLeft:"12px",
                            marginLeft:"12px"  
                          }}
                          className="hr_line"
                        ></div>

                        <div className="basic_details_section">
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            {getSymbolFromCurrency(item.currency)}
                            {"  "}
                            {Math.round(item.min_sal / 1000, 2)}k-{" "}
                            {Math.round(item.max_sal / 1000, 2)}k
                          </Typography>

                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                              paddingLeft:"5px"
                            }}
                          >
                            {getSymbolFromCurrency(item.currency)}
                            {"  "}
                            {abbreviateNumber(item.min_sal)}-{" "}{abbreviateNumber(item.max_sal)}
                          </Typography>
                        </div>

                        <div
                          style={{
                            borderLeft: "1px",
                            borderStyle: "solid",
                            height: "22px",
                            top: "10",
                            fontWeight: "400",
                            color: "#000000",
                            opacity: "26%",
                            fontFamily: "Inter,sans-serif",
                            marginLeft:"12px" 
                          }}
                          className="hr_line"
                        ></div>

                        <div className="basic_details_section">
                          <img src={LocationIcon} className="icon_size" />
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              overflow: "hidden",
                              maxWidth: "200px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                              paddingLeft: "5px",
                            }}
                          >
               
                            {item?.location?.length <= 17 ? item?.location : item?.location.slice(0, 35) + ".."}
                            
                          </Typography>
                        </div>

                        <div
                          style={{
                            borderLeft: "1px",
                            borderStyle: "solid",
                            height: "22px",
                            top: "10",
                            fontWeight: "400",
                            color: "#000000",
                            opacity: "26%",
                            fontFamily: "Inter,sans-serif",
                          }}
                          className="hr_line"
                        ></div>

                        <div className="basic_details_section">
                          <img src={peopleIcon} className="icon_size" />
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                              paddingLeft: "5px",
                            }}
                          >
                            {item.positions?item.positions:""}
                          </Typography>
                        </div>
                      </div>
                    </div> */}

                    <div
                      className="main_skills_sec"
                      style={{ minHeight: "80px", maxHeight: "81px" }}
                    >
                      <div className="job_card_top_details_commision_cont">
                        <div className="job_details"></div>
                        <span className="mx-2"></span>
                        <span className="card-job-top--price">
                          {item.time}
                          <span></span>
                        </span>
                      </div>
                      <div className="jobs_title_card">
                        <Chip
                          variant="solid"
                          color="neutral"
                          size="sm"
                          style={{
                            color: "#777AD6",
                            background: "#F5ECFF",
                            fontFamily: "Inter",
                          }}
                        >
                          {item.job_type.replace("_", " ")}
                        </Chip>

                        <Chip
                          variant="solid"
                          color="neutral"
                          size="sm"
                          icon={<LocationOnIcon />}
                          style={{
                            color: "#081753",
                            background: "rgba(8, 23, 83, 0.07)",
                            fontFamily: "Inter",
                          }}
                        >
                          {item?.location?.length <= 17
                            ? item?.location
                            : item?.location.slice(0, 35) + ".."}
                        </Chip>
                        <Chip
                          variant="soft"
                          color="neutral"
                          size="sm"
                          sx={{ fontFamily: "Inter" }}
                        >
                          {/* {item.min_exp}-{item.max_exp}
                          &nbsp; Yrs */}
                          {`${item.min_exp}${
                            item.min_exp_months > 0
                              ? `.${item.min_exp_months}`
                              : ""
                          }`}{" "}
                          -{" "}
                          {`${item.max_exp}${
                            item.max_exp_months > 0
                              ? `.${item.max_exp_months}`
                              : ""
                          }`}
                          yrs
                        </Chip>
                        <Chip
                          variant="soft"
                          color="neutral"
                          size="sm"
                          style={{
                            background: "#F2F8F6",
                            color: "#07A996",
                            fontFamily: "Inter",
                          }}
                        >
                          {getSymbolFromCurrency(item.currency)}
                          {Math.round(item.min_sal / 1000, 2)}k-
                          {Math.round(item.max_sal / 1000, 2)}k
                          {`/${item?.payout}`}
                        </Chip>
                        {item?.location == "Remote" ? (
                          ""
                        ) : (
                          <Chip
                            variant="soft"
                            color="neutral"
                            size="sm"
                            style={{
                              background: "#F2F8F6",
                              color: "#07A996",
                              fontFamily: "Inter",
                            }}
                          >
                            {item.work_mode.replace("_", " ")}
                          </Chip>
                        )}
                      </div>
                    </div>

                    <div
                      className="skills_section"
                      style={{ paddingLeft: "10px" }}
                    >
                      <ul
                        className="skill_main_section"
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#121212",
                          opacity: "50%",
                          fontFamily: "Inter,sans-serif",
                        }}
                      >
                        {item?.key_skill_details?.slice(0, 3).map(each => (
                          <li
                            key={each.id}
                            className="typography-field"
                            style={{ paddingRight: "10px" }}
                          >
                            {each.name}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="apply_section">
                      <div className="d-flex gap-2 align-items-center">
                        <CssVarsProvider>
                          <Button
                            style={{
                              // backgroundColor: "#081753",
                              background: "#ffe7bb",
                              color: "#066a4c",
                              fontFamily: "Inter,sans-serif",
                              border: "none",
                            }}
                            variant="solid"
                            className="refer-now-btn cursor-pointer "
                            startDecorator={
                              <GroupAddIcon className="refer_btn_icon" />
                            }
                            onClick={() => {
                              if (
                                user.type !== undefined &&
                                user.type !== null &&
                                user.type !== ""
                              )
                                setReferModal({
                                  modal: "center",
                                  details: item,
                                  purpose: "refer",
                                });
                              else {
                                navigate("/login");
                              }
                            }}
                          >
                            Refer Now
                          </Button>
                        </CssVarsProvider>
                        <Button
                          variant="outlined"
                          className="apply-now-btn cursor-pointerf"
                          startDecorator={
                            <GroupAddIcon className="refer_btn_icon" />
                          }
                          style={{
                            // color: "#000000",
                            // border: "1px solid rgba(8, 23, 83, 0.1)",
                            background: "#cde2e7",
                            color: "#066a4c",
                            border: "none",
                            fontFamily: "Inter,sans-serif",
                          }}
                          onClick={() =>
                            setReferModal({
                              modal: "center",
                              details: item,
                              purpose: "self",
                            })
                          }
                        >
                          {/* {item.is_candidate_applied_to_job_post == 0 
                            ? "Apply Self"
                            : "Applied"} */}
                          {token == null
                            ? "Apply Self"
                            : item?.is_candidate_applied_to_job_post == 0
                            ? "Apply Self"
                            : "Applied"}
                        </Button>
                        {token !== null ? (
                          <Button
                            onClick={() =>
                              item.is_candidate_applied_to_job_post == 0
                                ? handelSavedJobs({
                                    job_post_id: item.id,
                                    company_id: item.company_id,
                                    type:
                                      item.is_candidate_saved_the_job_post == 0
                                        ? "save"
                                        : "unsave",
                                  })
                                : toast.error("You have applied already!!")
                            }
                            variant="outlined"
                            className="apply-now-btn cursor-pointerf"
                            style={{
                              color: "#000000",
                              color: item.is_candidate_saved_the_job_post
                                ? "rgba(8, 23, 83, 0.1)"
                                : "#000000",
                              border: "1px solid rgba(8, 23, 83, 0.1)",
                            }}
                          >
                            <div style={{ height: "10px", marginTop: "-30px" }}>
                              <Checkbox
                                {...label}
                                checked={item.is_candidate_saved_the_job_post}
                                icon={<BookmarkBorderIcon />}
                                checkedIcon={<BookmarkIcon />}
                                sx={{
                                  "&.Mui-checked": {
                                    color: "rgb(6, 106, 76)",
                                  },
                                }}
                              />
                            </div>
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div style={{ paddingTop: "10px", padding: "10px" }}>
                      <div className="time_section">
                        <Typography
                          fontWeight="md"
                          textColor="primary.plainColor"
                          mb={0.5}
                          style={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#121212",
                            opacity: "50%",
                            fontFamily: "Inter,sans-serif",
                            paddingTop: "5px",
                          }}
                          className="date_posts"
                        >
                          Posted{" "}
                          <span
                            style={{
                              color: "black",
                              fontWeight: "700",
                              fontSize: "12px",
                            }}
                          >
                            {Math.floor(
                              Math.abs(
                                new Date() -
                                  new Date(
                                    item.createdAt
                                      .slice(0, 10)
                                      .toLocaleString()
                                      .replace(/-/g, "/")
                                  )
                              ) /
                                (1000 * 3600 * 24)
                            )}{" "}
                            days
                          </span>{" "}
                          ago
                        </Typography>

                        <Typography
                          fontWeight="md"
                          textColor="primary.plainColor"
                          mb={0.5}
                          style={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#121212",
                            opacity: "50%",
                            fontFamily: "Inter,sans-serif",
                            paddingTop: "5px",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              fontWeight: "700",
                              fontSize: "12px",
                            }}
                          >
                            {Math.ceil(
                              Math.abs(
                                new Date(
                                  item?.application_deadline
                                    .slice(0, 10)
                                    .toLocaleString()
                                    .replace(/-/g, "/")
                                ) -
                                  new Date(
                                    item?.createdAt
                                      .slice(0, 10)
                                      .toLocaleString()
                                      .replace(/-/g, "/")
                                  )
                              ) /
                                (1000 * 60 * 60 * 24)
                            )}{" "}
                            days
                          </span>{" "}
                          left to apply
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <ReferNowModal
        show={referModal.modal}
        purpose={referModal.purpose}
        onHide={modal =>
          setReferModal({
            modal: modal,
            details: {},
          })
        }
        params={referModal}
        setSeshuModal={props.setSeshuModal}
      />
    </>
  );
}
export default Lists;

function ElementsList() {
  return (
    <>
      {Array.from(new Array(15)).map((item, index) => (
        <div className="card-job" id="card-job">
          <div className="job_card_top_details_cont">
            <div
              className="card-job-top--image job_card_top_details_img_cont"
              id="job_card_top_details_img_cont"
            >
              <Skeleton
                animation="wave"
                variant="circular"
                className="job_card_top_details_img"
                width={60}
                height={60}
              />
            </div>

            <div
              className="job_card_top_details_info_cont"
              id="job_card_top_details_info_cont"
            >
              <Skeleton animation="wave" width="100%" />
              <Skeleton animation="wave" width="100%" />
              <Skeleton animation="wave" width="100%" />
            </div>
          </div>
          <div className="job_post_actions_cont">
            <Skeleton animation="wave" width="100%" />
          </div>
          <div className="job_post_actions_cont">
            <Skeleton animation="wave" width="100%" />
          </div>

          <div className="job_post_actions_cont">
            <Skeleton animation="wave" width="100%" />
          </div>

          <div className="job_post_actions_cont">
            <Skeleton animation="wave" width="100%" />
          </div>
        </div>
      ))}
    </>
  );
}
