import callApi from "../../callApi";

export const forgetPassword = async email => {
  return await callApi(`/common/forgotpassword/${email}`);
};
export const resetPassword = async (data, token) => {
  return await callApi(`/common/resetpassword`, data, "PATCH", {
    token: token,
  });
};
