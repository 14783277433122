import React from "react";
// import helpingHands from "../../assets/images/infoImages/helpingHands.png";
import refer from '../../assets/images/refersection.png';
import CompanyListCarousal from "../Home/CompanyListCarousal";
import CompanyTrustCarousal from "../AboutUs/AboutsUsPages/CompanyTrustCarousal";
function EmployersPage5() {
  return (
    <>
      
      <div className="hire_candidates_card_section_layout pt-5">
        <section className="refer_section_tab">
          <div className="refer_main_sec">
            <div className="refer_partition_sec">
              <div className="refer_sec1">
                <h2 className="Shifyt_trust">
                Referral Partner <span >Reviews</span>
                </h2>
                <CompanyTrustCarousal />
              </div>
           
              {/* <img
                src={refer}
                style={{ marginTop: "12%", height: "150px" }}
                className="dummyImage"
              /> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default EmployersPage5;
