import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import { updateProfileNormal } from "../../../helpers/dataFetcher/profile/update";
import UploadFileModal from "../../../Reusable/FileUpload";
import "../../Accounts/Profiles/profile.css";
import { imageURL } from "../../../Constants/commonURLS";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Link, useNavigate } from "react-router-dom";
import { getProfile } from "../../../helpers/dataFetcher/profile/profile";
import ReactSelect from "react-select";
import { Controller, useForm } from "react-hook-form";
import { findAllFuncArea } from "../../../helpers/dataFetcher/jobs/jobs";

import {
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Typography,
  NativeSelect,
} from "@mui/material";
import dayjs from "dayjs";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
// import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { LocationCityOutlined, LocationOnOutlined } from "@mui/icons-material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { CssVarsProvider, Button } from "@mui/joy";
// import Button from '@mui/material/Button';
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

function ProfileDescription(props) {
  const [completed, setCompleted] = useState(false);
  const [location, setLocation] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const [jobOptions, setJobOptions] = useState();
  const [selected, setSelected] = useState([]);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  useEffect(() => {
    fetchJobRoles();
  }, []);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);

  const [modelOpen, setModelOpen] = useState(false);
  const { userDetails, setUserDetails } = props;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = async data => {
    const result = selected?.map(item => item.id);
    const finalResult = [...result];
    console.log(data, "data");
    const obj = {
      profile_image: data.profile_image,
      last_name: data.last_name,
      first_name: data.first_name,
      email: data.email,
      dob: data.dob,
      // dob:"",
      gender: data.gender,
      resume_file: data.resume_file,
      phone_number: data?.phone_number,
      country: data.country,
      state: data.state,
      city: data?.city,
      gender: data?.gender,
      postal_code: data?.postal_code,
      address: data?.address?.description,
      description: data?.about,
      //   job_type:finalResult,
      update_type_to_send_email: "email",
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );

    const { status } = await updateProfileNormal("about_me", o);
    if (status !== 200) {
      toast.error("Update Carrer Profile");
      return;
    }
    setCompleted(true);

    props.onHide();

    const { data: response } = await getProfile();
    setUserDetails(response);
  };

  function disableFutDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return date => {
      return Date.parse(date) > startSeconds;
    };
  }
  useEffect(() => {
    if (userDetails) {
      setValue("first_name", userDetails.first_name);
      setValue("last_name", userDetails.last_name);
      setValue("profile_image", userDetails.profile_image);
      setValue("email", userDetails.email);
      setValue("dob", dayjs(userDetails.dob));
      setValue("gender", userDetails.gender?.toLowerCase());
      setValue("resume_file", userDetails.resume_url);
      setValue("country", userDetails.country);
      setValue("state", userDetails.state);
      setValue("city", userDetails?.city);
      setValue("phone_number", userDetails?.phone_number);
      setValue("postal_code", userDetails?.postal_code);
      setValue("address", userDetails?.location);
      setValue("about", userDetails?.about);
      setValue("job_role", userDetails?.job_role);
    }
  }, [userDetails]);

  async function fetchJobRoles() {
    const { data: items } = await findAllFuncArea();
    const data = items.map(iterator => {
      return {
        id: iterator.id,
        label: iterator.name,
        value: iterator.name,
      };
    });
    setJobOptions(data);
  }

  const updateAboutMe = async () => {};

  const toggleUploadModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setValue("profile_image", url.replace(imageURL, ""));
    toggleUploadModel();
  };

  return (
    <>
      <UploadFileModal
        isOpen={modelOpen}
        toggle={toggleUploadModel}
        handleImage={handleImageUpload}
      />

      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div className="form-group mb-3">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-lables">
                Profile
              </label>
            </div>
          </div>
        </div>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} className="about_us_form summary">
        <ThemeProvider theme={globalTheme}>
          <Col
            xs={12}
            className="mt-4"
            // style={{ height: "10vh", overflowY: "scroll" }}
          >
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Type in here…"
              error={Boolean(errors.about)}
              {...register("about", { required: true })}
              style={{
                minHeight: "100px",
                maxHeight: "120px",
                borderRadius: "8px",
                borderColor: errors.about ? "red" : "#ced4da",
                overflowY: "scroll !important",
              }}
            />
          </Col>
        </ThemeProvider>
        <Row className="mt-3">
          <div className="d-flex justify-content-end">
            <Button
              style={{
                margin: "3px",
                // background: "rgb(236, 236, 236)",
                // color: "rgb(8, 23, 83)",
                background: "#cde2e7",
                color: "#066a4c",
              }}
              type="submit"
            >
              Save
            </Button>
            <Button
              style={{
                margin: "3px",
                // background: "rgb(236, 236, 236)",
                // color: "rgb(8, 23, 83)",
                background: "#ffe7bb",
                color: "#066a4c",
              }}
              type="submit"
            >
              Close
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
}

export default ProfileDescription;
