import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import google from "../../../assets/images/google.png";
import CompanyS from '../../../assets/images/companyS.png';
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {},
  head: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.8)",
  },

  para: {
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.75)",
  },
});

function CompaniesViews({cProfile}) {
  const classes = useStyles();
  return (
    <>
       <h2 className="benefits_txt">About</h2>
      <div className="comp_view_sec">
        {/* <h2 className="benefits_txt">Companies people also viewed</h2> */}
        <p style={{wordWrap:"break-word"}}>{cProfile?.about?cProfile.about:"N/A"}</p>
        
        {/* <Grid container spacing={2} className="company_grid1">
          <Grid item>
            <ButtonBase sx={{ width: 35, height: 40 }}>
              <Img alt="company Image" src={CompanyS} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} lg={8} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.head}
                >
                  Sritech solutions
                </Typography>

                <div className="categories_c">
                  <p className={classes.para}>Staffing & agency</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        <Grid container spacing={2} className="company_grid1">
          <Grid item>
            <ButtonBase sx={{ width: 35, height: 40 }}>
              <Img alt="company Image" src={CompanyS} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} lg={8} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.head}
                >
                  Digital Insurance
                </Typography>

                <div className="categories_c">
                  <p className={classes.para}>Finance</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        <Grid container spacing={2} className="company_grid1">
          <Grid item>
            <ButtonBase sx={{ width: 35, height: 40 }}>
              <Img alt="company Image" src={CompanyS} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} lg={8} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.head}
                >
                  Combinator
                </Typography>

                <div className="categories_c">
                  <p className={classes.para}>Technology, Information</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}


      </div>
    </>
  );
}

export default CompaniesViews;
