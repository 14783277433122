import React from "react";

function EarningPartnerReport() {
    return (
        <>
            <h5 className="mb-3">Your Partner Report</h5>
            <div className="card border-0 p-4 table-bg">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Partner's</th>
                            <th scope="col">Signup Date</th>
                            <th scope="col">Validity Upto</th>
                            <th scope="col">Clients Referred</th>
                            <th scope="col">Candidates Referred</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <p className="text-center">No data</p>
                           
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default EarningPartnerReport