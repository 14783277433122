import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import OwlCarousel from "react-owl-carousel3";
import zIndex from "@mui/material/styles/zIndex";

const useStyles = makeStyles({
  arrow: {
    zIndex: 0,
  },
});
function CompanyTrustCarousal() {
  const classes = useStyles();
  let array1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3001 },
      items: 8,
    },
    desktop2: {
      breakpoint: { max: 3000, min: 1601 },
      items: 8,
      gap: 3,
    },
    desktop1: {
      breakpoint: { max: 1600, min: 1024 },
      items: 5,
      gap: 4,
    },
    tablet2: {
      breakpoint: { max: 1024, min: 773 },
      items: 3,
      gap: 2,
    },
    tablet1: {
      breakpoint: { max: 772, min: 464 },
      items: 2,
      gap: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      gap: 2,
    },
  };

  const reviewsList = [
    {
      review:
        "I’ve joined Shifyt as a side job and started making good money, more than my full time job. I quit my full time job and continue to be a Referral partner at Shifyt",
    },
    {
      review:
       "Being on sabbatical, I earn on Shifyt as a referral partner to fulfil my financial needs",
    },
    {
      review:
      "Working as a Referral partner on Shifyt, I made good income while spending time on my hobbies",
    },
    {
      review:
      "Shifyt is the go to platform for people who want to earn working remotely and at convenience",
    },
    {
      review:
      "I’m able to fulfil my financial needs working with Shifyt as a Referral partner and earning money through referrals",
    },
    {
      review:
      "Working as a Referral partner on Shifyt, I made good income while spending time on my hobbies",
    },
  ];

  return (
    <>
      <div className="company_trustlist_main_sec">
        <Carousel responsive={responsive} className={classes.arrow}>
          {/* {array1.map((each, index) => (
            <div className="card_trust_section" key={index}>
              <div className="card_trust_inside_section">
                <div className="card_inside_section2">
                  <p className="trst_para_txt">
                    We custom build an audience on social media and advertise
                    your job & the offered reward both candidates & recruiters
                  </p>
                  <p className="company_para_txt">Infosys Pvt.Ltd</p>
                  <p className="company_mem_txt">Dinesh Kumar</p>
                </div>
              </div>
            </div>
          ))} */}
          {reviewsList.map((each,index)=>(
             <div className="card_trust_section" key={index}>
             <div className="card_trust_inside_section">
               <div className="card_inside_section2">
                 <p className="trst_para_txt">
                  {each.review}
                 </p>
               </div>
             </div>
           </div>
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default CompanyTrustCarousal;
