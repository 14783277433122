import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import confusedImage from "../assets/images/infoImages/confused.svg";
import forgotImage from "../assets/images/infoImages/forgot.jpg";
import { Col, Form, Row, Label } from "reactstrap";
import { Input, OutlinedInput } from "@mui/material";
import { useDispatch } from "react-redux";
import { addUserAction } from "../store/auth/actions";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { CssVarsProvider } from "@mui/joy/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import { forgetPassword } from "../helpers/dataFetcher/auth";
import { toast } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import signUpImage from "../assets/images/signUp.png";
import loginPage from '../assets/images/loginPage.png';
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
// import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import google from "../assets/images/google.png";
import linkedin from "../assets/images/linkedin2.png";
import {globalTheme} from '../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    borderColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 600,
      fontSize: 16,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& input": {
      padding: "12px 14px",
      borderRadius: 8,
    },
  },
});

function ForgetPassword() {
  const [loginStatus, setLoginStatus] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [divClick, setDivClick] = useState(false);
  const [passwordClick, setPasswordClick] = useState(false);
  const usePasswordRef = useRef(null);
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        // setDivClick(false);
      } else {
        setError(false);
        setDivClick(true);
      }
      if (
        usePasswordRef.current &&
        !usePasswordRef.current.contains(event.target)
      ) {
        // setPasswordClick(false);
      } else {
        setError(false);
        setPasswordClick(true);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const loginFunc = async data => {
    setLoginStatus(true);
    const { status, data: loginObj } = await forgetPassword(data.email);
    if (status !== 200) {
      setError(true);
      setLoginStatus(false);
      return;
    }
    setLoginStatus(false);
    toast.success(loginObj?.data);
    localStorage.setItem("token", loginObj.access);
    localStorage.setItem("user_id", loginObj.id);
    localStorage.setItem("user_type", loginObj.usertype);
    dispatch(addUserAction(loginObj));
    return;
  };

  return (
    <>
      {/* <section className="vh-100">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-9 col-lg-6 col-xl-5">
              <img
                src={forgotImage}
                className="img-fluid"
                alt="Sample image"
              />
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <div>
                <div className="mb-3">
                  <h4 style={{fontFamily:"sans-serif"}}>Welcome Back</h4>
                 
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                  <div
                    className="login_with_google_cont"
                    style={{ marginRight: "10px" }}
                  >
                    <div>
                      <img
                        src={google}
                        className="login_google_icon"
                        alt="login-Shifyt"
                      />
                    </div>
                    <div>Log in with Google</div>
                  </div>
                  <div
                    className="login_with_google_cont"
                    style={{ marginLeft: "10px" }}
                  >
                    <div>
                      <img
                        src={linkedin}
                        size={12}
                        className="login_google_icon"
                        alt="login-Shifyt"
                      />
                    </div>
                    <div>Log in with linkedin</div>
                  </div>
                </div>

                 <div className="login_hr_cont text-center">
                  <div>
                    <hr />
                  </div>
                  <div>
                    <span>or</span>
                  </div>
                  <div>
                    <hr />
                  </div>
                </div>

                <Box
                  component="form"
                  onSubmit={handleSubmit(loginFunc)}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email ? true : false}
                    {...register("email", { required: true })}
                    InputProps={{
                      style: { borderRadius: "5px",height:"51.3px"},
                    }}
                  />
                  <Col xs={12}>
                    {error ? (
                      <div className="error_message_login">
                        <ErrorOutlineOutlined style={{ color: "#c13515" }} />
                        <div>
                          <p className="motivational_text">
                            Let's Try That Again
                          </p>
                          <p className="reason_for_error">
                            The Credentials are Not matching with our Records.
                            Make sure you've entered the right Credentials and
                            try again.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submit
                  </Button>
                 
                  <div className="d-flex justify-content-end" style={{paddingBottom:"30px"}}>
                    <p className="signup">
                      Go back to login?{" "}
                      <Link variant="plain" component={Link} to="../login">
                        Login
                      </Link>
                    </p>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <ThemeProvider theme={globalTheme}>
      <div className="signup_layout">
        <div className="signup_main_section">
          <div className=" col-lg-5">
            <div className="signup_part1">
              <div>
                <h2 className="signup_heading_section">
                  The most efficient platform to easily
                </h2>
                <span className="signup_span_section">
                  Apply jobs, Refer candidates
                </span>
                <h2 className="signup_heading_section">talented people</h2>
              </div>
              <img src={loginPage} className="signup_img" />
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-7 checking">
            <div className="signup_part2">
              <div className="sign_head_txt">
                Change Password{" "}
                <span className="sign_head_txt2">{"  "}Here</span>
              </div>

              <Box sx={{ width: "100%" }}>
                <Form onSubmit={handleSubmit(loginFunc)}>
                  <div className="form_main_container">
                    <div className="names_cont">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="names_inside_cont2">
                          <Label className="signup_label_txt">
                            Email Address
                          </Label>
                          <OutlinedInput
                            placeholder="Email"
                            className={classes.root}
                            //   style={{borderRadius:"8px"}}
                            fullWidth
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            autoFocus
                            error={errors.email ? true : false}
                            {...register("email", { required: true })}
                          />
                          <p style={{ color: "red" }}>
                            {errors.email ? "Email required" : null}
                          </p>
                        </div>
                      </div>
                    </div>

                    <Button
                      variant="outlined"
                      type="submit"
                      style={{
                        // background: "#081753",
                        borderRadius: "8px",
                        fontFamily: "Inter,sans-serif",
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#066a4c",
                        fontStyle: "normal",
                        background: "#cde2e7",
                        border:"0px"
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Box>

              <div className="terms_txt">
                <p>
                  Go back to login?{" "}
                  <Link href="#" className="signin_txt" to="../login">
                    Login
                  </Link>
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      </ThemeProvider>
    </>
  );
}

export default ForgetPassword;
