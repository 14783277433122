import React, { useEffect, useState } from "react";
import Chip from "@mui/joy/Chip";
// import noJobs from "../../assets/images/no_jobs.svg";
import noJobs from "../../assets/images/nojobsShiyft.png";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import {
  getAllInterviewsList,
  getAllMyReferals,
} from "../../helpers/dataFetcher/jobs/jobs";
import moment from "moment";
import { Skeleton } from "@mui/material";
//TODO PUT A DEFAULT IMAGE AND LOADER HERE
import { styled } from "@mui/system";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { Avatar, Button, CssVarsProvider } from "@mui/joy";
import { imageURL } from "../../Constants/commonURLS";
import { deepPurple } from "@mui/material/colors";
import MyInterviewsList from './MyInterviewsList';
import SocketConnection from "../../hooks/socket";
import ComingSoon from '../../Reusable/comingSoon';
function createData(name, calories, fat) {
  return { name, calories, fat };
}

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
  }
  `
);
function Interviews() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = useState("");
  const { lastMessage } = SocketConnection();
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [status, setStatus] = useState(false);
  const [referJob, setReferJob] = useState([]);
  useEffect(() => {
    getAllJobs();
  }, []);
  useEffect(() => {
    searchFilterData();
  }, [search]);
  useEffect(() => {
    getAllJobs();
  }, [search == ""]);

   /*socket integration for interviews page start */
  
   useEffect(() => {
    if (
      lastMessage?.data !== undefined ||
      lastMessage?.data !== null ||
      lastMessage?.data !== ""
    ) {
      const resultant = lastMessage?.data;
      if (resultant !== undefined) {
        const data = JSON.parse(resultant);
        if (data?.type === "interviews") {
          getAllJobs()
        }
      }
    }
  }, [lastMessage]);
  console.log(lastMessage,"lstmsg")
  /*socket integration for interviews page End */


  async function searchFilterData() {
    const filterData = referJob.filter(item => {
      if (item?.schedule_interview_company?.company_name.toLowerCase().includes(search.toLowerCase())) {
        return item;
      } else if (item.job_post_details
        ?.job_post_in_user_job_post?.job_title.toLowerCase().includes(search.toLowerCase())) {
        return item;
      } 
    });
    setReferJob(filterData);
  }

  async function getAllJobs() {
    const { data, status } = await getAllInterviewsList();
    setReferJob(data?.data);
    if (status === 200) {
      setStatus(true);
    }
  }


  // async function fetchTableData() {
  //   const filterData = referJob.filter(item => {
  //     if (item?.schedule_interview_company?.company_name.toLowerCase().includes(search.toLowerCase())) {
  //       return item;
  //     } else if (item.job_post_details
  //       ?.job_post_in_user_job_post?.job_title.toLowerCase().includes(search.toLowerCase())) {
  //       return item;
  //     } 
  //   });
  //   console.log(filterData) 
  //   if (filterData?.length > 0) {
  //     return  filterData.map((item,index)=>{
  //       return (
  //         <tr>
  //         <td>{index + 1}</td>
  //         <td>
  //           <span className="d-flex gap-2 align-items-center">
  //             <FeatherIcon icon="briefcase" size="14" />
  //             <Link
  //               to={`../employer-single-list/${item.company_user_id}`}
  //             >
  //               {item?.schedule_interview_company?.company_name}
  //             </Link>
  //             {/* <Avatar
  //               alt="avatar"
  //               src={`${imageURL}${item?.schedule_interview_company?.company_image}`}
  //               sx={{
  //                 width: 38,
  //                 height: 38,
  //                 bgcolor: deepPurple[500],
  //               }}
  //             >
  //               <span className="profile_letters">
  //                 {item?.schedule_interview_company?.company_name.charAt(
  //                   0
  //                 )}
  //               </span>
  //             </Avatar> */}
  //             <img
  //                 alt="avatar"
  //                 src={`${imageURL}${item?.schedule_interview_company?.company_image}`}
  //                 sx={{
  //                   width: 38,
  //                   height: 38,
  //                   bgcolor: deepPurple[500],
  //                 }}
  //                 style={{
  //                   height: "38px",
  //                   width: "38px",
  //                   borderRadius: "5px",
  //                 }}
  //               />
  //           </span>
  //         </td>

  //         <td>
  //           <span className="d-flex gap-2 align-items-center">
  //             <FeatherIcon icon="briefcase" size="14" />
  //             <Link
  //               to={`../job/${item.job_post_details?.job_post_in_user_job_post?.id}`}
  //             >
  //               {
  //                 item.job_post_details
  //                   ?.job_post_in_user_job_post?.job_title
  //               }
  //             </Link>
  //           </span>
  //         </td>
  //         <td>
  //           <span className="d-flex gap-2 align-items-center">
  //             <FeatherIcon icon="clock" size="14" />
  //             {moment([
  //               new Date(item?.start_date).getFullYear(),
  //               new Date(item?.start_date).getMonth(),
  //               new Date(item?.start_date).getDay(),
  //             ])
  //               .format("dddd, MMMM Do YYYY")
  //               .toString()}{" "}
  //             {item?.start_time}
  //           </span>
  //         </td>

  //         <td>
  //           <span className="d-flex gap-2 align-items-center">
  //             <FeatherIcon icon="user" size="14" />
  //             <span className="d-flex gap-2 align-items-center">
  //               {item.interviewer?.email}
  //             </span>
  //             <br />
  //             <span className="d-flex gap-2 align-items-center">
  //               {item.interviewer?.first_name}{" "}
  //               {item?.interviewer?.last_name}
  //             </span>
  //           </span>
  //         </td>

  //         {/* <td>
  //           <CssVarsProvider>
  //             <Button
  //               onClick={() => {
  //                 window.open(item?.meeting_link, "_blank");
  //               }}
  //             >
  //               Join Meeting
  //             </Button>
  //           </CssVarsProvider>
  //         </td> */}
  //           <td>
  //           <CssVarsProvider>
  //           <Link
  //               onClick={() => {
  //                 window.open(item?.meeting_link, "_blank");
  //               }}
  //             >
  //               Join Meeting
  //             </Link>
  //           </CssVarsProvider>
  //         </td>
  //       </tr>
  //       )
  //     }) 
    
  //   }
  // }

  return (
    <div className="d-flex align-items-start gap-2 justify-content-center" style={{marginTop:"30px",marginLeft:"58px"}}>
      <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
        <Sidebar />
      </div>
      <div className="col-lg-10 col-sm-12 col-md-12  font_inherit overflow-auto">
        <ComingSoon />
      {/* <input
          type="search"
          placeholder="Search Here..."
          onChange={e => setSearch(e.target.value)}
          style={{ marginBottom: "5px", width: "60%" }}
        /> */}
        <div className="">
          {status ? (  
          <> 
          {/* <MyInterviewsList referJob={referJob}/> */}

          {/*<>
          <div>
              <Root>
                <div  style={{ overflowY: "scroll", height: "500px" }}>
                <table aria-label="custom pagination table mb-4">
                  <thead style={{ position: "sticky", top: "0" }}>
                    <tr>
                      <th>S.No</th>
                      <th>Company</th>
                      <th>Job Title</th>
                      <th>Date & Time</th>
                      <th>Interviewer</th>
                      <th>Meeting Link</th>
                    </tr>
                  </thead>
                  <tbody>
                 
                  {referJob?.length > 0 ? (
                      referJob?.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <span className="d-flex flex-column gap-2 align-items-center">
                            
                              <img
                                  alt="Loading"
                                  src={`${imageURL}${item?.schedule_interview_company?.company_image}`}
                                  sx={{
                                    width: 38,
                                    height: 38,
                                    bgcolor: deepPurple[500],
                                  }}
                                  style={{
                                    height: "38px",
                                    width: "38px",
                                    borderRadius: "5px",
                                  }}
                                />
                              <Link
                                to={`../employer-single-list/${item.company_user_id}`}
                              >
                                {item?.schedule_interview_company?.company_name}
                              </Link>
                            
                            </span>
                          </td>

                          <td>
                            <span className="d-flex gap-2 align-items-center">
                              <FeatherIcon icon="briefcase" size="14" />
                              <Link
                                to={`../job/${item.job_post_details?.job_post_in_user_job_post?.id}`}
                              >
                                {
                                  item.job_post_details
                                    ?.job_post_in_user_job_post?.job_title
                                }
                              </Link>
                            </span>
                          </td>
                          <td>
                            <span className="d-flex gap-2 align-items-center">
                              <FeatherIcon icon="clock" size="14" />
                              {moment([
                                new Date(item?.start_date).getFullYear(),
                                new Date(item?.start_date).getMonth(),
                                new Date(item?.start_date).getDay(),
                              ])
                                .format("dddd, MMMM Do YYYY")
                                .toString()}{" "}
                              {item?.start_time}
                            </span>
                          </td>

                          <td>
                            <span className="d-flex gap-2 align-items-center">
                              <FeatherIcon icon="user" size="14" />
                              <span className="d-flex gap-2 align-items-center">
                                {item.interviewer?.email}
                              </span>
                              <br />
                              <span className="d-flex gap-2 align-items-center">
                                {item.interviewer?.first_name}{" "}
                                {item?.interviewer?.last_name}
                              </span>
                            </span>
                          </td>

                         
                            <td>
                            <CssVarsProvider>
                            <Link
                                onClick={() => {
                                  window.open(item?.meeting_link, "_blank");
                                }}
                              >
                                Join Meeting
                              </Link>
                            </CssVarsProvider>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <><div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "100%",
                        width: "100%",
                        marginTop:"70px"
                      }}
                    >
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={noJobs}
                          style={{ width: "300px", height: "300px" }}
                          alt="no jobs"
                        />
                        <p>No Interviews Found</p>
                      </div>
                    </div></>
                    )}


                    {emptyRows > 0 && (
                      <tr style={{ height: 34 * emptyRows }}>
                        <td colSpan={3} />
                      </tr>
                    )}
                  </tbody>


                  {/* <tfoot>
                    <tr>
                      <CustomTablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        slotProps={{
                          select: {
                            "aria-label": "rows per page",
                          },
                          actions: {
                            showFirstButton: true,
                            showLastButton: true,
                          },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </tr>
                      </tfoot> 


                </table>
                </div>
              </Root>
            </div>

            </>*/}
          
          </>
           
           
          ) : (
            <>
              {/* {Array.from(new Array(15)).map((item, index) => (
                <div className="card-employers" id="card-employers" key={index}>
                  <div className="align-items-center comp_card_main_cont">
                    <div className="comp_employers_logo">
                      <div
                        className="comp_profile_card_cont"
                        id="comp_profile_card_cont"
                      >
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={80}
                        />
                      </div>
                    </div>
                    <div className="employer_name_cont">
                      <p className="employer_name">
                        <span>
                          <strong className="comp_name">
                            <Skeleton width="100%" />
                          </strong>
                        </span>
                      </p>
                      <div className="d-flex comp_position_cont">
                        <Skeleton width="100%" />
                      </div>
                      <div className="comp_specs_cont">
                        <Skeleton width="100%" />
                      </div>
                    </div>
                    <div className="comp_employers_redirection_cont"></div>
                  </div>
                </div>
              ))} */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Interviews;
