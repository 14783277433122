import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
  SecondaryTypography
} from "../../../assets/MUI/materialUiStyles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";
import moment from "moment";

export default function CandidateInformation({ candidateDetails,setRecall,reCall }) {
  const [candidate, setCandidate] = useState({});
  useEffect(() => {
    setCandidate(candidateDetails);
  }, [candidateDetails]);


  const ageObj = candidate?.dob? `${moment(candidate.dob).format("DD/MM/YYYY")}-(${candidate?.age_of_candidate}Yrs)`:"null"
  const age = ageObj.toString()


  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px !important",
            paddingLeft: "4px",
            alignSelf:"stretch"
          }}
          orientation="vertical"
          flexItem
        />
        <SecondaryTypography component="h3" variant="h3">
          Candidate Details
        </SecondaryTypography>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={1}>
            <InputButtonField
              text_field={"First Name"}
              input_value={candidate?.first_name}
            // input_value={""}
              type="input"
              update_type={"first_name"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />

            <InputButtonField
              text_field={"Last Name"}
              input_value={candidate?.last_name}
              // input_value={""}
              type="input"
              update_type={"last_name"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />

            <InputButtonField
              text_field={"Gender"}
              input_value={candidate?.gender}
              // input_value={""}
              type="select"
              update_type={"gender"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />
            <InputButtonField
              text_field={"Current Company"}
              input_value={candidate?.current_company}
              // input_value={""}
              type="input"
              update_type={"current_company"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />

            <InputButtonField
              text_field={"Current Designation"}
              input_value={candidate?.current_postition}
              // input_value={""}
              type="input"
              update_type={"current_postition"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />
            <InputButtonField
              text_field={"Current Department"}
              input_value={candidate?.current_department}
              // input_value={""}
              type="input"
              update_type={"current_department"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />
            <InputButtonField
              text_field={"Current Industry"}
              input_value={candidate?.current_industry}
              // input_value={""}
              type="input"
              update_type={"current_industry"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />
            <InputButtonField
              text_field={"Work Location"}
              input_value={candidate?.work_location}
              // input_value={""}
              type="location"
              update_type={"location"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />
            <InputButtonField
              text_field={"Birth Date"}
              input_value={age}
            // input_value={""}
              type="date"
              update_type={"dob"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />
            <InputButtonField
              text_field={"Current Address"}
              input_value={candidate?.location}
            // input_value={""}
              type="multiline"
              update_type={"current_address"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />
            {/* <InputButtonField
              text_field={"Email"}
              input_value={candidate?.email}
              type="input"
              update_type={"candidate_email"}
              wholeData = {candidate}
            /> */}
               <InputButtonField
              text_field={"Other Contact"}
              input_value={candidate?.other_contact}
              type="input"
              update_type={"other_contact"}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
            />
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}