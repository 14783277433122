import { extendTheme } from '@mui/joy/styles';
import {Button,Modal,CssVarsProvider,ModalDialog,buttonClasses} from '@mui/joy';


const JoyS = {
    mainColor: '#081753',
    fontFamily:"Inter",
    borderRadius:"8px",
    btnText: "#ffffff",
    borderRadiusTxtF:"4px",
    height:48,
    errorColor:"#d32f2f",
}

export const JoyUiTheme = extendTheme({
    // overrides: {
    //     JoyCssBaseline: {
    //       "@global": {
    //         html: {
    //           WebkitFontSmoothing: "auto",
    //         },
    //       },
    //     },
    //   },
    components: {
        JoyButton:{
            styleOverrides: {
               root: {
                textTransform:"capitalize",
                borderRadius:JoyS.borderRadius,
                fontFamily:JoyS.fontFamily,
                color:JoyS.btnText,
                fontWeight:600,
                // fontSize:"14px",
               letterSpacing:"0px",
               background:JoyS.mainColor,
               color:JoyS.btnText
               }
            }
        },
        JoyModal:{
            styleOverrides:{
                root: {

                }
            }
        },
        JoyModalDialog: {
            styleOverrides: {
                root: {
                    
                }
            }
        }
        
    }
})

