export default async function getUserDetailsFromGoogle(user) {
  fetch(
    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
    {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    }
  )
    .then(res => {
      console.log(res.data);
    })
    .catch(err => console.log(err));
}
