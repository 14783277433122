import React, { useState } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Link from "@mui/joy/Link";
// import Card from "@mui/joy/Card";
import Card from "@mui/material/Card";

import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Col, Row } from "reactstrap";
import { makeStyles } from "@mui/styles";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Select from "@mui/material/Select";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles({
  root: {
    // background: "#515D86 !important",
    background: "#cde2e7 !important",
    border: "1px solid #515D86 !important",
    borderRadius: "8px !important",
    //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: "rgba(255, 255, 255, 0.94) !important",
    padding: "0px !important",
    margin: "10px",
  },
  dropDownCard: {
    "&.css-822hb5-MuiList-root": {
      background: "none !important",
      padding: "0px !important",
    },
    "&.css-tlelie-MuiListItemText-root": {
      margin: "0px !important",
    },
  },
});

const options = ["Last Week", "Last Week", "Last Month", "Last 3 Months"];



function DashBoardPage1({referJob,appliedJobs,byRefer}) {

  // const byReferJobs = byRefer[0]?.company_details?.company?.map((each)=>{
  //   return {
  //     count:each.company_details.company
  //   }
  // })
  const referdJobs = referJob?referJob?.length:"0"
  const applied = appliedJobs?appliedJobs?.length:"0"
  const referd = byRefer?byRefer?.length:"0"

  const dashBoardList = [
    { id: 0, name: "My Referral Jobs", count: referd, latest: "+22" },
    { id: 1, name: "Applied Jobs", count: applied, latest: "+34" },
    { id: 2, name: "Referred Jobs", count: referdJobs, latest: "+26" },
 

  ];
  
  const classes = useStyles();

  /*drop down selection start */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* drop down selection End */

  return (
    <>
      <ThemeProvider theme={globalTheme}>
        <div className="dashpage1_main">
          <Row md="2" sm="2" xs="1" lg="3" xl="4">
            {dashBoardList?.map((each, index) => (
              <>
              <Col xs={12} sm={6} lg={4} md={6} xl={4} key={index}>
                <Card
                  variant="outlined"
                  orientation="horizontal"
                  className={classes.root}
                  sx={{
                    width: 200,
                    minHeight: 104,
                    position: "relative",
                  }}
                >
                  <div className="card_inside_cont">
                    <div
                      className="dash_divider"
                      style={{
                        height: "50%",
                        position: "absolute",
                        top: "20%",
                      }}
                    ></div>
                    <div className="apply_cont">
                      <h2 className="apply">{each?.name}</h2>
                      <p className="appled">{each?.count}</p>

                      {/* <div className="apply_dropdown">
                        <p className="apply_count">+26</p>
                        <div>
                          <Select
                            className={`${classes.dropdown} select_dropdown`}
                            defaultValue="Latest"
                            indicator={<KeyboardArrowDown />}
                            sx={{
                              width: "auto",
                              height: 30,
                              border: "none !important",
                              borderRadius: "8px",
                              background: "#515D86 !important",
                              // color:"#ffffff",
                              marginLeft: "5px",
                            }}
                          >
                            <MenuItem value={"Latest"}>Latest</MenuItem>
                            <MenuItem value={"Past 1 Month"}>
                              Past 1 Month
                            </MenuItem>
                            <MenuItem value={"Past 3 Month"}>
                              Past 3 Month
                            </MenuItem>
                          </Select>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Card>
              </Col>
              </>
            ))}

            
          </Row>
        </div>
      </ThemeProvider>
    </>
  );
}

export default DashBoardPage1;
