import {
  FormControl,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Stack,
  Button,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import {
  BlackTypography,
  PrimaryOpacitedButton,
  PrimarySaveButton,
  PrimaryCurrencyInputs,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryNormalFormHelperText,
  SecondaryFilledButton,
  PrimaryButton,
  ColorTypography
} from "../../../assets/MUI/materialUiStyles";
import { grey } from "@mui/material/colors";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import GoogleMaterialInputLocation from "../../../components/InputLocation";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";
// import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
// import SearchParamHook from "hooks/searchParams";
// import ReactLanguageSelect from "react-languages-select";
// import "react-languages-select/css/react-languages-select.css";
import getSymbolFromCurrency from "currency-symbol-map";
import cc from "currency-codes";
import { transformedCountries } from "./Nationality";
import { transformedLanguages } from "./Nationality";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { formatNumberWithCommas } from "./formatNumber";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "10px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  // height: 500,
  overflow: "auto",
};

export default function InputButtonField({
  text_field,
  input_value,
  type,
  update_type,
  input_type = "text",
  apiUrl = "update",
  currency_field,
  work_field,
  pay_field,
  update_type2,
  update_type3,
  update_type4,
  setVisaStatus,
  candidateDetails,
  fetchUserJobPostDetails,
  wholeData,
  setRecall,
  reCall,
  preDefineValue
  // salaryModel,
  // setSalaryModel,
}) {
  const [salaryModel, setSalaryModel] = useState(false);
  const [userJobPostId, setUserJobPostId] = useState(null);
  // const searchParams = SearchParamHook();
  const currencies = cc.codes()?.map(item => item);
  // useEffect(() => {
  //   let queryParamsForUserJobPostId = searchParams.get("user_job_post_id");
  //   if (queryParamsForUserJobPostId)
  //     setUserJobPostId(queryParamsForUserJobPostId);
  // }, [searchParams]);

  useEffect(() => {
    let userDetails = localStorage.getItem("userData");
    setUserJobPostId(userDetails?.id);
  }, []);
  const [input, setInput] = useState(false);

  const [data, setData] = useState(input_value);
  useEffect(() => {
    setData(input_value);
  }, [input_value]);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const [dateValue, setDateValue] = useState("");

  useEffect(() => {
    // setValue("previous", input_value);
    setValue(update_type, input_value);
  }, [update_type, input_value]);


  /*checking */
  useEffect(() => {
    if(!["expected_salary","current_salary"].includes(update_type))
    setData(input_value);
    if (update_type == "expected_salary" && Boolean(preDefineValue)) {
      const{expected_salary,currency,pay,work} = preDefineValue
      setValue("exp_salary", expected_salary)
      setValue("currency", currency)
      setValue(update_type3, pay)
      setValue(update_type4, work)
      const text = `${currency}-${expected_salary}/${pay}`
      setData(text)
    }
    if (update_type == "current_salary" && Boolean(preDefineValue)) {
      const{current_salary,currency,pay,work} = preDefineValue
      setValue("cur_salary", current_salary)
      setValue("currency", currency)
      setValue(update_type3, pay)
      setValue(update_type4, work)
      const text = `${currency}-${current_salary}/${pay}`
      setData(text)
    }

  }, [input_value]);
  /*checking */

  const toggleSalaryModel = () => {
    setSalaryModel(!salaryModel);
  };
  function checkInputs() {
    if (data === "" || data === null) {
      return true;
    }
    return false;
  }
  function toggleInputs() {
    setInput(!input);
    if (type == "salary") {
      setSalaryModel(!salaryModel);
    }
  }

  async function onSubmit(data) {
    console.log(wholeData, "wholeData");
    let data1 = { ...data };
    for (const key in wholeData) {
      if (wholeData.hasOwnProperty(key)) {
        // Check if the key exists in obj1; if not, add it
        if (!data1.hasOwnProperty(key)) {
          data1[key] = wholeData[key];
        }
      }
    }
    console.log(data1, "finalll");

    const obj = {
      // profile_image: data1.profile_image,
      last_name: data1?.last_name,
      first_name: data1?.first_name,
      email: data1?.email,
      dob: data1?.dob,

      current_company: data1?.current_company,
      current_postition: data1?.current_postition,
      current_department: data1?.current_department,
      current_industry: data1?.current_industry,
      work_location: [data1?.location?data1.location:wholeData?.work_location[0]],
      // work_location: ["Hyderabad, Telangana, India"],
      other_contact: data1?.other_contact,
      current_address: data1?.current_address,
      experience: data1?.experience,
      languages: data1?.languages,
      // work_exp:data1?.work_exp,
      // courses:data1?.courses,
      nationality: data1?.nationality,
      // work_authorization:data1?.work_authorization,

      gender: data1.gender,
      // resume_file: data1.resume_file,
      // phone_number: data1?.phone_number,
      // country: data1.country,
      // state: data1.state,
      // city: data1?.city,

      // postal_code: data1?.postal_code,
      // address: data1?.address?.description,
      // description: data1?.about,

      // job_role: data1?.current_postition,
      // current_salary: [`${data1?.currency}-${data1?.current_salary}/${data1?.pay}`],
      // expected_salary:  [`${data1?.currency}-${data1?.expected_salary}/${data1?.pay}`],

      // current_salary: [
      //   data1?.expected_salary
      //     ? data1?.current_ctc
      //     : `${data1?.currency}-${data1?.current_salary}/${data1?.pay}`,
      // ],
      // expected_salary: [
      //   data1?.current_salary
      //     ? data1?.expected_ctc
      //     : `${data1?.currency}-${data1?.expected_salary}/${data1?.pay}`,
      // ],

      notice_period: data1?.notice_period ? data1.notice_period : "30",
      // work_mode: "Full_Time",
      update_type_to_send_email: "email",
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );

    // const { status } = await updateProfileNormal("about_me", o);

    if (watch(update_type) == "Yes") {
      setVisaStatus(watch(update_type));
      localStorage.setItem("visa", watch(update_type));
      data.visa = watch(update_type);
      data.from = candidateDetails?.from
        ? candidateDetails?.from
        : localStorage.getItem("from");
      data.to = candidateDetails?.to
        ? candidateDetails?.to
        : localStorage.getItem("to");
      data.type = candidateDetails?.file
        ? candidateDetails?.file
        : localStorage.getItem("file");

      let dataV = {
        last_name: data1?.last_name,
        first_name: data1?.first_name,
        email: data1?.email,
        update_type_to_send_email: "email",
        is_visa: Boolean(data.visa),
        visa_details: [{ ...data }],
      };
      await updateUserProfileDetails("about_me", dataV);
      setTimeout(() => {
        setRecall(!reCall);
      }, 1000);
    } else if (data?.from) {
      localStorage.setItem("from", watch(update_type));
      data.from = watch(update_type);
      data.visa = candidateDetails?.visa
        ? candidateDetails?.visa
        : localStorage.getItem("visa");
      data.to = candidateDetails?.to
        ? candidateDetails?.to
        : localStorage.getItem("to");
      data.type = candidateDetails?.file
        ? candidateDetails?.file
        : localStorage.getItem("file");

      let dataV = {
        last_name: data1?.last_name,
        first_name: data1?.first_name,
        email: data1?.email,
        update_type_to_send_email: "email",
        is_visa: true,
        visa_details: [{ ...data }],
      };
      await updateUserProfileDetails("about_me", dataV);
      setTimeout(() => {
        setRecall(!reCall);
      }, 1000);
      // await updateUserProfileDetails(userJobPostId, dataV);
    } else if (data?.to) {
      localStorage.setItem("to", watch(update_type));
      data.to = watch(update_type);
      data.from = candidateDetails?.from
        ? candidateDetails?.from
        : localStorage.getItem("from");
      data.visa = candidateDetails?.visa
        ? candidateDetails?.visa
        : localStorage.getItem("visa");
      data.type = candidateDetails?.file
        ? candidateDetails?.file
        : localStorage.getItem("type");

      let dataV = {
        last_name: data1?.last_name,
        first_name: data1?.first_name,
        email: data1?.email,
        update_type_to_send_email: "email",
        is_visa: true,
        visa_details: [{ ...data }],
      };
      await updateUserProfileDetails("about_me", dataV);
      setTimeout(() => {
        setRecall(!reCall);
      }, 1000);
      // await updateUserProfileDetails(userJobPostId, dataV);
    } else if (data?.file) {
      localStorage.setItem("file", watch(update_type));
      data.file = watch(update_type);
      data.from = candidateDetails?.from
        ? candidateDetails?.from
        : localStorage.getItem("from");
      data.to = candidateDetails?.to
        ? candidateDetails?.to
        : localStorage.getItem("to");
      data.visa = candidateDetails?.visa
        ? candidateDetails?.visa
        : localStorage.getItem("visa");

      let dataV = {
        last_name: data1?.last_name,
        first_name: data1?.first_name,
        email: data1?.email,
        update_type_to_send_email: "email",
        is_visa: true,
        visa_details: [{ ...data }],
      };
      await updateUserProfileDetails("about_me", dataV);
      // setTimeout(()=>{
      //   setRecall(true)
      //  },[1000])

      // await updateUserProfileDetails(userJobPostId, dataV);
    } else if (update_type == "current_salary") {
    

      let final_data =  {
        "current_salary": [
            
        {
            current_salary: data.cur_salary,
            currency: data.currency,
            pay: data.pay,
            work:data.work
          }
        ]
    }

      const obj = {
        // profile_image: data1.profile_image,
        last_name: data1?.last_name,
        first_name: data1?.first_name,
        email: data1?.email,
        // current_salary: [
        //   data1?.expected_salary
        //     ? data1?.current_ctc
        //     : `${data1?.currency}-${data1?.current_salary}/${data1?.pay}`,
        // ],

        "current_salary": [
            
          {
              current_salary: data.current_salary,
              currency: data.currency,
              pay: data.pay,
              work:data.work
            }
          ],
      
        update_type_to_send_email: "email",
      };
      let filterOne = Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );


      // await updateUserProfileDetails(userJobPostId, final_data);
      await updateUserProfileDetails("about_me", filterOne);
      setTimeout(() => {
        setRecall(!reCall);
      }, 1000);
    } else if (update_type == "expected_salary") {
      let final_data = {
        expected_salary: [
          {
            ...data,
          },
        ],
      };


      const obj = {
        // profile_image: data1.profile_image,
        last_name: data1?.last_name,
        first_name: data1?.first_name,
        email: data1?.email,
       
        // expected_salary: [
        //   data1?.current_salary
        //     ? data1?.expected_ctc
        //     : `${data1?.currency}-${data1?.expected_salary}/${data1?.pay}`,
        // ],

        "expected_salary": [
        
          {
            expected_salary: data.expected_salary,
            currency: data.currency,
            pay: data.pay,
            work:data.work
          }
      ],
  
      
        update_type_to_send_email: "email",
      };
      let filterOne = Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );


      // await updateUserProfileDetails(userJobPostId, final_data);
      await updateUserProfileDetails("about_me", filterOne);
      setTimeout(() => {
        setRecall(!reCall);
      }, 1000);
    } else if (watch(update_type) == "No") {
      setVisaStatus(watch(update_type));
      localStorage.setItem("visa", watch(update_type));
      data.visa = watch(update_type);
      data.from = "";
      data.to = "";
      data.type = "";

      let dataV = {
        last_name: data1?.last_name,
        first_name: data1?.first_name,
        email: data1?.email,
        update_type_to_send_email: "email",
        is_visa: Boolean(data.visa),
        visa_details: [{ ...data }],
      };
      await updateUserProfileDetails("about_me", dataV);

      setTimeout(() => {
        setRecall(!reCall);
      }, 1000);
    
    } else {
      await updateUserProfileDetails("about_me", o);
      setTimeout(() => {
        setRecall(!reCall);
      }, 1000);
    }

    if (data?.dob) {
      setData(dateValue);
    } else if (update_type == "expected_salary") {
      let tweakData = `${data?.currency}-${data?.expected_salary}/${data?.pay}`;
      setData(tweakData);
    } else if (update_type == "current_salary") {
      let tweakData = `${data?.currency}-${data?.current_salary}/${data?.pay}`;
      setData(tweakData);
    } else {
      console.log(watch(update_type), "languages");
      setData(watch(update_type));
    }
    // setData(watch(update_type));

    toggleInputs();
  }

  function findSelect() {
    switch (update_type) {
      case "gender":
        return [
          { name: "Male", value: "male" },
          { name: "Female", value: "female" },
          { name: "Prefer not to say", value: "prefer_not_to_say" },
        ];
      default:
        [{ name: "", value: "" }];
    }
  }

  function findSelectType(type) {
    switch (type) {
      case "pay":
        return [
          { name: "Hourly", value: "Hourly" },
          { name: "Weekly", value: "Weekly" },
          { name: "Monthly", value: "Monthly" },
          { name: "Annually", value: "Annually" },
        ];
      case "worktype":
        return [
          { name: "Permanent", value: "Permanent" },
          { name: "Temporary", value: "Temporary" },
          { name: "Contract", value: "Contract" },
          { name: "Intern", value: "Intern" },
        ];
      case "noticeperiod":
        return [
          { name: "Immediately", value: "Immediately" },
          { name: "10 days", value: "10 days" },
          { name: "20 days", value: "20 days" },
          { name: "30 days", value: "30 days" },
          { name: "40 days", value: "40 days" },
          { name: "50 days", value: "50 days" },
          { name: "60 days", value: "60 days" },
          { name: "70 days", value: "70 days" },
          { name: "80 days", value: "80 days" },
          { name: "90 days", value: "90 days" },
          { name: "100 days", value: "100 days" },
          { name: "110 days", value: "110 days" },
          { name: "120 days", value: "120 days" },
          { name: "120+ days", value: "120+ days" },
        ];
      case "experience":
        return [
          { name: "1", value: "1" },
          { name: "2", value: "2" },
          { name: "3", value: "3" },
          { name: "4", value: "4" },
          { name: "5", value: "5" },
          { name: "6", value: "6" },
          { name: "7", value: "7" },
          { name: "8", value: "8" },
          { name: "9", value: "9" },
          { name: "10+", value: "10+" },
        ];

      case "nationality":
        return transformedCountries;
      case "languages":
        return transformedLanguages;

      case "visa":
        return [
          { name: "Yes", value: "Yes" },
          { name: "No", value: "No" },
        ];
      default:
        [{ name: "", value: "" }];
    }
  }

  /* Age calculation code start here */
  function calculateAge(selectedDate) {
    const differenceInMilliseconds = new Date() - new Date(selectedDate);
    const yearsDifference =
      differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365);
    const age = Math.floor(yearsDifference);
    return age;
  }

  // Calculate age whenever selectedDate changes
  const selectedDate = watch(update_type);
  const age = calculateAge(selectedDate);

  // Concatenate the selected date and age
  const newValueWithAge = `${selectedDate} ${age} yrs`;
  /* Age calculation code start here */

  function findUpdateInput(item) {
    switch (item) {
      case "input":
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <Stack direction="row" spacing={"1"}>
                <Input
                  fullWidth
                  placeholder={`${text_field}`}
                  {...register(update_type, { required: true })}
                  id="login-first-name-outlined"
                  autoFocus
                  label={`${update_type}`}
                  type={input_type}
                  error={errors[`${update_type}`]}
                  variant="outlined"
                />
                <IconButton onClick={toggleInputs} color="error">
                  <FeatherIcon icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </FormControl>
          </form>
        );
      case "multiline":
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <Stack direction="row" spacing={"1"}>
                <Input
                  fullWidth
                  // multiline
                  // tabs={3}
                  placeholder={`${text_field}`}
                  {...register(update_type, { required: true })}
                  id="login-first-name-outlined"
                  autoFocus
                  label={`${update_type}`}
                  type={"text"}
                  error={errors[`${update_type}`]}
                  variant="outlined"
                />
                <IconButton onClick={toggleInputs} color="error">
                  <FeatherIcon icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </FormControl>
          </form>
        );
      case "date":
        return (
          // <form onSubmit={handleSubmit(onSubmit)}>
          //   <FormControl sx={{ width: "100%" }} variant="standard">
          //     <Stack direction="row" spacing={"1"}>
          //       <LocalizationProvider dateAdapter={AdapterDayjs}>
          //         <Stack spacing={3}>
          //           <MobileDatePicker
          //             disableFuture={true}
          //             label="Date"
          //             value={watch(update_type)}
          //             onChange={newValue => {
          //               let differenceInMilliseconds =
          //                 new Date() - new Date(newValue);
          //               const yearsDifference =
          //                 differenceInMilliseconds /
          //                 (1000 * 60 * 60 * 24 * 365);

          //               const age = Math.floor(yearsDifference);
          //               const dateString = newValue;
          //               const date = new Date(dateString);

          //               const day = date
          //                 .getUTCDate()
          //                 .toString()
          //                 .padStart(2, "0");
          //               const month = (date.getUTCMonth() + 1)
          //                 .toString()
          //                 .padStart(2, "0");
          //               const year = date.getUTCFullYear();

          //               const formattedDate = `${day}/${month}/${year}`;
          //               const diff = `${formattedDate} ${age}yrs`;
          //               console.log(diff);

          //               const newValueWithAge = `${formattedDate} -(${age}yrs)`;
          //               // setValue(update_type, diff);
          //               setDateValue(newValueWithAge)
          //               setValue(update_type, newValue);
          //             }}
          //             renderInput={params => (
          //               <Input
          //                 {...params}
          //                 error={errors[`${update_type}`]}
          //                 {...register(update_type, { required: true })}
          //               />
          //             )}
          //           />
          //         </Stack>
          //       </LocalizationProvider>
          //       <IconButton color="error" onClick={toggleInputs}>
          //         <FeatherIcon icon="x" size="14" />
          //       </IconButton>
          //       <IconButton type="submit" color="success">
          //         <FeatherIcon icon="check" size="14" />
          //       </IconButton>
          //     </Stack>
          //   </FormControl>
          // </form>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <Stack direction="row" spacing={"1"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <MobileDatePicker
                      disableFuture={true}
                      label="Date"
                      value={watch(update_type)}
                      onChange={newValue => {
                        let differenceInMilliseconds =
                          new Date() - new Date(newValue);
                        const yearsDifference =
                          differenceInMilliseconds /
                          (1000 * 60 * 60 * 24 * 365);

                        const age = Math.floor(yearsDifference);
                        const dateString = newValue;
                        const date = new Date(dateString);

                        const day = date
                          .getUTCDate()
                          .toString()
                          .padStart(2, "0");
                        const month = (date.getUTCMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const year = date.getUTCFullYear();
                        const addDay = (parseInt(day) + 1).toString();
                        const formattedDate = `${addDay}/${month}/${year}`;
                        const diff = `${formattedDate} ${age}yrs`;
                        console.log(diff);

                        const newValueWithAge = `${formattedDate} -(${age}yrs)`;
                        // setValue(update_type, diff);
                        setDateValue(newValueWithAge);
                        setValue(update_type, newValue);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          error={errors[`${update_type}`]}
                          {...register(update_type, { required: true })}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
                <IconButton color="error" onClick={toggleInputs}>
                  <FeatherIcon icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </FormControl>
          </form>
        );
      case "select":
        return (
          <FormControl sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={"1"}>
                <Select
                  labelId={update_type}
                  id={update_type}
                  {...register(update_type, { required: true })}
                  input={
                    <Input
                      sx={{ width: "60%" }}
                      label="signup-user-type"
                      error={errors[`${update_type}`]}
                      {...register(update_type, { required: true })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {findSelect(type)?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <IconButton color="error">
                  <FeatherIcon onClick={toggleInputs} icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </form>
          </FormControl>
        );
      case "location":
        return (
          <FormControl sx={{ width: "100%" }}>
            <FormProvider
              register={register}
              watch={watch}
              errors={errors}
              formSetValue={setValue}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" spacing={"1"}>
                  <GoogleMaterialInputLocation updatingContent={update_type} />
                  <IconButton color="error">
                    <FeatherIcon onClick={toggleInputs} icon="x" size="14" />
                  </IconButton>
                  <IconButton type="submit" color="success">
                    <FeatherIcon icon="check" size="14" />
                  </IconButton>
                </Stack>
              </form>
            </FormProvider>
          </FormControl>
        );

      case "salary":
        return (
          <Modal
            open={salaryModel}
            onClose={() => {
              toggleSalaryModel();
              toggleInputs()
              // reset();
            }}
            aria-labelledby="edit-profile-modal-title"
            aria-describedby="edit-profile-modal-description"
          >
            <Box sx={style}>
              <BlackTypography
                variant="h2"
                sx={{ marginBottom: "10px", position: "sticky" }}
              >
                {text_field} Details
              </BlackTypography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item lg={3} md={3}>
                    <Autocomplete
                      componentsProps={{
                        paper: {
                          style: {
                            borderRadius: "8px",
                            width: "100%",
                            boxShadow:
                              "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                          },
                        },
                      }}
                      popupIcon={<FeatherIcon icon="chevron-down" size={14} />}
                      value={watch("currency")}
                      onChange={(event, newValue) => {
                        setValue("currency", newValue?.toUpperCase());
                      }}
                      // inputValue={watch("currency")}
                      onInputChange={(event, newInputValue) => {
                        setValue("currency", newInputValue?.toUpperCase());
                      }}
                      {...register("currency", { required: true })}
                      id="controllable-states-demo"
                      options={currencies}
                      renderInput={params => (
                        <FormControl variant="standard">
                          <PrimaryNormalInputLabel
                            shrink
                            htmlFor="folloups-job-type"
                            error={errors.currency}
                          >
                            Currency
                          </PrimaryNormalInputLabel>

                          <PrimaryCurrencyInputs
                            ref={params.InputProps.ref}
                            {...params}
                            // label="Controllable"
                          />
                        </FormControl>
                      )}
                    />
                    <PrimaryNormalFormHelperText error={errors.currency}>
                      {errors?.currency ? "job Title is a required field" : ""}
                    </PrimaryNormalFormHelperText>
                  </Grid>

                  <Grid item lg={9} md={9}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="min-sal-outlined"
                        error={errors.current_salary}
                      >
                        {text_field}
                      </PrimaryNormalInputLabel>
                      {update_type == "current_salary" ? (
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="2000000"
                          {...register("current_salary", {
                            required: true,
                            max: Number(watch("current_salary")),
                          })}
                          value={watch("current_salary")}
                          onInput={e => {
                            const rawValue = e.target.value.replace(/,/g, "");
                            const formattedValue =
                            formatNumberWithCommas(rawValue);
                            setValue("current_salary", formattedValue);
                          }}
                          id="min-sal-outlined"
                          autoFocus
                          labelId="min-sal-outlined"
                          label="Minimum Salary"
                          type={"text"}
                          error={errors.update_type}
                          endAdornment={
                            <InputAdornment
                              sx={{
                                background: "white",
                                position: "absolute",
                                right: "10px",
                              }}
                              position="end"
                            >
                              {getSymbolFromCurrency(watch("currency"))}
                            </InputAdornment>
                          }
                          variant="outlined"
                        />
                      ) : (
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="2000000"
                          {...register("expected_salary", {
                            required: true,
                            max: Number(watch("current_salary")),
                          })}
                          
                          onInput={e => {
                            const rawValue = e.target.value.replace(/,/g, "");
                            const formattedValue =
                            formatNumberWithCommas(rawValue);
                            setValue("expected_salary", formattedValue);
                          }}

                          id="min-sal-outlined"
                          autoFocus
                          labelId="min-sal-outlined"
                          label="Minimum Salary"
                          type={"text"}
                          error={errors.update_type}
                          endAdornment={
                            <InputAdornment
                              sx={{
                                background: "white",
                                position: "absolute",
                                right: "10px",
                              }}
                              position="end"
                            >
                              {getSymbolFromCurrency(watch("currency"))}
                            </InputAdornment>
                          }
                          variant="outlined"
                        />
                      )}
                      <PrimaryNormalFormHelperText
                        error={errors.expected_salary}
                      >
                        {errors?.expected_salary
                          ? "minimum Salary is a required field"
                          : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="min-sal-outlined"
                        error={errors.update_type3}
                      >
                        Pay out
                      </PrimaryNormalInputLabel>
                      <Select
                        labelId={update_type3}
                        id={update_type3}
                        // label={`${pay_field}`}
                        {...register(update_type3, { required: false })}
                        renderValue={selected => {
                          if (selected.length === 0) {
                            return <em>Pay out</em>;
                          }

                          return selected;
                        }}
                        input={
                          <PrimaryNormalInputs
                            sx={{ width: "100%" }}
                            label="signup-user-type"
                            error={errors[`${update_type3}`]}
                            {...register(update_type3, { required: true })}
                          />
                        }
                        value={watch(update_type3)}
                        MenuProps={MenuProps}
                      >
                        {findSelectType(pay_field)?.map(item => {
                          return (
                            <MenuItem
                              key={item?.value}
                              value={item?.value} // style={getStyles(name, personName, theme)}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="min-sal-outlined"
                        error={errors.update_type4}
                      >
                        Work mode
                      </PrimaryNormalInputLabel>
                      <Select
                        labelId={update_type4}
                        id={update_type}
                        {...register(update_type4, { required: false })}
                        input={
                          <PrimaryNormalInputs
                            sx={{ width: "100%" }}
                            label="signup-user-type"
                            error={errors[`${update_type4}`]}
                            {...register(update_type4, { required: false })}
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {findSelectType(work_field)?.map(item => {
                          return (
                            <MenuItem
                              key={item?.value}
                              value={item?.value} // style={getStyles(name, personName, theme)}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ marginLeft: "auto" }}>
                    <Stack spacing={1} direction="row">
                      <SecondaryFilledButton
                        onClick={() => {
                          toggleInputs()
                        }}
                      >
                        cancel
                      </SecondaryFilledButton>
                      <PrimaryButton type="submit">Submit</PrimaryButton>
                    </Stack>{" "}
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Modal>
        );
      case "noticeperiod":
        return (
          <FormControl sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={"1"}>
                <Select
                  labelId={update_type}
                  id={update_type}
                  {...register(update_type, { required: false })}
                  input={
                    <Input
                      sx={{ width: "100%" }}
                      label="signup-user-type"
                      error={errors[`${update_type}`]}
                      {...register(update_type, { required: false })}
                    />
                  }
                  MenuProps={MenuProps}
                  // menuPortalTarget={document.body} // Render the menu portal within the body
                  menuShouldBlockScroll={true} // Prevent the page from scrolling when the menu is open
                  modifiers={{
                    preventOverflow: {
                      boundariesElement: "viewport", // Keep the menu within the viewport
                    },
                  }}
                >
                  {findSelectType(type)?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <IconButton color="error">
                  <FeatherIcon onClick={toggleInputs} icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </form>
          </FormControl>
        );
      case "languages":
        return (
          <FormControl sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={"1"}>
                <Select
                  labelId={update_type}
                  multiple
                  id={update_type}
                  {...register(update_type, { required: false })}
                  input={
                    <Input
                      sx={{ width: "80%" }}
                      label="signup-user-type"
                      error={errors[`${update_type}`]}
                      {...register(update_type, { required: false })}
                    />
                  }
                  value={watch(update_type) || []} // Use watch to get selected values
                  onChange={event => {
                    setValue(update_type, event.target.value); // Update selected values using setValue
                  }}
                  MenuProps={MenuProps}
                >
                  {findSelectType(type)?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <IconButton color="error">
                  <FeatherIcon onClick={toggleInputs} icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </form>
          </FormControl>
        );
      case "experience":
        return (
          <FormControl sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={"1"}>
                <Select
                  labelId={update_type}
                  id={update_type}
                  {...register(update_type, { required: false })}
                  input={
                    <Input
                      sx={{ width: "100%" }}
                      label="signup-user-type"
                      error={errors[`${update_type}`]}
                      {...register(update_type, { required: false })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {findSelectType(type)?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <IconButton color="error">
                  <FeatherIcon onClick={toggleInputs} icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </form>
          </FormControl>
        );

      case "nationality":
        return (
          <FormControl sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={"1"}>
                <Select
                  labelId={update_type}
                  id={update_type}
                  {...register(update_type, { required: false })}
                  input={
                    <Input
                      sx={{ width: "100%" }}
                      label="signup-user-type"
                      error={errors[`${update_type}`]}
                      {...register(update_type, { required: false })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {findSelectType(type)?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <IconButton color="error">
                  <FeatherIcon onClick={toggleInputs} icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </form>
          </FormControl>
        );
      case "visa":
        return (
          <FormControl sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={"1"}>
                <Select
                  labelId={update_type}
                  id={update_type}
                  {...register(update_type, { required: false })}
                  input={
                    <Input
                      sx={{ width: "100%" }}
                      label="signup-user-type"
                      error={errors[`${update_type}`]}
                      {...register(update_type, { required: false })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {findSelectType(type)?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <IconButton color="error">
                  <FeatherIcon onClick={toggleInputs} icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </form>
          </FormControl>
        );

      case "file":
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <Stack direction="row" spacing={"1"}>
                <Input
                  fullWidth
                  placeholder={`${text_field}`}
                  {...register(update_type, { required: true })}
                  id="login-first-name-outlined"
                  autoFocus
                  label={`${update_type}`}
                  type={input_type}
                  error={errors[`${update_type}`]}
                  variant="outlined"
                />
                <IconButton onClick={toggleInputs} color="error">
                  <FeatherIcon icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </FormControl>
          </form>
        );
    }
  }
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <ColorTypography variant="h5" component="h5">
            {text_field}
          </ColorTypography>
        </Grid>
        <>
          {input ? (
            <Grid item xs={6}>
              {findUpdateInput(type)}
            </Grid>
          ) : (
            <>
              {checkInputs() ? (
                <Grid item xs={2}>
                  <PrimaryOpacitedButton
                    onClick={toggleInputs}
                    startIcon={<FeatherIcon icon="plus" size="14" />}
                  >
                    Add
                  </PrimaryOpacitedButton>
                </Grid>
              ) : (
                <>
                  <Grid item xs={4}>
                    <ColorTypography
                    varient="h6"
                    componenet="h6"
                      sx={{
                        overflow: "hidden",
                        maxWidth: "200px",
                        fontWeight: "500",
                        fontSize: "15px",
                        letterSpacing:"-0.2px",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "wrap",
                      }}
                    >
                      {data}
                    </ColorTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={toggleInputs} color="success">
                      <FeatherIcon icon="edit" size="14" />
                    </IconButton>
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      </Grid>
    </>
  );
}