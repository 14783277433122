import React from "react";
import "./ContactUs.css";
function ContactUsMap() {



  return (
    <>
      <div className="container wide mb-2">
        <div className="border-radius-5 overflow-hidden">
          <div id="map-basic" className="leaflet-map mb-50">
               <iframe src="https://maps.google.com/maps?q=Syoft, 1st Floor, Plot No. 16, Jayabheri Enclave, Gachibowli, Hyderabad, Telangana 500032&t=&z=13&ie=UTF8&iwloc=&output=embed"  className="contact_us_map"></iframe>
          </div>
        </div>
      </div>


{/* <div className="my-5">
  
  <footer className="text-center text-white"  style={{backgroundColor:"#3f51b5"}}>
    
    <div className="container">
     
      <section className="mt-5">
        
        <div className="row text-center d-flex justify-content-center pt-5">
          
          <div className="col-md-2">
            <h6 className="text-uppercase font-weight-bold">
              <a href="#!" className="text-white">About us</a>
            </h6>
          </div>
         
          <div className="col-md-2">
            <h6 className="text-uppercase font-weight-bold">
              <a href="#!" className="text-white">Products</a>
            </h6>
          </div>
          
          <div className="col-md-2">
            <h6 className="text-uppercase font-weight-bold">
              <a href="#!" className="text-white">Awards</a>
            </h6>
          </div>
       
          <div className="col-md-2">
            <h6 className="text-uppercase font-weight-bold">
              <a href="#!" className="text-white">Help</a>
            </h6>
          </div>
         
          <div className="col-md-2">
            <h6 className="text-uppercase font-weight-bold">
              <a href="#!" className="text-white">Contact</a>
            </h6>
          </div>
        
        </div>
      
      </section>

      <hr className="my-5" />

      <section className="mb-5">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
              distinctio earum repellat quaerat voluptatibus placeat nam,
              commodi optio pariatur est quia magnam eum harum corrupti
              dicta, aliquam sequi voluptate quas.
            </p>
          </div>
        </div>
      </section>
    
      <section className="text-center mb-5">
        <a href="" className="text-white me-4">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="" className="text-white me-4">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="" className="text-white me-4">
          <i className="fab fa-google"></i>
        </a>
        <a href="" className="text-white me-4">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="" className="text-white me-4">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="" className="text-white me-4">
          <i className="fab fa-github"></i>
        </a>
      </section>
    
    </div>
   
    <div
         className="text-center p-3"
         style="background-color: rgba(0, 0, 0, 0.2)"
         style={{backgroundColor:"rgba(0,0,0,0.2)"}}
         >
      © 2020 Copyright:
      <a className="text-white" href="https://mdbootstrap.com/"
         >MDBootstrap.com</a
        >
    </div>
  
  </footer>

</div> */}



    </>
  );
}

export default ContactUsMap;
