import { UPDATE_PROFILE_SIDEBAR, LOGIN_BTN_HANDLER } from "./actionTypes";

export const updateProfileSidebar = e => ({
  type: UPDATE_PROFILE_SIDEBAR,
  payload: e,
});

export const loginBtnHandler = e => ({
  type: LOGIN_BTN_HANDLER,
  payload: e,
});

