import { styled ,alpha} from "@mui/material/styles";
import {
  OutlinedInput,
  Input,
  Button,
  TextField,
  TextareaAutosize,
  Tabs,
  Select,
  Tab,
  Typography,
  InputBase,
  Avatar,
  Switch,
  Divider,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonClasses,
  toggleButtonGroupClasses,
  outlinedInputClasses,
  inputClasses,
  buttonClasses,
  tabsClasses,
  tabClasses,
  typographyClasses,
  boxClasses,
  textFieldClasses,
  textareaAutosizeClasses,
  selectClasses,
  InputBaseClasses,
  AvatarClasses,
  SwitchClasses,
  DividerClasses,
  dividerClasses
} from "@mui/material";
import { TextArea, textareaClasses } from "@mui/joy";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { palette } from "@mui/system";
import { JoyUiTheme } from "./joyUiStyles";

//Note: Currently making the global MUI is in progress by checking each page (Already mui is applied to each page separately by using makeStyles hook) now changing all into global in progress...

const MuiS = {
  // mainColor: "#afeae4",
  // mainColor:"#024430",
  mainColor:"#066a4c",
  hoverColor:"#afeae4",
  fontFamily: "Inter",
  borderRadius: "8px",
  btnText: "#ffffff",
  borderRadiusTxtF: "4px",
  height: 48,
  errorColor: "#d32f2f",
  backgroundColor:"#ECECEC"
};

export const globalTheme = createTheme({
  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       html: {
  //         WebkitFontSmoothing: "auto",
  //       },
  //     },
  //   },
  // },

  /* Global color and etc... start */
  palette: {
    global: {
      mainColor: "#afeae4",
      fontFamily: "Inter",
      borderRadius: "8px",
    },
  },

  /* Global color and etc... End */

  /* Componenets StyleOverrides start Here */
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 0,
          textTransform: "capitalize",
          color: "rgba(0, 0, 0, 0.52)",
          fontFamily: "Inter,sans-serif",
          fontSize: "16px",
          fontWeight: 500,
          padding: "8px !important",
          "&.Mui-selected": {
            background: "rgba(0, 0, 0, 0.1) !important",
            color: "#012582 !important",
          },
          "@media (min-width:300px) and (max-width:600px)": {
            "&.MuiToggleButton-root": {
              border: 0,
              textTransform: "capitalize",
              color: "rgba(0, 0, 0, 0.52)",
              fontFamily: "Inter,sans-serif",
              fontSize: "16px",
              fontWeight: 500,
              height: "fit-content !important",
            },
          },
        },
      },
    },

    // MuiTypography: {
    //   defaultProps: {
    //     variantMapping: {
    //       h1: 'h2',
    //       h2: 'h2',
    //       h3: 'h2',
    //       h4: 'h2',
    //       h5: 'h2',
    //       h6: 'h2',
    //       subtitle1: 'h2',
    //       subtitle2: 'h2',
    //       body1: 'span',
    //       body2: 'span',
    //     },
    //   },
    //   styleOverrides:{
    //     fontFamily: MuiS.fontFamily,
    //   }
    // },


    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.type === "soft" &&
            ownerState.color === "success" && {
              color: "black",
              borderRadius: "8px",
              overflow: "hidden",
              height: "auto",
              wordWrap: "break-word",
              background: "rgb(200, 250, 205)",
              padding: "12px",
            }),
          ...(ownerState.type === "soft" &&
            ownerState.color === "info" && {
              color: "black",
              borderRadius: "8px",
              overflow: "hidden",
              wordWrap: "break-word",
              height: "auto",
              background: "rgb(244, 246, 248)",
              padding: "12px",
            }),
        }),
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          gap: 20,
          "&.Mui-selected": {
            color: "#012582 !important",
            background: "none",
          },
        },

        grouped: {
          borderRadius: "10px !important",
          "&:not(:first-of-type)": {
            borderRadius: "10px !important",
          },
          "&:not(:last-of-type)": {
            borderRadius: "10px !important",
          },
        },
      },
    },

    /*MUI Outlined Input styles overrides start */
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: MuiS.borderRadius,
          borderWidth: "1px",
          height: MuiS.height,
          "& input": {
            padding: "12px 14px",
            borderRadius: MuiS.borderRadius,
          },
          "&:hover fieldset": {
            borderColor: `${MuiS.hoverColor} !important`
          },
          "&::placeholder": {
            fontFamily: MuiS.fontFamily,
          },
          "&.Mui-focused fieldset": {
            borderColor: `${MuiS.mainColor} !important`,
            borderWidth: "1px !important",
          },
          "&.Mui-error": {
            "&.Mui-focused fieldset": {
              borderColor: `${MuiS.errorColor} !important`,
            },
            "&:hover fieldset": {
              borderColor: `${MuiS.errorColor} !important`,
            },
            borderColor: `${MuiS.errorColor} !important`,
          },
          '& label.Mui-focused': {
            color: '#066a4c',
          },
        },
      },
    },

    /*MUI Outlined Input styles overrides End */


  /*MUI Textfield Input styles overrides Start */
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#E0E3E7',
          '--TextField-brandBorderHoverColor': '#B2BAC2',
          '--TextField-brandBorderFocusedColor': '#6F7E8C',
          // '& label.Mui-focused': {
          //   color: 'var(--TextField-brandBorderFocusedColor)',
          // },
          '& label.Mui-focused': {
            color: '#066a4c',
          },
          "&.Mui-error": {
            "&.Mui-focused fieldset": {
              borderColor: `${MuiS.errorColor} !important`,
            },
            "&:hover fieldset": {
              borderColor: `${MuiS.errorColor} !important`,
            },
            borderColor: `${MuiS.errorColor} !important`,
          },
        },
      },
    },

    /*MUI Textfield Input styles overrides End */

    /*MUI  Button styles overrides start */
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderRadius: MuiS.borderRadius,
          fontFamily: MuiS.fontFamily,
          color: MuiS.btnText,
          fontWeight: 600,
          // fontSize:"14px",
          letterSpacing: "0px",
        },
      },
    },
    /*MUI  Button styles overrides End */

    /* MUI Tabs Styles start */
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 48,
          width: "100% ",
          borderColor: "rgba(0, 0, 0, 0.3)",
          borderRadius: "8px !important",
          transitionDelay: "0.5ms",
          color: "rgba(0, 0, 0, 0.5)",
        },
        
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontFamily: MuiS.fontFamily,
          letterSpacing: "0px",
        },
        
      },
    },
    /* MUI Tabs Styles End */

    /* MUI Texfield styles start */
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: `${MuiS.borderRadiusTxtF} !important`,
          fontFamily: MuiS.fontFamily,
          color: MuiS.mainColor,
          // "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
          //   color: "#081753 !important",
          // },
          '& label.Mui-focused': {
            color: '#066a4c',
          },
        },
     
      },
    },
    /* MUI Texfield styles End */

    MuiSwitch: {
      styleOverrides: {
        root: {

        }
      }
    },

    /*Text Area styles start Joy Ui element */
    MuiTextarea: {
      styleOverrides: {
        borderRadius: "8px",
        borderWidth: "1px",
        height: "30px !important",
        "&:hover fieldset": {
          borderColor: MuiS.mainColor,
        },
        "&::placeholder": {
          fontFamily: MuiS.fontFamily,
        },
        "&.Mui-focused fieldset": {
          borderColor: `${MuiS.mainColor} !important`,
          borderWidth: "1px !important",
        },
        textarea: {
          minHeight: "30px !important",
          maxHeight: "31px !important",
        },
      },
    },
    /*Text Area styles End Joy Ui element */

    MuiSelect: {
      styleOverrides: {
        select: {
          fontWeight: 400,
        },
      },
    },








  },
  /* Componenets StyleOverrides End Here */
});


export const PrimaryTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h1}`]: {
    fontWeight: "800",
    textTransform: "capitalize",
    color: "#000000",
    fontSize: "16px",
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const PdfButton = styled(Button)(({theme})=>({
  background: "rgb(236, 236, 236)",
  color: "rgb(8, 23, 83)",
  textTransform:"capitalize",
  letterSpacing:"-2%"
}))

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 23,
  height: 23,
  border: `2px solid ${theme.palette.background.paper}`,
  alignSelf:"flex-end"
}));

export const SearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  // backgroundColor: MuiS.backgroundColor,
  '&:hover': {
    // backgroundColor: MuiS.backgroundColor,
    borderRadius: `${MuiS.borderRadiusTxtF} !important`,
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


export const NotificationSwitch = styled(Switch)(({ theme }) => ({
  width: 52,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",

      "& + .MuiSwitch-track": {
        opacity: 1,
        // backgroundColor: "rgba(3,27,87,0.6)",
        backgroundColor: "#cde2e7",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    marginTop: "5px",
    // backgroundColor: "rgb(3,27,87)",
    backgroundColor: "#066a4c",
    width: 22,
    height: 22,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: "10px",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    // backgroundColor: "rgba(3,27,87,0.2)",
    backgroundColor:"#cde2e7",
    borderRadius: 20 / 2,
  },

  // "& .MuiSwitch-switchBase.Mui-checked": {
  //   color: "rgba(3,27,87,0.6)",
  //   "&:hover": {
  //     backgroundColor: alpha("rgba(3,27,87,0.6)", "rgba(3,27,87,0.8)"),
  //   },
  // },
  // "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
  //   backgroundColor: "rgba(3,27,87,0.2)",
  // },
}));


export const MiddleDivider = styled(Divider)(({ theme }) => ({
  [`&.${dividerClasses.root}`]: {
    borderWidth:"0.1px",
    borderColor:MuiS.mainColor,
    width:"100%",
    marginTop:"10px"
  },
}));

export const NotificationTitleTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.root}`]: {
   color:"black",
   fontWeight:500,
   fontFamily:MuiS.fontFamily,
   fontSize:"16px",
  },
}));

export const NotificationDescTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.root}`]: {
   color:"black",
   fontWeight:400,
   fontFamily:MuiS.fontFamily,
   fontSize:"14px"
  },
}));

export const NotificationDescTextTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.root}`]: {
   color:"black",
   fontWeight:400,
   fontFamily:MuiS.fontFamily,
   fontSize:"12px",
   opacity:"50%"
  },
}));

