import React , {useState} from 'react';
import ReferPage1 from  './ReferPage1';
import ReferPage2 from  './ReferPage2';
import ReferPage3 from  './ReferPage3';
import Categories from "../AboutUs/AboutsUsPages/Categories";
import AboutUsExplore from "../AboutUs/AboutsUsPages/AboutUsExplore";
import CompaniesScrollPage  from './CompaniesPage';
import BlogsList from './BlogsPage';
import Footer from '../../layouts/footer/Footer'
import './ReferNowPage.css'

function ReferNowIndex(){
    return (
        <>
        <ReferPage1 />
        <ReferPage2 />
        <AboutUsExplore />
        <ReferPage3 />
        <Categories />
        <BlogsList />
        <CompaniesScrollPage />
   
        <section style={{paddingTop:"5%"}}>
          <Footer />
        </section>
        </>
    )
}

export default ReferNowIndex;