import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import { createTheme, ThemeProvider } from "@mui/material/styles";

 const datePickerTheme = createTheme({
    overrides: {
      MuiPickersDay: {
        daySelected: {
          backgroundColor: '#cde2e7',
          color: '#066a4c',
        },
        dayDisabled: {
            color: '#066a4c',
        },
      },
      MuiPickersYear: {
        yearSelected: {
        backgroundColor: '#cde2e7',
          color: '#066a4c',
        },
        yearDisabled: {
            color: '#066a4c',
        },
      },
      MuiPickersMonth: {
        monthSelected: {
            backgroundColor: '#cde2e7',
            color: '#066a4c',
        },
        monthDisabled: {
            color: '#066a4c',
        },
      },
    },
  });

  export default datePickerTheme;
  
