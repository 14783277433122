import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Skeleton from "@mui/material/Skeleton";
import { imageURL } from "../../../Constants/commonURLS";
import { BookmarksOutlined } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import EarnImage from "../../../assets/images/earnImage.svg";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useNavigate } from "react-router-dom";
import CardOverflow from "@mui/joy/CardOverflow";
// import Divider from "@mui/joy/Divider";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import JobInsights from "../../BrowseJobsList/JobInsights";
import SkillsSection from "../../BrowseJobsList/SkillsSection";
import JobInfoDescription from "../../BrowseJobsList/JobInfoDescription";
import Box from "@mui/joy/Box";
import { getSavedJobsList } from "../../../helpers/dataFetcher/jobs/jobs";
import JobDescription from "../../BrowseJobsList/JobDescription";
import { getJobPostById } from "../../../helpers/dataFetcher/jobs/jobs";
import { getCompanyById } from "../../../helpers/dataFetcher/companies";
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { savedJobById } from "../../../helpers/dataFetcher/jobs/jobs";
import getSymbolFromCurrency from "currency-symbol-map";
// import noJobs from "../../../assets/images/no_jobs.svg";
import noJobs from "../../../assets/images/nojobsShiyft.png";


function MySavedJobs() {
  const [savedJobs, setSavedJobs] = useState([]);
  const [Job, setJob] = useState({});
  const [company, setCompany] = useState({});
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(null);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [referModal, setReferModal] = useState({
    modal: "",
    purpose: "",
    modalType: "",
  });

  useEffect(() => {
    getSavedJobs();
  }, []);

  async function getSavedJobs() {
    const savedJobData = await getSavedJobsList();
  
    setSavedJobs(savedJobData?.data?.data);

    const jobDataList = await getJobPostById(
      savedJobData.data.data[0]?.saved_job_post_id?.id
    );
    const company_data_list = await getCompanyById(
      savedJobData?.data?.data[0]?.company_user_details?.id
    );

    // console.log(jobDataList?.data,"savedJobData")
    setCompany(company_data_list?.data);
    setJob(jobDataList?.data);
  }

  const getjobById = async data => {
    setSelectedButton(data.job_id === selectedButton ? null : data.job_id);
    const jobDataList = await getJobPostById(data.job_id);

    const company_data_list = await getCompanyById(data?.company_id);
    // console.log(company_data_list,"company_data_list")
    setCompany(company_data_list?.data);
    setJob(jobDataList?.data);
  };

  const handelSavedJobs = async data => {
    const { status } = await savedJobById(data);
    if (status !== 200) {
      setTimeout(() => {
        toast.success("Saved job successfully");
      }, []);
    } else {
      setTimeout(() => {
        toast.error("Unsaved job successfully");
      }, []);
    }
    props.fechJobsBasedonOffset();
  };

  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    return `${scaledNumber.toFixed(0)}k`;
  };

  return (
    <>
      <div
       className="d-flex align-items-start gap-2 justify-content-center" style={{marginTop:"30px",marginLeft:"51px"}}
      >
        <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
          <Sidebar />
        </div>
        {savedJobs?.length > 0 ? (
          <div
            className="col-lg-10 col-sm-12 col-md-12  font_inherit overflow-auto"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              flexWrap: "wrap",
              height: "80vh",
              oveflow: "auto",
              marginLeft: "10px",
            }}
          >
            <div
              className="col-lg-4 col-md-6 col-sm-12 hide_scroll_bar"
              style={{ height: "98%", overflow: "auto" }}
            >
              <div className="saved_jobs">
                <div className="content-page">
                  <div className="box-filters-job mt-15 mb-10"></div>
                  <div className="job-list-list mb-15">
                    {/* <div className="list-recent-jobs" id="list_jobs"> */}
                    <div>
                      {savedJobs?.length > 0
                        ? savedJobs?.map((item, index) => (
                            <React.Fragment>
                              <div
                                className="latest_jobs_main_card_section mb-3"
                                key={item?.saved_job_post_id?.id}
                                // onClick={() =>
                                //   getjobById(item?.saved_job_post_id?.id)
                                // }
                                style={{
                                  backgroundColor:
                                    selectedButton ===
                                    item?.saved_job_post_id?.id
                                      ? "#cde2e7"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  getjobById({
                                    job_id: item?.saved_job_post_id?.id,
                                    company_id: item?.company_user_details?.id,
                                  })
                                }
                              >
                                <div className="latest_jobs_inside_section">
                                  <div className="title_section_1">
                                    <div className="title_name_logo_sec">
                                      <div
                                        style={{
                                          height: "44px",
                                          width: "44px",
                                          borderRadius: "50%",
                                          border:
                                            "0.5px solid rgba(0, 0, 0, 0.25)",
                                        }}
                                      >
                                        <Avatar
                                          src={`${imageURL}${item?.saved_job_post_company?.company_image}`}
                                          style={{
                                            borderRadius: "50%",
                                            borderColor: "rgba(0, 0, 0, 0.25)",
                                            height: "100%",
                                            width: "100%",
                                          }}
                                        >
                                          {item?.saved_job_post_company?.company_name?.charAt(0)}
                                        </Avatar>
                                      </div>
                                      <div className="title_sec_divider">
                                        <Typography
                                          fontWeight="md"
                                          textColor="primary.plainColor"
                                          mb={0.5}
                                          style={{
                                            cursor: "pointer",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                            color: "#121212",
                                            fontFamily: "Inter,sans-serif",
                                          }}
                                          // onClick={() => navigate(`../job/${item?.saved_job_post_id?.id}`)}
                                        >
                                          {item?.saved_job_post_id?.job_title}
                                          {/* {item?.job_title?.length <= 20 ? item?.job_title : item?.job_title.slice(0, 18) + ".."} */}
                                        </Typography>
                                        <Typography
                                          fontWeight="md"
                                          textColor="primary.plainColor"
                                          mb={0.5}
                                          style={{
                                            cursor: "pointer",
                                            textTransform: "capitalize",
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            color: "#121212",
                                            opacity: "50%",
                                            fontFamily: "Inter,sans-serif",
                                          }}
                                          // onClick={() =>
                                          //   navigate(
                                          //     `../employer-single-list/${item?.saved_job_post_company?.id}`
                                          //   )
                                          // }
                                        >
                                          {item?.saved_job_post_company
                                            ?.company_name?.length <= 20
                                            ? item?.saved_job_post_company
                                                ?.company_name
                                            : item?.saved_job_post_company?.company_name.slice(
                                                0,
                                                18
                                              ) + ".."}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className="commission_sec">
                                      <Stack direction="row" spacing={2}>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={EarnImage}
                                        />
                                      </Stack>
                                      <div className="title_sec_divider">
                                        <Typography
                                          fontWeight="md"
                                          textColor="primary.plainColor"
                                          mb={0.5}
                                          sx={{
                                            cursor: "pointer",
                                            textTransform: "capitalize",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                            color: "#121212",
                                            fontFamily: "Inter,sans-serif",
                                          }}
                                        >
                                          Earn Upto
                                        </Typography>
                                        <Typography
                                          fontWeight="md"
                                          textColor="primary.plainColor"
                                          mb={0.5}
                                          sx={{
                                            cursor: "pointer",
                                            textTransform: "capitalize",
                                            fontSize: "13px",
                                            fontWeight: "Bold",
                                            color: "#066a4c !important",
                                            fontFamily: "Inter,sans-serif",
                                          }}
                                        >
                                          {getSymbolFromCurrency(item.currency)}{" "}
                                          {abbreviateNumber(
                                            (item?.saved_job_post_id?.max_sal *
                                              item?.saved_job_post_id
                                                ?.commission_rate) /
                                              100
                                          )}
                                        </Typography>
                                      </div>
                                      {/* <div
                                        style={{
                                          height: "10px",
                                        }}
                                        onClick={() =>
                                          handelSavedJobs({
                                            job_post_id:
                                              item?.saved_job_post_id?.id,
                                            company_id: item.company_id,
                                            type:
                                              item.is_candidate_saved_the_job_post ==
                                              0
                                                ? "save"
                                                : "unsave",
                                          })
                                        }
                                      >
                                        <Checkbox
                                          {...label}
                                          checked={
                                            item.is_candidate_saved_the_job_post
                                          }
                                          icon={<FavoriteBorder />}
                                          checkedIcon={<Favorite />}
                                          sx={{
                                            "&.Mui-checked": {
                                              color: "#081753",
                                            },
                                          }}
                                        />
                                      </div> */}
                                    </div>
                                  </div>

                                  <div className="main_skills_sec">
                                    <div className="job_card_top_details_commision_cont">
                                      <div className="job_details"></div>
                                      <span className="mx-2"></span>
                                      <span className="card-job-top--price">
                                        {/* {item.time} */}
                                        <span></span>
                                      </span>
                                    </div>
                                    <div className="jobs_title_card">
                                      <Chip
                                        variant="solid"
                                        color="neutral"
                                        size="sm"
                                        style={{
                                          color: "#777AD6",
                                          background: "#F5ECFF",
                                        }}
                                      >
                                        {item?.saved_job_post_id?.job_type.replace(
                                          "_",
                                          " "
                                        )}
                                      </Chip>

                                      <Chip
                                        variant="solid"
                                        color="neutral"
                                        size="sm"
                                        icon={<LocationOnIcon />}
                                        style={{
                                          color: "#081753",
                                          background: "rgba(8, 23, 83, 0.07)",
                                        }}
                                      >
                                        {item?.saved_job_post_id?.location
                                          ?.length <= 17
                                          ? item?.saved_job_post_id?.location
                                          : item?.saved_job_post_id?.location.slice(
                                              0,
                                              35
                                            ) + ".."}
                                      </Chip>
                                      <Chip
                                        variant="solid"
                                        color="neutral"
                                        size="sm"
                                        style={{
                                          color: "#081753",
                                          background: "rgba(8, 23, 83, 0.07)",
                                        }}
                                      >
                                        {item?.saved_job_post_id?.min_exp}-
                                        {item?.saved_job_post_id?.max_exp}
                                        &nbsp; Yrs
                                      </Chip>
                                      <Chip
                                        variant="soft"
                                        color="neutral"
                                        size="sm"
                                        style={{
                                          background: "#F2F8F6",
                                          color: "#07A996",
                                        }}
                                      >
                                        {getSymbolFromCurrency(
                                          item?.saved_job_post_id?.currency
                                        )}
                                        {Math.round(
                                          item?.saved_job_post_id?.min_sal /
                                            1000,
                                          2
                                        )}
                                        k-
                                        {Math.round(
                                          item?.saved_job_post_id?.max_sal /
                                            1000,
                                          2
                                        )}
                                        k
                                      </Chip>
                                      <Chip
                                        variant="soft"
                                        color="neutral"
                                        size="sm"
                                        style={{
                                          background: "#F2F8F6",
                                          color: "#07A996",
                                        }}
                                      >
                                        {item?.saved_job_post_id?.work_mode.replace(
                                          "_",
                                          " "
                                        )}
                                      </Chip>
                                    </div>
                                  </div>

                                  <div
                                    className="skills_section"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <ul
                                      className="skill_main_section"
                                      style={{
                                        cursor: "pointer",
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: "#121212",
                                        opacity: "50%",
                                        fontFamily: "Inter,sans-serif",
                                      }}
                                    >
                                      {item?.key_skills_details
                                        ?.slice(0, 3)
                                        .map(each => (
                                          <li
                                            key={each.id}
                                            className="typography-field"
                                            style={{ paddingRight: "10px" }}
                                          >
                                            {each?.name}
                                          </li>
                                        ))}
                                    </ul>
                                  </div>

                                  {/* <div className="apply_section">
                                  <div className="d-flex gap-2 align-items-center">
                                  
                                  
                                    <Button
                                      onClick={() =>
                                        handelSavedJobs({
                                          job_post_id: item.saved_job_post_id.id,
                                          company_id: item.company_id,
                                          type:
                                            item.is_candidate_saved_the_job_post ==
                                            0
                                              ? "save"
                                              : "unsave",
                                        })
                                      }
                                      variant="outlined"
                                      className="apply-now-btn cursor-pointerf"
                                      style={{
                                        color: "#000000",
                                        color:
                                          item.is_candidate_saved_the_job_post
                                            ? "rgba(8, 23, 83, 0.1)"
                                            : "#000000",
                                        border:
                                          "1px solid rgba(8, 23, 83, 0.1)",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: "10px",
                                          marginTop: "-30px",
                                        }}
                                      >
                                        <Checkbox
                                          {...label}
                                          checked={
                                            item.is_candidate_saved_the_job_post
                                          }
                                          icon={<FavoriteBorder />}
                                          checkedIcon={<Favorite />}
                                          sx={{
                                            "&.Mui-checked": {
                                              color: "#081753",
                                            },
                                          }}
                                        />
                                      </div>
                                    </Button>
                                  </div>
                                </div> */}

                                  <div
                                    style={{
                                      paddingTop: "10px",
                                      padding: "10px",
                                    }}
                                  >
                                    <div className="time_section">
                                      <Typography
                                        fontWeight="md"
                                        textColor="primary.plainColor"
                                        mb={0.5}
                                        style={{
                                          cursor: "pointer",
                                          textTransform: "capitalize",
                                          fontSize: "12px",
                                          fontWeight: "400",
                                          color: "#121212",
                                          opacity: "50%",
                                          fontFamily: "Inter,sans-serif",
                                          paddingTop: "5px",
                                        }}
                                        className="date_posts"
                                      >
                                        Posted{" "}
                                        <span
                                          style={{
                                            color: "black",
                                            fontWeight: "700",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {Math.floor(
                                            Math.abs(
                                              new Date() -
                                                new Date(
                                                  item?.saved_job_post_id?.createdAt
                                                    .slice(0, 10)
                                                    .toLocaleString()
                                                    .replace(/-/g, "/")
                                                )
                                            ) /
                                              (1000 * 3600 * 24)
                                          )}{" "}
                                          days
                                        </span>{" "}
                                        ago
                                      </Typography>

                                      <Typography
                                        fontWeight="md"
                                        textColor="primary.plainColor"
                                        mb={0.5}
                                        style={{
                                          cursor: "pointer",
                                          textTransform: "capitalize",
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          color: "#121212",
                                          opacity: "50%",
                                          fontFamily: "Inter,sans-serif",
                                          paddingTop: "5px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "black",
                                            fontWeight: "700",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {Math.ceil(
                                            Math.abs(
                                              new Date(
                                                item?.saved_job_post_id?.application_deadline
                                              ) -
                                                new Date(
                                                  item?.saved_job_post_id?.createdAt
                                                )
                                            ) /
                                              (1000 * 60 * 60 * 24)
                                          )}{" "}
                                          days
                                        </span>{" "}
                                        left to apply
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-7">
              <div className="job_description_sec mt-3">
                <JobDescription jobDetails={Job} company={company} />
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              height: "100%",
              width: "100%",
              marginTop: "70px",
            }}
          >
            <div className="d-flex align-items-center flex-column">
              <img
                src={noJobs}
                style={{ width: "300px", height: "300px" }}
                alt="no jobs"
              />
              <p>No Jobs Found</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MySavedJobs;
