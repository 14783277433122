import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function BlogsListsCategoriesTags({ categories }) {
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setSearch(searchParams.get("q") === null ? "" : searchParams.get("q"));
  }, [searchParams.get("q")]);
  return (
    <>
      <div className="widget_search mb-40">
        <div className="search-form">
          <form
            onSubmit={e => {
              e.preventDefault();

              if (location.pathname !== "/blogs") {
                navigate(`/blogs?q=${search}`);
                return;
              }
              setSearchParams({ q: search });
            }}
          >
            <input
              type="text"
              placeholder="Search…"
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
            <button type="submit">
              <i className="fi-rr-search"></i>
            </button>
          </form>
        </div>
      </div>
      <div className="sidebar-shadow widget-categories">
        <h5 className="sidebar-title">Category</h5>
        <ul>
          {categories?.map(item => (
            <li className="d-flex justify-content-between align-items-center">
              <span>{item?.name}</span>
              <span className="count">{item?.blogsCount}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
export default BlogsListsCategoriesTags;
