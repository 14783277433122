import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import React, { useState,useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Slider from "@mui/material/Slider";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/joy/Checkbox";
import { useSearchParams } from "react-router-dom";
import Input from "@mui/joy/Input";
import { Search } from "@mui/icons-material";
// import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";


const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light" ? grey[0] : theme.palette.background.default,
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const style = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
};
const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export default function SmallScreenCompanyFilter(props) {
  
  const filterOptions = [
    { id: 1, name: "Industry" },
    { id: 2, name: "Location" },
    { id: 3, name: "Experience" },
 
  ];
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilter, setSelectedFilter] = useState(1);
  const [selectedFilterName,setSelectedFilterName] = useState('')
  const { window } = props;
  const [filterName, setFilterName] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [fnAreas, setFnAreas] = useState([]);
  const [searchFunc, setSearchFunc] = useState("");
  const [searchKeySkill, setSearchKeySkill] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [keySkills, setKeySkills] = useState([]);
  const [salary, setSalary] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [workMode, setWorkMode] = useState([]);

  const toggleDrawer = (newOpen, tag) => () => {
    setOpen(newOpen);
    setFilterName(tag);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

    /* Functional Areas display code start */
    function displayFunctanality() {
      const filterFunctalityAreas = props.functionalArea?.filter(each =>
        each.name.toLowerCase().includes(searchFunc.toLowerCase())
      );
      if (filterFunctalityAreas?.length > 0) {
        return filterFunctalityAreas?.map((each, i) => {
          return (
            <li key={each.id}>
              <label className="cb-container">
                <input
                  type="checkbox"
                  // checked={each.isChecked}
                  onChange={e => handleFuncAreasChange(e, each.id)}
                />{" "}
                <span className="text-small">{each.name}</span>
                <span className="checkmark"></span>
              </label>
              <span className="number-item">{each.countOfJobs}</span>
            </li>
          );
        });
      } else {
        return (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        );
      }
    }
    /* Functional Areas display code End */


    // useEffect(() => {
    //   props.filterJobs();
    // }, [searchParams]);



    /* keyskills display  code start */
    function displayKeyskills() {
      const filterFunctalityAreas = props.keySkills?.filter(each =>
        each.skill.toLowerCase().includes(searchKeySkill.toLowerCase())
      );
      if (filterFunctalityAreas?.length > 0) {
        return filterFunctalityAreas?.map((each, i) => {
          return (
            <li key={each.id}>
              <label className="cb-container">
                <input
                  type="checkbox"
                  // checked={each.isChecked}
                  onChange={e => handleKeySkillChange(e, each.id)}
                />{" "}
                <span className="text-small">{each.skill}</span>
                <span className="checkmark"></span>
              </label>
              <span className="number-item">{each.jobsCount}</span>
            </li>
          );
        });
      } else {
        return (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        );
      }
      
    }
    /*keyskills display  code End */


/* Main search functanality code start */
    const setParameters = data => {
      setSearchParams({
        title:
          searchParams.get("title") !== null ? searchParams.get("title") : "",
        location:
          searchParams.get("location") !== null
            ? searchParams.get("location")
            : "",
        fn_areas: searchParams.getAll("fn_areas"),
        salary: searchParams.getAll("salary"),
        key_skills: searchParams.getAll("key_skills"),
        exp: searchParams.get("exp") !== null ? searchParams.get("exp") : "",
        job_type: searchParams.getAll("job_type"),
        ...data,
      });
    };
    /* Main search functanality code End */

    /* Functional Areas handlechange functanality code start */
    const handleFuncAreasChange = (e, id) => {
      const exist = fnAreas.findIndex(x => x === id);
      if (exist !== -1) {
        const fnAreaList = fnAreas;
        fnAreaList.splice(exist, 1);
        setFnAreas(fnAreaList);
        setParameters({ fn_areas: fnAreaList });
      } else {
        setFnAreas([...fnAreas, id]);
        setParameters({ fn_areas: [...fnAreas, id] });
      }
      
    };

    /* Functional Areas handlechange functanality code End */

    /* Key skills handlechange functanality code start */
    const handleKeySkillChange = (e, id) => {
      const exist = keySkills.findIndex(x => x === id);
      if (exist !== -1) {
        const fnAreaList = keySkills;
        keySkills.splice(exist, 1);
        setKeySkills(keySkills);
        setParameters({ key_skills: keySkills });
      } else {
        setKeySkills([...keySkills, id]);
        setParameters({ key_skills: [...keySkills, id] });
      }
      
    };
    /* Key skills handlechange functanality code end */

/* Salary handle change functionality code start */
    const handleSalaryChange = (e, id, range) => {
      const exist = salary.findIndex(x => x === range);
      if (exist !== -1) {
        const salaryList = salary;
        salaryList.splice(exist, 1);
        setSalary(salaryList);
        setParameters({ salary: salaryList });
      } else {
        setSalary([...salary, range]);
        setParameters({ salary: [...salary, range] });
      }
    };

 /* Salary handle change functionality code End */

 /*Job type handleChange functionality code start */
 const handleCheckedJobType = (e, id, type) => {
  const exist = jobType.findIndex(x => x === type);
  if (exist !== -1) {
    const jobT = jobType;
    jobT.splice(exist, 1);
    setParameters({
      job_type: jobT,
    });
    setJobType(jobT);
  } else {
    setParameters({ job_type: [...jobType, type] });
    setJobType([...jobType, type]);
  }
};

/*Job type handleChange functionality code End */


const handleCheckedWork = (e, id, type) => {

  const exist = workMode.findIndex(x => x === type);
  if (exist !== -1) {
    const jobT = workMode;
    jobT.splice(exist, 1);
    setParameters({
      work_mode: jobT,
    });
    setWorkMode(jobT);
  } else {
    setParameters({ work_mode: [...workMode, type] });
    setWorkMode([...workMode, type]);
  }

};

  return (
    <Root>
      {/* <CssBaseline /> This CssBaseLine causing styling conflicts which makes vibrations to the whole webpage don't uncomment*/ }
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          background: "transparent",
        }}
      >
        <Chip
          color="neutral"
          onClick={toggleDrawer(true, 1)}
          variant="outlined"
          startDecorator={<FeatherIcon icon="filter" size="12" color="gray" />}
        >
          Filter
        </Chip>
        <Chip
          color="neutral"
          onClick={toggleDrawer(true, 3)}
          variant="outlined"
          startDecorator={
            <FeatherIcon icon="briefcase" size="12" color="gray" />
          }
        >
          Experience Level
        </Chip>
        <Chip
          color="neutral"
          onClick={toggleDrawer(true, 3)}
          variant="outlined"
          startDecorator={
            <FeatherIcon icon="briefcase" size="12" color="gray" />
          }
        >
          Industry
        </Chip>
      </Box>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        className="filter-drawer-swipable"
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            {/* {filterOptions[filterName]?.name} */}
            Filters
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <List
                sx={{
                  "& .Mui-selected": {
                    background: "transparent",
                    borderLeft: "5px solid var(--theme-color--light)",
                  },
                }}
                component="nav"
                aria-label="mailbox folders"
              >
                {filterOptions?.map(item => (
                  <ListItemButton
                    key={item.id}
                    divider
                    selected={selectedFilter === item.id}
                    onClick={() => {
                      setSelectedFilter(item.id),setSelectedFilterName(item.name);
                    }}
                    // onClick={() => {
                    //   setSelectedFilter(item.name);
                    // }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                ))}
              </List>
            </Grid>
            <Divider orientation="vertical" flexItem>
              .
            </Divider>
           

            {/* {selectedFilterName === "Industry" || selectedFilter == 1? <Grid item xs={7}>
              <div>
                <Input
                  size="sm"
                  startDecorator={<Search size="sm" color={"primary"} />}
                  type="text"
                  placeholder="Search categories here..."
                  onChange={e => {
                    setSearchFunc(e.target.value);
                  }}
                />
                <ul className="list-checkbox" style={{maxHeight:"200px",overflowY:"scroll"}}>{displayFunctanality()}</ul>
                <hr />
              </div>
            </Grid> :null}
            {selectedFilterName === "Key Skill"? <Grid item xs={7}>
            <>
                <Input
                  size="sm"
                  startDecorator={<Search size="small" color={"primary"} />}
                  // type="text"
                  placeholder="Search keyskills..."
                  onChange={e => {
                    setSearchKeySkill(e.target.value);
                  }}
                />
                <ul className="list-checkbox" style={{maxHeight:"200px",overflowY:"scroll"}}>{displayKeyskills()}</ul>
                <hr />
              </>
            </Grid> :null} */}

            {/* {selectedFilterName === "Location"? <Grid item xs={7}>
            <div className="form-group" style={{border:"2px solid grey",borderRadius:"10px",padding:"10px"}}>
                <ul className="list-checkbox">
                  {props?.salaryRange?props.salaryRange?.map(each => {
                    return (
                      <li key={each.id}>
                        <label className="cb-container">
                          <input
                            type="checkbox"
                            // checked={each.isChecked}
                            onChange={e =>
                              handleSalaryChange(e, each.id, each.salaryValue)
                            }
                          />{" "}
                          <span className="text-small">{each.salaryRange}</span>
                          <span className="checkmark"></span>
                        </label>
                        <span className="number-item">
                          {each.jobsCountOnSalary}
                        </span>
                      </li>
                    );
                  }):(
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  )}
                </ul>
              </div>
            </Grid> :null} */}


{/* 
            {selectedFilterName === "Experience"? <Grid item xs={7}>
            <div className="form-group" style={{paddingTop:"30px"}}>
                <Slider
                  defaultValue={1}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={30}
                  onChange={e => {
                    setExperienceLevel(e.target.value);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <span>0Yrs</span>
                  <span>30Yrs</span>
                </div>
                <button
                  className="theme_new_btn "
                  onClick={() => {
                    setParameters({ exp: experienceLevel });
                  }}
                >
                  Apply
                </button>
                <hr />
              </div>
            </Grid> :null} */}

          </Grid>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}
