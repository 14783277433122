import { Route, Routes } from "react-router-dom";
import LayoutUI from "../layouts/LayoutUI";

import { publicRoutes, routes } from "./routes";
import AuthCheckRoutes from "./authCheck";
import SocketConnection from "../hooks/socket";

export default function AppRoutes() {

  return (
    <>
      <Routes>
        {routes.map(({ path, Component }, idx) => (
          <Route
            key={idx}
            path={path}
            element={<AuthCheckRoutes>{Component}</AuthCheckRoutes>}
          />
        ))}
        {publicRoutes.map(({ path, Component }, idx) => (
          <Route
            key={idx}
            path={path}
            authProtected={false}
            element={<LayoutUI>{Component}</LayoutUI>}
          />
        ))}
        <Route path="*" element={<div>Not Found</div>}></Route>
      </Routes>
    </>
  );
}
