import Sidebar from "../Accounts/Sidebar/Sidebar";
import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ChangePassword from "../../Authentication/ChangePassword";
import ChangeEmail from "../../Authentication/ChangeEmail";
import ChangePhoneNumber from "../../Authentication/ChangePhoneNumber";
import BankDetails from "./BankDetails";
import NotificationEnable from "./NotificationsEnable";
import NotificationTable from "./NotificationsTable";
import styled from "@mui/material/styles/styled";
import { Container } from "reactstrap";
import {globalTheme} from '../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";

export default function Settings() {
  const [value, setValue] = React.useState("1");

  const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: 16,
    fontFamily: "Inter,sans-serif",
    textTransform: "none",
    "&:first-letter": {
      textTransform: "capitalize",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={globalTheme}>
    <div className=" profile_main_cont">
      <Container
        fluid
        className="chat_main_cont"
        style={{ paddingRight: "0px", paddingLeft: "0px", marginTop: "30px",marginLeft:"51px"}}
      >
        <div className="d-flex align-items-start gap-2 justify-content-center">
          {/* <div className="col-sm-12 col-md-2 col-lg-2 profile_left_sidebar">
            <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky" id="profile_left_sidebar_sticky_settings">
              <Sidebar />
            </div>
          </div> */}
       
          <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
            <Sidebar />
          </div>
         

          <div className="col-lg-10 col-md-10 col-sm-12">
            <Box
              sx={{
                width: "88%",
                typography: "body1",
                border: "2px solid whitesmoke",
                borderRadius: "10px",
                marginTop: "10px",
                "@media (max-width: 577px)": {
                  marginLeft: "10%",
                },
              }}
            >
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    variant={"scrollable"}
                    aria-label="lab API tabs example"
                    sx={{
                      // overflowX: "scroll !important",
                      ".MuiTabs-indicator": {
                        // backgroundColor: "#081753",
                        // color: "#081753",
                        backgroundColor: "#066a4c",
                        color: "#066a4c",
                      },
                      ".MuiTab-root.Mui-selected ": {
                        // color: "#081753",
                        color:"#066a4c",
                        borderRadius: "8px",
                      },
                      "& .css-heg063-MuiTabs-flexContainer": {
                        // overflow: "auto",
                      },
                    }}
                  >
                    <StyledTab label=" Password" value="1" />
                    <StyledTab label=" Email" value="2" />
                    <StyledTab label=" Phone Number" value="3" />
                 
                    <StyledTab label=" Notifications" value="4" />
                    <StyledTab label="Bank Details" value="6" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <ChangePassword />
                </TabPanel>
                <TabPanel value="2">
                  <ChangeEmail />
                </TabPanel>
                <TabPanel value="3">
                  <ChangePhoneNumber />
                </TabPanel>
              
                <TabPanel value="4">
                  <NotificationTable />
                </TabPanel>
                <TabPanel value="6">
                  <BankDetails />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </Container>
    </div>
    </ThemeProvider>
  );
}
