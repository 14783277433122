import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { SmartToaster, toast } from "react-smart-toaster";
import { handleResendOTP } from "../../helpers/dataFetcher/otpVerifiy/verify";

const CountdownTimer = ({ email, totalSec }) => {
  const countRef = useRef("");
  const [restart, setRestart] = useState(true);
  const [timee, setTime] = useState(Date.now() + totalSec);

  const resendVerificationCode = async (e, apiii) => {
    e.preventDefault();

    const { status } = await handleResendOTP(email);
    if (status === 200) {
      toast.success("OTP sent successfully");
    }
  };

  const renderer = ({ hours, minutes, seconds, completed, api }) => {
    if (completed) {
      return (
        <div className="d-flex justify-content-center pt-4">
          <a
            className="cursor-pointer"
            onClick={e => resendVerificationCode(e, api)}
          >
            Resend
          </a>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center">
          <div className="p-3">
            <span>{minutes > 9 ? minutes : `0 ${minutes}`}</span>
            <span>{" : "}</span>
            <span>{seconds > 9 ? seconds : `0 ${seconds}`}</span>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (restart) {
      countRef?.current?.start();
    }
  }, [restart, timee]);

  return (
    <Countdown
      date={timee}
      renderer={renderer}
      autoStart={false}
      ref={countRef}
    ></Countdown>
  );
};

export default CountdownTimer;
