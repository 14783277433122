import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BorderCard,
  BlackTypography,
  GreyTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
  MiddleDivider,
  PrimaryAvatar,
  ColorTypography
} from "../../../assets/MUI/materialUiStyles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useForm } from "react-hook-form";
import { FormControl, Grid, Stack, Card, CardContent,IconButton } from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  padding:"24px",
  width: "60%",
  height: 400,
  // overflow: "auto",
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function WholeEditWorkExperience({
  type,
  open,
  data,
  editToggleModel,
  toggleModel,
  setUpdateType,
  setUpdateIndex,
  removeExperience
}) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
    // React.useEffect(() => {
    //   if (type?.includes("update")) {
    //     setValue("title", currentData.title);
    //     setValue("employer", currentData.employer);
    //     setValue("start_date", currentData.start_date);
    //     setValue("end_date", currentData.end_date);
    //   }
    // }, [type]);

  const handleRemoveExperience = (id)=>{
    removeExperience(id)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          editToggleModel();
          reset();
        }}
        aria-labelledby="edit-profile-modal-title"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
          <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
            Work Experiences
          </BlackTypography>
          <IconButton  onClick={() => {
                editToggleModel();
              }} sx={{top:"-4px"}}>
            <FeatherIcon
              icon="x"
             
            />
            </IconButton>
            </Stack>
          <BorderCard sx={{height:"80%",overflowY:"scroll"}}>
            <CardContent
              sx={{ height: "calc(100% -40px)", overflowY: "scroll" }}
            >
              <Stack spacing={3}>
                {data?.map(
                // {[1,2,3]?.map(
                  (
                    { title, employer, start_date, end_date, skill,desc,description,image },
                    workExpIndex
                  ) => {
                    const isLastItem = workExpIndex === data.length - 1;
                    return (
                      <>
                        <Stack
                          spacing={1}
                          direction="row"
                          sx={{ "&: hover": { background: "transparent" } }}
                        >
                          {/* <Stack
                            style={{ whiteSpace: "normal", width: "90%" }}
                            spacing={0.8}
                          >
                            <BlackTypography variant="h4" component="h4">
                              {title}
                            </BlackTypography>
                            <BlackTypography variant="h5" component="h5">
                              {employer} - Fulltime 
                            </BlackTypography>
                            <GreyTypography variant="h6" component="h6">
                              {start_date} - {end_date} 
                            </GreyTypography>
                            <BlackTypography>Skills:</BlackTypography>
                            <GreyTypography
                              variant="h6"
                              component="h6"
                              style={{ width: "100%", wordWrap: "break-word" }}
                            >
                              {skill?.map((each, index, array) => (
                                <React.Fragment key={each}>
                                  {each?.skillName?.inputValue
                                    ? each.skillName.inputValue
                                    : each.skillName.name}
                                  {" -"}
                                  {each?.experienceyears}
                                  {"."}
                                  {each?.experienceyears}
                                  {"Yrs"}
                                  {index !== array.length - 1 && ", "}
                                </React.Fragment>
                              ))}
                            </GreyTypography>

                            <BlackTypography>Description:</BlackTypography>    
                      <GreyTypography contentEditable='true' dangerouslySetInnerHTML={{ __html: desc }}></GreyTypography>

                            {!isLastItem && <MiddleDivider />}
                          </Stack> */}



                          <Stack>
                            <PrimaryAvatar
                              variant="square"
                              sx={{ border: "none" }}
                              //   src={`${syoft}`}
                              src={`${image}`}
                              alt={`${employer?.charAt(0, 2)}`}
                            />
                          </Stack>
                          <Stack direction="column">
                         <ColorTypography variant="h3" component="h3" sx={{fontWeight:600,color:"#000000"}}>
                              {title}
                            </ColorTypography>
                            <ColorTypography variant="h4" component="h4">
                              {employer} - Fulltime
                            </ColorTypography>
                            <GreyTypography variant="h6" component="h6">
                              {start_date} - {end_date}
                            </GreyTypography>
                            <BlackTypography>Skills:</BlackTypography>

                            <GreyTypography
                              variant="h6"
                              component="h6"
                              style={{ width: "100%", wordWrap: "break-word" }}
                            >
                              {skill?.map((each, index, array) => (
                                <React.Fragment key={each}>
                                  {each?.skillName?.inputValue
                                    ? each.skillName.inputValue
                                    : each.skillName.name}
                                  {" -"}
                                  {each?.experienceyears}
                                  {"."}
                                  {each?.experiencemonths}
                                  {"Yrs"}
                                  {index !== array.length - 1 && ", "}
                                </React.Fragment>
                              ))}
                            </GreyTypography>

                            <BlackTypography>Description:</BlackTypography>
                            <GreyTypography
                          variant="h6"
                          component="h6"
                          style={{ width: "100%", wordWrap: "break-word" }}
                        >
                          {desc}
                        </GreyTypography>
                       
                          </Stack>   


                        
                          <Stack
                            justifyContent="flex-start"
                            marginLeft="auto !important"
                            alignItems="end"
                            // direction="row"
                          >
                            <Stack direction="row" spacing={1}>
                            <FeatherIcon
                              icon="edit"
                              size="16"
                              onClick={() => {
                                toggleModel();
                                setUpdateType(`update${workExpIndex}`);
                                setUpdateIndex(workExpIndex);
                              }}
                            />
                            <FeatherIcon
                              icon="trash"
                              size="16"
                            onClick={()=>handleRemoveExperience(workExpIndex)}
                            />
                            </Stack>
                          </Stack>
                        </Stack>
                      </>
                    );
                  }
                )}
              </Stack>
            </CardContent>
          </BorderCard>
          {/* <SecondaryFilledButton
            onClick={() => {
              editToggleModel();
            }}
            sx={{ width: "auto", marginLeft: "88%", marginTop: "5px" }}
          >
            Close
          </SecondaryFilledButton> */}
        </Box>
      </Modal>
    </div>
  );
}