import React from "react";
import Breadcrumb from "../Blogs/Breadcrumb";
import UserShortInfo from "../UserShortInfo/UserShortInfo";

function Notifications() {
  const data = [
    {
      id: 1,
      img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660212017/marketing-director_w9zneb.svg",
      name: "Application Sent",
      time: "23 minutes",
      desc: "Lorem ipsum doller samet ulharu jingat zing zing ulvaerghuta",
    },
    {
      id: 2,
      img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660211965/business-development_atj0ff.svg",
      name: "Application Viewed",
      time: "23 minutes",
      desc: "Lorem ipsum doller samet ulharu jingat zing zing ulvaerghuta",
    },
    {
      id: 3,
      img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660211938/testing_tpzeev.svg",
      name: "Application Downloaded",
      time: "23 minutes",
      desc: "Lorem ipsum doller samet ulharu jingat zing zing ulvaerghuta",
    },
    {
      id: 4,
      img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660210867/proof-reading_fqghvi.svg",
      name: "Interview Scheduled",
      time: "23 minutes",
      desc: "Lorem ipsum doller samet ulharu jingat zing zing ulvaerghuta",
    },
  ];
  return (
    <>
      <Breadcrumb breadcrumb="Notification" />
      <div className="container" style={{ marginTop: "10px" }}>
        <div className="row">
          <div className="col-sm-12 col-md-9 col-lg-9">
            <div className="recent-activities">
              <ul className="sub-menu pb-0">
                {data.map((item, index) => (
                  <li
                    key={index}
                    className="p-3 border-bottom notification-hover cursor-pointer list-unstyled"
                  >
                    <div className="media mb-3 d-flex align-items-center">
                      <img
                        className="mr-3"
                        src={item.img}
                        style={{ width: "45px", height: "45px" }}
                        alt="icon"
                      />
                      <div className="media-body ms-2">
                        <h6 className="mt-0">{item.name}</h6>
                        <span
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          {item.desc}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between ms-5">
                      <span className="notification-time ms-2">
                        {item.time}
                      </span>
                      <span className="notification-delete">Delete</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3">
            <UserShortInfo />
          </div>
        </div>
      </div>
    </>
  );
}
export default Notifications;
