import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { debounce } from "@mui/material/utils";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
import parse from "autosuggest-highlight/parse";
import {
  BlackTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
  PrimaryCheckbox,
  PrimaryAutoComplete,
  autocompleteProps,
  MultipleSelectInputs,
  PrimaryOutlinedButton,
  DescriptionInputs,
} from "../../../assets/MUI/materialUiStyles";
import { LocationCityOutlined, LocationOnOutlined } from "@mui/icons-material";
import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
  Controller,
} from "react-hook-form";

import {
  TextField,
  Autocomplete,
  InputLabel,
  Typography,
  NativeSelect,
  TextareaAutosize,
} from "@mui/material";

import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";

import GoogleExperienceInputLocation from "../../../components/ExperienceLocation";
import GoogleMaterialInputLocation from "../../../components/InputLocation";
import {
  FormControl,
  Grid,
  Stack,
  Select,
  MenuItem,
  IconButton,
  createFilterOptions,
  Checkbox,
} from "@mui/material";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import UploadModel from "./ImageUpload";

import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
// import {
//   createKeySkills,
//   getAllKeySkills,
//   getAllKeySkillsWithStatus,
// } from "helpers/services/jobAttributes/keyskills";
import { getAllKeySkillsWithStatus } from "../../../helpers/dataFetcher/profile/profile";
import htmlToDraft from "html-to-draftjs";
import { v4 as uuid } from "uuid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  height: 500,
  overflow: "auto",
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-hot-toast";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function AddEditWorkExperience({
  type,
  open,
  currentData,
  toggleModel,
  updateOrEditWorExperience,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      skill: [{ skillName: "", experienceyears: "" }],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "skill",
    });

  const [model, setModel] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [exactEdit, setExactEdit] = useState(null);

  function JobDescription(data) {
    const contentBlock = htmlToDraft(data);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  }

  const handleEditorData = e => {
    setValue("description", e);
    setValue(
      "desc",
      draftToHtml(convertToRaw(watch("description").getCurrentContent()))
    );
  };

  const [skillLoader, setSkillLoader] = useState(true);
  const [keySkills, setKeySkills] = useState([]);
  const [companyImageUrl, setCompanyImageUrl] = useState(false);
  const [editImage, setEditImage] = useState("");

  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    getKeySKills();
  }, []);

  async function getKeySKills() {
    const { data, status } = await getAllKeySkillsWithStatus();
    setKeySkills(
      data?.map(item => ({
        name: item?.name,
        value: item?.id,
        isActive: item?.is_active,
        id: item?.id,
      }))
    );
    if (status === 200) {
      setSkillLoader(false);
    }
  }

  const filter = createFilterOptions();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
      },
    },
  };

  const [checked, setChecked] = React.useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const experience = [
    { name: "0", value: "0" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10+", value: "10+" },
  ];

  // console.log(currentData,type);

  React.useEffect(() => {}, []);


  React.useEffect(() => {
    if (type?.includes("update")) {
      setValue("title", currentData.title);
      setValue("employer", currentData.employer);
      setValue("start_date", currentData.start_date);
      setValue("end_date", currentData.end_date);
      setValue("skill", currentData?.skill);
      setValue("location", currentData.location);
      // setValue("image", currentData.image);
      setValue("image", currentData?.image?companyImageUrl.image:"N/A");
      setCompanyImageUrl(!companyImageUrl);
      // setValue("desc", JobDescription(currentData?.desc));
      setValue("desc", currentData?.desc);
      setExactEdit(currentData?.desc);
      setChecked(currentData.is_currently_working || false);
      setValue("notice_period", currentData.notice_period);
    } else if (type === "create") {
      reset();
    }
  }, [type]);

  function findSelectType(type) {
    switch (type) {
      case "noticeperiod":
        return [
          { name: "Immediately", value: "Immediately" },
          { name: "10 Days", value: "10 Days" },
          { name: "20 Days", value: "20 Days" },
          { name: "30 Days", value: "30 Days" },
          { name: "40 Days", value: "40 Days" },
          { name: "50 Days", value: "50 Days" },
          { name: "60 Days", value: "60 Days" },
          { name: "70 Days", value: "70 Days" },
          { name: "80 Days", value: "80 Days" },
          { name: "90 Days", value: "90 Days" },
          { name: "100 Days", value: "100 Days" },
          { name: "110 Days", value: "110 Days" },
          { name: "120 Days", value: "120 Days" },
          { name: "120+ Days", value: "120+ Days" },
        ];

      case "experienceSection":
        return [
          { name: "0", value: "0" },
          { name: "1", value: "1" },
          { name: "2", value: "2" },
          { name: "3", value: "3" },
          { name: "4", value: "4" },
          { name: "5", value: "5" },
          { name: "6", value: "6" },
          { name: "7", value: "7" },
          { name: "8", value: "8" },
          { name: "9", value: "9" },
          { name: "10+", value: "10+" },
        ];

      case "experiencemonths":
        return [
          { name: "1", value: "1" },
          { name: "2", value: "2" },
          { name: "3", value: "3" },
          { name: "4", value: "4" },
          { name: "5", value: "5" },
          { name: "6", value: "6" },
          { name: "7", value: "7" },
          { name: "8", value: "8" },
          { name: "9", value: "9" },
          { name: "10", value: "10" },
          { name: "11", value: "11" },
        ];
      default:
        [{ name: "", value: "" }];
    }
  }

  function imageModel(edit) {
    setEditImage(edit);
    setModel(!model);
  }

  // function handleUpload() {
  //   setModelOpen(!modelOpen);
  // }

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    toast.success("uploaded...");
    setValue("image", url);
    setCompanyImageUrl(!companyImageUrl);
    setTimeout(() => {
      if (!editImage) {
        imageModel();
      }
      // imageModel();
    }, 1000);
  };

  /*Location testing */
  function disableFutDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return date => {
      return Date.parse(date) > startSeconds;
    };
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);

  /*Location testing */

  return (
    <>
      {model && (
        <UploadModel
          open={model}
          toggle={imageModel}
          handleImage={handleImageUpload}
        />
      )}
      <div>
        <Modal
          open={open}
          onClose={() => {
            toggleModel();
            if (!type?.includes("update")) {
              reset();
            }
          }}
          aria-labelledby="edit-profile-modal-title"
          aria-describedby="edit-profile-modal-description"
        >
          <Box sx={style}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <BlackTypography
                variant="h2"
                sx={{ marginBottom: "10px", position: "sticky" }}
              >
                {type === "create" ? "Create" : "Edit"} Work Experience
              </BlackTypography>
              <IconButton
                onClick={() => {
                  toggleModel();
                }}
                sx={{ top: "-4px" }}
              >
                <FeatherIcon icon="x" />
              </IconButton>
            </Stack>

            {/* <form onSubmit={handleSubmit(updateOrEditWorExperience(data,checked))}> */}
            <form
              onSubmit={handleSubmit(data => {
                updateOrEditWorExperience(data, checked);
                if (!type?.includes("update")) {
                  reset();
                }
              })}
            >
              <Grid
                container
                spacing={2}
                sx={{ height: "90%", overflow: "auto" }}
              >
                <Grid item md={12} lg={12} sm={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-title-outlined"
                      // error={errors.titile}
                    >
                      Job Title
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      fullWidth
                      placeholder="Enter your title"
                      {...register("title", { required: true })}
                      id="wor-exp-title-outlined"
                      helperText={errors?.title ? "email is required" : ""}
                      autoFocus
                      label="Job Title"
                      type={"text"}
                      name="title"
                      error={errors.title}
                      variant="outlined"
                      rows={4}
                    />
                    {/* <PrimaryNormalFormHelperText error={errors.title}>
                    {errors.title ? "title is required Field" : ""}
                  </PrimaryNormalFormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item md={12} lg={12} sm={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-employer-outlined"
                    >
                      company Name
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      fullWidth
                      placeholder="Enter your employer"
                      {...register("employer", { required: true })}
                      id="wor-exp-employer-outlined"
                      // helperText={errors?.employer ? "email is required" : ""}
                      autoFocus
                      label="Job employer"
                      type={"text"}
                      name="employer"
                      // error={errors.employer}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} lg={12} sm={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    {/* <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-employer-outlined"
                  >
                    company Image
                  </PrimaryNormalInputLabel> */}

                    {!companyImageUrl ? (
                      <PrimaryOutlinedButton
                        sx={{ maxWidth: "220px" }}
                        onClick={() => {
                          imageModel();
                        }}
                        startIcon={<FeatherIcon icon="edit" size="14" />}
                      >
                        Company image
                      </PrimaryOutlinedButton>
                    ) : (
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor="work-exp-title-outlined"
                        >
                          Company image
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="Enter your employer"
                          {...register("image", { required: true })}
                          id="wor-exp-employer-outlined"
                          // helperText={errors?.employer ? "email is required" : ""}
                          autoFocus
                          label="Company Image"
                          type={"text"}
                          name="employer"
                          // error={errors.employer}
                          variant="outlined"
                          readOnly={true}
                        />
                        <PrimaryOutlinedButton
                          sx={{ maxWidth: "220px", marginTop: "8px" }}
                          onClick={() => {
                            imageModel("edit");
                          }}
                          startIcon={<FeatherIcon icon="edit" size="14" />}
                        >
                          Upload image
                        </PrimaryOutlinedButton>
                      </FormControl>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={12} lg={12} sm={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-start_date-outlined"
                      // error={errors.location}
                      sx={{ marginLeft: "-15px" }}
                    >
                      Location
                    </PrimaryNormalInputLabel>
                    <FormProvider
                      register={register}
                      watch={watch}
                      errors={errors}
                      formSetValue={setValue}
                    >
                      <Stack
                        direction="row"
                        spacing={"1"}
                        sx={{ marginTop: "16px" }}
                      >
                        <Autocomplete
                          sx={{ width: "100%" }}
                          size="small"
                          id="google-map-demo"
                          value={getValues("location") || ""}
                          // defaultValue={userDetails?.location|| ""}
                          getOptionLabel={option =>
                            typeof option === "string"
                              ? option
                              : option.description
                          }
                          filterOptions={x => x}
                          options={options}
                          autoComplete
                          includeInputInList
                          filterSelectedOptions
                          noOptionsText="No locations"
                          {...register("location", { required: false })}
                          // onChange={(event, newValue) => {
                          //   setValue("location", newValue);
                          //   setOptions(newValue ? [newValue, ...options] : options);
                          // }}

                          onChange={(event, newValue) => {
                            // Set the location value to the description property
                            setValue("location", newValue?.description || ""); // Use the description or an empty string if newValue is null
                            setOptions(
                              newValue ? [newValue, ...options] : options
                            );
                          }}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={errors?.address ? true : false}
                              // label="Add a location"
                              fullWidth
                            />
                          )}
                          renderOption={(props, option) => {
                            const matches =
                              option.structured_formatting
                                ?.main_text_matched_substrings || [];

                            const mainText =
                              option.structured_formatting?.main_text || "";
                            const secondaryText =
                              option.structured_formatting?.secondary_text ||
                              "";

                            const parts = parse(
                              mainText,
                              matches.map(match => [
                                match.offset,
                                match.offset + match.length,
                              ])
                            );

                            return (
                              <li {...props}>
                                <Grid container alignItems="center">
                                  <Grid
                                    item
                                    sx={{ display: "flex", width: 44 }}
                                  >
                                    <LocationCityOutlined
                                      sx={{ color: "text.secondary" }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    sx={{
                                      width: "calc(100% - 44px)",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {parts.map((part, index) => (
                                      <Box
                                        key={index}
                                        component="span"
                                        sx={{
                                          fontWeight: part.highlight
                                            ? "bold"
                                            : "regular",
                                        }}
                                      >
                                        {part.text}
                                      </Box>
                                    ))}
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {secondaryText}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </li>
                            );
                          }}
                        />
                        {/* <GoogleExperienceInputLocation updatingContent="location" /> */}
                      </Stack>
                    </FormProvider>
                  </FormControl>
                </Grid>

                <Grid item md={12} lg={12} sm={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-start_date-outlined"
                      // error={errors.titile}
                    >
                      Start Date
                    </PrimaryNormalInputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        disableFuture={true}
                        label="start Date"
                        value={watch("start_date")}
                        onChange={newValue => {
                          setValue("start_date", newValue);
                        }}
                        renderInput={params => (
                          <PrimaryNormalInputs
                            {...params}
                            //   error={errors?.start_date}
                            {...register("start_date", { required: true })}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={12} lg={12} sm={12}>
                  <Stack
                    direction="row"
                    justifyContent=""
                    alignItems="center"
                    spacing={1}
                  >
                    <PrimaryCheckbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <BlackTypography>
                      I am currently working in this role
                    </BlackTypography>
                  </Stack>
                </Grid>
                {!checked ? (
                  <Grid item md={12} lg={12} sm={12}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="work-exp-end_date-outlined"
                        //   error={errors.titile}
                      >
                        End Date
                      </PrimaryNormalInputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          disableFuture={true}
                          label="end Date"
                          value={watch("end_date")}
                          onChange={newValue => {
                            setValue("end_date", newValue);
                          }}
                          renderInput={params => (
                            <PrimaryNormalInputs
                              {...params}
                              // error={errors?.end_date}
                              {...register("end_date", { required: true })}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item md={12} lg={12} sm={12}>
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-employer-outlined"
                      error={errors.titile}
                    >
                      Notice Period
                    </PrimaryNormalInputLabel>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <Stack direction="row" spacing={"1"}>
                        <Select
                          // labelId={notice_period}
                          // id={update_type}
                          {...register("notice_period", { required: false })}
                          input={
                            <PrimaryNormalInputs
                              sx={{ width: "100%" }}
                              // label="signup-user-type"
                              // error={errors[`${noticeperiod}`]}
                              {...register("notice_period", {
                                required: false,
                              })}
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {findSelectType("noticeperiod")?.map(item => {
                            return (
                              <MenuItem
                                key={item?.value}
                                value={item?.value} // style={getStyles(name, personName, theme)}
                              >
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Stack>
                    </FormControl>
                  </Grid>
                )}
                <Grid item md={12} lg={12} sm={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="folloups-about-outlined"
                      // error={errors.about}
                    >
                      Description
                    </PrimaryNormalInputLabel>

                    {/* <div
                    style={{
                      ...(errors?.description
                        ? {
                            border: "0.5px solid red",
                            borderRadius: "8px",
                            padding: "10px",
                            minHeight: "250px",
                          }
                        : {
                            border: "0.5px solid gray",
                            borderRadius: "8px",
                            padding: "10px",
                            minHeight: "250px",
                          }),
                      marginTop: "23px",
                    }}
                  >
                    <Editor
                      editorState={watch("description")}
                      onEditorStateChange={handleEditorData}
                    />
                  </div> */}
                    {/* <DescriptionInputs
                      fullWidth
                      // multiline
                      // placeholder="Description"
                      {...register("description", { required: true })}
                      id="login-about-outlined"
                      autoFocus
                      label="Description"
                      type={"text"}
                      error={errors.description}
                      variant="outlined"
                      rows={4}
                    /> */}

                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      error={Boolean(errors.desc)}
                      {...register("desc", { required: true })}
                      style={{
                        minHeight: "100px",
                        marginTop: "22px",
                        maxHeight: "110px",
                        borderRadius: "8px",
                        borderColor: errors.desc ? "red" : "#ced4da",
                        overflowY: "scroll !important",
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="question-outlined"
                      error={errors.question}
                    >
                      Skill & Experience
                    </PrimaryNormalInputLabel>
                    <Stack spacing={2}>
                      {fields.map((field, index) => {
                        return (
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems={"center"}
                            key={field.id}
                          >
                            <Controller
                              render={props => {
                                return (
                                  <PrimaryAutoComplete
                                    sx={{ width: "100%" }}
                                    {...props}
                                    disableCloseOnSelect
                                    componentsProps={autocompleteProps}
                                    multiple={false}
                                    loading={skillLoader}
                                    loaderText="Fetching Skills"
                                    options={keySkills}
                                    renderTags={(tagValue, getTagProps) =>
                                      tagValue.map((option, index) => (
                                        <SecondaryFilledChip
                                          deleteIcon={
                                            <FeatherIcon icon={"x"} size="14" />
                                          }
                                          label={option.name}
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) =>
                                      option?.isActive ? (
                                        <li {...props} key={props.id}>
                                          <Checkbox
                                            icon={
                                              <FeatherIcon
                                                icon="square"
                                                size="14"
                                              />
                                            }
                                            checkedIcon={
                                              <FeatherIcon
                                                icon="check-square"
                                                size="14"
                                              />
                                            }
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.name}
                                        </li>
                                      ) : null
                                    }
                                    getOptionLabel={option =>
                                      option?.name || ""
                                    }
                                    renderInput={params => (
                                      <FormControl
                                        variant="standard"
                                        sx={{ width: "100%" }}
                                      >
                                        <MultipleSelectInputs
                                          sx={{
                                            ...(errors.skillName && {
                                              borderColor: "red",
                                            }),
                                          }}
                                          error={
                                            errors[`skill.${index}.skillName`]
                                          }
                                          variant="standard"
                                          ref={params.InputProps.ref}
                                          {...params}
                                        />
                                      </FormControl>
                                    )}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);
                                      const { inputValue } = params;
                                      const isExisting = options.some(
                                        option => inputValue === option.name
                                      );
                                      if (inputValue !== "" && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          isActive: true,
                                          id: uuid(),
                                          name: `${inputValue}`,
                                        });
                                      }
                                      return filtered;
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    value={getValues(`skill.${index}.skillName`)}
                                    // value={getValues("skillName")}
                                    onChange={async (_, data) => {
                                      const lastIndex = data?.length - 1;
                                      if (data && data[lastIndex]?.inputValue) {
                                        const { data: skill } =
                                          await createKeySkills({
                                            name: data[lastIndex]?.inputValue,
                                            is_active: true,
                                          });
                                        await getKeySKills();
                                        props.field.onChange([
                                          ...getValues("skillName"),
                                          {
                                            name: skill.key_skill_res.name,
                                            value: skill.key_skill_res.id,
                                          },
                                        ]);
                                      } else {
                                        props.field.onChange(data);
                                      }
                                    }}
                                  />
                                );
                              }}
                              rules={{ required: true }}
                              name={`skill.${index}.skillName`}
                              control={control}
                            />

                            <FormControl
                              sx={{ width: "60%" }}
                              variant="standard"
                            >
                              <PrimaryNormalInputLabel
                                shrink
                                htmlFor="work-exp-start_date-outlined"
                                // error={errors.titile}
                              >
                                Years
                              </PrimaryNormalInputLabel>
                              <Controller
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    labelId="experienceyears"
                                    id="experienceyears"
                                    input={
                                      <PrimaryNormalInputs
                                        label="signup-user-type"
                                        error={
                                          errors[
                                            `skill.${index}.experienceyears`
                                          ]
                                        }
                                        {...register(
                                          `skill.${index}.experienceyears`,
                                          {
                                            required: true,
                                          }
                                        )}
                                      />
                                    }
                                    MenuProps={MenuProps}
                                  >
                                    {findSelectType("experienceSection")?.map(
                                      item => (
                                        <MenuItem
                                          key={item?.value}
                                          value={item?.value}
                                        >
                                          {item?.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                )}
                                name={`skill.${index}.experienceyears`}
                                control={control}
                              />
                            </FormControl>

                            <FormControl
                              sx={{ width: "60%" }}
                              variant="standard"
                            >
                              <PrimaryNormalInputLabel
                                shrink
                                htmlFor="work-exp-start_date-outlined"
                                // error={errors.titile}
                              >
                                Months
                              </PrimaryNormalInputLabel>
                              <Controller
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    labelId="experiencemonths"
                                    id="experiencemonths"
                                    input={
                                      <PrimaryNormalInputs
                                        label="signup-user-type"
                                        error={
                                          errors[
                                            `skill.${index}.experiencemonths`
                                          ]
                                        }
                                        {...register(
                                          `skill.${index}.experiencemonths`,
                                          {
                                            required: true,
                                          }
                                        )}
                                      />
                                    }
                                    MenuProps={MenuProps}
                                  >
                                    {findSelectType("experienceSection")?.map(
                                      item => (
                                        <MenuItem
                                          key={item?.value}
                                          value={item?.value}
                                        >
                                          {item?.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                )}
                                name={`skill.${index}.experiencemonths`}
                                control={control}
                              />
                            </FormControl>

                            <IconButton
                              onClick={() =>
                                append({
                                  skillName: "",
                                  experienceyears: "",
                                  experiencemonths,
                                })
                              }
                            >
                              <FeatherIcon icon="plus" size="14" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                if (index !== 0) remove(index);
                              }}
                            >
                              <FeatherIcon icon="minus" size="14" />
                            </IconButton>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </FormControl>
                </Grid>

                <Grid item xs={7}></Grid>
                <Grid item xs={5}>
                  <Stack spacing={1} direction="row">
                    <SecondaryFilledButton
                      onClick={() => {
                        toggleModel();
                      }}
                    >
                      cancel
                    </SecondaryFilledButton>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                  </Stack>{" "}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
}
