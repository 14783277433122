import React from "react";
import { Link, useNavigate } from "react-router-dom";
function Pricing() {
    const navigate = useNavigate()
    return (
        <>
            <main className="main">
                <section className="section-box mt-90 mb-50">
                    <div className="container">
                        <div className="w-50 w-md-100 mx-auto text-center">
                            <h3 className="mb-30 wow animate__animated animate__fadeInUp">Start saving time today and choose your best plan</h3>
                        </div>
                        <div className="mw-650 text-center wow animate__animated animate__fadeInUp">
                            <p className="mb-35 text-md-lh24 color-black-5">Best for freelance developers who need to save their time</p>
                        </div>
                        <div className="text-center mt-20">
                            <span className="text-lg text-billed">Billed Yearly</span>
                            <label className="switch ml-20 mr-20">
                                <input type="checkbox" id="cb_billed_type" name="billed_type" onchange="checkBilled()" />
                                <span className="slider round"></span>
                            </label>
                            <span className="text-lg text-billed">Billed Monthly</span>
                        </div>
                        <div className="block-pricing mt-125 mt-md-50">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                    <div className="box-pricing-item">
                                        <div className="box-info-price">
                                            <span className="text-price for-month display-month">$20</span>
                                            <span className="text-price for-year">$240</span>
                                            <span className="text-month">/month</span>
                                        </div>
                                        <div>
                                            <h4 className="mb-15">Intro</h4>
                                            <p className="text-desc-package mb-30">
                                                For most businesses that want to otpimize web queries
                                            </p>
                                        </div>
                                        <ul className="list-package-feature">
                                            <li>All limited links</li>
                                            <li>Own analytics platform</li>
                                            <li>Chat support</li>
                                            <li>Optimize hashtags</li>
                                            <li>Unlimited users</li>
                                        </ul>
                                        <div>
                                            <span className="btn btn-border">Choose plan</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    <div className="box-pricing-item">
                                        <div className="box-info-price">
                                            <span className="text-price for-month display-month">$50</span>
                                            <span className="text-price for-year">$600</span>
                                            <span className="text-month">/month</span>
                                        </div>
                                        <div>
                                            <h4 className="mb-15">Base</h4>
                                            <p className="text-desc-package mb-30">
                                                For most businesses that want to otpimize web queries
                                            </p>
                                        </div>
                                        <ul className="list-package-feature">
                                            <li>All limited links</li>
                                            <li>Own analytics platform</li>
                                            <li>Chat support</li>
                                            <li>Optimize hashtags</li>
                                            <li>Unlimited users</li>
                                        </ul>
                                        <div>
                                            <span className="btn btn-border">Choose plan</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                    <div className="box-pricing-item most-popular">
                                        <div className="text-end mb-10">
                                            <span className="btn btn-white-sm">Most popular</span>
                                        </div>
                                        <div className="box-info-price">
                                            <span className="text-price for-month display-month">$100</span>
                                            <span className="text-price for-year">$1200</span>
                                            <span className="text-month">/month</span>
                                        </div>
                                        <div>
                                            <h4 className="mb-15">Pro</h4>
                                            <p className="text-desc-package mb-30">
                                                For most businesses that want to otpimize web queries
                                            </p>
                                        </div>
                                        <ul className="list-package-feature">
                                            <li>All limited links</li>
                                            <li>Own analytics platform</li>
                                            <li>Chat support</li>
                                            <li>Optimize hashtags</li>
                                            <li>Unlimited users</li>
                                        </ul>
                                        <div>
                                            <span className="btn btn-border">Choose plan</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                    <div className="box-pricing-item">
                                        <div className="box-info-price">
                                            <span className="text-price for-month display-month">$200</span>
                                            <span className="text-price for-year">$2400</span>
                                            <span className="text-month">/month</span>
                                        </div>
                                        <div>
                                            <h4 className="mb-15">Enterprise</h4>
                                            <p className="text-desc-package mb-30">
                                                For most businesses that want to otpimize web queries
                                            </p>
                                        </div>
                                        <ul className="list-package-feature">
                                            <li>All limited links</li>
                                            <li>Own analytics platform</li>
                                            <li>Chat support</li>
                                            <li>Optimize hashtags</li>
                                            <li>Unlimited users</li>
                                        </ul>
                                        <div>
                                            <span className="btn btn-border">Choose plan</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-box mt-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="box-image-findjob box-image-about ml-0">
                                    <figure><img alt="jobhub" src="assets/imgs/page/about/banner-price.png" /></figure>
                                    <Link to="https://www.youtube.com/watch?v=ea-I4sqgVGY" className="btn-play-video popup-youtube"></Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box-info-job box-info-about pl-90">
                                    <span className="text-blue text-uppercase wow animate__animated animate__fadeInUp">About us</span>
                                    <h5 className="heading-36 mb-30 mt-10 wow animate__animated animate__fadeInUp">Post your job and find the people you need</h5>
                                    <p className="text-md mb-10 wow animate__animated animate__fadeInUp">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered.
                                    </p>
                                    <h5 className="heading-md-regular mt-30 mb-10 wow animate__animated animate__fadeInUp">Find fulltime & internship job</h5>
                                    <p className="text-md wow animate__animated animate__fadeInUp">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered.
                                    </p>
                                    <h5 className="heading-md-regular mt-30 mb-10 wow animate__animated animate__fadeInUp">Find fulltime & internship job</h5>
                                    <p className="text-md wow animate__animated animate__fadeInUp">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered.
                                    </p>
                                    <div className="mt-50 wow animate__animated animate__fadeInUp">
                                        <span onClick={() => navigate("/more-jobs")} className="mt-sm-15 mt-lg-30 btn btn-border icon-chevron-right">Browse all</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-box bg-blue-full mt-90 mb-50">
                    <div className="container">
                        <h3 className="mb-20">Want to talk about ideas? <br />Let's get started right now.</h3>
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="text-gray-200">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                            </div>
                            <div className="col-lg-6 pl-100 pl-md-15 mt-md-50">
                                <div className="box-button-shadow mr-20">
                                    <span className="btn btn-default">Contact us</span>
                                </div>
                                <span className="btn btn-border icon-chevron-right">Learn more</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-box mt-50 mb-60">
                    <div className="container">
                        <div className="box-newsletter">
                            <h5 className="text-md-newsletter">Sign up to get</h5>
                            <h6 className="text-lg-newsletter">the latest jobs</h6>
                            <div className="box-form-newsletter mt-30">
                                <form className="form-newsletter">
                                    <input type="text" className="input-newsletter" value="" placeholder="contact.alithemes@gmail.com" />
                                    <button className="btn btn-default font-heading icon-send-letter">Subscribe</button>
                                </form>
                            </div>
                        </div>
                        <div className="box-newsletter-bottom">
                            <div className="newsletter-bottom"></div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default Pricing