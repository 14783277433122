import React from "react";
import "./companyProfile.css";
import companyBackImage from "../../../assets/images/companyBackImage.jpeg";
import defaultCompanyImage from "../../../assets/images/company_default_img.svg";
import FeatherIcon from "feather-icons-react";
import { imageURL } from "../../../Constants/commonURLS";
import { Chip } from "@mui/joy";
import EmployerProfilePage1 from './EmployerProfilePage1'
function EmployerNavbarProfile(company) {
  return (
    <>
      <section className="section-box">
        <EmployerProfilePage1  companyList={company}/>
        {/* <div className="comp_profile_top_main_cont">
          <div className="comp_profile_top_cont">
          
          </div>
          <div>
            <div className="company_profile_img_cont_main">
              <div className="company_profile_img_cont">
                <img
                  src={`${imageURL}${company?.company?.company_image}`}
                  alt=""
                  style={{ width: "70px", height: "70px" }}
                  onError={e => {
                    e.target.src = defaultCompanyImage;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="company_profile_desc_cont">
            <div className="company_profile_desc_details_cont">
              <p>{company?.company?.company_name}</p>
              <Chip
                variant="outlined"
                color="neutral"
                size="sm"
                startDecorator={<FeatherIcon icon="user" size="10" />}
              >
                {company?.location}
              </Chip>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}
export default EmployerNavbarProfile;
