import React from "react";
import Stepper from "react-stepper-horizontal";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import "./styles.css";

const styles = {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center"
};

const ApplicationStatus = () => (
    <div style={{ marginTop: "130px" }}>
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-3">
                    <Sidebar />
                </div>
                <div className="col-sm-12 col-md-9 col-lg-9 recent-activities">
                    <div style={styles}>
                        <div className="mb-4 card border-0">
                            <Stepper
                                steps={[
                                    {
                                        title: "Application Sent",
                                        onClick: function () {
                                            alert("sfsdjkfhksfdhhksdf");
                                        }
                                    },
                                    { title: "Applications viewed By Recruiters" },
                                    { title: "Resume Viewed" },
                                    { title: "Interview Scheduled" },
                                    { title: "Awaiting for response" }
                                ]}
                                activeStep={1}
                                activeColor="rgb(151 119 250)"
                                completeColor="rgb(151 119 250)"
                                activeTitleColor="#6B87A4"
                                completeTitleColor="#6B87A4"
                                circleFontColor="#FFF"
                                defaultBorderColor="#046DAF"
                                defaultBorderStyle="#046DAF"
                                completeBarColor="#046DAF"
                            />
                        </div>
                        <div className="mb-4">
                            <h5 className="status-activity">Activity on this job</h5>
                        </div>
                        <div className="row mb-4">
                            <div className="col-sm-12 col-md-3 col-lg-3 border-right">
                                <div className=" p-4">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="jobs-count mb-2" style={{ marginLeft: "15px" }}>160</div>
                                    </div>
                                    <div className="dashoard-card-title text-muted text-end">Total Applications</div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-3 col-lg-3 border-right">
                                <div className="p-4">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="jobs-count mb-2" style={{ marginLeft: "15px" }}>60</div>
                                    </div>
                                    <div className="dashoard-card-title text-muted text-end">Applications Shortlisted</div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <div className=" p-4">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="jobs-count mb-2" style={{ marginLeft: "15px" }}>30</div>
                                    </div>
                                    <div className="dashoard-card-title text-muted text-end">Applications Viewed</div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <h5 className="status-activity">What may work for you.</h5>
                            <p className="text-start">Following criteria suggests how well you match with this job.</p>
                            <ul className="list-group border-0">
                                <li className="list-group-item border-0 text-start"><i className="fas fa-check check-icon text-primary" />Location</li>
                                <li className="list-group-item border-0 text-start"><i className="fas fa-check check-icon text-primary" />Key Skills</li>
                                <li className="list-group-item border-0 text-start"><i className="fas fa-check check-icon text-primary" />Early Application</li>
                                <li className="list-group-item border-0 text-start"><i className="fas fa-check check-icon text-primary" />Department</li>
                                <li className="list-group-item border-0 text-start"><i className="fas fa-check check-icon text-primary" />Industry</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ApplicationStatus
