import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { imageURL } from "../../Constants/commonURLS";
import { ReferNowModal } from "../JobsList/Alljobs/ReferModal";
import getSymbolFromCurrency from "currency-symbol-map";
import "../../assets/JobList/AllJobs.css";
import defaultCompanyImage from "../../assets/images/company_default_img.svg";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import AspectRatio from "@mui/joy/AspectRatio";
import CardContent from "@mui/joy/CardContent";
//import List from './List';
import Skeleton from "@mui/material/Skeleton";
import { BookmarksOutlined } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import EarnImage from "../../assets/images/earnImage.svg";
import workIcon from "../../assets/images/work 2.svg";
import LocationIcon from "../../assets/images/location 2.svg";
import peopleIcon from "../../assets/images/people.svg";
import heartIcon from "../../assets/images/heart.svg";
import { useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { toast } from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ShareIcon from '@mui/icons-material/Share';
import copy from "copy-to-clipboard";

function CompanyBasedJobs({ compJobList,setJobId }) {
  const navigate = useNavigate();
  const [referModal, setReferModal] = useState({
    modal: "",
    purpose: "",
    modalType: "",
  });

  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    return `${scaledNumber.toFixed(0)}k`;
  };

   /* share link code start here */
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [id,setId] = React.useState({id:""})
   const openS = Boolean(anchorEl);
   // const handleClick = event => {
   //   setAnchorEl(event.currentTarget);
   // };
 
   const handleClick = (event,id) => {
     setId({id:id})
     setAnchorEl(event.currentTarget);
     handleCloseOption(id)
   };
 
   const handleClose = () => {
     setAnchorEl(null);
   };
 
   const handleCloseOption = (id) => {
     copy(`https://shifyt.com/job?id=${id}`);
     toast.success("link copied successfully");
     setAnchorEl(null);
   };
 
   /* share link code End here */


  return (
    <>
      <div className="content-page">
        <div className="box-filters-job mt-15 mb-10"></div>
        <div className="job-list-list mb-15">
          <div className="">
            {compJobList?.map((item, index) => (
              <React.Fragment key={index}>
                <div className="latest_jobs_main_card_section mb-3">
                  <div key={item.id} className="latest_jobs_inside_section">
                    <div className="title_section_1">
                      <div className="title_name_logo_sec">
                        {/* <div
                          style={{
                            height: "44px",
                            width: "44px",
                            borderRadius: "50%",
                            border: "0.5px solid rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <Avatar
                            src={`${imageURL}${item?.company_details?.company_image}`}
                            style={{
                              borderRadius: "50%",
                              borderColor: "rgba(0, 0, 0, 0.25)",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            {item?.company_details?.company_name?.charAt(0)}
                          </Avatar>
                        </div> */}
                        <div className="title_sec_divider">
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#121212",
                              fontFamily: "Inter,sans-serif",
                            }}
                            // onClick={() => navigate(`../job/${item.id}`)}
                            onClick = {()=>setJobId({id:item?.id})}
                          >
                            {/* {item.job_title} */}
                            {item?.job_title?.length <= 20
                              ? item?.job_title
                              : item?.job_title?.slice(0, 18) + ".."}
                          </Typography>
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                            }}
                            onClick={() =>
                              navigate(
                                `../employer-single-list?id=${item?.created_by_user_details?.id}`
                              )
                            }
                          >
                            {/* {item.company_name} */}
                            {item?.company_details?.company_name?.length <= 20
                              ? item?.company_details?.company_name
                              : item?.company_details?.company_name?.slice(0, 18) + ".."}
                          </Typography>
                        </div>
                      </div>
                      <div className="commission_sec">
                        {/* <Stack direction="row" spacing={2}>
                          <Avatar alt="Remy Sharp" src={EarnImage} />
                        </Stack> */}
                        <div className="title_sec_divider">
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            sx={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#121212",
                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            Earn Upto
                          </Typography>
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            sx={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "Bold",
                              color: "#066a4c !important",
                              // opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            {getSymbolFromCurrency(item.currency)}{" "}
                            {abbreviateNumber(
                              (item.max_sal * item.commission_rate) / 100
                            )}
                          </Typography>
                        </div>
                        <IconButton
                            variant="plain"
                            color="neutral"
                            size="sm"
                            sx={{ ml: "auto",marginTop:"-17px"}}
                            aria-controls={openS ? "long-menu" : undefined}
                            aria-expanded={openS ? "true" : undefined}
                            aria-haspopup="true"
                            // onClick={handleClick}
                            onClick={(event)=>handleClick(event,item?.id)}
                          >
                  
                            <ShareIcon fontSize="small"/>
                          </IconButton>
                      </div>
                    </div>

                  

                    <div className="main_skills_sec">
                      <div className="job_card_top_details_commision_cont">
                        <div className="job_details"></div>
                        <span className="mx-2"></span>
                        <span className="card-job-top--price">
                          {item.time}
                          <span></span>
                        </span>
                      </div>
                      <div className="jobs_title_card">
                        <Chip
                          variant="solid"
                          color="neutral"
                          size="sm"
                          style={{ color: "#777AD6", background: "#F5ECFF",fontFamily:"Inter" }}
                        >
                          {item.job_type.replace("_", " ")}
                        </Chip>

                        <Chip
                          variant="solid"
                          color="neutral"
                          size="sm"
                          icon={<LocationOnIcon />}
                          style={{
                            color: "#081753",
                            background: "rgba(8, 23, 83, 0.07)",
                            fontFamily:"Inter"
                          }}
                        >
                          {item?.location?.length <= 17
                            ? item?.location
                            : item?.location?.slice(0, 35) + ".."}
                        </Chip>
                        <Chip variant="soft" color="neutral" size="sm"  style={{
                            color: "#081753",
                            background: "rgba(8, 23, 83, 0.07)",
                            fontFamily:"Inter"
                          }}>
                          {/* {item.min_exp}-{item.max_exp}
                          &nbsp; Yrs */}
                            {`${item.min_exp}${
                            item.min_exp_months > 0
                              ? `.${item.min_exp_months}`
                              : ""
                          }`}{" "}
                          -{" "}
                          {`${item.max_exp}${
                            item.max_exp_months > 0
                              ? `.${item.max_exp_months}`
                              : ""
                          }`}
                          yrs
                        </Chip>
                        <Chip
                          variant="soft"
                          color="neutral"
                          size="sm"
                          style={{ background: "#F2F8F6", color: "#07A996",fontFamily:"Inter" }}
                        >
                          {getSymbolFromCurrency(item.currency)}
                          {Math.round(item.min_sal / 1000, 2)}k-
                          {Math.round(item.max_sal / 1000, 2)}k{`/${item.payout.replace("_","")}`}
                        </Chip>
                        <Chip
                          variant="soft"
                          color="neutral"
                          size="sm"
                          style={{ background: "#F2F8F6", color: "#07A996",fontFamily:"Inter" }}
                        >
                          {item.work_mode.replace("_", " ")}
                        </Chip>
                      </div>
                    </div>

                    <div
                      className="skills_section"
                      style={{ paddingLeft: "10px" }}
                    >
                      <ul
                        className="skill_main_section"
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#121212",
                          opacity: "50%",
                          fontFamily: "Inter,sans-serif",
                        }}
                      >
                        {item.key_skill_details?.slice(0, 3).map(each => (
                          <li
                            key={each.id}
                            className="typography-field"
                            style={{ paddingRight: "10px" }}
                          >
                            {each.name}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* <div className="apply_section">
                      <div className="d-flex gap-2 align-items-center">
                        <CssVarsProvider>
                          <Button
                            style={{ backgroundColor: "#081753" }}
                            variant="solid"
                            className="refer-now-btn cursor-pointer "
                            startDecorator={
                              <GroupAddIcon className="refer_btn_icon" />
                            }
                            onClick={() => {
                              if (
                                user.type !== undefined &&
                                user.type !== null &&
                                user.type !== ""
                              )
                                setReferModal({
                                  modal: "center",
                                  details: item,
                                  purpose: "refer",
                                });
                              else {
                                navigate("/login");
                              }
                            }}
                          >
                            Refer Now
                          </Button>
                        </CssVarsProvider>
                        <Button
                          variant="outlined"
                          className="apply-now-btn cursor-pointerf"
                          startDecorator={
                            <GroupAddIcon className="refer_btn_icon" />
                          }
                          style={{
                            color: "#000000",
                            border: "1px solid rgba(8, 23, 83, 0.1)",
                          }}
                          onClick={() =>
                            setReferModal({
                              modal: "center",
                              details: item,
                              purpose: "self",
                            })
                          }
                        >
                          {item.is_candidate_applied_to_job_post == 0
                            ? "Apply Self"
                            : "Applied"}
                        </Button>
                      </div>
                    </div> */}

                    {/* <div style={{ paddingTop: "10px", padding: "10px" }}>
                      <div className="time_section">
                        <Typography
                          fontWeight="md"
                          textColor="primary.plainColor"
                          mb={0.5}
                          style={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#121212",
                            opacity: "50%",
                            fontFamily: "Inter,sans-serif",
                            paddingTop: "5px",
                          }}
                          className="date_posts"
                        >
                          Posted{" "}
                          <span
                            style={{
                              color: "black",
                              fontWeight: "700",
                              fontSize: "12px",
                            }}
                          >
                            {Math.floor(
                              Math.abs(
                                new Date() -
                                  new Date(
                                    item.createdAt
                                      .slice(0, 10)
                                      .toLocaleString()
                                      .replace(/-/g, "/")
                                  )
                              ) /
                                (1000 * 3600 * 24)
                            )}{" "}
                            days
                          </span>{" "}
                          ago
                        </Typography>

                        <Typography
                          fontWeight="md"
                          textColor="primary.plainColor"
                          mb={0.5}
                          style={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#121212",
                            opacity: "50%",
                            fontFamily: "Inter,sans-serif",
                            paddingTop: "5px",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              fontWeight: "700",
                              fontSize: "12px",
                            }}
                          >
                            32 days
                          </span>{" "}
                          left to apply
                        </Typography>
                      </div>
                    </div> */}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <ReferNowModal
        show={referModal.modal}
        purpose={referModal.purpose}
        onHide={modal =>
          setReferModal({
            modal: modal,
            details: {},
          })
        }
        params={referModal}
      />
    </>
  );
}

export default CompanyBasedJobs;
