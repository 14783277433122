import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartToaster, toast } from "react-smart-toaster";

function ScreeningPartner() {
    const [step, setStep] = useState(1);
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');

    const onSubmit = () => {
        if (email === "batman@Shifyt.com")
            navigate('/dashboard')
        else {
            toast.error("Invalid Credentials")
        }
    }

    switch (step) {
        case 1: {
            return (
                <>
                    <div style={{ backgroundColor: "#f7f7f7" }}>
                        <div className="container">
                            <div className="row" style={{ marginTop: "8%" }}>
                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ borderRight: "2px solid #ebebeb" }}>
                                    <div style={{ paddingTop: "16%" }}>
                                        {/* <h4 className="about-me mb-3">ZEPUL HIRING SUITE FOR EMPLOYERS</h4> */}
                                        <div className="d-flex justify-content-center">
                                            <img
                                                style={{ width: "400px" }}
                                                src="https://res.cloudinary.com/dt6u679fs/image/upload/v1666699306/loginFormImage_wzvvqa.png"
                                                alt="screening-part-login"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="p-4" style={{ paddingTop: "16%" }}>
                                        <h3 className="panel-title mb-4">Create An Account</h3>
                                        <div className="card-body pt-0">
                                            <div className="row" style={{ marginLeft: "-25px" }}>
                                                <div className="col-sm-12 col-md-6 col-lg-6 form-group mb-4">
                                                    <label>Name</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder="Name"
                                                        name="name"
                                                        type="text"
                                                        autoFocus=""
                                                    // onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-6 form-group mb-4">
                                                    <label>Contact</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder="Contact"
                                                        type="text"
                                                        autoFocus=""
                                                    // onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row" style={{ marginLeft: "-25px" }}>
                                                <div className="col-sm-12 col-md-6 col-lg-6 form-group mb-4">
                                                    <label>Designation</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder="Designation"
                                                        type="text"
                                                        autoFocus=""
                                                    // onChange={(e) => setNumber(e.target.value)}

                                                    />
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-6 form-group mb-4">
                                                    <label>Company Name</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder="Company Name"
                                                        type="text"
                                                    // onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-6 form-group mb-4">
                                                    <label>Location</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder="Location"
                                                        type="text"
                                                    // onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-6 form-group mb-4">
                                                    <label>Email Id</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder="Email Id"
                                                        type="text"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 form-group mb-4">
                                                    <label>Worked as</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder="Workedd as"
                                                        type="text"
                                                    // onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-6 form-group mb-4">
                                                    <label>Experience</label>
                                                    <select className="form-select" style={{ height: "50px" }}>
                                                        <option>1 Year</option>
                                                        <option>2 Years</option>
                                                        <option>3 Years</option>
                                                        <option>4 Years</option>
                                                        <option>5 Years</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row" style={{ marginLeft: "-25px" }}>
                                                <div className="col-sm-12 col-md-6 col-lg-6 offset-lg-6 offset-md-6">
                                                    <button
                                                        className="btn mb-3 d-block btn-default btn-shadow w-100 hover-up"
                                                        onClick={(e) => setStep(step + 1)}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        case 2: {
            return (
                <>
                    <div style={{ backgroundColor: "#f7f7f7" }}>
                        <div className="container">
                            <div className="row" style={{ marginTop: "8%", paddingBottom: "20%" }}>
                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ borderRight: "2px solid #ebebeb" }}>
                                    <div style={{ paddingTop: "10%" }}>
                                        <div className="d-flex justify-content-center">
                                            <img
                                                style={{ width: "400px" }}
                                                src="https://res.cloudinary.com/dt6u679fs/image/upload/v1666699306/loginFormImage_wzvvqa.png"
                                                alt="scree-part-login"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="p-4">
                                        <div className="card-body" style={{ paddingTop: "18%" }}>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 mb-4 col-lg-6 offset-3">
                                                    <label>Verify Email Otp</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder="OTP"
                                                        name="name"
                                                        type="number"
                                                        autoFocus=""
                                                    // onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 mb-4 col-lg-6 offset-3">
                                                    <button
                                                        className="btn mb-3 d-block btn-default btn-shadow w-100 hover-up"
                                                        onClick={(e) => onSubmit(e)}
                                                    >
                                                        Sign Up
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SmartToaster
                            width={"200px"}
                            store={toast}
                            lightBackground={false}
                            position={"top_right"}
                        />
                    </div>
                </>
            )
        }


        default:
            return (
                <div className="App">
                    <div className="custom-card form-bg custom-sell-form p-5 mt-2">
                    </div>
                </div>
            );
    }
}
export default ScreeningPartner;