import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import {
  getUserByToken,
  getUserWithToken,
  login,
} from "../helpers/dataFetcher/profile/profile";
import { Col, Form, Row } from "reactstrap";
import google from "../assets/images/google.png";
import linkedinButtonImage from "../assets/images/linkedinButtonImage.png";
import linkedinImageButton from "../assets/images/linkedinImageButton.png";
import googleButtonImage from "../assets/images/googleButtonImage.png";
import linkedin from "../assets/images/linkedin2.png";
import { useDispatch } from "react-redux";
import { addUserAction } from "../store/auth/actions";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { CssVarsProvider } from "@mui/joy/styles";
import CircularProgress from "@mui/joy/CircularProgress";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { linkedinDeatilsWithToken } from "../helpers/dataFetcher/auth/linkedin";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import getUserDetailsFromGoogle from "../helpers/dataFetcher/auth/google";
import { toast } from "react-hot-toast";
import SignIn from './SignInPage';

function Login() {
  const [loginStatus, setLoginStatus] = useState(false);
  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => getUserDetailsFromGoogle(codeResponse),
    onError: error => console.log("Login Failed:", error),
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [divClick, setDivClick] = useState(false);
  const [passwordClick, setPasswordClick] = useState(false);
  const usePasswordRef = useRef(null);
  const ref = useRef(null);
  const [id, setId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  let id1;
  async function setUserDetailsForActions(
    loginObj,
    type = "normal",
    loginType = "normal"
  ) {
    navigate("/");
    localStorage.setItem("token", loginObj.access);
    localStorage.setItem("user_id", loginObj.id);
    localStorage.setItem("user_type", loginObj.usertype);

    const { status: userStatus, data: userData } = await getUserWithToken({
      Authorization: "Bearer " + loginObj.access,
    });
    if (userStatus == 200) {
      dispatch(
        addUserAction({
          ...userData?.data,
          access: loginObj.access,
          type: type,
          loginType: loginType,
        })
      );
    }
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        // setDivClick(false);
      } else {
        setError(false);
        setDivClick(true);
      }
      if (
        usePasswordRef.current &&
        !usePasswordRef.current.contains(event.target)
      ) {
        // setPasswordClick(false);
      } else {
        setError(false);
        setPasswordClick(true);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const loginFunc = async data => {
    setLoginStatus(true);
    const { status, data: loginObj } = await login({
      email: data.email,
      password: data.password,
    });
    if (status !== 200) {
      setError(true);
      setLoginStatus(false);
      return;
    }
    setLoginStatus(false);
    setUserDetailsForActions(loginObj);
    return;
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: "78yn8vmivtm2to",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: async code => {
      console.log(code);
      const { status, data: loginObj } = await login({
        email: "support@Shifyt.com",
        password: "123456",
        login_type: "LinkedIn",
        linkedIn_token: code,
      });
      if (status !== 200) {
      } else {
        setUserDetailsForActions(loginObj, "external", "linkedin");
      }
    },
    scope: "r_emailaddress r_liteprofile",
    onError: error => {
      console.log(error);
    },
  });
  return (
    <>
      <section className="vh-100">
        {/* <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-9 col-lg-6 col-xl-5">
              <div className="banner-imgs banner_image">
                <div className="card text-bg-dark border-0">
                  <img
                    src="assets/imgs/banner/banner-color.png"
                    className="card-img"
                    alt="banner-colors"
                  />
                  <div className="card-img-overlay" style={{ top: "20%" }}>
                    <img
                      alt="jobhub"
                      src="https://res.cloudinary.com/dt6u679fs/image/upload/v1666782413/MicrosoftTeams-image_7_q2fhku.png"
                      className="img-responsive shape-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <div>
                <div className="mb-3">
                  <h4 style={{ fontFamily: "sans-serif" }}>Welcome to Shifyt</h4>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                  <div
                    className="login_with_google_cont"
                    style={{ marginRight: "10px" }}
                    onClick={googleLogin}
                  >
                    <div>
                      <img
                        src={google}
                        className="login_google_icon"
                        alt="login-Shifyt"
                      />
                    </div>
                    <div>Log in with Google</div>
                  </div>
                  <div
                    className="login_with_google_cont"
                    onClick={linkedInLogin}
                    style={{ marginLeft: "10px" }}
                  >
                    <div>
                      <img
                        src={linkedin}
                        size={12}
                        className="login_google_icon"
                        alt="login-Shifyt"
                      />
                    </div>
                    <div>Log in with linkedin</div>
                  </div>
                </div>

                <div className="login_hr_cont text-center">
                  <div>
                    <hr />
                  </div>
                  <div>
                    <span>or</span>
                  </div>
                  <div>
                    <hr />
                  </div>
                </div>

                <Box
                  component="form" 
                  onSubmit={handleSubmit(loginFunc)}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email ? true : false}
                    {...register("email", { required: true })}
                    InputProps={{
                      style: { borderRadius: "5px", height: "51.3px" },
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={errors.password ? true : false}
                    {...register("password", { required: true })}
                    InputProps={{
                      style: { borderRadius: "5px", height: "51.3px" },
                    }}
                  />

                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                  <Grid container style={{ paddingBottom: "30px" }}>
                    <Grid item xs>
                      <Link href="#" variant="body2" to="../forget-password">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      Don't have an account?
                      <Link href="#" variant="body2" to="../registration">
                        {" Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          </div>
        </div> */}
        <SignIn />
      </section>
    </>
  );
}

export default Login;
