import React from "react";
import SignUpForJobs from "../JobsList/SignUpForJobs/SignUpForJobs";
import ContactUsMap from "./ContactUsMap";
import GetInTouch from "./GetInTouch";
import CompanyAddress from './Address'
import FooterSection from '../AboutUs/AboutsUsPages/Footer'
import Footer from '../../layouts/footer/Footer'
import {globalTheme} from '../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
function ContactUsIndex() {
    return (
        <>
            <ThemeProvider theme={globalTheme}>
            <GetInTouch />
            {/* <CompanyAddress /> */}
            <SignUpForJobs />
            <Footer />
            {/* <ContactUsMap /> */}
            {/* <FooterSection/> */}
            </ThemeProvider>
        </>
    )
}

export default ContactUsIndex;