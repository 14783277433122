import React from "react";
import { Skeleton } from "@mui/material";

import "../RecentJobs.css";

function SkeletonRecentJobs() {
  return (
    <>
      <div className="home-list-recent-jobs">
        {Array.from(new Array(6)).map((item, index) => (
         <div className="card-job" id="card-job" key={index}>
         <div className="job_card_top_details_cont">
           <div className="card-job-top--image job_card_top_details_img_cont" id="job_card_top_details_img_cont">
             <Skeleton
               animation="wave"
               variant="circular"
               className="job_card_top_details_img"
               width={60}
               height={60}
             />
           </div>
           
           <div className="job_card_top_details_info_cont" id="job_card_top_details_info_cont">
             <Skeleton animation="wave" width="100%" />
             <Skeleton animation="wave" width="100%" />
             <Skeleton animation="wave" width="100%" />
           </div>
         </div>
         <div className="job_post_actions_cont">
           <Skeleton animation="wave" width="100%" />
         </div>
         <div className="job_post_actions_cont">
           <Skeleton animation="wave" width="100%" />
         </div>

         <div className="job_post_actions_cont">
           <Skeleton animation="wave" width="100%" />
         </div>

         <div className="job_post_actions_cont">
           <Skeleton animation="wave" width="100%" />
         </div>
       </div>
        ))}
      </div>
    </>
  );
}

export default SkeletonRecentJobs;
