import React from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/joy/Chip";
import getSymbolFromCurrency from "currency-symbol-map";
import Divider from "@mui/material/Divider";

function SkillsSection(props) {
  return (
    <>
      <div className="insights_sec pt-4">
        <Typography className="job_insights">Skills</Typography>
        {props.jobDetails?.key_skills_details?.length >0?
        <div className="jobs_title_card pb-5">
          {props.jobDetails?.key_skills_details?.map((each)=>
          <Chip
            variant="solid"
            color="neutral"
            size="sm"
            // icon={<LocationOnIcon />}
            style={{ color: "#081753", background: "rgba(8, 23, 83, 0.07)" }}
          >
            {each.name}
          </Chip>
          )}
        </div>
        :"No data Found"}
      </div>
      <Divider orientation="horizontal" sx={{ height: "auto !important" }} />
    </>
  );
}

export default SkillsSection;
