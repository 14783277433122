import { POST } from "../../../Constants/methods";
import callApi from "../../callApi";
export const getAllChats = async () => {
  return await callApi("chats/all_chats");
};
export const findChatBasedOnId = async id => {
  return await callApi(`chats/chat_details/${id}`);
};
export const initializeChat = async id => {
  return await callApi("/chats/initiate_chat", { user_id: id }, POST);
};
export const sendMessage = async (id, message, type) => {
  return await callApi(
    "/chats/send_message",
    { user_id: id, message, type },
    POST
  );
};

const findAllContactsModal = async(item)=>{
  return item.map((each)=>{
    return {
      id:each.id,
      name:each.first_name,
      lastName:each.last_name,
      recents_msgs:"Hey this is dummy msg",
      avatar:each.image,
      status:each.isActive

    }
  })
}

export const findAllChatContacts = async ()=>{
  const {data, status} = await callApi("common/get_contacts")
  return {data: await findAllContactsModal(data.data)}
}





// function removeDuplicates(aarr) {
//   var unique = [];
//   aarr.forEach(function (item) {
//     if (!unique.includes(item.id)) {
//       unique.push(item.id);
//     }
//   });
//   return unique;
// }

/*Demo chat API cals start @seshu */

export const getAllAppliedJobsList = async()=>{
  return await callApi(`/chats/get_all_applied_job_posts`)
}

export const getAllCompanyMenList = async(userJobDetails)=>{
  return await callApi(`chats/get_all_job_post_chat_channels?user_job_post_id=${userJobDetails.user_job_post_id}&job_post_id=${userJobDetails.job_post_id}`)
}
/*Demo chat API cals End @seshu */

export const getAllChatsByChannel = async(channelId)=>{
  return await callApi(`chats/all_chats?channel_id=${channelId}`)
}

export const sendUserMessages = async(data)=>{
  return await callApi(`chats/send_message`,data,POST)
}