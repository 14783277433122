import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import DemoChatSidebar from "./DemoChatSidebar";
import DemoChatMain from "./DemoChatMain";
import JobsListChat from "./JobsListChat";
// import chatImg from "../../assets/images/chat.svg";
import chatImg from "../../assets/images/chatShiyft2.png";
import NoDataFoundComponent from "../../Nodatafound/NoDataFoundReusable";
import "./DemoChat.css";
import {
  findChatBasedOnId,
  findContactsBasedOnUserId,
  getAllChats,
  getAllChatsByChannel,
  sendUserMessages,
} from "../../helpers/dataFetcher/chats/chats";
import { getAllAppliedJobsList } from "../../helpers/dataFetcher/chats/chats";
import JobDescription from "../BrowseJobsList/JobDescription";
import JobDrawChatDescription from "../BrowseJobsList/JobDrawChatDescription";
import { getJobDetailsById } from "../../helpers/dataFetcher/jobs/jobs";
import { useNavigate } from "react-router-dom";
import SocketConnection from "../../hooks/socket";
import { get_chat_active_user_list } from "../../helpers/sockets";
import { getISODay } from "date-fns";

function DemoChat() {
  const [openKeys, setOpenKeys] = useState(["unread", "allmessage"]);
  const [chat, setChat] = useState([]);
  const rootSubmenuKeys = ["unread"];
  const [selectedChat, setSelectedChat] = useState([]);
  const [senderId, setSenderId] = useState("");
  const [receiverId, setReceiverId] = useState({ id: "" });
  const [text, setText] = useState("");
  const [attachment, setAttachment] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [postImg, setPostImg] = useState("");
  const [contacts, setContacts] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [jobList, setJobList] = useState(true);
  const [chatMain, setChatMain] = useState(true);
  const [userJobDetails, setUserJobDetails] = useState({});
  const [jobs, setJobs] = useState("");
  const [jobId, setJobId] = useState("");
  const [hideJobSec, setHideJobSec] = useState(false);
  const [jobDesc, setJobDesc] = useState(false);
  const [jobPostId, setJobPostId] = useState({ id: "" });
  const [userJobPostId, setUserJobPostId] = useState({ id: "" });
  const [channelId, setChannelId] = useState({ id: "" });
  const [selectedChannelData, setSelectedChannelData] = useState([]);
  const [userId, setUserId] = useState("");
  const [selectedChannelDetails, setSelectedChannelDetails] = useState();
  const [routeNavigate, setRouteNavigate] = useState(false);
 
  const navigate = useNavigate();

  const { lastMessage } = SocketConnection();

  /*Required state @seshu */
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [seshuReceiverId, setSeshuReceiverId] = useState();
  const [activeSocketUsersList,setActiveSocketUsersList] = useState()
  /*Required state End@seshu */

  const userData = useSelector(state => state.User);

  useEffect(() => {
    setUserId(userData.user.id);
  }, [userData]);
  useEffect(()=>{

  },[jobDesc])

  const toggleModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setPostImg(url);
    toggleModel();
  };
  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted) getChatList();
  //   return () => {
  //     mounted = false;
  //   };
  // }, []);
  // useEffect(() => {
  //   if (
  //     lastMessage?.data !== undefined ||
  //     lastMessage?.data !== null ||
  //     lastMessage?.data !== ""
  //   ) {
  //     const resultant = lastMessage?.data;
  //     if (resultant !== undefined) {
  //       const data = JSON.parse(resultant);
  //       if (data?.type === "chats") {
  //         getChatList();
  //         console.log(data?.data);
  //         if (data?.data?.chat_id === receiverId?.chat_id) {
  //           handleIncomingMessages();
  //         }
  //       }
  //     }
  //   }
  // }, [lastMessage]);
  async function handleIncomingMessages(receiverId) {
    // const { data } = await findChatBasedOnId(receiverId?.chat_id);
    data?.data?.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    setSelectedChat(data?.data);
  }
  // async function getChatList() {
  //   const { data, status } = await getAllChats();
  //   setChat(data?.data);
  // }

  jQuery(document).ready(function () {
    $(".company_chat_list a").click(function () {
      $(".chatbox").addClass("showbox");
      return false;
    });

    $(".chat-icon").click(function () {
      $(".chatbox").removeClass("showbox");
    });
  });

  /* Demochat Integration start @seshu */

  useEffect(() => {
    getAllJobs();
  }, []);

  async function getAllJobs() {
    const getAllJobsList = await getAllAppliedJobsList();
    setAppliedJobs(getAllJobsList?.data?.data);
    // getjobById(getAllJobsList?.data?.data[0].job_post_id);
    setJobId(getAllJobsList?.data?.data[0].job_post_id);
    setJobDesc(false);
  }

  /* Demochat Integration end @seshu */

  

  /* Get Job Description By Id code start Here@ seshu */
  useEffect(() => {
    getjobById(jobId);
    localStorage.setItem("jobId",jobId)
  }, [jobId]);
  const getId = async()=>{
    return localStorage.getItem("jobId")
  }
  const getjobById = async jobId => {
    // setSelectedButton(data.job_id === selectedButton ? null : data.job_id);
    const jobDataList = await getJobDetailsById(jobId);

   if(jobId){
     if(jobDataList?.data?.length >0){
 
      const iterate = jobDataList?.data?.filter((each)=>(each.id) == id)
      setJobs(iterate)
     }else {
      setJobs(jobDataList?.data)
     }
   }
    // if(jobDataList?.data?.length >0){
    //   console.log(jobId,"jobbbb")
    //   let id = await getId()
    //   const iterate = jobDataList?.data?.filter((each)=>parseInt(each.id) == id)
   
    //   setJobs(iterate)
    // }else {
    //   setJobs(jobDataList?.data)
    // }
    // setJob(jobDataList?.data);
  
    // setJobDesc(true);
  };
  
  /* Get Job Description By Id code start Here@ seshu */

  /* Get chats based on ID Code start here @seshu */

  const getChatById = async receiverId => {
    const result = await findChatBasedOnId();
  };

  /* Get chats based on ID Code End here @seshu */

  /* Get All chats Code start Here */
  // useEffect(() => {
  //   getAllChatsData(channelId.id);
  //   getActiveUsersDataSocket()
  // }, [channelId.id]);

  const getAllChatsData = async id => {
    const allChatsData = await getAllChatsByChannel(id);
    const sortedData = allChatsData?.data?.data.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.updatedAt);
    });
    setSelectedChannelData(sortedData);
  };

  const getActiveUsersDataSocket = async()=>{
    const activeUsersList = await get_chat_active_user_list()
    setActiveSocketUsersList(activeUsersList?.data)
  }

  /*Get All chats code End here */

  /*Send Messages code start here @seshu */

  const sendUserMessage = async () => {

    /* Iteration active users start */
    // const activeUserId = activeSocketUsersList?.filter((each))
     /* Iteration active users End */
    const data = {
      user_job_post_id: userJobPostId.id,
      receiver_id: receiverId.id,
      job_post_id: jobPostId.id,
      message: text ? text : attachment,
    };
    if (data.message !== "") {
      const sendData = await sendUserMessages(data);
    }
    setText("");
    setAttachment([]);
    const retriveMessagesData = await getAllChatsByChannel(channelId.id);
    const sortedData = retriveMessagesData?.data?.data.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.updatedAt);
    });
    setSelectedChannelData(sortedData);
  };
  /*Send Messages code End here @seshu */

  /* Navigation route implementation code start here @seshu */

  /* Navigation route implementation code End here @seshu */

  return (
    <>
      <Container
        fluid
        className="chat_main_cont"
        style={{
          paddingRight: "0px",
          paddingLeft: "0px",
          marginTop: "30px",
          marginLeft: "51px",
        }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-0 col-lg-2">
            {/* <Sidebar /> */}
            <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
              <Sidebar />
            </div>
          </div>
          <div
            className="col-sm-12 col-md-12 col-lg-10"
            style={{ paddingLeft: "28px", height: "80vh", overflowY: "scroll" }}
          >
            {appliedJobs?.length > 0 ? (
              <>
                <div className="demochat_main_cont">
                  <div className="col-sm-12 col-md-6 col-lg-4 jobsPageWidth">
                    {jobList ? (
                      <JobsListChat
                        setChat={setChat}
                        setRouteNavigate={setRouteNavigate}
                        receiverId={receiverId}
                        senderId={senderId}
                        setShowPicker={setShowPicker}
                        showPicker={showPicker}
                        setText={setText}
                        selectedChat={selectedChat}
                        setSelectedChat={setSelectedChat}
                        text={text}
                        setJobList={setJobList}
                        appliedJobs={appliedJobs}
                        setAppliedJobs={setAppliedJobs}
                        setUserJobDetails={setUserJobDetails}
                        setJobId={setJobId}
                        setHideJobSec={setHideJobSec}
                        jobId={jobId}
                       
                      />
                    ) : ""}
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-8">
                    {hideJobSec ? (
                      ""
                    ) :  jobs !== undefined? (
                      <div
                        className="col-12 col-md-11 col-lg-11"
                        style={{ marginTop: "33px", marginLeft: "10px" }}
                      >
                        <div className=" mt-3">
                          <JobDrawChatDescription jobDetails={jobs} />
                        </div>
                        
                      </div>
                    ) : (
                      <div className="chat_main_box_cont">
                        <div
                          style={{
                            display: "grid",
                            placeItems: "center",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4 mt-3">
                            <div className="contact_no_show">
                              <img src={chatImg} alt="chat" />
                              <p>Shifyt Chat for Web</p>
                              <span className="pat-20 end_to_end">
                                End to End Encrypted
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <NoDataFoundComponent />
            )}
          </div>
        </div>
      </Container>
    </>
  );
}

export default DemoChat;
