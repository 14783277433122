import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./Ourpartner.css";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";

export default function OurPartners() {
  const slides = [
    "assets/imgs/jobs/logos/samsung.svg",
    "assets/imgs/jobs/logos/google.svg",
    "assets/imgs/jobs/logos/facebook.svg",
    "assets/imgs/jobs/logos/pinterest.svg",
    "assets/imgs/jobs/logos/avaya.svg",
    "assets/imgs/jobs/logos/forbes.svg",
    "assets/imgs/jobs/logos/avis.svg",
    "assets/imgs/jobs/logos/nielsen.svg",
    "assets/imgs/jobs/logos/doordash.svg",
  ];

  const Carousel = () => (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={200}
      isIntrinsicHeight={true}
      totalSlides={slides.length}
      visibleSlides={5}
      dragStep={2}
      step={3}
    >
      <div
        className="align-items-center partners_slider_cont"
      >
        <div className="">
          <div className="d-flex justify-content-end">
            <ButtonBack className="partner-carousel-btn-left">
              <WestIcon />
            </ButtonBack>
          </div>
        </div>
        <div className="">
          <Slider className="partner_slider"> 
            {slides.map((item, idx) => (
              <Slide index={idx} key={idx}>
                <div
                  className="card hover-up border-0 swiper-slide-active"
                >
                  <div className="customSlide">
                    <img
                      className="images-rounded"
                      src={item}
                      style={{
                        // width: "50px",
                        height: "50px",
                      }}
                      alt="partners"
                    />
                  </div>
                </div>
              </Slide>
            ))}
          </Slider>
        </div>
        <div className="">
          <ButtonNext className="partner-carousel-btn-right">
            <EastIcon />
          </ButtonNext>
        </div>
      </div>
    </CarouselProvider>
  );

  return (
    <>
      <Carousel />
    </>
  );
}
