import React, { useEffect, useState } from "react";
import "../../Accounts/Profiles/profile.css";
import ProfileModal from "../Profiles/ProfileModals/ProfileModals";
import UploadModel from "../../../components/common/uploadModel";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import EditIcon from "@mui/icons-material/Edit";
import { getProfile } from "../../../helpers/dataFetcher/profile/profile";
import FileViewer from "react-file-viewer";
import {
  updateDeleteProfile,
  updateProfileNormal,
  updateSkill,
} from "../../../helpers/dataFetcher/profile/update";
import FeatherIcon from "feather-icons-react";
import { imageURL } from "../../../Constants/commonURLS";
import { format } from "date-fns";
import { Col, Row } from "reactstrap";
import { convertintoStringDates } from "../../../functions/date";
import { deepPurple } from "@mui/material/colors";
import { Avatar, CircularProgress, IconButton, Skeleton } from "@mui/material";
import { toast } from "react-hot-toast";
import Divider from "@mui/material/Divider";
import { Button, CssVarsProvider } from "@mui/joy";
import Close from "@mui/icons-material/Close";
import Chip from "@mui/joy/Chip";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tooltip from "@mui/material/Tooltip";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import Stack from "@mui/material/Stack";
import UploadFileModal from "../../../Reusable/FileUpload";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { resumeParser } from "../../../helpers/dataFetcher/resume/resume_parser";
import { updateKeySkills } from "../../../helpers/dataFetcher/jobAttributes/keyskills";
import { useSelector } from "react-redux";

// const getFileExtension = (url) => {
//   const parts = url.split(".");
//   return parts[parts.length - 1].toLowerCase();
// };

// const getFileType = (extension) => {
//   if (extension === "pdf") {
//     return "pdf";
//   } else if (extension === "doc" || extension === "docx") {
//     return "docx";
//   } else if (extension === "xls" || extension === "xlsx") {
//     return "xlsx";
//   } else if (extension === "csv") {
//     return "csv";
//   }
//   // Add more file type checks as needed

//   // Default to "file" type if the extension is not recognized
//   return "file";
// };

function ProfileHeader(props) {
  const [tabValue, setTabValue] = useState(0);
  const [status, setStatus] = useState(false);
  const [resumeLoad, setResumeLoad] = useState(false);
  const [preview, setPreview] = useState(false);
  const [resumePre, setResumePre] = useState();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [profileModal, setProfileModal] = useState({
    modal: false,
    type: "",
    action: "",
    modalType: "",
    details: {},
    file: "",
  });

  const [model, setModel] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const userData = useSelector(state => state.User);
  // const fileLink = userDetails ? `${imageURL}${userDetails.resume_url}` : "";
  // const fileExtension = getFileExtension(fileLink);
  // const fileType = getFileType(fileExtension);

  // const filePath = userDetails ? `${imageURL}${userDetails.resume_url}` : "";
  // const fileExtension = filePath.split('.').pop().toLowerCase();

  const supportedFileTypes = ["pdf", "docx", "xlsx", "csv"];
  const filePath = userDetails ? `${imageURL}${userDetails.resume_url}` : "";

  let fileType = supportedFileTypes.find(type => {
    const fileExtension = filePath.split(".").pop().toLowerCase();
    return supportedFileTypes.includes(fileExtension);
  });

  // If no supported file type is found, set a default fileType
  if (!fileType) {
    fileType = "docx";
  }

  useEffect(() => {
    dataFetcher();
  }, []);

  async function dataFetcher() {
    const { data, status } = await getProfile();
    setUserDetails(data);
    if (status === 200) {
      setStatus(true);
    }
  }

  async function handleImage(data) {
    const url = await getPreSignedURL(data);
  }

  const visaStatus = [
    { status: "Administrative processing", exp_date: "11 Sep 2022" },
  ];

  async function removeExperience(id) {
    const newArray = userDetails.work_exp_details.filter(
      item => item.is_active === true
    );

    const filterExperience = newArray.filter(each => {
      if (each.id !== id) {
        return { ...each };
      }
    });

    const updatedOne = filterExperience.map(item => {
      return {
        title: item.title,
        company: item.company,
        description: item.description,
        location: item.location,
        notice_period:
          item?.notice_period === null ? 0 : Number(item.notice_period),
        is_currently_working: item.is_currently_working,
        start_date: item.start_date,
        end_date:
          item.end_date === ""
            ? new Date()
            : // ? `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
              item.end_date,
      };
    });

    const { dataa, status } = await updateDeleteProfile("work_experience", {
      update_type_to_send_email: "email",
      email: userDetails.email,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      work_exp: updatedOne,
    });

    dataFetcher();
  }

  async function removeEducation(id) {
    const newArray = userDetails.education_training_details.filter(
      item => item.is_active === true
    );
    const filterExperience = newArray.filter(each => {
      if (each.id !== id) {
        return { ...each };
      }
    });

    const updatedOne = filterExperience?.map(item => {
      return {
        institute_name: item.institute_name,
        certificate_name: item.certificate_name,
        start_date: item.start_date,
        /*end_date:
          item.end_date === ""
            ? `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
        : item.end_date,*/
        end_date: item.end_date,
        description: item.description,
      };
    });

    const { dataa, status } = await updateProfileNormal("education_training", {
      update_type_to_send_email: "email",
      email: userDetails.email,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      education_training: updatedOne,
    });

    dataFetcher();
  }


  return (
    <>
      {status ? (
        <>
          <UploadModel
            show={model}
            onHide={() => {
              setModel(false);
            }}
            handleImage={handleImage}
          />
          <div className="profile_main_cont_shrink2 ShifytScroll">
            <div className="profile_card profile_card_2">
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2">
                  <div>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <Avatar
                        alt="avatar"
                        src={`${imageURL}${userDetails?.profile_image}`}
                        // sx={{ width: 38, height: 38, bgcolor: deepPurple[500] }}
                        sx={{
                          width: 80,
                          height: 80,
                          background: "#cde2e7",
                          color: "#066a4c",
                        }}
                        onClick={() =>
                          setProfileModal({
                            modal: "center",
                            type: "imageUpload",
                          })
                        }
                      >
                        <span className="profile_letter_txt">
                          {userDetails.first_name?.charAt(0) +
                            "" +
                            userDetails.last_name?.charAt(0)}
                        </span>
                      </Avatar>
                    </IconButton>
                  </div>
                  <div className="pt-2 user_socila_links">
                    <p className="user_title_name">
                      {userDetails.first_name} {userDetails.last_name}
                    </p>

                    <div className="user_designation">
                      <p>{userDetails?.job_role ? userDetails.job_role : ""}</p>
                    </div>
                    <div className="user_designation">
                      {/* <p>{userDetails?.job_role ? userDetails.email : ""}</p> */}
                      <p>{userData?.user?.email?userData?.user?.email:""}</p>
                    </div>

                    <div className="user_designation">
                      {/* <p>{userDetails?.job_role ? userDetails.phone_number : ""}</p> */}
                      <p>{userData?.user?.phone_number?userData?.user?.phone_number:""}</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            {/* <div>
              <div className="profile_category_desc">
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Resume</h2>
               
              </div>

             

              <div className="about_card">
                <div className="resume_main_cont">
                  <div className="col-sm-12 col-md-5 col-lg-5">
                    <div className="resume_url_cont">
                  
                      {userDetails?.resume_url ? (
                        <p
                          style={{
                            fontSize: "10px",
                            paddingTop: "3%",
                            paddingLeft: "3%",
                          }}
                        >{`${imageURL}${userDetails?.resume_url}`}</p>
                      ) : (
                        <p
                          style={{
                            fontSize: "10px",
                            paddingTop: "3%",
                            paddingLeft: "3%",
                          }}
                        ></p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-7 col-lg-7">
                    <div className="resume_name_cont">
                      <Button
                        type="button"
                        variant="outlined"
                        className="user-desc"
                        style={{
                          background: "#cde2e7",
                          color: "#066a4c",
                          borderRadius: "8px",
                          height: "48px",
                          marginRight: "5%",
                          border:"0px"
                        }}
                        onClick={async () => {
                          const input_field = document.createElement("input");
                          input_field.type = "file";
                          input_field.accept = "application/*";
                          input_field.click();
                          input_field.onchange = async e => {
                            setResumeLoad(true);
                            const file = e.target.files;
                            setResumePre(
                              URL.createObjectURL(e.target.files[0])
                            );
                            setPreviewUrl(
                              URL.createObjectURL(e.target.files[0])
                            );
                          
                            const parseData = await resumeParser(
                              file[0],
                              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ilh5Zyt4flUlPklQU1MtO2oiLCJpYXQiOjE2NzAyMzY4NzN9.f86Y2GG0a9eQENcKPpfB7tZHNsNFWvuME9WbqPBfonU"
                            );
                          
                           
                            const data = await getPreSignedURL(file);
                            const { status } = await updateProfileNormal(
                              "resume",
                              {
                                resume_file: data.replace(imageURL, ""),
                                first_name: userDetails.first_name,
                                last_name: userDetails.last_name,
                                email: userDetails.email,
                                update_type_to_send_email: "email",
                              }
                            );
                            if (status === 200) {
                              setResumeLoad(false);
                              const { data } = await getProfile();
                              setUserDetails(data);
                            } else {
                              toast.error("Something went wrong");
                            }
                          };
                        }}
                      >
                        <span>
                          {!resumeLoad ? (
                            <>
                             
                              {userDetails?.resume_url === null
                                ? "Add"
                                : "Update"}{" "}
                              resume
                            </>
                          ) : (
                            <CircularProgress thickness={1} color="primary" />
                          )}
                        </span>
                      </Button>

                      <Tooltip title="Preview">
                        <VisibilityOutlinedIcon
                          style={{ marginRight: "5%", size: "8" }}
                          onClick={() => {
                            setPreview(!preview);
                            setProfileModal({
                              
                            });
                          }}
                        />
                      </Tooltip>
                     

                      <Tooltip title="Download">
                        <FeatherIcon
                          icon="download"
                          size="18"
                          style={{
                            color: "#000000",
                            cursor: "pointer",
                            marginRight: "5%",
                          }}
                         
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href = `${imageURL}${userDetails?.resume_url}`;
                            link.download = "resume.pdf";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                        />
                      </Tooltip>

                    
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {preview && (
              <div style={{ width: "100%", height: "350px", overflow: "auto" }}>
                <FileViewer
                  fileType={fileType}
                  filePath={`${imageURL}${userDetails?.resume_url}`}
                />
              </div>
            )}
          </div>
          <ProfileModal
            setProfileModal={doc => setProfileModal(doc)}
            show={profileModal.modal}
            onHide={data => {
              setProfileModal({
                modal: data,
                modalType: "",
                type: "",
                id: "",
                details: {},
              });
            }}
            params={profileModal}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
          />
        </>
      ) : (
        <>
          {Array.from(new Array(15)).map((item, index) => (
            <div style={{ paddingLeft: "50px" }}>
              <div className="card-employers" id="card-employers" key={index}>
                <div className="align-items-center comp_card_main_cont">
                  <div className="comp_employers_logo">
                    <div
                      className="comp_profile_card_cont"
                      id="comp_profile_card_cont"
                    >
                      <Skeleton variant="rectangular" width={80} height={80} />
                    </div>
                  </div>
                  <div className="employer_name_cont">
                    <p className="employer_name">
                      <span>
                        <strong className="comp_name">
                          <Skeleton width="100%" />
                        </strong>
                      </span>
                    </p>
                    <div className="d-flex comp_position_cont">
                      <Skeleton width="100%" />
                    </div>
                    <div className="comp_specs_cont">
                      <Skeleton width="100%" />
                    </div>
                  </div>
                  <div className="comp_employers_redirection_cont"></div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default ProfileHeader;
