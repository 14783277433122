import callApi from "../../callApi";

// export const getAllCompanies = async (name, location,id) => {
//   return await callApi(
//     `common/get_companies_list?name=${name}&location=${location}`
//   );
// };

let userId = localStorage.getItem("user_id")

export const getAllCompanies = async (name, location, id) => {
  let url = `common/get_companies_list?name=${name?name:""}&location=${location?location:""}`;

  if (id) {
    url += `&industry=${id}`;
  }

  return await callApi(url);
};




export const getCompanyById = async id => {
  return await callApi(`company_authorise/${id}`);
};

export const getCompanyJobsById = async id =>{
  return await callApi(`user_job_post_update/company_job_posts/${id}`)
  // return await callApi(`user_job_post_update/company_job_posts/${id}?user_id=${userId}`)
}


/* Get Industires List GET Method test @seshu */

export const getCompanyIndustires = async ()=>{
  return await callApi(`referral_partner_authorise/industries_list`)
}

/* Get Industires List GET Method test @seshu */

/* Get Industires List based on ID GET Method test @seshu */

export const getCompanyIndustryById = async (id)=>{
  return await callApi(``)
}

/* Get Industires List based on ID GET Method test @seshu */

/*Get All comapny locations GET Method test start here @seshu */
export const getAllCompanyLocations = async ()=>{
  return await callApi('')
}
/*Get All comapny locations GET Method test end here @seshu */

/*Get All comapny Experience level GET Method test start here @seshu */
export const getExpLevel = async()=>{
  return await callApi('')
}
/*Get All comapny Experience level GET Method test End here @seshu */

/*Get All New companies List POST Method start here testing @seshu */
export const findAllNewCompanies = async()=>{
  return await callApi('',)
}
/*Get All New companies List POST Method End here testing @seshu */
