import { useEffect } from "react";
import LayoutUI from "../layouts/LayoutUI";
import { Navigate, redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AuthCheckRoutes({
  path,
  Component,
  children,
  ...rest
}) {
  let location = useLocation();
  const user = localStorage.getItem("token");
  if (user === "" || user === null || user === undefined)
    return <Navigate to="/login" state={{ from: location }} replace />;

  return <LayoutUI>{children}</LayoutUI>;
}
