import React, { useEffect } from "react";
import { allCategories } from "../../helpers/dataFetcher/blogs";
import { useSearchParams } from "react-router-dom";

function WelcomeText({ categories }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <div className="archive-header pt-50 pb-50 text-center">
        <div className="container">
          <h3 className="mb-30 text-center w-75 mx-auto">
            Relevant news and more for you. Welcome to our blog
          </h3>
          <div className="text-center">
            <div className="sub-categories">
              {categories?.map(item => (
                <span
                  className="btn btn-tags-sm mb-10 mr-5"
                  onClick={() => {
                    setSearchParams({ c_id: item?.id });
                  }}
                >
                  {item?.name}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="post-loop-grid">
        <div className="container">
          <div className="row">
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WelcomeText;
