import React from "react";
import Recruiting from "../JobsList/JobsListSidePanel/Recruiting";
import SetReminder from "../JobsList/JobsListSidePanel/SetRemainder";
import SidePanelFilters from "../JobsList/JobsListSidePanel/SidePanelFilter";
import SignUpForJobs from "../JobsList/SignUpForJobs/SignUpForJobs";
import MainSearchFilterBar from "../MainSearchFilterBar/MainSearchFilterBar";
import OurPartners from "../OurClients/OurPartners";
import CandidateLists from "./CandidateList";

function CandidateIndex() {
    return (
        <>
            <MainSearchFilterBar />
            <div className="section-box mt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
                            <SetReminder />
                            <SidePanelFilters />
                            <Recruiting />
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <CandidateLists />
                        </div>
                    </div>
                </div>
            </div>
            <OurPartners />
            <SignUpForJobs />
        </>
    )
}
export default CandidateIndex;