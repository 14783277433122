import { useEffect } from "react";

export default function checkMouseDown({ pickerRef, setShowPicker }) {
  useEffect(() => {
    const handleClick = e => {
      if (pickerRef.current && !pickerRef.current.contains(e.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  });
  return {};
}
