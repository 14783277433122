import React from "react";

function RecruiterProfile() {
    return (
        <>
            <div className="author-bio p-30 mt-50 border-radius-15 bg-white">
                <div className="author-image mb-15">
                    <a href="author.html">
                        <img src="assets/imgs/avatar/ava_14.png" alt="profile"
                            className="avatar" /></a>
                    <div className="author-infor">
                        <h5 className="mb-5">Steven Job</h5>
                        <p className="mb-0 text-muted font-xs">
                            <span className="mr-10">306 posts</span>
                            <span className="has-dot">Since 2012</span>
                        </p>
                    </div>
                </div>
                <div className="author-des">
                    <p>Hi, I'm a recruiter with over 25 years of experience. I have worked in many
                        multinational companies and corporations. With my experiences, I hope my
                        articles will bring you knowledge and inspiration.</p>
                </div>
            </div>
        </>
    )
}
export default RecruiterProfile;