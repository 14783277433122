import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import "./RegisterSlide.css";
import Carousel from "react-bootstrap/Carousel";

function RegisterSlides() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="reg_slides_main">
      <Row className="reg_slides_header_text">
        <Col xs={12} className="reg_slides_header_col">
          <img src="https://app.Shifyt.com/static/media/logosmallwhite.3830c335.png" className="reg_slides_header_img" />
          <h4>Shifyt</h4>
        </Col>
      </Row>
      <Row className="reg_slides_text">
        <Col xs={12} className="reg_slides_text_col">
          <h5>A few click away from creating your Shifyt account</h5>
        </Col>
      </Row>
      <Row className="reg_slides_cont">
        <Col xs={12}>
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item className="slides_cont">
              <img
                className="d-block w-100 slides_images"
                src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                alt="First slide"
              />
              <Carousel.Caption>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="slides_cont">
              <img
                className="d-block w-100 slides_images"
                src="https://images.unsplash.com/photo-1589561253898-768105ca91a8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80"
                alt="Second slide"
              />

              <Carousel.Caption>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="slides_cont">
              <img
                className="d-block w-100 slides_images"
                src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                alt="Third slide"
              />

              <Carousel.Caption>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="slides_cont">
              <img
                className="d-block w-100 slides_images"
                src="https://images.unsplash.com/photo-1582005450386-52b25f82d9bb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                alt="Third slide"
              />

              <Carousel.Caption>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </div>
  );
}

export default RegisterSlides;
