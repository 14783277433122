import React from "react";
import { Chrono } from "react-chrono";

function VerticalChrono() {
  const items = [
    {
        title: (
            <div>
              <img src="http://someurl/image.jpg" alt="Image" />
              <h3>Title Here</h3>
              <p>Some content here...</p>
            </div>
          ),
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      media: {
        type: "IMAGE",
        source: {
          url: "http://someurl/image.jpg",
        },
      },
    },
    // Add mirrored item for left and right side
    {
      title: "May 1940",
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      media: {
        type: "IMAGE",
        source: {
          url: "http://someurl/image.jpg",
        },
      },
      style: {
        backgroundColor: "lightgrey",
        color: "black",
        border: "1px solid black",
      },
      mediaPosition: "LEFT", // To position the media on the left side
      cardAlign: "LEFT", // To align the card text on the left side
    },
  ];

  return (
    <>
      <div className="Shifyt_work_section">
        <div style={{ marginLeft: "20px" }}>
          <Chrono
            items={items}
            mode="VERTICAL_ALTERNATING"
            hideControls={false}
            allowDynamicUpdate={false}
            useReadMore={false}
            enableBreakPoint
            verticalBreakPoint={400}
            theme={{
                primary: '#afeae4',
                secondary: '#afeae4',
                cardBgColor: '#afeae4',
                titleColor: 'black',
                titleColorActive: '#afeae4',
              }}
          />
        </div>
      </div>
    </>
  );
}

export default VerticalChrono;

