import { DELETE, POST, PUT } from "../../../Constants/methods";
import callApi from "../../callApi";

export async function createKeySkills(data) {
  const item = { name: data.name };
  await callApi("job_attributes/key_skills", item, POST);
}
export async function getAllKeySkills() {
  return await callApi("job_attributes/key_skills");
}
export async function updateKeySkills(sample, id) {
  const items = { name: sample.name };
  const data = await callApi(`job_attributes/key_skills/${id}`, items, PUT);
  if (data.status !== 200) return true;
  return false;
}
export async function getSingleKeySkill(id) {
  const data = await callApi(`job_attributes/key_skills/${id}`);
  return data.data;
}
export async function deleteKeySkill(id) {
  await callApi(`job_attributes/key_skills/${id}`, {}, DELETE);
}
