import React from "react";
import { useNavigate } from "react-router-dom";

function Successfull() {
    const navigate = useNavigate();

    return (
        <>
            <div className="container" style={{ marginTop: "90px" }}>
                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-3"></div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="card p-5 border-0 mt-5">
                            <div className="card p-4 mb-3" style={{ backgroundColor: "rgba(63, 19, 228, 0.05)", borderRadius: "12px" }}>
                                <div className="media d-flex mb-2">
                                    <img className="mr-3"
                                        src="https://res.cloudinary.com/dt6u679fs/image/upload/v1660649939/photo-1440778303588-435521a205bc_xxnshn.avif"
                                        alt="success"
                                        style={{ borderRadius: "20px", height: "50px", width: "50px" }}
                                    />
                                    <div className="media-body" style={{ marginLeft: "10px" }}>
                                        <h5 className="mt-0" style={{ fontSize: "16px" }}>Dileep_Resume.pdf</h5>
                                        <div className="small">54KB.14-aug-22</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center ">
                                <img src="assets/imgs/theme/illustration.svg" style={{ width: "300px", height: "250px" }} alt="success"/>
                            </div>
                            <div className="d-flex justify-content-center">
                                <p className="mb-2 success-text ">You have Successfully Referred for the Job</p>
                            </div>
                            <p className="small text-center text-success font-weight-bold mb-3">Congratulations, your application has been sent </p>

                            <button
                                type="submit"
                                className="btn d-block btn-default btn-shadow ml-40 hover-up"
                                onClick={() => navigate('/jobs-list')}
                            >Find more jobs</button>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-3"></div>
                </div>
            </div>
        </>
    )
}

export default Successfull