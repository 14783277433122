import React, { useState } from 'react';
import { Modal } from "react-bootstrap";

export const PasswordModal = (props) => {
    const [shown, setShown] = useState(false);
    const [value, changeValue] = useState("");

    return (
        <>
            <Modal {...props} backdrop="static" className="custom-delete-modal mt-5 pt-5" keyboard={false}>
                <Modal.Header
                    className="border-0 px-4 bg-transparent close-modal"
                    closeButton>
                </Modal.Header>
                <Modal.Body className="pt-0" style={{ padding: "30px" }}>
                    <div className="d-flex justify-content-center mb-3">
                        <img
                            style={{ width: "70px" }}
                            alt="Shifyt"
                            src="https://res.cloudinary.com/dt6u679fs/image/upload/v1666179835/confirm_password_ljwonu.svg" />
                    </div>

                    <div className="input-group mb-3">
                        <input
                            type={shown ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter password"
                            value={value}
                            onChange={e => changeValue(e.target.value)}
                        />
                        <span className="input-group-text"
                            onClick={() => setShown(!shown)}
                        >
                            {shown ? <span>Show</span> : <span>hide</span>}
                        </span>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-primary'>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};