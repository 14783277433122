import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { WS_URL } from "../helpers/sockets";
import { get_chat_active_user_list } from "../helpers/sockets";

export default function SocketConnection() {
  const user = useSelector(state => state?.User?.user);
  const [socketURL, setSocketURL] = useState("");
  const [status, setStatus] = useState(true);

  // useEffect(() => {
      
  //   if (user?.id !== null || user?.id !== undefined || user?.id !== "")
  //     setSocketURL(WS_URL + user?.id);
  //   else {
  //     setSocketURL(null);
  //   }
  // }, [user?.id]);

  
  useEffect(() => {
      if (Boolean(user?.id))
      (async () => {
        const activeUsersList = await get_chat_active_user_list();
        const extractMatchId = activeUsersList?.data?.filter(each => each.userId === user?.id);
       
        if (extractMatchId?.length > 0) {      
          const connectionId = extractMatchId[0]?.connectionId; // Assuming connectionId is present in the first element of extractMatchId
         
          setSocketURL(WS_URL + user?.id + `&connectionId=${connectionId}`);
        } else {
          setSocketURL(WS_URL + user?.id);
        }
      })();
    else {
      setSocketURL(null);
      setStatus(false)
    }
    
  }, [user?.id]);


  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    socketURL || WS_URL,
    {
      share: true,
      shouldReconnect: () => true,
    },
    status
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  return {
    connectionStatus,
    sendMessage,
    lastMessage,
    readyState,
    getWebSocket,
  };
}
