import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
  SecondaryTypography
} from "../../../assets/MUI/materialUiStyles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
// import SearchParamHook from "hooks/searchParams";

export default function WorkAuthInformation({ candidateDetails,fetchUserJobPostDetails,setRecall,reCall }) {
  const [candidate, setCandidate] = useState({});
  const [visaStatus, setVisaStatus] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setCandidate(candidateDetails);
  }, [candidateDetails]);

 

//  const visaAvail = candidate?.visa_details?.map((each)=>each.visa)
//  const visaFrom = candidate?.visa_details?.map((each)=>each.from)
//  const visaTo = candidate?.visa_details?.map((each)=>each.to)
//  const visaType = candidate?.visa_details?.map((each)=>each.type)


const visaAvail = candidate?.visa_details?.length > 0?candidate?.visa_details?.map((each)=>each.visa):"N/A"
const visaFrom = candidate?.visa_details?.length >0?candidate?.visa_details?.map((each)=>each.from):"N/A"
const visaTo = candidate?.visa_details?.length >0 ?candidate?.visa_details?.map((each)=>each.to):"N/A"
const visaType = candidate?.visa_details?.length >0?candidate?.visa_details?.map((each)=>each.type):"N/A"


  return (
    <Stack spacing={2} className="mt-3">
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
         sx={{
          borderTopRightRadius: `5px`,
          borderBottomRightRadius: `5px`,
          height: "30px !important",
          paddingLeft: "4px",
        }}
          orientation="vertical"
          flexItem
        />
       <SecondaryTypography component="h3" variant="h3">
          Work Authorization
        </SecondaryTypography>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={1}>
            <InputButtonField
              text_field={"Country"}
              input_value={candidate?.nationality?candidate.nationality:"India"}
              type="nationality"
              update_type={"nationality"}
              wholeData = {candidate}
              setRecall={setRecall}
            />

            <InputButtonField
              text_field={"Visa status"}
              input_value={visaAvail}
                // input_value={"yes"}
              type="visa"
              update_type={"visa"}
              setVisaStatus={setVisaStatus}
              candidateDetails={candidate}
              wholeData = {candidate}
              setRecall={setRecall}
              reCall={reCall}
              // fetchUserJobPostDetails={fetchUserJobPostDetails}
            />

            {visaStatus == "Yes" || visaAvail == "Yes"? (
              <>
                <InputButtonField
                  text_field={"From"}
                  //  input_value={"12/03/2019"}
                  input_value={visaFrom?visaFrom:"N/A"}
                  type="date"
                  update_type={"from"}
                  candidateDetails={candidate}
                  wholeData = {candidate}
                  setRecall={setRecall}
                  reCall={reCall}
                />
                <InputButtonField
                  text_field={"To"}
                  // input_value={"12/03/2019"}
                  input_value={visaTo?visaTo:"N/A"}
                  type="date"
                  update_type={"to"}
                  candidateDetails={candidate}
                  wholeData = {candidate}
                  setRecall={setRecall}
                  reCall={reCall}
                />
                <InputButtonField
                  text_field={"Type"}
                  // input_value={"H1B Visa"}
                  input_value={visaType?visaType:"N/A"}
                  type="file"
                  update_type={"file"}
                  candidateDetails={candidate}
                  wholeData = {candidate}
                  setRecall={setRecall}
                />
              </>
            ) : (
              ""
            )}
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}
