import React, { useState } from "react";
import { Col, Row } from "reactstrap";

function OverViewPage1({cProfile}) {
  
  return (
    <>
      <div>
        <h2 className="benefits_txt">{cProfile?.company?.company_name?cProfile?.company?.company_name:"NA"}</h2>
        {/* <p className="company_para_text">
          Digit is an IRDA licensed internet first general insurer launched by
          Fairfax Holdings and Kamesh Goyal, a former executive from Allianz.
          Fairfax holds 45% stake in the company. Offers insurance for cars,
          mobile handsets, travel, and jewellery.
        </p> */}
      
        <div className="comp_about_card">
          <Row
            className="user-desc"
            md="3"
            sm="2"
            xs="1"
            // sx={{ padding: "40px",}}
          >
            <Col xs={6} className="about_comp_details">
              <p>Company Size</p>
              <p>{cProfile?.company?.size_of_company?cProfile?.company?.size_of_company:"NA"}</p>
            </Col>
            {/* <Col xs={6} className="about_comp_details">
              <p>Founded</p>
              <p>2016</p>
            </Col> */}
            <Col xs={6} className="about_comp_details">
              <p>Headquarters</p>
              {/* <p>{cProfile?.company?.city?cProfile?.company?.city:"NA"}</p> */}
              <p>{cProfile?.company?.location?cProfile?.company?.location:"NA"}</p>
            </Col>
          </Row>
        </div>
        </div>
    
    </>
  );
}

export default OverViewPage1;
