import React from "react";
import Modal from "@mui/material/Modal";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./canvasPreview";
import { Button, CssVarsProvider } from "@mui/joy";
class CropModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
      selectedFiles: [],
    };
    this.state = {
      scale: 1,
      rotate: 0,
      aspect: 1 / 1,
      loading: false,
      crop: undefined,
      completedCrop: undefined,
    };
    this.imageRef = React.createRef();
    this.previewCanvasRef = React.createRef();
    this.onImageLoad = this.onImageLoad.bind(this);
    this.centerAspectCrop = this.centerAspectCrop.bind(this);
  }
  centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  onImageLoad(e) {
    if (this.state.aspect) {
      const { width, height } = e.currentTarget;
      this.setState({
        crop: this.centerAspectCrop(width, height, this.state.aspect),
      });
    }
  }
  componentDidUpdate(_, prevProps) {
    setTimeout(() => {
      if (
        prevProps.completedCrop !== this.state.completedCrop &&
        this.state.completedCrop?.width &&
        this.state.completedCrop?.height &&
        this.imageRef.current &&
        this.previewCanvasRef.current
      ) {
        canvasPreview(
          this.imageRef?.current,
          this.previewCanvasRef?.current,
          this.state.completedCrop,
          this.state.scale,
          this.state.rotate
        );
      }
    }, 100);
  }

  render() {
    const { isOpen, toggle, handleImage, imageSrc } = this.props;
    return (
      <div
        style={{
          maxHeight: "300px",
          maxWidth: "300px",
        }}
      >
        {!this.state.loading ? (
          <>
            <div className="modal-body">
              {!!imageSrc && (
                <div style={{ zIndex: "999" }}>
                  <ReactCrop
                    crop={this.state.crop}
                    onChange={c => this.setState({ crop: c })}
                    onComplete={c => this.setState({ completedCrop: c })}
                    aspect={this.state.aspect}
                  >
                    <img
                      ref={this.imageRef}
                      alt="Crop me"
                      src={imageSrc}
                      onLoad={this.onImageLoad}
                      //   style={{ maxWidth: "400px", maxHeight: "800px" }}
                    />
                  </ReactCrop>
                </div>
              )}
            </div>
            <div className="modal-footer gap-2">
              <CssVarsProvider>
                <Button
                  variant="solid"
                  type="button"
                  onClick={() => {
                    console.log("hielo");
                    toggle();
                  }}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  style={{
                    margin: "3px",
                    background: "#ffe7bb",
                    color: "#066a4c",
                  }}
                >
                  Close
                </Button>
              </CssVarsProvider>
              <CssVarsProvider>
                <Button
                  variant="solid"
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => {
                    this.setState({ loading: true });
                    const data = await fetch(
                      this.previewCanvasRef.current.toDataURL(this.props.type)
                    )
                      .then(r => r.blob())
                      .then(
                        blobFile =>
                          new File([blobFile], "fileNameGoesHere", {
                            type: this.props.type,
                          })
                      );
                    handleImage([data]);
                    this.setState({ loading: true });
                  }}
                  style={{
                    margin: "3px",
                    background: "#cde2e7",
                    color: "#066a4c",
                  }}
                >
                  Save Changes
                </Button>
              </CssVarsProvider>
            </div>
            <div style={{ textAlign: "center" }}>
              {!!this.state.completedCrop && (
                <canvas
                  ref={this.previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    display: "none",
                    objectFit: "contain",
                    width: this.state.completedCrop.width,
                    height: this.state.completedCrop.height,
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "200px 0px",
              display: "grid",
              placeItems: "center",
              background: "rgba(18, 38, 63, 0.03)",
            }}
          >
            <div className="spinner-border text-info m-1"></div>
          </div>
        )}
      </div>
    );
  }
}
export default CropModal;
