import React, { useState } from "react";
import { Button } from "reactstrap";
import { getPreSignedURL } from "../helpers/dataFetcher/pre_signed_urls/upload";
import UploadFileModal from "./FileUpload";
import FileUploadButton from "./FileUploadButton";
import {Form, Input} from 'reactstrap';
import './forms.css'

function Certificates() {
  const [checkBox, setCheckBox] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [data, setData] = useState([{
    certificateName: "",
    organizationName: "",
    issueDate: "",
    expiryDate: "",
    organizationId:"",
    organizationUrl: ""

  }])
  const [error, setError] = useState(false)

  const toggleModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setProfileImg(url);
    toggleModel();
  };

  const months = [
    {
      id: 1,
      name: "january",
      year: "2022",
    },
    {
      id: 2,
      name: "February",
      year: "2021",
    },
    {
      id: 3,
      name: "March",
      year: "2020",
    },
    {
      id: 4,
      name: "April",
      year: "2019",
    },
    {
      id: 5,
      name: "May",
      year: "2018",
    },
    {
      id: 6,
      name: "June",
      year: "2017",
    },
    {
      id: 7,
      name: "July",
      year: "2016",
    },
    {
      id: 8,
      name: "August",
      year: "2015",
    },
    {
      id: 9,
      name: "September",
      year: "2014",
    },
    {
      id: 10,
      name: "October",
      year: "2013",
    },
    {
      id: 11,
      name: "November",
      year: "2012",
    },
    {
      id: 12,
      name: "December",
      year: "2011",
    },
  ];

  return (
    <>
      <UploadFileModal
        isOpen={modelOpen}
        toggle={toggleModel}
        handleImage={handleImageUpload}
      />
      <Form className="certificate_form">
        
      <div className="row">
        
        <div className="col-sm-12 col-md-6 col-lg-6">
          <label>Certification Name</label>
          <Input
            type="text"
            
            className="form-control"
            placeholder="Ex:Python certified"
          />
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
          <label>Issuing Organization</label>
          <Input type="text" className="form-control" placeholder="Ex:Google" />
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              onChange={e => setCheckBox(e.target.checked)}
            />
            <label className="form-check-label" for="exampleCheck1">
              This credential does not expire
            </label>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
          <label>Issue Date</label>
          <Input type="date" className="form-control" />
        </div>

        {!checkBox ? (
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <label>Expiry Date</label>
            <Input type="date" className="form-control" />
          </div>
        ) : null}
      </div>

      <div className="row mb-3">
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
          <label>Credential ID</label>
          <Input type="text" className="form-control" placeholder="Ex:Google" />
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6">
          <label>Credential URL</label>
          <Input type="text" className="form-control" placeholder="Ex:Google" />
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 mt-2 mb-3">
          <Button color="success" className="p-3" onClick={toggleModel}>Upload Certificate</Button>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button className="btn btn-primary rounded_btn p-3 login_btns btn-default">Save</button>
      </div>
      </Form>
    </>
  );
}

export default Certificates;
