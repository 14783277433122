import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import styled from "@mui/material/styles/styled";
import { Typography } from "@mui/material";
import JobDrawDescription from "./JobDrawDescription";
import CompanyJobDrawDescription from "./CompanyJobDrawDescription";
import CompanyBasedDrawJob from "./CompanyBasedDrawJob";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function JobTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
      backgroundColor: "#cde2e7",
      borderWidth:"4px",
      borderRadius:"8px",
      fontFamily:"Inter"
    },
    ".MuiTab-root.Mui-selected ": {
      color:"#066a4c"
  },
  }));

  const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: 14,
    textTransform: "none",
    fontFamily:"Inter",
    letterSpacing:"-1%",
    lineHeight:"16px",
    "&:first-letter": {
      textTransform: "capitalize",
    },
  }));

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <StyledTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            //   indicatorColor="secondary"
              textColor="#081753"
          >
            <StyledTab label="Job Description"  sx={{letterSpacing:"-1%"}}{...a11yProps(0)} />
            <StyledTab label="Company" {...a11yProps(1)} />
            <StyledTab label="More Jobs" {...a11yProps(2)} />
          </StyledTabs>
        </Box>
        <TabPanel value={value} index={0}>
          <JobDrawDescription jobDetails={props.jobDetails}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
         <CompanyJobDrawDescription company={props.company}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CompanyBasedDrawJob  compJobList = {props.jobsList}/>
        </TabPanel>
      </Box>
    </>
  );
}

export default JobTabs;
