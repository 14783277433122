import React from "react";
import AboutEmployer from "../Employers/EmployerSingle/AboutEmployer";
import EmployerBranches from "../Employers/EmployerSingle/EmployerBranches";
import EmployerOffices from "../Employers/EmployerSingle/EmployerOffices";
import EmployerOverview from "../Employers/EmployerSingle/EmployerOverview";
import EmployerNavbarProfile from "../Employers/EmployerSingle/EmployerProfile";
import EmployerVacancies from "../Employers/EmployerSingle/EmployerVacancies";
import SignUpForJobs from "../JobsList/SignUpForJobs/SignUpForJobs";
import JobsOpening from "../Reusable/JobsOpening";

function CandidateSingle() {
    const routeName = window.location.pathname
    return (
        <>
            <EmployerNavbarProfile />
            <section className="section-box mt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <AboutEmployer routeName={routeName} />
                            <EmployerBranches routeName={routeName} />
                            <EmployerOffices routeName={routeName} />
                            <EmployerVacancies routeName={routeName} />
                            <JobsOpening routeName={routeName} />
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
                            <EmployerOverview />
                        </div>
                    </div>
                </div>
                <SignUpForJobs />
            </section>
        </>
    )
}
export default CandidateSingle;