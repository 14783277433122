import React from "react";
import { Container } from "reactstrap";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import WithdrawMoney from "./WithdrawMoney";

function MyMoney() {
  return (
    <>
      <div className="mb-3 profile_main_cont">
        <Container fluid className="chat_main_cont">
          <div className="row">
            <div className="col-sm-12 col-md-2 col-lg-2 profile_left_sidebar">
              <Sidebar />
            </div>
            <div className="col-sm-12 col-md-10 col-lg-10">
              <div className="leadership-bg p-4 mb-4">
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <h4 className="leadership-text text-center ">
                      Withdraw fund
                    </h4>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 border-left">
                    <div className="text-center">
                      <h4 className="leadership-text text-center text-primary">
                        $ 0.000 USD
                      </h4>
                      <p className="leadership-desc text-center">
                        Total commission earned
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <WithdrawMoney />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default MyMoney;
