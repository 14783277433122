import React from "react";
import SignUpForJobs from "../JobsList/SignUpForJobs/SignUpForJobs";
import AboutUsExplore from "./AboutsUsPages/AboutUsExplore";
import AboutUsJoBoard from "./AboutsUsPages/AboutUsJobBoard";
import AboutUsResult from "./AboutsUsPages/AboutUsResult";
import Categories from "./AboutsUsPages/Categories";
import FromBlogs from "./AboutsUsPages/FromBlogs";
import HappyCustomer from "./AboutsUsPages/HappyCustomer";
import OurTeam from "./AboutsUsPages/OurTeam";
import FooterSection from "./AboutsUsPages/Footer";
import Footer from '../../layouts/footer/Footer'

function AboutUs() {
    return (
        <>
        <div style={{marginTop:"-50px"}}>
            <AboutUsJoBoard />
            <AboutUsExplore />
            <Categories />
            <Footer />
            {/* <AboutUsResult />
            <OurTeam />
            <HappyCustomer />
            <FromBlogs />
            <SignUpForJobs />
            <FooterSection /> */}
            </div>
        </>
    )
}

export default AboutUs;