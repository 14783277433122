import React from "react";

function UserShortInfo() {
    return (
        <>
            <div className="card p-3 border-0 recent-activities mb-4">
                <div className="d-flex align-items-center mb-4">
                    <div className="flex-shrink-0">
                        <img src="https://res.cloudinary.com/dt6u679fs/image/upload/v1660220048/pic2_mkait2.jpg"
                            alt="notificaion-icon"
                            style={{ width: "40px", height: "40px", borderRadius: "50px" }}
                        />
                    </div>
                    <div className="flex-grow-1 ms-3" style={{ fontFamily: "Montserrat, sans-serif" }}>
                        <div className="about-me">Murali Chowhan</div>
                        <div className="small" style={{ lineHeight: "12px" }}>
                            Lorem ipsum doler samet ulha vinjath atal bihai vajpasie.
                        </div>
                    </div>
                </div>

                <div className="card p-3 border-0 mb-4" style={{ backgroundColor: "#f7f7f7" }}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                            <div className="about-me">
                                500
                            </div>
                            <div className="small">Referred jobs</div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                            <div className="about-me">
                                300
                            </div>
                            <div className="small">Applied jobs</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card p-3 border-0 recent-activities">
                <div className="d-flex mb-4 align-items-center">
                    <div className="flex-shrink-0">
                        <img src="https://res.cloudinary.com/dt6u679fs/image/upload/v1660211938/testing_tpzeev.svg"
                            alt="notificaion-icon"
                            style={{ width: "40px", height: "40px", borderRadius: "50px" }}
                        />
                    </div>
                    <div className="flex-grow-1 ms-3" style={{ fontFamily: "Montserrat, sans-serif" }}>
                        <div className="about-me">Get the Shifyt app</div>
                        <div className="small" style={{ lineHeight: "12px" }}>
                            Get the Shifyt app
                            We will send you a link, open it on your phone to download the app
                        </div>
                    </div>
                </div>

                <div className="card p-3 border-0 mb-4" style={{ backgroundColor: "#f7f7f7" }}>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                        />
                        <span className="input-group-text cursor-pointer" id="basic-addon2">Share</span>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserShortInfo;