import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/joy/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function ShifytTrust() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <div className="trust_main_section">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="trust_part1">
            <h2 className="trust_txt">
              {/* See Why we are trusted
              <br />
              the world over */}
              Why Companies Trust Shifyt
            </h2>
            <p className="trust_para_txt">
              Companies partnered with Shifyt and hiring using the platform have
              seen a 10x cut in time to hire and saving around70% of the hiring
              cost & opportunity cost.
            </p>
          
            <Button
              variant="contained"
              style={{
                // background: "#FFFFFF",
                // color: "#081854",
                background: "#024430",
                color: "#ffe7bb",
                fontFamily: "Inter ,sans-serif",
                fontWeight: "700",
                textAlign: "center",
                marginTop: "20px",
                // border: "1px solid rgba(0, 0, 0, 0.57)",
                borderRadius: "8px !important",
              }}
              onClick={() =>
                window.open(
                  "https://app.Shifyt.com/login?user_type=CA",
                  "_blank"
                )
              }
            >
              About our company
              <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
            </Button>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="trust_part2">
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={6} style={{ paddingTop: "20px" }}>
                  <div className="btn_container1">
                    <div className="btn_cont">
                      <h2 className="trust_txt1">Dedicated Account Manager</h2>
                      {/* <p className="jobs_posted_txt">An account manager is tagged to handle your queries</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="btn_container2">
                    <div className="btn_cont">
                      <h2 className="trust_txt1">Free Unlimited Job Postings</h2>
                      {/* <p className="jobs_posted_txt">Post limitless job on the platform absolutely free</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: "20px" }}>
                  <div className="btn_container3">
                    <div className="btn_cont">
                      <h2 className="trust_txt1">Transparency</h2>
                      {/* <p className="jobs_posted_txt">Shifyt maintains end-to-end transparency throughout the hiring process</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="btn_container4">
                    <div className="btn_cont">
                      <h2 className="trust_txt1">Risk Free Hiring</h2>
                      {/* <p className="jobs_posted_txt">Companies are obligated to pay after the end of probation period</p> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShifytTrust;
