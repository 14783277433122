import React from "react";
import "../../../assets/css/newsletter/index.css";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
function SignUpForJobs() {
  return (
    <section className="newsletter_cover mt-5 ">
      <form className="newsletter_main_content">
        <div className="newsleter_form_prefix">
          <MailOutlinedIcon color="gray" />
          <input
            type="email"
            className="input-newsletter"
            placeholder="enter your email here"
          />
        </div>
        <button className="newletter_send_btn">
          <VerifiedOutlinedIcon />
          <span>Subscribe</span>
        </button>
      </form>
    </section>
  );
}
export default SignUpForJobs;