import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryTypography,
  GrayDivider,
  GreyTypography,
  BlackTypography,
  PrimaryButton,
  PrimaryOpacitedButton,
  MiddleDivider,
  BorderCardWork,
  SecondaryTypography,
  PrimaryAvatar,
  ColorTypography
} from "../../../assets/MUI/materialUiStyles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
// import SearchParamHook from "hooks/searchParams";
import { useEffect, useState } from "react";
import AddEditEducation from "./AddEditEducation";
import WholeEditEducation from "./WholeEditEducation";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";

export default function EducationInformation({
  candidateDetails,
  setRecall,
  reCall
}) {
  const [educationModel, setEducationModel] = useState(false);
  const [editEducationModel, setEditEducationModel] = useState(false);
  const [updateType, setUpdateType] = useState("create");
  const [updateIndex, setUpdateIndex] = useState(0);
  const [educationalDetails, setEducationalDetails] = useState([]);
  const [wholeDetails, setWholeDetails] = useState();
  useEffect(() => {
    setWholeDetails(candidateDetails);
    if (candidateDetails?.courses) {
      setEducationalDetails(candidateDetails?.courses);
    }
  }, [candidateDetails]);
  const [userJobPostId, setUserJobPostId] = useState(null);
  //   const searchParams = SearchParamHook();
  //   useEffect(() => {
  //     let queryParamsForUserJobPostId = searchParams.get("user_job_post_id");
  //     if (queryParamsForUserJobPostId)
  //       setUserJobPostId(queryParamsForUserJobPostId);
  //   }, [searchParams]);
  function toggleModel() {
    setEducationModel(!educationModel);
  }
  function editToggleModel() {
    setEditEducationModel(!editEducationModel);
  }

  async function updateOrEditEducationalDetails(ed_data,checkMark) {
    toggleModel();
   

    if (updateType?.includes("update")) {
      const obj1 = { ...ed_data,is_currently_pursuing:checkMark }
      const data = [...educationalDetails];
      data[updateIndex] = {
        ...obj1,
      };

      setEducationalDetails(data);
      const obj = {
        last_name: wholeDetails?.last_name,
        first_name: wholeDetails?.first_name,
        email: wholeDetails?.email,
        courses: [...data],
        update_type_to_send_email: "email",
      };
      let o = Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );

      await updateUserProfileDetails("about_me", o);
      setTimeout(() => {
        setRecall(!reCall);
      }, [1000]);
    }else {
        /* start here */
    const obj = {
      last_name: wholeDetails?.last_name,
      first_name: wholeDetails?.first_name,
      email: wholeDetails?.email,
      courses: [...educationalDetails, ed_data],
      update_type_to_send_email: "email",
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
    /*End here */

    await updateUserProfileDetails("about_me", o);
    
    setTimeout(() => {
      setRecall(!reCall);
    }, [1000]);
    }



  
  }

  /* Remove work experience code start here */
  const removeEducation = async id => {
   
    const filterEducation = educationalDetails?.filter((each, index) => {
      if (index !== id) {
        return { ...each };
      }
    });

    const obj = {
      last_name: wholeDetails?.last_name,
      first_name: wholeDetails?.first_name,
      email: wholeDetails?.email,
      courses: [...filterEducation],
      update_type_to_send_email: "email",
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );

    await updateUserProfileDetails("about_me", o);

    setTimeout(() => {
      setRecall(!reCall);
    }, [1000]);
  };

  /* Remove work experience code End here */

  return (
    <Stack spacing={2} className="mt-3">
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <AddEditEducation
          toggleModel={toggleModel}
          open={educationModel}
          type={updateType}
          currentData={educationalDetails[updateIndex]}
          updateOrEditWorExperience={updateOrEditEducationalDetails}
        />
        <WholeEditEducation
          editToggleModel={editToggleModel}
          toggleModel={toggleModel}
          open={editEducationModel}
          type={updateType}
          data={educationalDetails}
          setUpdateType={setUpdateType}
          setUpdateIndex={setUpdateIndex}
          removeEducation={removeEducation}
        />
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px !important",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <SecondaryTypography component="h3" variant="h3">
          Education Section
        </SecondaryTypography>
        {/* <PrimaryOpacitedButton
          onClick={() => {
            toggleModel();
            setUpdateType("create");
          }}
          startIcon={<FeatherIcon icon="plus" size="14" />}
        >
          Add
        </PrimaryOpacitedButton> */}
        <FeatherIcon
          icon="plus"
          size="18"
          onClick={() => {
            toggleModel();
            setUpdateType("create");
          }}
        />
        <FeatherIcon
          icon="edit"
          size="16"
          onClick={() => {
            editToggleModel();
            setUpdateType("wholeeditEducation");
          }}
        />
      </Stack>
      <BorderCardWork>
        <CardContent>
          {educationalDetails?.map(
            // {[1,2,3]?.map(
            (
              { certificate, institute, start_date, end_date,desc, description,institute_image },
              EdIndex
            ) => {
              const isLastItem = EdIndex === educationalDetails.length - 1;
              return (
                <>
                 

                  {/* <Stack spacing={2} direction="row">
                    <Stack
                      style={{
                        whiteSpace: "normal",
                        width: "90%",
                        marginBottom: "15px",
                      }}
                      spacing={0.8}
                    >
                      <BlackTypography variant="h4" component="h4">
                        {certificate}
                      </BlackTypography>
                      <BlackTypography variant="h5" component="h5">
                        {institute}{" "}
                      </BlackTypography>
                      <GreyTypography variant="h6" component="h6">
                        {start_date}-{end_date}
                      </GreyTypography>
                      <BlackTypography>Description:</BlackTypography>
                      <GreyTypography contentEditable='true' dangerouslySetInnerHTML={{ __html: desc }}></GreyTypography>
                      <GreyTypography>{description}</GreyTypography>

                   

                      {!isLastItem && <MiddleDivider />}
                    </Stack>
                  </Stack> */}

                     <Stack direction={"row"} spacing={1} sx={{paddingTop:"12px"}}>
                        <Stack>
                          <PrimaryAvatar
                            variant="square"
                            sx={{ border: "none" }}
                            src={`${institute_image}`}
                            alt={`${institute?.charAt(0, 2)}`}
                          />
                        </Stack>
                        <Stack direction="column">
                        <ColorTypography variant="h3" component="h3" sx={{fontWeight:600,color:"#000000"}}>
                            {certificate}
                          </ColorTypography>
                          <ColorTypography variant="h4" component="h4">
                            {institute}{" "}
                          </ColorTypography>
                          <GreyTypography variant="h6" component="h6">
                            {start_date}-{end_date}
                          </GreyTypography>
                          <BlackTypography>Description:</BlackTypography>
                          <GreyTypography
                          variant="h6"
                          component="h6"
                          style={{ width: "100%", wordWrap: "break-word" }}
                        >
                          {desc}
                        </GreyTypography>
                        </Stack>
                      </Stack>
                      {!isLastItem && <MiddleDivider />}
                </>
              );
            }
          )}
        </CardContent>
      </BorderCardWork>
    </Stack>
  );
}
