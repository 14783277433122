import { POST } from "../../../Constants/methods";
import callApi from "../../callApi";
import { covertProfile } from "./models/profile";
async function getId() {
  return localStorage.getItem("user_id");
}
export const getProfile = async () => {
  const data = await callApi(`/common/get_user_details_based_on_token`);
  const result = await covertProfile(data.data?.data);
  return { data: result, status: data.status };
};

/* Get details based on userid */
export const getProfileById = async (id) => {
  const data = await callApi(`/referral_partner_authorise/get_user_details_based_on_id?user_id=${id}`);

  const result = await covertProfile(data?.data);
  return { data: result, status: data.status };
};
/* Get details based on userid */

export const login = async data => {
  return await callApi("/admin_authorise/login", data, POST);
};
export const signup = async data => {
  return await callApi("/referral_partner_authorise/register", data, POST);
};
export const getUserByToken = async () => {
  return await callApi("/common/get_user_details_based_on_token");
};
export const getLeaderShipBoardDetails = async () => {
  return await callApi("/referral_partner_leader_ship_board");
};
export const getUserWithToken = async data => {
  return await callApi(
    "/common/get_user_details_based_on_token",
    {},
    "GET",
    data
  );
};

export async function getUserInfo(){
  return await callApi("/common/get_user_details_based_on_token");
}


/* profile summary new url func calls start here */
export const updateUserProfileDetails = async(type,data)=>{
  return await callApi(
    `/referral_partner_authorise/${await getId()}`,
    {
      update_type: type,
      ...data,
    },
    "PUT"
  );
}

export async function getAllKeySkillsWithStatus() {
  return await callApi("job_attributes/key_skills");
}

export async function createKeySkills(data) {
  const item = { name: data.name };
  return await callApi("job_attributes/key_skills", item, POST);
}

/* profile summary new url func calls End here */