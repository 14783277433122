import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import google from "../../../assets/images/google.png";
import CompanyS from '../../../assets/images/companyS.png';
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {},
  head: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.8)",
  },

  para: {
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.75)",
  },
});

function CompaniesCulture({cProfile}) {
  const classes = useStyles();
  console.log(cProfile,"culture description")
  return (
    <>
       <h2 className="benefits_txt culture">Company culture</h2>
      <div className="comp_view_sec">
        <p style={{wordWrap:"break-word"}}>{cProfile?.company?.describe_your_culture?cProfile.company.describe_your_culture:"N/A"}</p>
        {/* <p style={{wordWrap:"break-word"}}>{cProfile?.about?cProfile.about:"N/A"}</p> */}
      </div>
    </>
  );
}

export default CompaniesCulture;
