import React, { useEffect, useState } from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import OwlCarousel from "react-owl-carousel3";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

import { findBlogsList } from "../../helpers/dataFetcher/jobs/jobs";
// import { Button } from "@mui/joy";
export default function CardsCarousel() {
  const navigate = useNavigate();
  const [slidesData, setSlidesData] = useState();
  const slides = [
    {
      id: 1,
      image:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1661770085/photo-1565688527174-775059ac429c_scqoyc.avif",
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 2,
      image:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1661766304/photo-1543269664-56d93c1b41a6_p25hxh.avif",
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 3,
      image:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1661766089/photo-1542744173-8e7e53415bb0_ypsbma.avif",
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 4,
      image:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1661766304/photo-1543269664-56d93c1b41a6_p25hxh.avif",
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 5,
      image:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1661766089/photo-1542744173-8e7e53415bb0_ypsbma.avif",
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 6,
      image:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1661766089/photo-1542744173-8e7e53415bb0_ypsbma.avif",
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
  ];
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 3,
        gap:10
      },
      1400: {
        items: 4,
        gap:10
      },
      1600: {
        items: 8,
        gap:10
      },
    },
    loop: true,
    lazyLoad: true,
    autoplay: true,
    dots: false,
    autoplaySpeed: 1000,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    nav: true,
    navText: [
      "<i className='fa fa-chevron-left'></i>",
      "<i className='fa fa-chevron-right'></i>",
    ],
  };

  useEffect(() => {
    fetchBolgsList();
  }, []);

  const fetchBolgsList = async () => {
    const response = await findBlogsList();
    setSlidesData(response.data);
  };
  const Carousel = () => (
    <div className="row align-items-center">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="p-1">
          <OwlCarousel
            nav
            loop
            {...options}
            className="owl-carousel-category owl-theme"
          >
            {/* {slidesData?.map(item => (
              <div key={item?.id} className="item">
                <div className="recent-activities">
                  <div className="latest_new_image_card">
                    <img
                      src={item.img ? item.img : slides[0]["img"]}
                      className="latest_card_img"
                      alt="img"
                    />
                  </div>
                  <div className="card-body ">
                    <h6 className="card-title latest_job_card_title mb-1 ">
                      {item.name}
                    </h6>
                    <p className="card-text latest_job_card_text mb-2 ">
                      {item.desc.slice(0, 25)}..
                    </p>
                    <span
                      onClick={() => navigate("/blogs")}
                      className="latest_jobs_link"
                    >
                      Read Now
                    </span>{" "}
                  </div>
                </div>
              </div>
            ))} */}

            {slidesData?.map((item,index) => (
              
                <Card sx={{ maxWidth: 345,margin:"2%",border:"0.5px solid #000000 !important"}} variant="outlined" key={index}>
                  <CardMedia
                    component="img"
                    alt="Img"
                    height="150"
                    // image="/static/images/cards/contemplative-reptile.jpg"
                    image={item.img ? item.img : slides[0]["image"]}
                  />
                  <CardContent style={{maxHeight:"90px",padding:"0px !important",paddingTop:"5px"}}>
                    <Typography gutterBottom variant="h1" className="card_txt_1" component="div" style={{maxHeight:"30px",overflow:"hidden",paddingLeft:"9px"}}>
                      {item.name}
                    </Typography>
                    <Typography variant="body2" className="card_para_1"  style={{maxHeight:"50px",overflow:"hidden",paddingTop:"2px",paddingBottom:"3px"}}>
                      {item.desc}
                    </Typography>
                  </CardContent>
                  <CardActions style={{maxHeight:"10px",paddingTop:"30px"}}>
                    {/* <Button size="small"  onClick={() => navigate("/blogs")}>View</Button> */}
                  </CardActions>
                </Card>
              
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Carousel />
    </>
  );
}
