import { Card, CardContent, Chip, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryChip,
  PrimaryDivider,
  PrimaryTypography,
  deleteIconContainer,
  SecondaryTypography
} from "../../../assets/MUI/materialUiStyles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import AddEditKeySkills from "./addKeySkills";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";
// import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
// import SearchParamHook from "hooks/searchParams";

export default function CandidateSkills({ candidateDetails }) {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [wholeDetails,setWholeDetails] = useState()

//   const searchParams = SearchParamHook();
  useEffect(() => {
    setWholeDetails(candidateDetails)

    if (candidateDetails?.skill_details) {
      const result = candidateDetails?.skill_details?.map(
        item => ({
          value: item.id,
          name: item.name,
        })
      );
      setSelectedSkills(result);
    }
  }, [candidateDetails]);
  async function handleDelete(skillIndex) {
    // let queryParamsForUserJobPostId = searchParams.get("user_job_post_id");
    let skill = selectedSkills;
    let updatedSkills = skill?.filter((each)=>{
      if(each.value !== skillIndex){
        return each.value
      }
    })
    const extract = updatedSkills?.map((each)=>each.value)
  //  const deleteSkills =  await updateUserJobPost({
  //     key_skills: [...extract],
  //   });

    /*Test */
    const obj = {
      last_name: wholeDetails?.last_name,
      first_name: wholeDetails?.first_name,
      email: wholeDetails?.email,
      skills: [...extract],
      update_type_to_send_email: "email",
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );

   await updateUserProfileDetails("about_me", o);
   /*test */

    setSelectedSkills([...updatedSkills]);
  }
  const [skillModel, setSkillModel] = useState(false);
  function toggleModel() {
    setSkillModel(!skillModel);
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px !important",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
       <SecondaryTypography component="h3" variant="h3">
          Skills
        </SecondaryTypography>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        style={{
          gap: "10px",
        }}
        useFlexGap
        flexWrap="wrap"
      >
        {selectedSkills?.map((skill, skillIndex) => (
        // {["ReactJs","NodeJs","Python"]?.map((skill, skillIndex) => (
          <PrimaryChip
            color="primary"
            variant="filled"
            // label={skill}
            label={skill.name}
            onDelete={() => {
              handleDelete(skill.value);
            }}
            deleteIcon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="x" size="14" />
              </div>
            }
          />
        ))}
        <PrimaryChip
          color="primary"
          variant="filled"
          label={`Add ${selectedSkills?.length? "/ Remove":""} Skills`}
          onClick={() => {
            toggleModel();
          }}
          //   onDelete={handleDelete}
          icon={
            <div style={deleteIconContainer}>
              <FeatherIcon icon="plus" size="14" />
            </div>
          }
        />
      </Stack>
      {skillModel ?<AddEditKeySkills
        toggleModel={toggleModel}
        open={skillModel}
        onSubmit={setSelectedSkills}
        currentData={selectedSkills}
        wholeDetails={wholeDetails}
      />: null}
    </Stack>
  );
}
