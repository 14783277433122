import React from "react";
import Certificates from "../../../../Reusable/Files";
import Languages from "../../../../Reusable/Languages";
import ResumePreview from '../../../../Reusable/ResumePreview';
import Skills from "../../../../Reusable/Skills";
import USVisaStatus from "../../../../Reusable/VisaStatus";
import TrainingAndEducation from "../../../../Reusable/TrainingAndEducation";
import WorkExperience from "../../../../Reusable/WorkExperience";
import Aboutus from "../../../Reusable/Profile/AboutUs";
import "./profilemodal.css";
import EditWorkExperience from "../../../../Reusable/EditWorkExperience";
import EditTrainingAndEducation from "../../../../Reusable/EditTrainingAndEducation";
import { ModalClose, ModalDialog } from "@mui/joy";
import Modal from "@mui/material/Modal";
import ProfileDescription from '../../../Reusable/Profile/ProfileDescription'
import ImageUpload from '../../../Reusable/Profile/ImageUpload';
import { Box } from "@mui/material";
function ProfileModal(props) {
  const { userDetails, setUserDetails, onHide, setProfileModal, show } = props;
  return (
    <Box>
      <Modal
        className="modal_background_white profile_modal"
        onClose={() => onHide(false)}
        sx={{
          "& .css-1htn9tb-JoySheet-root-JoyModalDialog-root": {
            background: "white",
          },
        }}
        
        open={!!show}
      >
        <ModalDialog
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center" || undefined}
        >
          <ModalClose
            // onClose={() => onHide(false)}
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
              backgroundColor:"white !important",
              borderColor:"white !important"
            }}
            onClick={() => onHide(false)}
          />
          {props.params.type === "profileDescription" ? (
            <ProfileDescription
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              onHide={onHide}
            />
          ) : null}
           {props.params.type === "imageUpload" ? (
            <ImageUpload
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              onHide={onHide}
            />
          ) : null}
           {props.params.type === "profile" ? (
            <Aboutus
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              onHide={onHide}
            />
          ) : null}
          {props.params.type === "skills" ? (
            <Skills
              onHide={onHide}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
            />
          ) : null}
          {props.params.type === "experience" ? (
            <WorkExperience
              onHide={onHide}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              setProfileModal={setProfileModal}
            />
          ) : null}
          {props.params.type === "edit-experience" ? (
            <EditWorkExperience
              id={props?.params?.id}
              onHide={onHide}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              setProfileModal={setProfileModal}
            />
          ) : null}
          {props.params.type === "us_visa" ? (
            <USVisaStatus
              onHide={onHide}
              userDetails={userDetails}
              setUserDetails={setUserDetails}
            />
          ) : null}
          {/* Expected ctc  */}
          {props.params.type === "ctc" ? (
            <ExpectedCtc
              onHide={onHide}
              userDetails={userDetails}
              setUserDetails={setUserDetails}
            />
          ) : null}
          {props.params.type === "Training" ? (
            <TrainingAndEducation
              onHide={onHide}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              setProfileModal={setProfileModal}
              //isEdit = {isEdit}
              //setIsEdit = {setIsEdit}
            />
          ) : null}

          {props.params.type === "edit-education" ? (
            <EditTrainingAndEducation
              id={props?.params?.id}
              onHide={onHide}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              setProfileModal={setProfileModal}
            />
          ) : null}

          {props.params.type === "Languages" ? (
            <Languages
              onHide={onHide}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
            />
          ) : null}
          {props.params.type === "Files" ? (
            <Certificates
              onHide={onHide}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              setProfileModal={setProfileModal}
            />
          ) : null}
           {props.params.type === "resumePreview" ? (
            <ResumePreview
              onHide={onHide}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              setProfileModal={setProfileModal}
            />
          ) : null}
        </ModalDialog>
      </Modal>
    </Box>
  );
}
export default ProfileModal;
