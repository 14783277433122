import styled from "styled-components";
import { Navbar } from "react-bootstrap";

export const Nav = styled(Navbar)`
  background: ${({ scrollnav,path }) => (scrollnav ? "white" : path ? "#cde2e7":'white')};
  box-shadow: ${({ changevalue }) =>
    changevalue >= 60 ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" : "none"};
  height: 80px;
  display: flex;
  margin: auto;
  margin-top: 0px;
  padding-top: 0px;
  justify-content: left;
  align-items: left;
  font-size: 20px;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  z-index: 999;
  width: 100%;
  position: absolute;
  padding: 0 24px;
`;
export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1050px) {
    display: block;
    position: absolute;
    top: 0;
    right: -90px;
    transform: translate(-100%, 60%);
    font-size: 1.4rem;
    cursor: pointer;
    color: #414b52;
    margin: 0;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -24px;
  float: left;
  @media screen and (max-width: 1050px) {
    margin-right: 20px;
  }
  margin-bottom: -7px;
`;

export const NavItem = styled.li`
  height: fit-content;
  margin-right: 20px;
  color: black;
  @media screen and (max-width: 1050px) {
    display: none;
  }
`;
