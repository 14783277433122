import React, { useState, useEffect } from "react";
import CardsCarousel from "../Home/CardsCarousel";
import { findBlogsList } from "../../helpers/dataFetcher/jobs/jobs";

function BlogsList() {
  const [slidesData, setSlidesData] = useState();
  useEffect(() => {
    fetchBolgsList();
  }, []);

  const fetchBolgsList = async () => {
    const response = await findBlogsList();
    setSlidesData(response.data);
  };
  return (
    <>
    {slidesData?.length > 0?
      <section className="section-box mt-50">
        <div className="latest_job_section">
          <div className="row mb-3 align-items-center">
              <div className="col-lg-7 col-md-7">
                <h2 className="blogs_head_txt" data-wow-delay=".1s">
                  Blogs <span>Which are trending</span>
                </h2>
              </div>
      
            {/* <div className="col-lg-7 col-md-7">
                <h2
                className='blogs_head_txt'
                  data-wow-delay=".1s"
                >
                  Blogs <span>Which are trending</span>
                </h2>
              </div> */}
            {/* <div className="col-lg-5 col-md-5 text-lg-end text-start">
                <a
                  href="/blogs"
                  // onClick={() => navigate("/blogs")}
                  className="wow animate__animated animate__fadeInUp hover-up latest_job_view_btn"
                  data-wow-delay=".1s"
                >
                  View all <EastIcon className="m-1" />
                </a>
              </div> */}
          </div>
          <CardsCarousel />
        </div>
      </section>:""}
    </>
  );
}

export default BlogsList;
