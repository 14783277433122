import SignUpForJobs from "../JobsList/SignUpForJobs/SignUpForJobs";
import BlogsLists from "./BlogsList/BlogsList";
import BlogsListsCategoriesTags from "./BlogsList/BlogslistsCategoriesTags";
import Breadcrumb from "./Breadcrumb";
import WelcomeText from "./WelcomeText";
import BlogsCategoriesList from './BlogCategoriesList';
import BlogsPageList from './BlogsPageList';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import './Blogs.css';
import {
  allCategories,
  getAllBlogs,
  getBlogsByCategory,
} from "../../helpers/dataFetcher/blogs";
import { useSearchParams } from "react-router-dom";
import SocketConnection from "../../hooks/socket";
function BlogsListIndex() {
  const [searchParams, _] = useSearchParams();
  const { lastMessage } = SocketConnection();
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    dataFetcher();
  }, []);
  async function dataFetcher() {
    const { data } = await allCategories();
    setCategories(data?.data);
    getBlogs();
  }
  async function getBlogs() {
    if (
      searchParams.get("c_id") !== null &&
      searchParams.get("c_id") !== undefined &&
      searchParams.get("c_id") !== ""
    ) {
      const { data: blog } = await getAllBlogBasedOnCategory(
        searchParams.get("c_id")
      );
      setBlogs(blog?.data);
      return;
    }
    const { data: blog } = await getAllBlogs({
      category_name:
        searchParams.get("q") === null ? "" : searchParams.get("q"),
    });
    setBlogs(blog?.data);
  }
  useEffect(() => {
    getBlogs();
  }, [searchParams.get("q")]);
  useEffect(() => {
    getAllBlogBasedOnCategory(searchParams.get("c_id"));
  }, [searchParams.get("c_id")]);
  async function getAllBlogBasedOnCategory(cid) {
    if (
      searchParams.get("c_id") !== null &&
      searchParams.get("c_id") !== undefined &&
      searchParams.get("c_id") !== ""
    ) {
      const { data } = await getBlogsByCategory(cid === null ? "" : cid);
      setBlogs(data?.data);
    }
  }

  /*socket integration for Blogs page start */
  
  useEffect(() => {
    if (
      lastMessage?.data !== undefined ||
      lastMessage?.data !== null ||
      lastMessage?.data !== ""
    ) {
      const resultant = lastMessage?.data;
      if (resultant !== undefined) {
        const data = JSON.parse(resultant);
        if (data?.type === "blogs") {
          dataFetcher()
        }
      }
    }
  }, [lastMessage]);

  /*socket integration for Blogs page End */

  return (
    <>
      {/* <WelcomeText categories={categories} />
      <div className="post-loop-grid">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <BlogsLists blogsData={blogs} />
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
              <BlogsListsCategoriesTags categories={categories} />
            </div>
          </div>

          <div className="paginations wow animate__animated animate__fadeIn">
            <Stack spacing={1}>
              <Pagination count={20} variant="outlined" shape="rounded" />
            </Stack>
          </div>

          <SignUpForJobs />
        </div>
      </div> */}

      <BlogsCategoriesList />
      <BlogsPageList />
    </>
  );
}

export default BlogsListIndex;
