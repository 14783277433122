import { withRouter } from "react-router-dom";
import React, { useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone"
import NewOne from "./CropModal";
// const { Row, Col, Card, Form } = require("reactstrap");
import {Row, Col, Card, Form } from 'reactstrap';
import { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { toast } from "react-hot-toast";
import { Box, Modal, Stack } from "@mui/material";
import {
  BlackTypography,
  BorderedCardActionArea,
  GreyTypography,
  PrimaryButton,
  PrimaryTypography,
} from "../../../assets/MUI/materialUiStyles";

import FeatherIcon from "feather-icons-react/build/FeatherIcon";
export default function UploadModel({
  open,
  toggle,
  handleImage,
  doCrop = true,
}) {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
    });

  const [crop, setCrop] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  function handleCrop() {
    setCrop(!crop);
  }

  function handleAcceptedFiles(files) {
    if (files.length === 1) {
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        crop: false,
      })
    );
    setSelectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height:400,
    background: "white",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={open}
      onClose={toggle}
      aria-labelledby="edit-profile-modal-title"
      aria-describedby="edit-profile-modal-description"
    >
      <Box style={style}>
        <div className="modal-header my-3">
          <PrimaryTypography variant="h2">Image Upload</PrimaryTypography>
        </div>
        <div className="modal-body">
          <Form>
            <div className="dropzone">
              <BorderedCardActionArea
                className="dz-message needsclick"
                sx={{ width: "100%" }}
                {...getRootProps()}
              >
                <Stack spacing={2} alignItems={"center"}>
                  <input
                    {...getInputProps()}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <FeatherIcon icon="upload-cloud" size="40" />
                  <BlackTypography variant="h6" component="h6">
                    Drop your Images here or
                  </BlackTypography>
                  <GreyTypography variant="h6" component="h6">
                    Supported File types (20) MB
                  </GreyTypography>{" "}
                </Stack>
              </BorderedCardActionArea>
            </div>
            <div className="dropzone-previews mt-3" id="file-previews">
              {acceptedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          {acceptedFiles[0].type.startsWith("image/") ? (
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={URL.createObjectURL(f)}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col>
                          <p className="text-muted font-weight-bold">
                            {f.name}
                          </p>
                          <p className="mb-0">
                            <strong>{formatBytes(f.size)}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
          </Form>
        </div>
        <div className="modal-footer my-2">
          <PrimaryButton
            sx={{
              width: "150px",
            }}
            onClick={() => {
              if (acceptedFiles.length === 0) {
                toast.error("Please select a file");
                return;
              }
              // if (selectedFiles[0].size > 2168009) {
              //   toast.error(
              //     "File size is too large. Please upload a file less than 2MB"
              //   );
              //   return;
              // }
              if (!doCrop) {
                handleImage(acceptedFiles);
                return;
              }
              if (acceptedFiles[0].type.startsWith("image/")) {
                handleCrop();
                return;
              }
              handleImage(acceptedFiles);
            }}
          >
            Confirm
          </PrimaryButton>
        </div>
        {acceptedFiles?.length > 0 ? (
          <NewOne
            isOpen={crop}
            toggle={handleCrop}
            imageSrc={URL.createObjectURL(acceptedFiles[0])}
            type={acceptedFiles[0].type}
            handleImage={handleImage}
          />
        ) : (
          <></>
        )}
      </Box>
    </Modal>
  );
}