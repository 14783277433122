import { combineReducers } from "redux";

import ProfileSidebar from "./Layout/reducers";
import CompanyReducer from "./company/reducers";
import JobApplyStatusReducer from './JobApplyStatus/reducer';

import User from "./auth/reducers";
const rootReducer = combineReducers({
  ProfileSidebar,
  User,
  CompanyReducer,
  JobApplyStatusReducer
});

export default rootReducer;
