import { ADD_USER } from "./actionTypes.js";

const initState = {
  user: {},
};
export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case ADD_USER:
      return { ...state, user: payload };
    default:
      return state;
  }
}
