import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import OwlCarousel from "react-owl-carousel3";
import combinator from "../../assets/images/combinator.png";
import stripe from "../../assets/images/stripe.png";
import openSea from "../../assets/images/openSea.png";
import doorDarsh from "../../assets/images/doorDarsh.png";
import razor from "../../assets/images/razor.png";
import america from "../../assets/images/america.png";
import morgan from "../../assets/images/morgan.png";
import dummy1 from "../../assets/images/dummy1.png";
import Button from "@mui/joy/Button";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import meta from "../../assets/images/meta-01.svg";
import { getAllCompanies } from "../../helpers/dataFetcher/companies";
import { useSearchParams } from "react-router-dom";
import defaultCompanyImage from "../../assets/images/company_default_img.svg";
import { useNavigate } from "react-router-dom";
// import noJobs from "../../assets/images/no_jobs.svg";
import noJobs from "../../assets/images/nojobsShiyft.png";
import { FaIndustry } from "react-icons/fa";
import CompanyCard from "./CompanyCard";

const useStyles = makeStyles({
  arrowBtn: {
    zIndex: 0,
  },
});

function CompanyListCarousal() {
  const [loading, setLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const navigate = useNavigate();

  let array1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const classes = useStyles();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      gap: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      gap: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      gap: 10,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    setName(searchParams.get("name"));
    dataFetcher(
      searchParams.get("name") === null ? "" : searchParams.get("name"),
      searchParams.get("location") === null ? "" : searchParams.get("location")
    );
  }, [searchParams.get("name"), searchParams.get("location")]);
  async function dataFetcher(name, location) {
    const { data } = await getAllCompanies(name, location);
    if (data) {
      setLoading(false);
    }

    setCompanies(data?.data);
  }

  return (
    <>
      {loading ? (
        <>
          {" "}
          <Stack
            sx={{
              color: "grey.500",
              justifyContent: "center", // Align items horizontally in center
              alignItems: "center",
            }}
            spacing={2}
            direction="row"
          >
            <CircularProgress color="success" />
          </Stack>
        </>
      ) : (
        <>
          {companies.length > 0 ? (
            <div className="company_list_main_sec">
              <Carousel responsive={responsive} className={classes.arrowBtn}>
                {companies?.map((item, index) => {
                  return <CompanyCard item={item} />;
                })}
              </Carousel>
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                placeItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <div className="d-flex align-items-center flex-column">
                <img
                  src={noJobs}
                  style={{ width: "300px", height: "300px" }}
                  alt="no jobs"
                />
                <p>No Companies Found</p>
              </div>
            </div>
          )}
        </>
      )}

    </>
  );
}

export default CompanyListCarousal;
