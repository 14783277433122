import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

const iterate = [1];
export default function SkeletionChipsLoader() {
  return (
    <Stack spacing={1} sx={{marginTop:"5px",marginBottom:"3px"}}>
      {iterate?.map(each => (
        <Stack direction="row" spacing={2}>
          <Skeleton
            variant="rectangular"
            width={60}
            height={20}
            style={{
              borderRadius: "16px",
              // display: 'inline-block',
            }}
          />
         <Skeleton
            variant="rectangular"
            width={60}
            height={20}
            style={{
              borderRadius: "16px",
              // display: 'inline-block',
            }}
          />
         <Skeleton
            variant="rectangular"
            width={60}
            height={20}
            style={{
              borderRadius: "16px",
              // display: 'inline-block',
            }}
          />
          
        </Stack>
      ))}
    </Stack>
  );
}
