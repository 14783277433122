import React from "react";
import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryTypography,
  GrayDivider,
  GreyTypography,
  BlackTypography,
  PrimaryOpacitedButton,
  PrimaryButton,
  MiddleDivider,
  BorderCardWork,
  SecondaryTypography,
  ColorTypography,
  PrimaryAvatar,
} from "../../../assets/MUI/materialUiStyles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import AddEditWorkExperience from "./AddEditWorkExperience";
import EditWorkExperience from "./EditWorkExperience";
// import { updateJobPost } from "helpers/services/jobs/jobs";
// import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
// import SearchParamHook from "hooks/searchParams";
import WholeEditWorkExperience from "./WholeEditWorkExperience";
import Badge from "@mui/material/Badge";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";

export default function CandidateExperience({
  candidateDetails,
  setRecall,
  reCall,
}) {
  const [workExpModel, setWorkExpModel] = useState(false);
  const [workEditExpModel, setWorkEditExpModel] = useState(false);
  const [editWorkExpModel, setEditWorkExpModel] = useState(false);
  const [updateType, setUpdateType] = useState("create");
  const [updateIndex, setUpdateIndex] = useState(0);
  const [workExp, setWorkExp] = useState([]);
  const [wholeDetails, setWholeDetails] = useState();
  useEffect(() => {
    setWholeDetails(candidateDetails);

    if (candidateDetails?.work_exp) {
      setWorkExp(candidateDetails?.work_exp);
    }
  }, [candidateDetails]);

 

  const [userJobPostId, setUserJobPostId] = useState(null);
  // const searchParams = SearchParamHook();
  // useEffect(() => {
  //   let queryParamsForUserJobPostId = searchParams.get("user_job_post_id");
  //   if (queryParamsForUserJobPostId)
  //     setUserJobPostId(queryParamsForUserJobPostId);
  // }, [searchParams]);
  function toggleModel() {
    setWorkExpModel(!workExpModel);
  }


  function editToggleModel() {
    setEditWorkExpModel(!editWorkExpModel);
  }
  async function updateOrEditWorExperience(work_exp_data, checkMark) {
    toggleModel();

    if (updateType?.includes("update")) {
      const obj1 = { ...work_exp_data, is_currently_working: checkMark };
      const data = [...workExp];
      data[updateIndex] = {
        ...obj1,
      };

      setWorkExp(data);
      const obj = {
        last_name: wholeDetails?.last_name,
        first_name: wholeDetails?.first_name,
        email: wholeDetails?.email,
        work_exp: [...data],
        update_type_to_send_email: "email",
      };
      let o = Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );

      await updateUserProfileDetails("about_me", o);
      setTimeout(() => {
        setRecall(!reCall);
      }, [1000]);
    } else {
      const obj = {
        last_name: wholeDetails?.last_name,
        first_name: wholeDetails?.first_name,
        email: wholeDetails?.email,
        work_exp: [...workExp, work_exp_data],
        update_type_to_send_email: "email",
      };
      let o = Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );

      await updateUserProfileDetails("about_me", o);

      setTimeout(() => {
        setRecall(!reCall);
      }, [1000]);
    }

    // /* start here */
    // const obj = {
    //   last_name: wholeDetails?.last_name,
    //   first_name: wholeDetails?.first_name,
    //   email: wholeDetails?.email,
    //   work_exp: [...workExp, work_exp_data],
    //   update_type_to_send_email: "email",
    // };
    // let o = Object.fromEntries(
    //   Object.entries(obj).filter(([_, v]) => v != null)
    // );
    // /*End here */

    // await updateUserProfileDetails("about_me", o);

    // setTimeout(() => {
    //   setRecall(true);
    // }, [1000]);
  }

  /* Remove work experience code start here */
  const removeExperience = async id => {
    const filterExperience = workExp?.filter((each, index) => {
      if (index !== id) {
        return { ...each };
      }
    });
    const obj = {
      last_name: wholeDetails?.last_name,
      first_name: wholeDetails?.first_name,
      email: wholeDetails?.email,
      work_exp: [...filterExperience],
      update_type_to_send_email: "email",
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );

    await updateUserProfileDetails("about_me", o);

    setTimeout(() => {
      setRecall(!reCall);
    }, [1000]);
  };

  /* Remove work experience code End here */

  return (
    <Stack spacing={2} className="mt-3">
      <AddEditWorkExperience
        toggleModel={toggleModel}
        open={workExpModel}
        type={updateType}
        currentData={workExp[updateIndex]}
        updateOrEditWorExperience={updateOrEditWorExperience}
      />
      <WholeEditWorkExperience
        editToggleModel={editToggleModel}
        toggleModel={toggleModel}
        open={editWorkExpModel}
        type={updateType}
        data={workExp}
        setUpdateType={setUpdateType}
        setUpdateIndex={setUpdateIndex}
        removeExperience={removeExperience}
      />
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px !important",
            paddingLeft: "4px",
            alignSelf: "stretch",
          }}
          orientation="vertical"
          flexItem
        />
        <Stack
          spacing={1}
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <SecondaryTypography component="h3" variant="h3">
            Experience Section
          </SecondaryTypography>
          {/* <PrimaryOpacitedButton
            onClick={() => {
              toggleModel();
              setUpdateType("create");
            }}
            startIcon={<FeatherIcon icon="plus" size="14" />}
          >
            Add
          </PrimaryOpacitedButton> */}
          <FeatherIcon
            icon="plus"
            size="18"
            onClick={() => {
              toggleModel();
              setUpdateType("create");
            }}
          />
          <FeatherIcon
            icon="edit"
            size="16"
            onClick={() => {
              editToggleModel();
              setUpdateType("wholeEdit");
            }}
          />
        </Stack>
      </Stack>
      {/* <BorderCard>
        <CardContent>
          <Stack spacing={3}>
            {workExp?.map(
              ({ title, employer, start_date, end_date }, workExpIndex) => {
                return (
                  <>
                    <Stack spacing={1}>
                      <BlackTypography variant="h5" component="h5">
                        {title}
                      </BlackTypography>
                      <BlackTypography variant="h6" component="h6">
                        {employer}
                      </BlackTypography>
                      <GreyTypography variant="h6" component="h6">
                        {start_date} - {end_date}
                      </GreyTypography>
                      <PrimaryButton
                        sx={{ width: "100px" }}
                        onClick={() => {
                          toggleModel();
                          setUpdateType(`update${workExpIndex}`);
                          setUpdateIndex(workExpIndex);
                        }}
                      >
                        Edit
                      </PrimaryButton>
                    </Stack>
                  </>
                );
              }
            )}
          </Stack>
        </CardContent>
      </BorderCard> */}

      <BorderCardWork>
        <CardContent>
          <Stack spacing={3}>
            {workExp?.map(
              (
                {
                  title,
                  employer,
                  start_date,
                  end_date,
                  description,
                  desc,
                  skill,
                  image,
                },
                workExpIndex
              ) => {
                const isLastItem = workExpIndex === workExp.length - 1;
                return (
                  <>
                    {/* <Stack spacing={1} direction="row">
                      <Stack
                        style={{ whiteSpace: "normal", width: "90%" }}
                        spacing={0.8}
                      >
                        <ColorTypography variant="h3" component="h3" sx={{fontWeight:600,color:"#000000"}}>
                          {title}
                        </ColorTypography>
                        <ColorTypography variant="h4" component="h4">
                          {employer} - Fulltime
                        </ColorTypography>
                        <GreyTypography variant="h6" component="h6">
                          {start_date} - {end_date}
                        </GreyTypography>
                        <BlackTypography>Skills:</BlackTypography>

                        <GreyTypography
                          variant="h6"
                          component="h6"
                          style={{ width: "100%", wordWrap: "break-word" }}
                        >
                          {skill?.map((each, index, array) => (
                            <React.Fragment key={each}>
                              {each?.skillName?.inputValue
                                ? each.skillName.inputValue
                                : each.skillName.name}
                              {" -"}
                              {each?.experienceyears}
                              {"."}
                              {each?.experienceyears}
                              {"Yrs"}
                              {index !== array.length - 1 && ", "}
                            </React.Fragment>
                          ))}
                        </GreyTypography>

                        <BlackTypography>Description:</BlackTypography>
                        <GreyTypography contentEditable='true' dangerouslySetInnerHTML={{ __html: desc }}></GreyTypography>
                        <GreyTypography>{description}</GreyTypography>
                        {!isLastItem && <MiddleDivider />}
                      </Stack>
                    </Stack> */}

                    <Stack direction={"row"} spacing={1}>
                      <Stack>
                        <PrimaryAvatar
                          variant="square"
                          sx={{ border: "none" }}
                          //   src={`${syoft}`}
                          src={`${image}`}
                          alt={`${employer?.charAt(0, 2)}`}
                        />
                      </Stack>
                      <Stack direction="column">
                        <ColorTypography
                          variant="h3"
                          component="h3"
                          sx={{ fontWeight: 600, color: "#000000" }}
                        >
                          {title}
                        </ColorTypography>
                        <ColorTypography variant="h4" component="h4">
                          {employer} - Fulltime
                        </ColorTypography>
                        <GreyTypography variant="h6" component="h6">
                          {start_date} - {end_date}
                        </GreyTypography>
                        <BlackTypography>Skills:</BlackTypography>

                        <GreyTypography
                          variant="h6"
                          component="h6"
                          style={{ width: "100%", wordWrap: "break-word" }}
                        >
                          {skill?.map((each, index, array) => (
                            <React.Fragment key={each}>
                              {each?.skillName?.inputValue
                                ? each.skillName.inputValue
                                : each.skillName.name}
                              {" -"}
                              {each?.experienceyears}
                              {"."}
                              {each?.experiencemonths}
                              {"Yrs"}
                              {index !== array.length - 1 && ", "}
                            </React.Fragment>
                          ))}
                        </GreyTypography>

                        <BlackTypography>Description:</BlackTypography>
                        <GreyTypography
                          variant="h6"
                          component="h6"
                          style={{ width: "100%", wordWrap: "break-word" }}
                        >
                          {desc}
                        </GreyTypography>
                      </Stack>
                    </Stack>
                    {!isLastItem && <MiddleDivider />}
                  </>
                );
              }
            )}
          </Stack>
        </CardContent>
      </BorderCardWork>
    </Stack>
  );
}
