import { APPLY_STATUS } from "./actionTypes";

const initState = {
  jobApplyStatus:""
  };
  export default function reducer(state = initState, { type, payload }) {
    switch (type) {
      case APPLY_STATUS:
        return { ...state, jobApplyStatus: payload };
  
      default:
        return state;
    }
  }