import { withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./canvasPreview";
import { Box, Modal } from "@mui/material";
import { PrimaryButton, SecondaryFilledButton } from "../../../assets/MUI/materialUiStyles";

export default function NewOne({
  isOpen,
  toggle,
  handleImage,
  imageSrc,
  type,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    display: "grid",
    padding: "10px",
    maxHeight: "560px",
    // placeItems: "center",
    background: "white",
    borderRadius: "8px",
    boxShadow: 24,
    overflowY:"scroll",
    // p: 4,
  };
  const [crop, setCrop] = useState(undefined);
  const [scale, setCale] = useState(1);
  const [aspect, setAspect] = useState(1 / 1);
  const [rotate, setRotate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [completedCrop, setCompletedCrop] = useState(undefined);
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  const imageRef = useRef(null);
  const previewCanvasRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imageRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imageRef?.current,
          previewCanvasRef?.current,
          completedCrop,
          scale,
          rotate
        );
      }
    }, 100);
  }, [completedCrop]);
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      centered={false}
      aria-labelledby="edit-profile-modal-title"
      aria-describedby="edit-profile-modal-description"
    >
      <Box style={style}>
        {!loading ? (
          <>
            <div className="modal-body">
              {!!imageSrc && (
                <div style={{ zIndex: "999" }}>
                  <ReactCrop
                    crop={crop}
                    onChange={c => setCrop(c)}
                    onComplete={c => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imageRef}
                      alt="Crop me"
                      src={imageSrc}
                      onLoad={onImageLoad}
                      style={{ maxWidth: "400px", maxHeight: "800px" }}
                    />
                  </ReactCrop>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <SecondaryFilledButton
                type="button"
                sx={{ width: "100px" }}
                onClick={() => {
                  toggle(!isOpen);
                }}
                data-dismiss="modal"
              >
                Close
              </SecondaryFilledButton>
              <PrimaryButton
                sx={{ width: "100px" }}
                onClick={async () => {
                  setLoading(true);
                  const data = await fetch(
                    previewCanvasRef.current.toDataURL(type)
                  )
                    .then(r => r.blob())
                    .then(
                      blobFile =>
                        new File([blobFile], "fileNameGoesHere", {
                          type: type,
                        })
                    );
                  setLoading(true);
                  handleImage([data]);
                 
                }}
              >
                Confirm
              </PrimaryButton>
            </div>
            <div>
              {!!completedCrop && (
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    display: "none",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "grid",
              placeItems: "center",
            }}
          >
            <div className="loader"></div>
          </div>
        )}
      </Box>
    </Modal>
  );
}
