import React, { useEffect, useState } from "react";
import SignUpForJobs from "../../JobsList/SignUpForJobs/SignUpForJobs";
import JobsOpening from "../../Reusable/JobsOpening";
import AboutEmployer from "./AboutEmployer";
import EmployerBranches from "./EmployerBranches";
import EmployerOffices from "./EmployerOffices";
import EmployerOverview from "./EmployerOverview";
import EmployerNavbarProfile from "./EmployerProfile";
import EmployerVacancies from "./EmployerVacancies";
import EmployerDescription from "./EmployerDescription";
// import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import "./index.css";
import "./companyProfile.css";
import { Button } from "reactstrap";
import EastIcon from "@mui/icons-material/East";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Footer from "../../../layouts/footer/Footer";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAllCompanies,
  getCompanyById,
  getCompanyJobsById
} from "../../../helpers/dataFetcher/companies";


function Employer(props) {
  const [companies, setCompanies] = useState();
  const [jobsList,setJobsList] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const jobApply = useSelector((state)=>state.JobApplyStatusReducer)

  // const { id } = useParams();

/* trials */
const searchParams = new URLSearchParams(window.location.search);
const id = searchParams.get('id');

/* trials */


useEffect(()=>{
  dataFetcher(id);
  setIsLoading(!isLoading);
},[jobApply])

  useEffect(() => {
    dataFetcher(id);
    setIsLoading(!isLoading);
  }, [id]);

  async function dataFetcher(id) {
    const { data } = await getCompanyById(id);
    renderJobsList(id)
   
    setCompanies(data);
  }

  async function renderJobsList(id){
    const {data} = await getCompanyJobsById(id)
    setJobsList(data)
  }

  const [open, setOpen] = useState(true);
  const [forceClose, setForceClose] = useState(false);
  const [value, setValue] = React.useState("1");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const handleForceClose = () => {
    setForceClose(true);
    setOpen(false);
  };
  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      !forceClose
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="mb-8">
          {/* <Snackbar
            open={open}
            // onClose={handleClose}
            TransitionComponent={undefined}
            key={undefined ? "" : ""}
          >
            <div>
              <div
                className="company_jobs_snackbar_close_btn_cont"
                onClick={handleForceClose}
              ></div>
              <div className="company_jobs_snackbar">
                <span>Want to work here?</span>
                <Link to="/jobs-list/123">
                  <Button className="company_jobs_snackbar_btn" color="primary">
                    <span>View Jobs</span>
                    <span className="snackbar_btn_icons">
                      <EastIcon />
                    </span>
                  </Button>
                </Link>
              </div>
            </div>
          </Snackbar> */}
          <div></div>
          <EmployerNavbarProfile company={companies} isLoading={isLoading} />
          <EmployerDescription company={companies} list={jobsList} />
          {/* <section className="section-box mb-4">
            <div className="comp_profile_top_main_cont">
              <div className="comp_profile_categories_cont">
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="About" value="1" />
                      <Tab label="Jobs" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <AboutEmployer company={companies} />
                  </TabPanel>
                  <TabPanel value="2">
                    <JobsOpening />
                  </TabPanel>
                </TabContext>
              </div>
              <EmployerBranches /> <EmployerOffices />
            </div>
          </section> */}
        </div>
      ) : (
        <div className="content-page">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      )}
    </>
  );
}
export default Employer;
