import React from "react";

function EmployerOffices() {
    return (
        <>
            <h4 className="mt-30 mb-30">Our Office</h4>
            <img src="assets/imgs/page/employers/img-1.png" alt="jobhub" />
            <div className="divider"></div>
            
        </>
    )
}
export default EmployerOffices;