import React from "react";

function Earningbonus() {
    return (
        <>
            <h5 className="mb-3">Your Bonus</h5>
            <div className="card border-0 p-4 table-bg">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Date</th>
                            <th scope="col">Your commission</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <p className="text-center">No data</p>

                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default Earningbonus