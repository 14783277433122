import React from "react";
import { useNavigate } from "react-router-dom";

function CandidateLists() {
    const navigate = useNavigate();

    const candidateLists = [
        {
            id: 1,
            name: "Esther Howard",
            designation: "Front end developer",
            location: "Chicago",
            hours: "45 / hours",
            skills: [
                { name: "Java" },
                { name: "Python" },
                { name: "Javascript" },
                { name: "React" },
                { name: "Angular" }
            ],
            profile: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660220048/pic2_mkait2.jpg"
        },
        {
            id: 2,
            name: "Anjelina Javeri",
            designation: "UI/UX Designer",
            location: "Illinois",
            hours: "45 / hours",
            skills: [
                { name: "PSD" },
                { name: "Figma" },
                { name: "Javascript" },
                { name: "HTML5" },
                { name: "Angular" }
            ],
            profile: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660220048/pic2_mkait2.jpg"
        },
        {
            id: 3,
            name: "Anjelina Javeri",
            designation: "UI/UX Designer",
            location: "Illinois",
            hours: "45 / hours",
            skills: [
                { name: "PSD" },
                { name: "Figma" },
                { name: "Javascript" },
                { name: "HTML5" },
                { name: "Angular" }
            ],
            profile: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660220048/pic2_mkait2.jpg"
        },
        {
            id: 4,
            name: "Anjelina Javeri",
            designation: "UI/UX Designer",
            location: "Illinois",
            hours: "45 / hours",
            skills: [
                { name: "PSD" },
                { name: "Figma" },
                { name: "Javascript" },
                { name: "HTML5" },
                { name: "Angular" }
            ],
            profile: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660220048/pic2_mkait2.jpg"
        },
        {
            id: 5,
            name: "Anjelina Javeri",
            designation: "UI/UX Designer",
            location: "Illinois",
            hours: "45 / hours",
            skills: [
                { name: "PSD" },
                { name: "Figma" },
                { name: "Javascript" },
                { name: "HTML5" },
                { name: "Angular" }
            ],
            profile: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660220048/pic2_mkait2.jpg"
        }
    ]
    return (
        <>
            <div className="content-page">
                <div className="box-filters-job mt-15 mb-10">
                    <div className="row">
                        <div className="col-lg-7">
                            <span className="text-small">Showing <strong>41-60 </strong>of <strong>944 </strong>Candidate</span>
                        </div>
                        <div className="col-lg-5 text-lg-end mt-sm-15">
                            <div className="display-flex2">
                                <span className="text-sortby">Sort by:</span>
                                <div className="dropdown dropdown-sort">
                                    <button className="btn dropdown-toggle" type="button" id="dropdownSort" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>Newest Post</span> <i className="fi-rr-angle-small-down"></i></button>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                                        <li><span className="dropdown-item active" href="#">Newest Post</span></li>
                                        <li><span className="dropdown-item" href="#">Oldest Post</span></li>
                                        <li><span className="dropdown-item" href="#">Rating Post</span></li>
                                    </ul>
                                </div>
                                <div className="box-view-type">
                                    <span className="view-type"><img src="assets/imgs/theme/icons/icon-list.svg" alt="Shifyt" /></span>
                                    <span className="view-type"><img src="assets/imgs/theme/icons/icon-grid.svg" alt="Shifyt" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="employers-list">
                    {
                        candidateLists.map((item) =>
                            <div key={item.id} className="card-employers hover-up wow animate__animated animate__fadeIn">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-6 d-flex">
                                        <div className="employers-logo online mr-15">
                                            <span>
                                                <figure><img alt="Shifyt" src={item.profile} style={{ borderRadius: "50px" }} /></figure>
                                            </span>
                                        </div>
                                        <div className="employers-name">
                                            <h5><span><strong>Esther Howard</strong></span></h5>
                                            <span className="text-sm text-muted">UI/UX Designer</span>
                                            <div className="d-flex mt-15">
                                                <div className="rate small float-start">
                                                    <input type="radio" id="star5" name="rate" value="5" />
                                                    <label htmlFor="star5" title="text" className="">5 stars</label>
                                                    <input type="radio" id="star4" name="rate" value="4" />
                                                    <label htmlFor="star4" title="text" className="checked">4 stars</label>
                                                    <input type="radio" id="star3" name="rate" value="3" />
                                                    <label htmlFor="star3" title="text" className="checked">3 stars</label>
                                                    <input type="radio" id="star2" name="rate" value="2" />
                                                    <label htmlFor="star2" title="text" className="checked">2 stars</label>
                                                    <input type="radio" id="star1" name="rate" value="1" />
                                                    <label htmlFor="star1" title="text" className="checked">1 star</label>
                                                </div>
                                                <span className="text-muted text-small">(5.0)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="employers-info d-flex align-items-center">
                                            <span className="d-flex align-items-center"><i className="fi-rr-marker mr-5 ml-0"></i> Chicago, US</span>
                                            <span className="d-flex align-items-center ml-25"><i className="fi-rr-briefcase mr-5"></i>$45 / hour</span>
                                        </div>
                                        <div className="job-tags mt-25">
                                            <span className="hover-up">Figma</span>
                                            <span className="hover-up">Adobe XD</span>
                                            <span className="hover-up">PSD</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 text-lg-end d-lg-block d-none">
                                        <div className="card-grid-2-link">
                                            <span><i className="fi-rr-shield-check"></i></span>
                                            <span><i className="fi-rr-bookmark"></i></span>
                                        </div>
                                        <div className="mt-25">
                                            <span onClick={() => navigate("/candidate-single")} className="btn btn-border btn-brand-hover">View Profile</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="paginations mt-50">
                        <ul className="pager">
                            <li><span className="pager-prev"></span></li>
                            <li><span className="pager-number">1</span></li>
                            <li><span className="pager-number">2</span></li>
                            <li><span className="pager-number">3</span></li>
                            <li><span className="pager-number">4</span></li>
                            <li><span className="pager-number">5</span></li>
                            <li><span className="pager-number active">6</span></li>
                            <li><span className="pager-number">7</span></li>
                            <li><span className="pager-next"></span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateLists;