import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { imageURL } from "../../Constants/commonURLS";
import { ReferNowModal } from "../JobsList/Alljobs/ReferModal";
import getSymbolFromCurrency from "currency-symbol-map";
import "../../assets/JobList/AllJobs.css";
import defaultCompanyImage from "../../assets/images/company_default_img.svg";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import AspectRatio from "@mui/joy/AspectRatio";
import CardContent from "@mui/joy/CardContent";
//import List from './List';
import Skeleton from "@mui/material/Skeleton";
import { BookmarksOutlined } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import EarnImage from "../../assets/images/earnImage.svg";
import workIcon from "../../assets/images/work 2.svg";
import LocationIcon from "../../assets/images/location 2.svg";
import peopleIcon from "../../assets/images/people.svg";
import heartIcon from "../../assets/images/heart.svg";
import { useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function CompanyBasedDrawJob({ compJobList }) {
  const navigate = useNavigate();
  const [referModal, setReferModal] = useState({
    modal: "",
    purpose: "",
    modalType: "",
  });

  const abbreviateNumber = (number) => {
    const scaledNumber = number / 1000;
    return `${scaledNumber.toFixed(0)}k`;
  };
console.log(compJobList,"compJobList")

  return (
    <>
      <div className="content-page">
        <div className="box-filters-job mt-15 mb-10"></div>
        <div className="job-list-list mb-15">
          <div className="">
            {compJobList?.map((item, index) => (
              <div key="">
                <div className="latest_jobs_main_card_section mb-3">
                  <div key="" className="latest_jobs_inside_section">
                    <div className="title_section_1">
                      <div className="title_name_logo_sec">
                        <div className="title_sec_divider">
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#121212",
                              fontFamily: "Inter,sans-serif",
                            }}
                            onClick={() => navigate(`../job?id=${item.id}`)}
                            
                          >
                            {item?.job_title?.length <= 20
                              ? item?.job_title
                              : item?.job_title?.slice(0, 18) + ".."}
                          </Typography>
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                            }}
                            onClick={() =>
                              navigate(
                                `../employer-single-list?id=${item?.created_by_user_details?.id}`
                              )
                            }
                          >
                            {item?.company_details?.company_name?.length <= 20
                              ? item?.company_details?.company_name
                              : item?.company_details?.company_name?.slice(0, 18) + ".."}
                          </Typography>
                        </div>
                      </div>
                      <div className="commission_sec">
                        <Stack direction="row" spacing={2}>
                          <Avatar alt="Remy Sharp" src={EarnImage} />
                        </Stack>
                        <div className="title_sec_divider">
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            sx={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#121212",
                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            Earn Upto
                          </Typography>
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            sx={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "Bold",
                              color: "#066a4c !important",
                              // opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            {getSymbolFromCurrency(item.currency)}{" "}
                            {abbreviateNumber(
                              (item.max_sal * item.commission_rate) / 100
                            )}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className="main_skills_sec">
                      <div className="job_card_top_details_commision_cont">
                        <div className="job_details"></div>
                        <span className="mx-2"></span>
                        <span className="card-job-top--price">
                          {/* {item.time} */}
                          <span></span>
                        </span>
                      </div>
                      <div className="jobs_title_card">
                        <Chip
                          variant="solid"
                          color="neutral"
                          size="sm"
                          style={{ color: "#777AD6", background: "#F5ECFF" }}
                        >
                          {item.job_type.replace("_", " ")}
                        </Chip>

                        <Chip
                          variant="solid"
                          color="neutral"
                          size="sm"
                          icon={<LocationOnIcon />}
                          style={{
                            color: "#081753",
                            background: "rgba(8, 23, 83, 0.07)",
                          }}
                        >
                          {item?.location?.length <= 17
                            ? item?.location
                            : item?.location?.slice(0, 35) + ".."}
                        </Chip>
                        <Chip variant="soft" color="neutral" size="sm">
                          {item.min_exp}-{item.max_exp}
                          &nbsp; Yrs
                        </Chip>
                        <Chip
                          variant="soft"
                          color="neutral"
                          size="sm"
                          style={{ background: "#F2F8F6", color: "#07A996" }}
                        >
                          {getSymbolFromCurrency(item.currency)}
                          {Math.round(item.min_sal / 1000, 2)}k-
                          {Math.round(item.max_sal / 1000, 2)}k
                        </Chip>
                        <Chip
                          variant="soft"
                          color="neutral"
                          size="sm"
                          style={{ background: "#F2F8F6", color: "#07A996" }}
                        >
                          {item.work_mode.replace("_", " ")}
                        </Chip>
                      </div>
                    </div>
                    <div className="skills_section" style={{ paddingLeft: "10px",paddingTop:"10px"}}>
                      <ul
                        className="skill_main_section"
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#121212",
                          opacity: "50%",
                          fontFamily: "Inter,sans-serif",
                        }}
                      >
                        {item.key_skill_details?.slice(0, 3).map((each) => (
                          <li
                            key={each.id}
                            className="typography-field"
                            style={{ paddingRight: "10px" }}
                          >
                            {each.name}ReactJS
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyBasedDrawJob;
