import callApi from "../../callApi";

export const allCategories = async () => {
  return await callApi("/blogs/get_categories");
};
export const getAllBlogs = async ({ category_name }) => {
  return await callApi(`/blogs/blogs?category_name=${category_name}`);
};
export const getBlogsByCategory = async category_id => {
  return await callApi(`/blogs/blogbycategory_id/${category_id}`);
};
export const getBlogsBasedOnId = async blog_id => {
  return await callApi(`/blogs/blog/${blog_id}`);
};
