import React from "react";
import RelatedPosts from "./RelatedPosts";

function BlogsDescription({ blogData, description }) {
  return (
    <>
      <div className="single-body">
        <figure className="mb-30">
          <img src={blogData?.image} alt="blogs" />
        </figure>
        <div
          style={{ listStyle: "initial" }}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        <RelatedPosts />
      </div>
    </>
  );
}
export default BlogsDescription;
