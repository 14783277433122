import React ,{useState} from 'react';
import { Col, Row } from "reactstrap";
import website from '../../../assets/images/website.png';
import websiteLogo from '../../../assets/images/websiteLogo.png';
import GoogleReviews from '../../../assets/images/GoogleReviews.png';
import GlassdoorReview from '../../../assets/images/GlassdoorReview.png';
import AmbitionLogo from '../../../assets/images/AmbitionLogo.png';
import ComparablyLogo from '../../../assets/images/ComparablyLogo.png';
import PayscaleLogo from '../../../assets/images/PayscaleLogo.png';
import CarrerLogo from '../../../assets/images/CarrerLogo.png';
import reviews from '../../../assets/images/reviews.png';
import Comparably from '../../../assets/images/comparably.svg';
import carrerBliss from '../../../assets/images/carrerBliss.svg';
import glassDoor from '../../../assets/images/Glassdoor.svg';
import payScale from '../../../assets/images/Payscale.svg';
import googleReview from '../../../assets/images/reviews2.svg';

function OverViewPage3({cProfile}){
  const urlList = {
    website:cProfile?.company?.website?cProfile?.company?.website:"",
    google:cProfile?.company?.google_reviews?cProfile.company.google_reviews:"",
    glassdoor:cProfile?.company?.glassdoors_url?cProfile.company.glassdoors_url:"",
    ambition:cProfile?.company?.ambition_box_url?cProfile.company.ambition_box_url:"",
    compare:cProfile?.company?.comparably_url?cProfile.company.comparably_url:"",
    carrer:cProfile?.company?.careerbliss_url?cProfile.company.careerbliss_url:"",
    payscale:cProfile?.company?.payscale_url?cProfile.company.payscale_url:"",
  }
  const hasData = Object.values(urlList).some((value) => value !== "");
    return (
        <>
        <div className='overView_sec2'>
        <h2 className="benefits_txt">{cProfile?.company?.company_name?cProfile?.company?.company_name:"NA"}{" "}Reviews</h2>
        <div className="comp_about_card">
          {hasData ?
          <Row
            className="user-desc"
            xl="5"
            lg="4"
            md="3"
            sm="3"
            xs="2"
            sx={{ padding: "40px",}}
          >
            {urlList.website?
            <Col xs={4} md={4} className="about_comp_details">
              <div className='web_cont'>          
              <a href={`${urlList.website}`} target="_blank"><img src={websiteLogo} className="website_img"/></a>
              </div>
            </Col>:""
            }
            {urlList.google?
            <Col xs={4} md={4} className="about_comp_details">
              <div className='web_cont'>
              <a href={`${urlList.google}`} target="_blank"><img src={GoogleReviews} className="website_img"/></a>
              </div>
            </Col>:""
           }
           {urlList.glassdoor?
            <Col xs={4} md={4} className="about_comp_details">
               <div className='web_cont'>
               <a href={`${urlList.glassdoor}`} ><img src={GlassdoorReview} className="website_img" /></a>
              </div>
            </Col>:""
            }
            {urlList.ambition?
            <Col xs={4} md={4} className="about_comp_details">
            <div className='web_cont'>
            <a href={`${urlList.ambition}`} ><img src={AmbitionLogo} className="website_img"/></a>
            </div>
            </Col>:""
            }
            {urlList.compare?
            <Col xs={4} md={4} className="about_comp_details">
            <div className='web_cont'>
               <a href={`${urlList.compare}`}><img src={ComparablyLogo} className="website_img" /></a>
              </div>
            </Col>:""
            }
            {urlList.carrer?
            <Col xs={4} md={4} className="about_comp_details">
           
            <div className='web_cont'>
               <a href={`${urlList.carrer}`}><img src={CarrerLogo} className="website_img" /></a>
              </div>
            </Col>:""}
            <Col xs={4} md={4} className="about_comp_details">
           
            <div className='web_cont'>
               <a href={`${urlList?.payscale}`}><img src={PayscaleLogo} className="website_img" /></a>
              </div>
            </Col>
          </Row>
          :<div style={{display:"flex",justifyContent:"center",alignItems:"center",paddingTop:"15px"}}>No data found</div>}
        </div>
      </div>
        </>
    )
}

export default OverViewPage3;