import React from 'react';
import comingSoonImage from '../assets/images/comingSoon.png'
import comingSoonImage2 from '../assets/images/ComingSoon2.png';
import comingSoonImage3 from '../assets/images/comingSoon3.png';
function ComingSoon(){
    return (
        <>
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
      <img src={comingSoonImage3} alt='comingsoon' style={{height:"500px"}}/>
      <h2 style={{fontFamily:"Inter",fontWeight:700,fontSize:"40px",color:"#024430"}}>Coming Soon</h2>
    </div>
        </>
    )
}

export default ComingSoon;