import React from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ComingSoon from '../../Reusable/comingSoon';

function BankDetails() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  return (
    <>
    <ComingSoon />
      {/* <div className="main_container">
        <h1 className="password_heading">Bank Details</h1>
        <p className="password_paragraph">
          Please enter your bank details here
        </p>
        <Box
          component="form"
          // onSubmit={handleSubmit(changeEmail)}
          // noValidate
          sx={{ mt: 1 }}
          className="form_container"
        >
          <div className="bank_card_cont">
            <p>Bank Name</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                label="Bank Name"
                name="email"
                type="text"
                autoComplete="email"
                autoFocus
                // error={errors.oldPassword ? true : false}
                {...register("bankName", { required: true })}
                InputProps={{
                  style: { borderRadius: "5px"},
                }}
              />
            </div>
          </div>
      
          <div className="bank_card_cont">
            <p>Account Holder Name</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                label="Holder Name"
                type="text"
                autoFocus
                // error={errors.oldPassword ? true : false}
                {...register("holderName", { required: true })}
                InputProps={{
                  style: { borderRadius: "5px" },
                }}
              />
            </div>
          </div>

          <div className="bank_card_cont">
            <p>Account Number</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                label="Account Number"
                type="number"
                autoFocus
                // error={errors.oldPassword ? true : false}
                {...register("accountNumber", { required: true })}
                InputProps={{
                  style: { borderRadius: "5px" },
                }}
              />
            </div>
          </div>
          <div className="bank_card_cont">
            <p>IFSC Code</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                label="IFSC Code"
                type="number"
                autoFocus
                // error={errors.oldPassword ? true : false}
                {...register("ifsc", { required: true })}
                InputProps={{
                  style: { borderRadius: "5px" },
                }}
              />
            </div>
          </div>
          <div className="bank_card_cont">
            <p>Branch</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                label="Branch"
                type="text"
                autoFocus
                // error={errors.oldPassword ? true : false}
                {...register("branch", { required: true })}
                InputProps={{
                  style: { borderRadius: "5px" },
                }}
              />
            </div>
          </div>
 
          <div
            className="d-flex justify-content-center submit-button mt-4"
          
          >
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                type="submit"
                style={{
                  background: "#081753",
                  color: "#ffffff",
                  textTransform: "capitalize",
                }}
              >
                Update
              </Button>
            </Stack>
          </div>
        </Box>
      </div> */}
    </>
  );
}

export default BankDetails;
