import React from "react";

function UpcomingInterviews() {
    const data = [
        {
            id: 1,
            img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1661766145/istockphoto-1348968807-170667a_ay9kvz.jpg",
            name: "Kmc",
            designation: "Software developer",
            time: "12:00 - 1:00"
        },
        {
            id: 2,
            img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1660220048/pic2_mkait2.jpg",
            name: "Kmc",
            designation: "Software developer",
            time: "12:00 - 1:00"
        },
        {
            id: 3,
            img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1657728982/profile_uumsbr.png",
            name: "Kmc",
            designation: "Software developer",
            time: "12:00 - 1:00"
        },
        {
            id: 4,
            img: "https://res.cloudinary.com/dt6u679fs/image/upload/v1650879044/avatar-4_goanjj.jpg",
            name: "Kmc",
            designation: "Software developer",
            time: "12:00 - 1:00"
        }
    ]
    return (
        <>
            {/* <h4 className="about-me mb-3">Dashboard</h4> */}
            <div className="recent-activities">
                <div className="about-me mb-3">Upcoming Interviews</div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Profile</th>
                            <th scope="col">Name</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item,index) =>
                                <tr key={index}>
                                    <th>
                                        <img src={item.img} style={{ borderRadius: "10px", width: "30px" }} alt="interviews" />
                                    </th>
                                    <td>{item.name}</td>
                                    <td>{item.designation}</td>
                                    <td>{item.time}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default UpcomingInterviews