import React from "react";

function AreHiring() {
    return (
        <>
            <div className="sidebar-normal">
                <div>
                    <h6 className="small-heading">Are you also hiring?</h6>
                    <ul className="ul-lists">
                        <li><span>Writing & Translation</span></li>
                        <li><span>Video & Animation</span></li>
                        <li><span>Music & Audio</span></li>
                        <li><span>Digital Marketing</span></li>
                        <li><span>Business</span></li>
                        <li><span>Programming & Tech</span></li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default AreHiring;