import React , {useState} from 'react';
import EmployerPage1 from './EmployerPage1';
import EmployerPage2 from './EmployerPage2';
import EmployerPage3 from './EmployerPage3';
import EmployesPage4 from './EmployersPage4';
import EmployersPage5 from './EmployersPage5';
import EmployerPage6 from './EmployersPage6';
import BlogsList from '../ReferNowPage/BlogsPage';
import CompaniesScrollPage from '../ReferNowPage/CompaniesPage';
import Footer from '../../layouts/footer/Footer'
import './EmployerPage.css';

function EmployersIndex (){
    return (
        <>
        <EmployerPage1 />
        <EmployerPage2 />
        <EmployersPage5 />
        <EmployerPage3 />
        <EmployesPage4 />
        <EmployerPage6 />
        <BlogsList />
        <CompaniesScrollPage />
        <section style={{paddingTop:"5%"}}>
          <Footer />
        </section>
        </>
    )
}

export default EmployersIndex;