import React, { useState, useReducer,useEffect } from "react";
import "./register.css";
import { signup } from "../helpers/dataFetcher/profile/profile";
// import signUpImage from "../assets/images/signUp.png";
import signUpImage from "../assets/images/loginPage.png";
// import Button from "@mui/joy/Button";
import Button from '@mui/material/Button';
import { Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import OtpVerificationModal from "./OtpVerificationModal";
import { MuiTelInput } from "mui-tel-input";
import { Input, OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styled from "@mui/material/styles/styled";
import Popover from "@mui/material/Popover";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUserAction } from "../store/auth/actions";
import { useRef } from "react";


import "./login.css";
import {
  getUserByToken,
  getUserWithToken,
  login,
} from "../helpers/dataFetcher/profile/profile";

import google from "../assets/images/google.png";
import linkedinButtonImage from "../assets/images/linkedinButtonImage.png";
import linkedinImageButton from "../assets/images/linkedinImageButton.png";
import googleButtonImage from "../assets/images/googleButtonImage.png";
import linkedin from "../assets/images/linkedin2.png";

import { ErrorOutlineOutlined } from "@mui/icons-material";
import { CssVarsProvider } from "@mui/joy/styles";
import CircularProgress from "@mui/joy/CircularProgress";

import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

import { useLinkedIn } from "react-linkedin-login-oauth2";
import { linkedinDeatilsWithToken } from "../helpers/dataFetcher/auth/linkedin";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import getUserDetailsFromGoogle from "../helpers/dataFetcher/auth/google";
import {globalTheme} from '../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";


/* Mui styles and design start for tabs API and Pop over API Start */
const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    borderColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px !important",
    transitionDelay:"0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 600,
      fontSize: 16,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },  
    "& input": {
        padding: "12px 14px", 
        borderRadius: 8,
      },
      "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth:"1px !important",
        borderColor:"#081753 !important"
      }
  
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 16,
  textTransform: "none",
  "&:first-letter": {
    textTransform: "capitalize",
  }, 
}));

const StyledPopover = styled(Popover)({
  "& .MuiPaper-root.MuiPopover-paper": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
});

const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  fontFamily: "Inter,sans-serif",
  "& input::placeholder": {
    fontFamily: "Inter , sans-serif !important",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    color: "rgba(0, 0, 0, 0.5)",
    borderRadius: 8,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* Mui styles and design start for tabs API and Pop over API End */

function SignIn() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedButton, setSelectedButton] = useState(null);
    
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = React.useState(0);
  const [phone, setPhone] = React.useState("");
  const [age, setAge] = React.useState("");

  const [userType, setUserType] = useState("US");

  const handleDescChange = event => {
    setAge(event.target.value);
  };

  /* Sign in for candidates functionality OR Integration start */
  const [loginStatus, setLoginStatus] = useState(false);
  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => getUserDetailsFromGoogle(codeResponse),
    onError: error => console.log("Login Failed:", error),
  });
  const {
    register,
    handleSubmit,
    // setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [divClick, setDivClick] = useState(false);
  const [passwordClick, setPasswordClick] = useState(false);
  const usePasswordRef = useRef(null);
  const ref = useRef(null);
  const [id, setId] = useState("");
  
  let id1;
  async function setUserDetailsForActions(
    loginObj,
    type = "normal",
    loginType = "normal"
  ) {
    navigate("/");
    localStorage.setItem("token", loginObj.access);
    localStorage.setItem("user_id", loginObj.id);
    localStorage.setItem("user_type", loginObj.usertype);

    const { status: userStatus, data: userData } = await getUserWithToken({
      Authorization: "Bearer " + loginObj.access,
    });
    if (userStatus == 200) {
      dispatch(
        addUserAction({
          ...userData?.data,
          access: loginObj.access,
          type: type,
          loginType: loginType,
        })
      );
    }
  }
  const handleClickShowPassword = () => {
      console.log(showPassword,"show")
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        // setDivClick(false);
      } else {
        setError(false);
        setDivClick(true);
      }
      if (
        usePasswordRef.current &&
        !usePasswordRef.current.contains(event.target)
      ) {
        // setPasswordClick(false);
      } else {
        setError(false);
        setPasswordClick(true);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const loginFunc = async data => {
    setLoginStatus(true);
    const { status, data: loginObj } = await login({
      email: data.email,
      password: data.password,
    });
    if (status !== 200) {
      setError(true);
      toast.error("Invalid Credentials")
      setLoginStatus(false);
      return;
    }
    setLoginStatus(false);
    setUserDetailsForActions(loginObj);
    return;
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: "78yn8vmivtm2to",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: async code => {
      console.log(code);
      const { status, data: loginObj } = await login({
        email: "support@Shifyt.com",
        password: "123456",
        login_type: "LinkedIn",
        linkedIn_token: code,
      });
      if (status !== 200) {
      } else {
        setUserDetailsForActions(loginObj, "external", "linkedin");
      }
    },
    scope: "r_emailaddress r_liteprofile",
    onError: error => {
      console.log(error);
    },
  });

  /* Sign in for candidates functionality OR Integration End */

  /* Pop over functionality start mui */
  const handleChangeTabs = (_, newValue) => {
    setUserType(newValue);
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElCandidates, setAnchorElCandidates] = useState(null);
  const [anchorElPartner, setAnchorElPartner] = useState(null);

  const handleCandidatesPopoverOpen = event => {
   
    setAnchorElCandidates(event.currentTarget);
  };

  const handleCandidatesPopoverClose = () => {
    setAnchorElCandidates(null);
  };

  const handlePartnerPopoverOpen = event => {

    setAnchorElPartner(event.currentTarget);
  };

  const handlePartnerPopoverClose = () => {
    setAnchorElPartner(null);
  };

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //    const open = Boolean(anchorEl);
  const openCandidates = Boolean(anchorElCandidates);
  const openPartner = Boolean(anchorElPartner);

  /* Pop over functionality End mui */

//   const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleButtonClick = buttonValue => {
    setSelectedButton(buttonValue === selectedButton ? null : buttonValue);
  };

  return (
    <>
    <ThemeProvider theme={globalTheme}>
      <div className="signup_layout">
        <div className="signup_main_section">
          <div className=" col-lg-5">
            <div className="signup_part1">
              <div>
                <h2 className="signup_heading_section">
                  The most efficient platform to easily
                </h2>
                <span className="signup_span_section">
                  Apply jobs, Refer candidates
                </span>
                <h2 className="signup_heading_section">talented people</h2>
              </div>
              <img src={signUpImage} className="signup_img" />
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-7 checking">
            <div className="signup_part2">
              <div className="sign_head_txt">
                Sign {" "}in
                <span className="sign_head_txt2">{"  "}Here</span>
              </div>

              <Box sx={{ width: "100%" }}>
                <Form onSubmit={handleSubmit(loginFunc)}>
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: "8px",
                      width: "88%",
                      marginLeft: "3%",
                      marginTop: "5%",
                      height: "50px",
                      "@media (max-width: 576px)": {
                        width: "100%",
                      },
                    }}
                  >
                    <Tabs
                      value={userType}
                      onChange={handleChangeTabs}
                      className={classes.root}
                      aria-label="basic tabs example"
                      sx={{
                        ".MuiTabs-flexContainer": {
                          justifyContent: "space-between",
                        },
                        ".MuiTabs-indicator":{
                            backgroundColor:"#cde2e7"
                        },
                        ".MuiTab-root.Mui-selected ": {
                            color:"#066a4c"
                        },
                      }}
                    >
                      <StyledTab
                        label="Candidates"
                        value="US"
                        sx={{
                          color: userType === "US" ? "#066a4c" : "#000000",
                          background: userType === "US" ? "#cde2e7" : "#FFFFFF",
                          height: 48,
                          fontSize: 16,
                          borderRadius: 2,
                          border: "0px solid rgba(0, 0, 0, 0.15)",
                          transitionDelay: "0.3s"
                        }}
                        {...a11yProps(0)}
                        aria-owns={open ? "candidates-over-popover" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handleCandidatesPopoverOpen}
                        onMouseLeave={handleCandidatesPopoverClose}
                      />

                      <StyledTab
                        label="Company"
                        value="CA"
                        sx={{
                          color: userType === "CA" ? "#066a4c" : "#000000",
                          background: userType === "CA" ? "#cde2e7" : "#FFFFFF",
                          height: 48,
                          fontSize: 16,
                          borderRadius: 2,
                          border: "0px solid rgba(0, 0, 0, 0.15)",
                          transitionDelay: "0.3s"

                          
                        }}
                        {...a11yProps(1)}
                        
                        onClick={() => {
                            window.location.href = "https://app.Shifyt.com/login?user_type=CA";
                        }}
                      />

                      <StyledTab
                        label="Partner"
                        value="PL"
                        sx={{
                          color: userType === "PL" ? "#066a4c" : "#000000",
                          background: userType === "PL" ? "#cde2e7" : "#FFFFFF",
                          height: 48,
                          fontSize: 16,
                          borderRadius: 2,
                          border: "0px solid rgba(0, 0, 0, 0.15)",
                          transitionDelay: "0.3s"

                        }}
                        {...a11yProps(2)}
                        onClick={() => {
                            window.location.href = "https://app.Shifyt.com/login?user_type=PL";
                        }}
                        aria-owns={open ? "partner-over-popover" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePartnerPopoverOpen}
                        onMouseLeave={handlePartnerPopoverClose}
                      />

                      <StyledPopover
                        id="candidates-over-popover"
                        sx={{
                          pointerEvents: "none",
                        }}
                        open={openCandidates}
                        anchorEl={anchorElCandidates}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <div className="popup_main_cont">
                          <div className="popup_child_cont"></div>
                          <ul className="links_txt">
                            <li>&#x2022; Job Seeker</li>
                            <li>&#x2022; Referral Candidate</li>
                          </ul>
                        </div>
                      </StyledPopover>

                      <StyledPopover
                        id="partner-over-popover"
                        sx={{
                          pointerEvents: "none",
                        }}
                        open={openPartner}
                        anchorEl={anchorElPartner}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <div className="popup_main_cont2">
                          <div className="popup_child_cont2"></div>
                          <ul className="links_txt">
                            <li>&#x2022; Sreening Partner</li>
                            <li>&#x2022; Moderator</li>
                            <li>&#x2022; Technical Evaluator</li>
                            <li>&#x2022; Recruitment {""}Companies</li>
                            <li>&#x2022; BGV Verification</li>
                          </ul>
                        </div>
                      </StyledPopover>
                    </Tabs>
                  </Box>

                  <div className="form_main_container">
                    <div className="names_cont">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="names_inside_cont2">
                            <Label className="signup_label_txt">
                              Email Address
                            </Label>
                            <OutlinedInput
                              placeholder="Email"
                              className={classes.root}
                            //   style={{borderRadius:"8px"}}
                              fullWidth
                              id="email"
                            
                              name="email"
                              type="email"
                              // autoComplete="email"
                              autoFocus
                              error={errors.email ? true : false}
                              {...register("email", { required: true })}
                            />
                            <p style={{color:"red"}}>{errors.email ? "Email required" : null}</p>
                          </div>
                      
                      </div>
                    </div>

                    <div className="names_cont">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="names_inside_cont2">
                          <Label className="signup_label_txt">Password</Label>
                          <FormControl
                            sx={{ width: "100%" }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-password"
                              className={classes.root}
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              placeholder="Password"
                            //   required
                              fullWidth
                              name="password"
                              // id="password"
                              // autoComplete="current-password"
                              error={errors.password ? true : false}
                              {...register("password", { required: true })}
                            />
                            
                          </FormControl>
                       
                        </div>
                      </div>
                    </div>

                    <Button
                      variant="outlined"
                      type="submit"
                      disabled ={userType == "CA" || userType == 'PL'? true:false}
                      style={{
                        // background: "#081753",
                        // borderRadius: "8px",
                        // fontFamily: "Inter,sans-serif",
                        // fontWeight: "500",
                        fontSize: "16px",
                        // color: "#ffffff",
                        // fontStyle: "normal",
                        border:"0px",
                        color: "#066a4c",
                        background:`${userType == 'CA' || userType == 'PL'? "#cdddf7":"#cde2e7"}`
                      }}
                    >
                      Sign In
                    </Button>
                  </div>
                </Form>
              </Box>

              <div className="terms_txt">
                <p>
                  By clicking <span className="signup_span">"Sign in"</span> you
                  agree to{" "}
                  <span className="conditions_txt">Terms and Conditions</span>
                </p>
                <br />
                <p>
                  Don't have an account{" "}
                  <Link
                  href="#"
                    className="signin_txt"
                    to = "../registration"
                  >
                    Sign Up
                  </Link>
                </p>
                <br/>      
                <p>
                  Forgot your password{" "}
                  <Link
                  href="#"
                    className="signin_txt"
                    to="../forget-password"
                  >
                    Click Here
                  </Link>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
      </ThemeProvider>
    </>
  );
}

export default SignIn;
