import React from "react";
import VerticalChrono from "./VerticalChrono";
import VerticalChrono2 from "./VerticalChrono2";
import VerticalChronoSection from "./VerticalChrono3";

function Chrono() {
  return (
    <>
      <div style={{}} className="hire_candidates_card_section_layout">
        <section className="Shiyft_main_section">
          <div className="Shiyft_work_section">
            <h2 className="Shiyft_txt">
              Hire The Best Fit in Just <span className="Shiyft_span">5</span>{" "}
              Days!
            </h2>
            <p className="Shiyft_para_txt">
              We offer a comprehensive solution to streamline your entire hiring
              process enabling <br />
            </p>
            <p className="Shiyft_para_txt">
              {" "}
              you to effortlessly post jobs, receive referrals and hire top
              talent.
            </p>
            <h2 className="Shiyft_txt2">
              Other platforms <span className="Shiyft_span"></span>
            </h2>
          </div>

          <VerticalChrono2 />
          {/* <VerticalChronoSection /> */}
        </section>
      </div>
    </>
  );
}

export default Chrono;
