import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReferNowModal } from "../JobsList/Alljobs/ReferModal";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy";
import moment from "moment";
import { imageURL } from "../../Constants/commonURLS";
import Stack from "@mui/material/Stack";
import EarnImage from "../../assets/images/earnImage.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import CardOverflow from "@mui/joy/CardOverflow";
// import Divider from "@mui/joy/Divider";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import JobInsights from "./JobInsights";
import SkillsSection from "./SkillsSection";
import JobInfoDescription from "./JobInfoDescription";
import JobInfoRequirements from "./JobInfoRequirements";


function JobDescription(props) {


  const [ReferStatus, setReferStatus] = useState({
    modal: "",
    type: "",
    action: "",
    modalType: "",
  });

  const [referModal, setReferModal] = useState({
    modal: "",
    purpose: "",
    modalType: "",
  });
  const user = useSelector(state => state.User.user);
 


 

  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    return `${scaledNumber.toFixed(0)}k`;
  };

  let token = localStorage.getItem("token");


  return (
    <>
      {props.jobDetails && (
        <div className="job_card_sec">
          <Box sx={{paddingBottom:"20px"}}>
            <Card
              variant="outlined"
              sx={theme => ({
                // width: 300,
                width: "100%",
                gridColumn: "span 2",
                flexDirection: "row",
                flexWrap: "wrap",
                resize: "horizontal",
                overflow: "hidden",
                gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
                transition: "transform 0.3s, border 0.3s",
                border: 0,
                // "&:hover": {
                //   borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                //   transform: "translateY(-2px)"
                // },
                "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  // maxWidth: 300
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    mt: "auto",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Avatar
                      variant="soft"
                      color="neutral"
                      src={`${imageURL}${props.jobDetails?.company_details?.company_image}`}
                      style={{
                        borderRadius: "50%",
                        borderColor: "rgba(0, 0, 0, 0.25)",
                        border: "0.5px solid",
                      }}
                    >
                      {props.jobDetails?.company_details?.company_name.charAt(0)}
                    </Avatar>
                    <div style={{ paddingLeft: "8px" }}>
                      <Typography level="body2" className="job_title_txt">
                        {props.jobDetails?.job_title?.length <= 20
                              ? props.jobDetails?.job_title
                              : props.jobDetails?.job_title?.slice(0, 18) + ".."}
                      </Typography>
                      <Typography
                        fontWeight="lg"
                        level="body2"
                        className="company_name_text"
                      >
                        <span className="by_span">by</span>{" "}
                        {props.jobDetails?.company_details?.company_name}{" "}
                        <span className="by_span">in</span>{" "}
                        {/* <span className="dept_span">Development & IT{props.company?.user_industry_details[0]?.name}</span> */}
                        {props.company?.user_industry_details?.slice(0,1).map((each)=> <span className="by_span">{each.name}</span>)}
                      </Typography>
                    </div>
                  </div>

                  <div
                    className="commission_sec"
                    style={{ paddingRight: "40px" }}
                  >
                    <Stack direction="row" spacing={2}>
                      <Avatar alt="Remy Sharp" src={EarnImage} />
                    </Stack>
                    <div className="title_sec_divider">
                      <Typography
                        fontWeight="md"
                        textColor="primary.plainColor"
                        mb={0.5}
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#121212",
                          fontFamily: "Inter,sans-serif",
                        }}
                      >
                        Earn Upto
                      </Typography>
                      <Typography
                        fontWeight="md"
                        textColor="primary.plainColor"
                        mb={0.5}
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "13px",
                          fontWeight: "Bold",
                          color: "#066a4c !important",
                          // opacity: "50%",
                          fontFamily: "Inter,sans-serif",
                        }}
                      >
                        {getSymbolFromCurrency(props.jobDetails?.currency)}{" "}
                        {abbreviateNumber(
                          (props.jobDetails?.max_sal *
                            props.jobDetails?.commission_rate) /
                            100
                        )}{" "}
                      </Typography>
                    </div>
                  </div>
                </Box>
                {/* <Divider /> */}
                <CardOverflow
                  // variant="soft"
                  sx={{
                    display: "flex",
                    gap: 1.5,
                    width: "100%",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // py: 1.5,
                    // px: "var(--Card-padding)"
                    // bgcolor: 'background.level1',
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      level="body3"
                      sx={{ fontWeight: "md", color: "text.secondary" }}
                    >
                      <span className="details_list_key"> Posted:</span>
                      <span className="details_list_val">
                        {" "}
                        {Math.floor(
                          Math.abs(
                            new Date() -
                              new Date(
                                props.jobDetails?.createdAt
                                  ?.slice(0, 10)
                                  .toLocaleString()
                                  .replace(/-/g, "/")
                              )
                          ) /
                            (1000 * 3600 * 24)
                        )}{" "}
                        Days ago
                      </span>
                    </Typography>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "auto !important" }}
                    />
                    <Typography
                      level="body3"
                      sx={{ fontWeight: "md", color: "text.secondary" }}
                    >
                      <span className="details_list_key">Openings:</span>
                      <span className="details_list_val">
                        {props.jobDetails?.no_of_vacancies}
                      </span>
                    </Typography>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "auto !important" }}
                    />
                    <Typography
                      level="body3"
                      sx={{ fontWeight: "md", color: "text.secondary" }}
                    >
                      <span className="details_list_key">
                        Job Applications:
                      </span>
                      <span className="details_list_val">
                        {props.jobDetails?.applicants_count
                          ? props.jobDetails.applicants_count
                          : "NA"}
                      </span>
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                    }}
                  >
                    <Button
                      style={{ 
                      //   backgroundColor: "#081753", 
                      //  color: "#ffffff" ,
                      background: "#ffe7bb",
                      color: "#066a4c",
                       
                     }}
                      variant="solid"
                      className="refer-now-btn cursor-pointer "
                      startDecorator={
                        <GroupAddIcon className="refer_btn_icon" />
                      }
                      onClick={() => {
                        if (
                          user.type !== undefined &&
                          user.type !== null &&
                          user.type !== ""
                        )
                          setReferModal({
                            modal: "center",
                            details: props.jobDetails,
                            purpose: "refer",
                          });
                        else {
                          navigate("/login");
                        }
                      }}
                    >
                      Refer Now
                    </Button>

                    <Button
                      variant="outlined"
                      className="apply-now-btn cursor-pointerf"
                      startDecorator={
                        <GroupAddIcon className="refer_btn_icon" />
                      }
                      style={{
                        color: "#000000",
                        // border: "1px solid rgba(8, 23, 83, 0.1)",
                        background: "#cde2e7",
                        color: "#066a4c",
                        border:"0px"
                      }}
                      onClick={() =>
                        setReferModal({
                          modal: "center",
                          details: props.jobDetails,
                          purpose: "self",
                        })
                      }
                    >
                      {/* Apply Self */}

                      {token == null
                            ? "Apply Self"
                            : props?.jobDetails?.is_candidate_applied_to_job_post == 0
                            ? "Apply Self"
                            : "Applied"}
                    </Button>
                  </div>
                </CardOverflow>
              </Box>
            </Card>
            <Divider
              orientation="horizontal"
              sx={{ height: "auto !important" }}
            />
          </Box>
        </div>
      )}

      <ReferNowModal
        show={referModal.modal}
        purpose={referModal.purpose}
        onHide={modal =>
          setReferModal({
            modal: modal,
            details: {},
          })
        }
        params={referModal}
      />
      <JobInsights jobDetails={props.jobDetails}/>
      <SkillsSection jobDetails={props.jobDetails}/>
      <JobInfoDescription jobDetails={props.jobDetails}/>
      <JobInfoRequirements jobDetails={props.jobDetails}/>
    </>
  );
}

export default JobDescription;
