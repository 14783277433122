export const covertProfile = data => {
  return {
    id: data.id,
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    user_name: data.user_name,
    usertype: data.usertype,
    phone_number: data.phone_number,
    languages: data.languages,
    // location: data.location,
    location: data.current_address,
    city: data.city,
    state: data.state,
    country: data.country,
    dob: data.dob,
    postal_code: data.postal_code,
    gender: data.gender,
    about: data.about,
    profile_image: data.image,
    resume_url: data.resume,
    skill_details: data.skill_details,
    age_of_candidate:data?.age_of_candidate,
    work_exp_details: data.work_exp_details,
    education_training_details: data.education_training_details,
    // job_role:data?.job_role[0]?data.job_role[0]:"",
    job_role:data.job_role?data.job_role[0]:"",
    notice_period:data?.notice_period?data.notice_period:"",
    // current_ctc:data?.current_ctc?data.current_ctc:"",
    // expected_ctc:data?.expected_ctc?data.expected_ctc:"",
    current_salary:data?.current_salary?data?.current_salary:"",
    expected_salary:data?.expected_salary?data?.expected_salary:"",

    work_mode:data?.work_mode?data.work_mode:"",

    visa_details:data?.visa_details?data.visa_details:"",
    current_company:data?.current_company?data.current_company:"N/A",
    current_department:data?.current_department?data.current_department:"N/A",
    current_industry:data?.current_industry?data.current_industry:"N/A",
    current_postition:data?.current_postition?data.current_postition:"N/A",
    work_location:data?.work_location?data.work_location:"N/A",
    other_contact:data?.other_contact?data.other_contact:"N/A",
    experience:data?.experience?data.experience:"N/A",
    nationality:data?.nationality?data.nationality:"N/A",
    // languages:data?.languages[0]?data.languages[0]:"N/A",
    work_exp:data?.work_exp,
    courses:data?.courses
  };
};
