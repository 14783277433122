import React  from 'react';
// import noJobs from "../assets/images/no_jobs.svg";
import noJobs from '../assets/images/nojobsShiyft.png';
// import startChat from "../assets/images/start_chat.svg";
import startChat from '../assets/images/startChat.png';

function NoDataFoundComponent(){
    return(
        <><div
        style={{
          display: "grid",
          placeItems: "center",
          height: "100%",
          width: "100%",
          marginTop:"70px"
        }}
      >
        <div className="d-flex align-items-center flex-column">
          <img
            src={startChat}
            style={{ width: "300px", height: "300px" }}
            alt="no jobs"
          />
          <p>No Messages Found</p>
        </div>
      </div></>
    )
}

export default NoDataFoundComponent;