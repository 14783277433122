import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import website from "../../../assets/images/website.png";
import websiteLogo from "../../../assets/images/websiteLogo.png";
import GoogleReviews from "../../../assets/images/GoogleReviews.png";
import GlassdoorReview from "../../../assets/images/GlassdoorReview.png";
import AmbitionLogo from "../../../assets/images/AmbitionLogo.png";
import ComparablyLogo from "../../../assets/images/ComparablyLogo.png";
import PayscaleLogo from "../../../assets/images/PayscaleLogo.png";
import CarrerLogo from "../../../assets/images/CarrerLogo.png";
import reviews from "../../../assets/images/reviews.png";
import Comparably from "../../../assets/images/comparably.svg";
import carrerBliss from "../../../assets/images/carrerBliss.svg";
import glassDoor from "../../../assets/images/Glassdoor.svg";
import payScale from "../../../assets/images/Payscale.svg";
import googleReview from "../../../assets/images/reviews2.svg";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import CompanyJobsList from "./companyJobsList";
import noJobs from "../../../assets/images/nojobsShiyft.png";

function OverviewJobs({list}) {

  return (
    <>
    <h2 className="benefits_txt" style={{marginTop:"20px"}}>Jobs</h2>
        {list?.length > 0 ? (
              <CompanyJobsList lists={list} />
       
            ) : (
              <div className="d-flex align-items-center flex-column">
                <img
                  src={noJobs}
                  style={{ width: "300px", height: "300px" }}
                  alt="no jobs"
                />
                <p>No Jobs Found</p>
              </div>
            )}
    </>
  );
}

export default OverviewJobs;
