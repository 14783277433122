import React from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/joy/Chip";
import getSymbolFromCurrency from "currency-symbol-map";
import Divider from "@mui/material/Divider";

function JobInfoRequirements(props) {
  return (
    <>
      <div className="insights_sec pt-4">
        <Typography className="job_insights">Job Requirements</Typography>
        {props.jobDetails?.job_desc?.length >0 ?
        <div className="jobs_title_card pb-5">
          <div
            // className="job_description_contenet"
            dangerouslySetInnerHTML={{ __html: props.jobDetails?.requirements }}
          ></div>
        </div>
        :"No data Found"}
      </div>
      <Divider orientation="horizontal" sx={{ height: "auto !important" }} />
    </>
  );
}

export default JobInfoRequirements;
